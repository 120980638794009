import React, { useState, useEffect} from 'react';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { CFormGroup, CLabel} from '@coreui/react'
import Select from 'react-select';
import { ErrorMessage } from 'react-hook-form';

function SelectorTipoAreaTHRHF(props) {
    const {
        seleccionadoId,
        iniciar, // boolean: debe cambiar para permitir cargar la info cada vez que se visualiza, ejemplo: las modal
        cargado, // function: una vez que llego la información podemos retornar todos los datos(array)
        opcionTodos, // boolean: agregar opción todos con value vacio
        opcionGeneral, // boolean: agregar opción general con value general
        onChange, // function: devuelve el valor seleccionado { value: 1, label: 'Texto' }
        placeholder, // título
        Controller, // Props React-hook-form
        errors,
        control, // Props React-hook-form
        setValue, // Props React-hook-form para setear dato
        name // nombre del componente para obtener la información
    } = props;
    const [informacion, setInformacion] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(
        () => {
            function loadData() {
                api.tipoArea.get({}).then(
                    resultado => {
                        if (resultado && resultado.status === 200) {
                            let temporal  = [];
                            // if (opcionTodos) temporal.push({ value: '', label: 'Todos' })
                            // if (opcionGeneral) temporal.push({ value: 'general', label: 'General' })
                            resultado.data.datos.forEach(dato => {
                                temporal.push({ value: dato.id, label: dato.nombre, unidades: dato.unidades })
                            });
                            setInformacion(temporal)
                            if (cargado) cargado(resultado.data.datos)
                            setLoading(false)
                        } else {
                            let temporal = [];
                            // if (opcionTodos) temporal.push({ value: '', label: 'Todos' })
                            // if (opcionGeneral) temporal.push({ value: 'general', label: 'General' })

                            setInformacion(temporal);
                            toast.error('Error al cargar');
                            setLoading(false)
                        }
                    }
                )
            }
            if (informacion.length === 0 && iniciar) { // Loading = false, solo para cargar una vez
                loadData();
            } else {
                setInformacion([]);
            }
        },
        [iniciar]
    )

    useEffect(
        () => {
            if (informacion.length > 0) {
                let nombreSelecionado = ''
                informacion.forEach(dato => {
                    if (seleccionadoId && parseInt(dato.value) === parseInt(seleccionadoId)) {
                        nombreSelecionado = dato.label;
                    }
                });
                if (seleccionadoId && nombreSelecionado) {
                    setValue(name, { value: seleccionadoId, label: nombreSelecionado }) // seteamos el valor
                    if (onChange) {
                        informacion.forEach(info => {
                            if (info.value === seleccionadoId) {
                                onChange(info)
                            }
                        });
                    }
                }
                // else if (seleccionadoId === 'general') {
                //     setValue(name, { value: 'general', label: 'General' })
                // }
            }
        },
        [informacion, seleccionadoId, setValue, name]
    )

    return (
        <>
            <CFormGroup>
                <CLabel htmlFor="appendedInputButton">Tipo de Área</CLabel>
                <Controller
                    as={<Select
                        placeholder={placeholder ? placeholder : ''}
                        isClearable={true}
                        isLoading={loading}
                        options={informacion}
                    />}
                    control={control}
                    rules={{ required: 'Debe seleccionar una opción' }}
                    onChange={([selected]) => {
                        if (onChange) {
                            if (selected) {
                                informacion.forEach(info => {
                                    if (info.value === selected.value) {
                                        onChange(info)
                                    }
                                });
                            } else {
                                onChange(selected)
                            }
                        }
                        return selected;
                    }}
                    name={name}
                />
                {errors &&
                    <ErrorMessage errors={errors} name={name} as="div" className="invalid-feedback" />
                }
            </CFormGroup>
        </>
    );
}

export default SelectorTipoAreaTHRHF;