import React, { useEffect, useState, useMemo } from 'react';
import { CCol, CRow, CButton, CNav, CNavItem, CNavLink, CTabPane, CTabContent } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, separadorDeMiles } from '../../componentes/GrillaBase';
import { useParams } from 'react-router-dom';
import { FaPlus, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { ContextMenu, MenuItem } from 'react-contextmenu';
import ModalEliminar from '../administracion/ModalEliminar'

const GrillaInfraestructurasAsp = () => {
  let history = useHistory();
  let { area_protegida_id } = useParams();
  const columns = [
    { key: 'nombre', name: 'Nombre Infraestructura', formatter: LongTextFormatter, minWidth: 170 },
    { key: 'sector_nombre', name: 'Sector', formatter: LongTextFormatter, width: 100 },
    { key: 'area_nombre', name: 'Área', formatter: LongTextFormatter, width: 100 },
    { key: 'tipo_instalacion_nombre', name: 'Instalación Turística', formatter: LongTextFormatter, width: 150 },
    {
      key: 'unidades', name: 'Unidades Instalación', minWidth: 250,
      summaryFormatter() {
        return <div style={{ textAlign: 'right' }}><strong>Total</strong></div>;
      }
    },
    {
      key: 'costo_anual_mantencion', name: 'Anual Mantención', formatter: NumberFormatter, width: 115,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.costo_anual_mantencion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'costo_anual_operacion', name: 'Anual Operación', formatter: NumberFormatter, width: 110,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.costo_anual_operacion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'monto_inversion', name: 'Estimado Inversión', formatter: NumberFormatter, width: 120,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.monto_inversion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
  ];

  const [rows, setRows] = useState([]);
  const [areaProtegida, setAreaProtegida] = useState([]);
  const [activeTab, setActiveTab] = useState('información');
  const [msgEliminar, setMsgEliminar] = useState('');
  const [showEliminarInfraestructura, setShowEliminarInfraestructura] = useState(false);  // Modal para eliminar usuario
  const [infraestructura, setInfraestructura] = useState({});

  const summaryRows = useMemo(() => {
    const summaryRow = [];
    return [summaryRow];
  }, [rows]);

  useEffect(
    () => {
      if (area_protegida_id){
        obtenerDatos()
        obtenerAreaProtegida()
      }
    },
    [area_protegida_id]
  )

  async function obtenerDatos() {
    let resultado = await api.areaProtegida.infraestructurasAsp(area_protegida_id);

    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  async function obtenerAreaProtegida() {
    let resultado = await api.areaProtegida.getOne(area_protegida_id);
    if (resultado && resultado.status === 200) {
      setAreaProtegida(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  function agregarInfraestructura() {
    history.push(`/area-protegida/${area_protegida_id}/infraestructura/crear`)
  }

  const menuEliminar = (e, { rowIdx }) => {
    rows.forEach(element => {
      if (element.id === rows[rowIdx].id) {
        setInfraestructura(element)
        setMsgEliminar('¿Estás seguro de eliminar la infraestructura: ' + element.nombre + '? ');
        setShowEliminarInfraestructura(true)
      }
    });
  }

  async function retornoEliminarInfraestructura() {
    let resultado = await api.infraestructura.delete(infraestructura.id);
    if (resultado && resultado.status === 200) {
      setInfraestructura({})
      obtenerDatos()
      toast.success('Infraestructura eliminada con éxito');
      setShowEliminarInfraestructura(false)
    } else {
      toast.error(resultado.data.msg);
      setShowEliminarInfraestructura(false)
    }
  }

  async function menuEditar(e, { rowIdx }) {
    history.push(`/area-protegida/${area_protegida_id}/infraestructura/${rows[rowIdx].id}`)
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          { active: true, pathname: '', name: 'Administración' },
          { active: false, pathname: `/infraestructurasAsp`, name: 'Infraestructura' },
          { active: true, pathname: '', name: areaProtegida.nombre }]
        }
      >
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab="preview" active={activeTab === 'información'} onClick={() => setActiveTab('información')}>
              Información
						</CNavLink>
          </CNavItem>

        </CNav>
        <CTabContent>
          <CTabPane data-tab="preview" visible={activeTab === 'información'}>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <CButton color="success" style={{ float: 'right' }} onClick={agregarInfraestructura} className="mr-1 my-2"><FaPlus /> Agregar Infraestructura </CButton>
              </CCol>
            </CRow>
            <GrillaBase
              menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
                <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
              </ContextMenu>}
              rows={rows}
              columns={columns}
              summaryRows={summaryRows}
            />
          </CTabPane>
        </CTabContent>
        <ModalEliminar
          titulo="Eliminar"
          visible={showEliminarInfraestructura}
          infraestructura={infraestructura}
          setShow={setShowEliminarInfraestructura}
          confirmar={retornoEliminarInfraestructura}
        >
          {msgEliminar}
        </ModalEliminar>
      </ErpLayout>
    </>
  )
}

export default GrillaInfraestructurasAsp;