import React, { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import { useParams } from "react-router-dom";
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import Fecha from '../../componentes/Fecha';
import { useForm } from 'react-hook-form';
import { fechaHoy } from '../../utils/Funciones';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import GraficoCumplimientoTotal from './GraficoCumplimientoTotal';
import GraficoCumplimientoTotalParcial from './GraficoCumplimientoTotalParcial';

const GraficoCumplimiento = (props) => {
    const { register, errors} = useForm({});
    let { encuesta_id } = useParams();
    const [rows, setRows] = useState([]);
    const [fecha, setFecha] = useState(fechaHoy);
    const [regionId, setRegionId] = useState('');

    useEffect(
        () => {
            obtenerDatos();
        },
        [regionId, fecha]
    )

    async function obtenerDatos() {
        let resultado = await api.encuesta.evaluaciones(encuesta_id, {region_id: regionId, fecha: fecha.substring(0,4)});
        if (resultado && resultado.status === 200) {
            resultado.data.datos.forEach(evaluacionData => {
                evaluacionData.porcentaje_contestadas = Math.round(evaluacionData.porcentaje_contestadas) + '%';
                evaluacionData.porcentaje_cumplimiento_final = evaluacionData.porcentaje_cumplimiento_total + '%';
                evaluacionData.Cumplimiento= evaluacionData.porcentaje_cumplimiento_total;
                evaluacionData['Cumple'] = evaluacionData.porcentaje_cumplimiento;
                evaluacionData['Cumple parcialmente'] = evaluacionData.porcentaje_cumplimiento_parcial;
            });
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    return (
        <ErpLayout
            breadcrumbs={[
                {active: true, pathname: ``, name: 'Gráfico de cumplimiento'}]
            }>
            <CRow>
                <CCol xs="12" sm="12" md="6" className="mb-2">
                    <FiltroRegionesTH
                        todos={true}
                        onChange={(e) => {
                            setRegionId(e ? e.value : '')
                        }}
                    />
                </CCol>
                <CCol xs="12" sm="12" md="3" className="mb-2">
                    <Fecha
                        name='fecha'
                        label='Año'
                        register={register}
                        errors={errors}
                        dateFormat='yyyy'
                        formatoMesAno={false}
                        dateFormatOculto='y'
                        inicial={fecha}
                        onChange={(fecha) => {
                            setFecha(fecha + '-01-01')
                        }}
                    />
                </CCol>
            </CRow>
            <CRow>
                <GraficoCumplimientoTotal rows={rows} encuestaId={encuesta_id} regionId={regionId} fecha={fecha}/>
                <GraficoCumplimientoTotalParcial rows={rows} encuestaId={encuesta_id} regionId={regionId} fecha={fecha}/>
            </CRow>
        </ErpLayout>
  )
}

export default GraficoCumplimiento