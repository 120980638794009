import React, {useState, useEffect, useContext} from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CFormCheck } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';
import UserContext from '../../Contexto'

const MiCuenta = () => {
	const { register, handleSubmit, errors, setValue } = useForm({});
	const [usuario, setUsuario] = useState({});
	const [ updatePassword, setUpdatePassword ] = useState(false);
	const contexto = useContext(UserContext)

    useEffect(
        () => {
			if (contexto.data.usuario !== undefined) {
           		getUsuario(contexto.data.usuario.id);
			}
        },
        [contexto]
    )

    async function getUsuario(id) {
        let resultado = await api.usuarios.getOne(id);
        if (resultado && resultado.status === 200) {
            setUsuario(resultado.data.datos);
			setValue([
				{nombre_usuario: resultado.data.datos.nombre_usuario},
				{nombre_completo: resultado.data.datos.nombre_completo},
				{correo: resultado.data.datos.correo},
				{telefono: resultado.data.datos.telefono},
			])
        } else {
            toast.error('Error al obtener POA');
        }
    }

	const onSubmit = async (data) => {
		if (data.password !== data.repeat_password) {
			toast.error('Las contraseñas no coinciden');
			return false;
		}
		let resultado = await api.usuarios.put(usuario.id, data);
		if (resultado && resultado.status === 200) {
			toast.success('Cambios almacenados');
		} else {
			toast.error(resultado.data.msg);
		}
	}

	return (
		<ErpLayout
			breadcrumbs={[{active: true, pathname: '', name: 'Mi cuenta'}]
		}>
				<CForm onSubmit={handleSubmit(onSubmit)}>
					<CRow>
						<CCol xs="12" sm="6" md="4" lg="4" className="mb-2">
							<CFormLabel htmlFor="nf-email">Nombre de usuario</CFormLabel>
							<CFormControl
								type="text"
								name="nombre_usuario"
								placeholder=""
								disabled
								ref={
									register({
										required: { value: true, message: "campo obligatorio" }
									})
								}
							/>
							<ErrorMessage errors={errors} name="nombre_usuario" as="div" className="invalid-feedback" />
						</CCol>
						<CCol xs="12" sm="6" md="4" lg="4" className="mb-2">
							<CFormLabel htmlFor="nf-email">Nombre completo</CFormLabel>
							<CFormControl
								type="text"
								name="nombre_completo"
								placeholder=""
								ref={
									register({
										required: { value: true, message: "campo obligatorio" }
									})
								}
							/>
							<ErrorMessage errors={errors} name="nombre_completo" as="div" className="invalid-feedback" />
						</CCol>
						<CCol xs="12" sm="6" md="4" lg="4" className="mb-2">
							<CFormLabel htmlFor="nf-email">Correo</CFormLabel>
							<CFormControl
								type="text"
								name="correo"
								placeholder=""
								ref={
									register({
										required: { value: true, message: "campo obligatorio" }
									})
								}
							/>
							<ErrorMessage errors={errors} name="correo" as="div" className="invalid-feedback" />
						</CCol>
						<CCol xs="12" sm="6" md="4" lg="4" className="mb-2">
							<CFormLabel htmlFor="nf-email">Teléfono</CFormLabel>
							<CFormControl
								type="text"
								name="telefono"
								placeholder=""
								ref={
									register({})
								}
							/>
						</CCol>
					</CRow>
					<CRow>
						<>
							<CCol xs="12" sm="6" md="6" lg="6" className="mb-2" style={{marginTop: '30px'}}>
								<CFormCheck
									custom id="inline-checkbox1"
									onClick={() => updatePassword === false ? setUpdatePassword(true) : setUpdatePassword(false)}
									name="inline-checkbox1"
									value="option1"/>
								<CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox1" style={{paddingLeft: '5px'}}> Actualizar contraseña</CFormLabel>
							</CCol>
						</>
					</CRow>
						{updatePassword &&
							<CRow>
								<CCol xs="12" sm="6" md="4" lg="4">
									<CFormLabel htmlFor="nf-email">Nueva contraseña</CFormLabel>
									<CFormControl
										type="password"
										name="password"
										placeholder=""
										ref={
											register({
												required: { value: true, message: "campo obligatorio" }
											})
										}
									/>
									<ErrorMessage errors={errors} name="password" as="div" className="invalid-feedback" />
								</CCol>
								<CCol xs="12" sm="6" md="4" lg="4">
									<CFormLabel htmlFor="nf-email">Repetir nueva contraseña</CFormLabel>
									<CFormControl
										type="password"
										name="repeat_password"
										placeholder=""
										ref={
											register({
												required: { value: true, message: "campo obligatorio" }
											})
										}
									/>
									<ErrorMessage errors={errors} name="repeat_password" as="div" className="invalid-feedback" />
								</CCol>
							</CRow>
						}
					<CRow>
						<CCol xs="6" style={{marginTop: '10px'}}>
							<CButton color="success" className="px-4" type="submit"><FaSave /> Guardar</CButton>
						</CCol>
					</CRow>
				</CForm>
		</ErpLayout>
	)
}

export default MiCuenta