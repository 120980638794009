import axios from 'axios';
import { toast } from 'react-toastify';

export function descargar(documento) {
    fetch(documento.ruta_completa, {
        method: 'GET',
        // headers: {
        //     'Content-Type': 'application/pdf',
        // },
    })
    .then((response) => response.blob())
    .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${documento.nombre_documento}.${documento.tipo_archivo}`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}

export function getYMD(fecha) {
    if (fecha) {
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        return fecha.getFullYear() + '-' + mes + '-' + dia;
    } else {
        return '';
    }
}

/**
 * Cambia el formato de fecha 2008-01-31 a 31-01-2008
 * @param {Date} fecha
 */
export function getDMY(fecha) {
    if (fecha) {
        fecha = fecha.replace(/-/g, '/');
        fecha = new Date(fecha);
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        return dia + '/' + mes + '/' + fecha.getFullYear();
    } else {
        return '';
    }
}

export function getYMDHIS(fecha) {
    if (fecha) {
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        let hora = fecha.getHours();
        if (hora <= 9) {
            hora = '0' + hora;
        }
        let minutos = fecha.getMinutes();
        if (minutos <= 9) {
            minutos = '0' + minutos;
        }
        return fecha.getFullYear() + '-' + mes + '-' + dia + ' ' + hora + ':' + minutos + ':00';
    } else {
        return '';
    }
}

export function getYear(fecha) {
    if (fecha) {
       return fecha.getFullYear()
    } else {
        return '';
    }
}

export function getYearNow() {
    let fecha = new Date();
    return fecha.getFullYear();
}

export function fechaHoy() {
    let fecha = new Date();
    let mes = fecha.getMonth() + 1;
    if (mes <= 9) {
        mes = '0' + mes;
    }
    let dia = fecha.getDate();
    if (dia <= 9) {
        dia = '0' + dia;
    }
    let hora = fecha.getHours();
    if (hora <= 9) {
        hora = '0' + hora;
    }
    let minutos = fecha.getMinutes();
    if (minutos <= 9) {
        minutos = '0' + minutos;
    }
    return fecha.getFullYear() + '-' + mes + '-' + dia;
}

export function loading(activar) {
    if (activar) {
        document.getElementById("loading-global").style.display = "";
    } else {
        document.getElementById("loading-global").style.display = "none";
    }
}

export function Api(recurso, datos=null, cabeceras={}, requiere_token=true, metodo='get') {
    if(requiere_token===true) {
        const token = localStorage.getItem('token');
        cabeceras['Authorization'] = `Bearer ${token}`;
    }
    return new Promise(function (resolve, reject) {
        axios({
            method: metodo,
            url: `${process.env.REACT_APP_API_URL}${recurso}`,
            data: datos,
            headers: cabeceras
        }).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            resolve(error.response);
        });
    });
}

/**
 * Esta función procesa la información y crea el archivo excel temporal
 *
 * @export
 * @param {string} ruta
 * @param {Alert} alert
 */
 export async function exportar(ruta) {
    let resultado = await Api(ruta, null, {}, true, 'get');
    if (resultado && resultado.status === 200 && resultado.data.data) {
        download(`${process.env.REACT_APP_API_URL}${resultado.data.data.path}`, resultado.data.data.name);
    } else {
        if( resultado && resultado.data.msg){
            toast.error(`${resultado.data.msg}`);
        } else{
            toast.error(`No se pudo exportar`);
        }
    }
}

/**
 * Descarga el archivo
 *
 * @param {string} url
 * @param {string} filename
 */
 function download(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}
export function permiso(contexto, modulo, accion) {
    let respuesta = false;
    if (contexto.data.usuario !== undefined  && contexto.data.usuario.administrador === true) {
        return true;
    }
    if (contexto.data.usuario !== undefined  && contexto.data.usuario.permisos !== undefined) {
      contexto.data.usuario.permisos.forEach(data => {
        if (data.modulo === modulo && data[accion] === true) {
          respuesta = true;
        }
      })
    }
    return respuesta;
}

export function administrador(contexto) {
    if (contexto.data.usuario !== undefined  && contexto.data.usuario.administrador === true) {
        return true;
    } else {
        return false;
    }
}

export function permisoClass(contexto, modulo, accion) {
    if (permiso(contexto, modulo, accion)) {
      return "";
    } else {
      return 'ocultar-menu';
    }
}

export function mesTexto(numero_mes) {
    let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return meses[numero_mes - 1];
}

export const NumberGraphicFormatter = ({ value }) => {
    let number = Math.abs(value).toString();
    // 2 decimal places => 100, 3 => 1000, etc
    var decPlaces = Math.pow(10,2);

    // Enumerate number abbreviations
    var abbrev = [ "M", "MM", "B", "T" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= number) {
             // Here, we multiply by decPlaces, round, and then divide by decPlaces.
             // This gives us nice rounding to a particular decimal place.
             number = Math.round(number*decPlaces/size)/decPlaces;

             // Handle special case where we round up to the next abbreviation
             if((number == 1000) && (i < abbrev.length - 1)) {
                 number = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             number += abbrev[i];

             // We are done... stop
             break;
        }
    }
    return value >= 0 ? number : "-"+number;
};
