import React, { useState, useEffect } from 'react';
import {
    CFormLabel,
    CFormSelect
} from '@coreui/react-pro';

function SelectorHoja(props) {
    const { nombreHojas, cargado, todos, onChange, ...rest } = props;
    
    return (
        <>
            <CFormLabel htmlFor="appendedInputButton">Seleccione una hoja:</CFormLabel>
            <CFormSelect
                onChange={onChange}>
                {todos && <option value="">Todos</option>}
                {nombreHojas.map((tipo) =>
                    <option key={tipo.name} value={tipo.name}>{tipo.name}</option>
                )}
            </CFormSelect>
        </>
    );
}

export default SelectorHoja;