import React, { useState, useEffect } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter, CFormSelect, CNav,CTabContent, CTabPane, CNavItem, CNavLink } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { useForm, ErrorMessage } from 'react-hook-form';
import { FaSave, FaTimes } from 'react-icons/fa';
import api from '../../utils/Routes';
import { GrillaBase, LongTextFormatter2, CenterAlign } from '../../componentes/GrillaBase';
import CargarDocumento from '../../componentes/CargarDocumento';

const FormEvaluacion = (props) => {
    const {show, actividadId, poaId, cerrar, ...rest} = props;
    const [actividad, setActividad] = useState({});
    const { register, handleSubmit, errors, setValue } = useForm({});
    const columns = [
        { key: 'mes', name: 'Mes', width: 50, formatter: CenterAlign},
        { key: 'cumplimiento', name: 'Cumplimiento', width: 100, formatter: CenterAlign},
        { key: 'riesgo', name: 'Riesgo', width: 100, formatter: CenterAlign},
        { key: 'estado', name: 'Estado', width: 200},
        { key: 'descripcion_avance', name: 'Descripción', formatter: LongTextFormatter2, cellClass: "cont-longtext"}
    ];
    const [activeTab, setActiveTab] = useState('formulario');
    const [rows, setRows] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	const [enviarArchivos, setEnviarArchivos] = useState(false);

    useEffect(
        () => {
            if (actividadId) {
                obtenerDatos()
            }
        },
        [actividadId]
    )

    async function obtenerDatos() {
        let resultado = await api.actividad.get(actividadId);
        if (resultado && resultado.status === 200) {
            setActividad(resultado.data.datos)
            let actividadTemp = resultado.data.datos;
            setRows(actividadTemp.planificacion);
            setDocumentos(actividadTemp.documentos);
            setValue([
                { descripcion_cumplimiento: actividadTemp.descripcion_cumplimiento },
                { cumplimiento: actividadTemp.cumplimiento },
                { estado_evaluacion: actividadTemp.estado_evaluacion },
            ]);
        } else {
            cerrarModal();
            toast.error('Error al obtener datos');
        }
    }

    const cerrarModal = () => {
        setActividad({})
        cerrar()
    }

    async function guardar(data) {
        if (actividad.id) {
            let resultado = await api.actividad.putEvaluacion(poaId, actividad.id, data);
            if (resultado && resultado.status === 200) {
                setEnviarArchivos(true);
            } else {
                toast.error(resultado.data.msg, {autoClose:8000});
            }
        }
    }

    return (
        <>
            <CModal
                className='modal-historial-controles'
                keyboard={false}
                visible={show}
                size='lg'>
                <CModalHeader onDismiss={cerrarModal}>Evaluación: {actividad.descripcion}</CModalHeader>
                <CForm onSubmit={handleSubmit(guardar)}>
                    <CModalBody>
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="preview" active={activeTab === 'formulario'} onClick={() => setActiveTab('formulario')}>
                                    Formulario
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="preview" active={activeTab === 'Documentos'} onClick={() => setActiveTab('Documentos')}>
                                    Verificadores
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="preview" visible={activeTab === 'formulario'}>
                                <CRow className='mt-2'>
                                    <CCol xs="8" sm="8" md="6" lg="4" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Cumplimiento</CFormLabel>
                                        <CFormControl
                                            type="number"
                                            step="any"
                                            name="cumplimiento"
                                            placeholder=""
                                            ref={register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })}
                                        />
                                        <ErrorMessage errors={errors} name="cumplimiento" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="4" sm="4" md="4" lg="2" className="mb-2" style={{paddingTop: '30px'}}>
                                        Meta: {actividad.meta}
                                    </CCol>
                                    <CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
                                        <CFormLabel>Estado</CFormLabel>
                                        <CFormSelect name="estado_evaluacion"
                                            ref={register({
                                                required: 'Campo obligatorio'
                                            })}>
                                            <option value="Cumplido">Cumplido</option>
                                            <option value="Parcialmente cumplido">Parcialmente cumplido</option>
                                            <option value="No cumplido">No cumplido</option>
                                        </CFormSelect>
                                        <ErrorMessage errors={errors} name="estado_evaluacion" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="12" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Descripción cumplimiento</CFormLabel>
                                            <CFormControl
                                                name="descripcion_cumplimiento"
                                                component="textarea"
                                                rows="4"
                                                placeholder=""
                                                ref={register({
                                                    required: 'Campo obligatorio'
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="descripcion_cumplimiento" as="div" className="invalid-feedback" />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                        <b>Historial de controles</b>
                                        <GrillaBase
                                            rows={rows}
                                            columns={columns}
                                            minHeight={250}
                                        />
                                    </CCol>
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="preview" visible={activeTab === 'Documentos'}>
                                <CRow className="mt-2">
                                    <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                        <CargarDocumento
                                            documentos={documentos} // documentos que ya tiene la entidad en base de datos
                                            enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
                                            entidad_nombre={'Evaluación'}
                                            entidad_id={actividad.id}
                                            enviado={() => { // cuando se enviaron todos los archivos
                                                setEnviarArchivos(false)
                                                toast.success('Cambios almacenados');
                                            }}
                                            cambioArchivos={(data) => {}} />
                                    </CCol>
                                </CRow>
                            </CTabPane>
                        </CTabContent>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="light"
                            onClick={cerrarModal}
                        ><FaTimes /> Cerrar</CButton>
                        <CButton color="success" type="submit"><FaSave/> Guardar</CButton>
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default FormEvaluacion