import React, {useState} from 'react';
import { CFormLabel, CInputGroup, CFormControl, CButton } from '@coreui/react-pro';
import { FaSearch } from 'react-icons/fa';

/**
 * Este componente es un buscador que se activa cuando presionamos enter, hacemos click en el buscador o luego de 2 segundos de escribir
 *
 * @param {object} props
 * @returns Componente
 */
function Buscador(props) {

	const [valor, setvalor] = useState('');
	const [timer, setTimer] = useState(null);
	const WAIT_INTERVAL = 1000;
	const ENTER_KEY = 13;

	/**
	 * Evento cuando presionan botón buscar
	 */
	function onClick() {
		clearTimeout(timer); // Cuando es presionado el botón buscar, detenemos el setTimeOut si es que ya habia sido lanzado
		props.retornoBusqueda(valor); // LLamamos la función retorno del componente padre, por lo general es una llamada API
	}

	/**
	 * Cuando cambia el campo valor, actualizamos estado
	 *
	 * @param {object} e
	 */
	function onChange(e) {
		setvalor(e.target.value); // Lo unico que hace es actualizar estado
	}

	/**
	 * Cada vez que levantan el dedo de la tecla, detenemos el tiempo y luego de WAIT_INTERVAL segundos
	 * Se activa la función de búsqueda
	 *
	 * @param {*} e
	 */
	function onKeyUp(e) {
		clearTimeout(timer); // Cada vez que presionamos teclas detenemos la ejecución del setTimeOut si es que hay alguno andando
		if (e.keyCode === ENTER_KEY) { // Cuando la tecla presionada es enter, ejecutamos consulta
			props.retornoBusqueda(valor);
		} else {
			setTimer(setTimeout(() => { // Si no es enter dejamos ejecutando setTimeOut por WAIT_INTERVAL segundos
				props.retornoBusqueda(valor); // LLamamos la función retorno del componente padre, por lo general es una llamada API
			}, WAIT_INTERVAL));
		}
	}

	return (
		<>
			<CFormLabel htmlFor="appendedInputButton">{props.titulo ? props.titulo : 'Búsqueda de archivos'}</CFormLabel>
			<div className="controls">
				<CInputGroup>
					<CFormControl id="appendedInputButton" size="16" type="text" onKeyUp={onKeyUp} onChange={onChange} value={valor} placeholder={props.placeholder} required placeholder={props.placeholder} />
					<CButton variant="outline" color="secondary" size="sm"><FaSearch /> Buscar</CButton>
				</CInputGroup>
			</div>
		</>
	);
}

export default Buscador;