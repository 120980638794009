import { ApiRequest } from './Api';

function queryString(query) {
    if (Object.keys(query).length !== 0) { // Comprobamos si el objeto no viene vacio
        return '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&');
    } else {
        return '';
    }
}

const auth = {
    post: (data) => ApiRequest('post', `auth/login`, data, false),
}
const usuarios = {
    post: (data) => ApiRequest('post', `api/usuarios`, data, true),
    get: (query = {}) => ApiRequest('get', `api/usuarios${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/usuarios/${id}`, {}, true),
    getOneToken: (id) => ApiRequest('get', `api/usuarios/token`, {}, true),
    put: (id, data) => ApiRequest('put', `api/usuarios/${id}`, data, true),
    delete: (id) => ApiRequest('delete', `api/usuarios/${id}`, {}, true),
}
const proyectosAdjudicados = {
    get: (query = {}) => ApiRequest('get', `api/proyectos/adjudicados${queryString(query)}`, {}, true)
}
const documentos = {
    get: (query = {}) => ApiRequest('get', `api/documentos${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/documentos/${id}`, {}, true),
    extraerMetadata: (archivoId, metodo) => ApiRequest('put', `api/documentos/${archivoId}/meta-data/${metodo}`, {}, true),
    delete: (id) => ApiRequest('delete', `api/documentos/${id}`, {}, true),
    put: (id, data) => ApiRequest('put', `api/documentos/${id}`, data, true),
}
const documentosPersonal = {
    get: () => ApiRequest('get', `api/documentos/personal`, {}, true),
    getOne: (id) => ApiRequest('get', `api/documentos/personal/${id}`, {}, true)
}
const tipoInformacion = {
    get: (query={}) => ApiRequest('get', `api/tipo-informacion${queryString(query)}`, {}, false),
    getOne: (id) => ApiRequest('get', `api/tipo-informacion/${id}`, {}, false),
    getCategorias: () => ApiRequest('get', `api/tipo-informacion/categorias`, {}, false),
    post: (data) => ApiRequest('post', `api/tipo-informacion`, data, true),
    put: (id, data) => ApiRequest('put', `api/tipo-informacion/${id}`, data, true),
    delete: (id) => ApiRequest('delete', `api/tipo-informacion/${id}`, {}, true),
    postCategoria: (data) => ApiRequest('post', `api/tipo-informacion/categoria`, data, true),
    putCategoria: (id, data) => ApiRequest('put', `api/tipo-informacion/categoria/${id}`, data, true),
    deleteCategoria: (id) => ApiRequest('delete', `api/tipo-informacion/categoria/${id}`, {}, true),
}
const areaProtegida = {
    get: () => ApiRequest('get', `api/areas-protegidas`, {}, false),
    getOne: (id) => ApiRequest('get', `api/areas-protegidas/${id}`, {}, false),
    put: (id, data) => ApiRequest('put', `api/areas-protegidas/${id}`, data, true),
    post: (data) => ApiRequest('post', `api/areas-protegidas/`, data, true),
    provincias: (region_id) => ApiRequest('get', `api/areas-protegidas/provincias/${region_id}`, {},false),
    comunas: (region_id,query = {}) => ApiRequest('get', `api/areas-protegidas/${region_id}/comunas${queryString(query)}`, {}, false),
    infraestructurasAsp: (area_protegida_id) => ApiRequest('get', `api/areas-protegidas/${area_protegida_id}/infraestructurasAsp`, {}, true),
    infraestructuras: () => ApiRequest('get', `api/areas-protegidas/infraestructuras`, {}, true),
    concesiones: (area_protegida_id) => ApiRequest('get', `api/areas-protegidas/${area_protegida_id}/concesiones`, {}, true),
    sectores: (area_protegida_id, query={}) => ApiRequest('get', `api/areas-protegidas/${area_protegida_id}/sectores${queryString(query)}`, {}, true),
}
const poa = {
    get: (query = {}) => ApiRequest('get', `api/poa${queryString(query)}`, {}, true),
    getOne: (id, query = {}) => ApiRequest('get', `api/poa/${id}${queryString(query)}`, {}, true),
    actividades: (poa_id, query = {}) => ApiRequest('get', `api/poa/${poa_id}/actividades${queryString(query)}`, {}, true),
    getActividad: (actividad_id) => ApiRequest('get', `api/actividad/${actividad_id}`, {}, true),
    post: (data) => ApiRequest('post', `api/poa`, data, true),
    put: (id, data) => ApiRequest('put', `api/poa/${id}`, data, true),
    periodos: () => ApiRequest('get', `api/poa/periodos`, {}, true),
    reporteEstrategias: (periodo, query = {}) => ApiRequest('get', `api/poa/reporte/estrategias/${periodo}${queryString(query)}`, {}, true),
    reporteEvaluacion: (query = {}) => ApiRequest('get', `api/poa/reporte/evaluacion${queryString(query)}`, {}, true),
    delete: (id) => ApiRequest('delete', `api/poa/${id}`, {}, true),
}
const actividad = {
    get: (id) => ApiRequest('get', `api/actividad/${id}`, {}, true),
    put: (poaId, id, data) => ApiRequest('put', `api/poa/${poaId}/actividades/${id}`, data, true),
    post: (poaId, data) => ApiRequest('post', `api/poa/${poaId}/actividades`, data, true),
    putEvaluacion: (poaId, id, data) => ApiRequest('put', `api/poa/${poaId}/actividades/${id}/evaluaciones`, data, true),
    delete: (poaId, id) => ApiRequest('delete', `api/poa/${poaId}/actividades/${id}`, {}, true),
}
const tipoCompromiso = {
    get: () => ApiRequest('get', `api/tipo-compromiso`, {}, true)
}
const programaManejo = {
    get: () => ApiRequest('get', `api/programa-manejo`, {}, true)
}
const subestrategia = {
    get: () => ApiRequest('get', `api/subestrategias`, {}, true)
}
const personal = {
    get: (query= {}) => ApiRequest('get',`api/personal${queryString(query)}`,{},false),
}
const personalFechas = {
    get:() => ApiRequest('get',`api/personal/fecha`,{},false)
}
const regiones = {
    get: (query = {}) => ApiRequest('get', `api/regiones${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/regiones/${id}`, {}, true),
}
const planificacion = {
    get: (id) => ApiRequest('get', `api/planificacion/${id}`, {}, true),
    put: (poaId, id, data) => ApiRequest('put', `api/actividad/${poaId}/planificacion/${id}`, data, true)
}
const infraestructura = {
    get: () => ApiRequest('get', `api/infraestructura`, {}, true),
    getAll: () => ApiRequest('get', `api/infraestructuras`, {}, true),
    getOne: (id) => ApiRequest('get', `api/infraestructuras/${id}`, {}, true),
    post: (data) => ApiRequest('post', `api/infraestructuras`, data, true),
    put: (id, data) => ApiRequest('put', `api/infraestructuras/${id}`, data, true),
    delete: (id) => ApiRequest('delete', `api/infraestructura/${id}`, {}, true),
    reporte_regiones: () => ApiRequest('get', `/api/infraestructuras/reporte/regiones`, {}, true),
    reporte_tipo_instalacion: () => ApiRequest('get', `/api/infraestructuras/reporte/tipo_instalacion`, {}, true)
}
const concesion = {
    get: (query = {}) => ApiRequest('get', `api/concesion${queryString(query)}`, {}, true),
    getAll: () => ApiRequest('get', `api/concesiones`, {}, true),
    getServicio: () => ApiRequest('get', `api/concesion/servicios`, {}, true),
    getTipoContrato: (query = {}) => ApiRequest('get', `api/concesion/tipo-contrato${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/concesion/${id}`, {}, true),
    post: (data) => ApiRequest('post', `api/concesion`, data, true),
    put: (id, data) => ApiRequest('put', `api/concesion/${id}`, data, true),
    delete: (id) => ApiRequest('delete', `api/concesion/${id}`, {}, true)
}
const tipoServicio = {
    getOne: (tipo_servicio_id) => ApiRequest('get', `api/concesion/tipo-servicio/${tipo_servicio_id}`, {}, true),
    getTipoServicio: (servicio_id) => ApiRequest('get', `api/concesion/tipos-servicios/${servicio_id}`, {}, true),
    get: (servicio_id) => ApiRequest('get', `api/tipo-servicio/${servicio_id}`, {}, true),
    getAll: (query = {}) => ApiRequest('get', `api/tipo-servicio${queryString(query)}`, {}, true),
}
const servicio = {
    getAll: () => ApiRequest('get', `api/servicio`, {}, true),
}
const area = {
    get: (query= {}) => ApiRequest('get', `api/area${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/area/${id}`, {}, true),
    post: (data) => ApiRequest('post', `api/area`, data, true),
    put: (id, data) => ApiRequest('put', `api/area/${id}`, data, true)
}
const tipoInstalacion = {
    get: (query= {}) => ApiRequest('get', `api/tipo-instalacion${queryString(query)}`, {}, true),
}
const tipoArea = {
    get: (query= {}) => ApiRequest('get', `api/tipo-area${queryString(query)}`, {}, true),
}
const sector = {
    getOne: (id) => ApiRequest('get',`api/sector/${id}`,{},true),
    delete: (id) => ApiRequest('delete', `api/sector/${id}`, {}, true),
    post: (area_protegida_id,data) => ApiRequest('post', `api/area-protegida/${area_protegida_id}/sector`, data, true),
    put: (area_protegida_id,sector_id, data) => ApiRequest('put', `api/area-protegida/${area_protegida_id}/sector/${sector_id}`, data, true)
}
const visitacion = {
    getDatos: () => ApiRequest('get', `/api/visitacion/datos/`, {}, true),
    getMensual: (periodo, mes) => ApiRequest('get', `api/visitacion/mensual/${periodo}/${mes}`, {}, true),
    getAnual: (periodo) => ApiRequest('get', `api/visitacion/anual/${periodo}`, {}, true),
    getGraficoAnual: (periodo) => ApiRequest('get', `/api/visitacion/anual/grafico`, {}, true),
    getGraficoMensual: (periodo) => ApiRequest('get', `/api/visitacion/mensual/grafico`, {}, true),
    getGraficoNacionalidad: (periodo) => ApiRequest('get', `/api/visitacion/nacionalidad/grafico`, {}, true),
    getGraficoGenero: (periodo) => ApiRequest('get', `/api/visitacion/genero/grafico`, {}, true),
    getGraficoMensualRegion: (periodo, mes) => ApiRequest('get', `/api/visitacion/mensual/region/${periodo}/${mes}`, {}, true),
    getMensualSegmentado: (periodo, mes) => ApiRequest('get', `/api/visitacion/mensual/segmentos/${periodo}/${mes}`, {}, true),
}
const proveedor = {
    get: () => ApiRequest('get', `/api/proveedor`, {}, true),
    post: (data) => ApiRequest('post', `api/proveedor`, data, true),
    put: (proveedor_id, data) => ApiRequest('put', `api/proveedor/${proveedor_id}`, data, true)
}
const modulo = {
    get: () => ApiRequest('get', `api/modulos`, {}, true),
}
const evaluacion = {
    get: (query = {}) => ApiRequest('get', `api/evaluaciones${queryString(query)}`, {}, true),
    getOne: (id, query = {}) => ApiRequest('get', `api/evaluaciones/${id}${queryString(query)}`, {}, true),
    post: (data) => ApiRequest('post', `api/evaluaciones`, data, true),
    clonar: (id, data) => ApiRequest('post', `api/evaluaciones/${id}/clonar`, data, true),
    put: (evaluacionId, data) => ApiRequest('put', `api/evaluaciones/${evaluacionId}`, data, true),
    delete: (id) => ApiRequest('delete', `api/evaluaciones/${id}`, {}, true),
}
const encuesta = {
    getOne: (id) => ApiRequest('get', `api/encuestas/${id}`, {}, true),
    evaluaciones: (id, query = {}) => ApiRequest('get', `api/encuestas/${id}/evaluaciones${queryString(query)}`, {}, true),
    indicadoresCumplen: (id, query = {}) => ApiRequest('get', `api/encuestas/${id}/indicadores-cumplen${queryString(query)}`, {}, true),
    indicadoresDetalle: (id, query = {}) => ApiRequest('get', `api/encuestas/${id}/indicadores-detalle${queryString(query)}`, {}, true),
    cumplimientoPorComponente: (id, query = {}) => ApiRequest('get', `api/encuestas/${id}/cumplimiento-componente${queryString(query)}`, {}, true),
}
const indicador = {
    getOne: (id) => ApiRequest('get', `api/indicadores/${id}`, {}, true),
}
const respuesta = {
    getOne: (query = {}) => ApiRequest('get', `api/respuesta${queryString(query)}`, {}, true),
    put: (data) => ApiRequest('put', `api/respuesta`, data, true)
}
const investigacion = {
    get: (query = {}) => ApiRequest('get', `api/investigaciones${queryString(query)}`, {}, true),
    getOne: (id) => ApiRequest('get', `api/investigaciones/${id}`, {}, true),
    post: (data) => ApiRequest('post', `api/investigaciones`, data, true),
    put: (id, data) => ApiRequest('put', `api/investigaciones/${id}`, data, true),
    delete: (id) => ApiRequest('delete', `api/investigaciones/${id}`, {}, true),
    reporteAsp: (query = {}) => ApiRequest('get', `/api/investigaciones/areas-protegias${queryString(query)}`, {}, true),
    años: () => ApiRequest('get', `api/investigaciones/anos`, {}, true),
}

export default {
    auth, usuarios, proyectosAdjudicados, documentos, tipoInformacion, areaProtegida, poa, actividad, tipoCompromiso, programaManejo,
    subestrategia, personal, personalFechas, regiones, planificacion, documentosPersonal, infraestructura, area, tipoInstalacion,sector,
    tipoArea, visitacion, concesion, proveedor, tipoServicio, servicio, modulo, evaluacion, encuesta, indicador, respuesta, investigacion
}
