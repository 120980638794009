import React from 'react';
import { CCol, CRow } from '@coreui/react-pro';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraficoIndicadoresFase = (props) => {
    const { rows,titulo, height,...rest } = props;

    return (
        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12" className="py-2 mt-2" style={{textAlign: 'center'}}>
                <h5>{titulo}</h5>
            </CCol>

            <CCol xs="12" sm="12" md="12" lg="12" className="py-2" style={{overflowX: 'scroll'}}>
                <div style={{ minWidth: '750px', width: '100%', height: (height ? height : 400) }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            width={100}
                            height={300}
                            data={rows}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis dataKey="nombre" interval={0} type="category" width={500} fontSize={14} />
                        <Tooltip position={{ x: 0}} labelStyle={{ whiteSpace:'normal'}}/>
                        <Legend />
                        <Bar dataKey="cantidad_cumple" name="N° Parques que cumplen" stackId="a" fill="#8884d8" />
                        <Bar dataKey="cantidad_cumple_parcialmente" name="N° Parques que cumplen parcialmente" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="cantidad_no_cumple" name="N° Parques que No cumplen" stackId="a" fill="#8f8f8f" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CCol>
        </CRow>
    )
}

export default GraficoIndicadoresFase