import React, { useEffect, useState, useContext } from 'react';
import { CCol, CRow, CButton, CTooltip } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import { GrillaBase, CenterAlign } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus, FaPencilAlt, FaRegEye , FaRegFileExcel, FaTrashAlt} from 'react-icons/fa';
import { useHistory, useParams } from "react-router-dom";
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import FormEvaluacion from './FormEvaluacion';
import Fecha from '../../componentes/Fecha';
import { useForm } from 'react-hook-form';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import { fechaHoy, exportar } from '../../utils/Funciones';
import ModalEliminar from '../administracion/ModalEliminar';
import UserContext from '../../Contexto'


const GrillaEvaluaciones = (props) => {
    let history = useHistory();
    const { register, errors} = useForm({});
    let { encuesta_id } = useParams();
    const columns = [
        { key: 'area_protegida_nombre', name: 'ASP'},
        { key: 'region_nombre', name: 'Región'},
        { key: 'responsable_nombre', name: 'Responsable'},
        { key: 'fecha', name: 'Año', width: 100, formatter: CenterAlign },
        { key: 'cantidad_respuestas', name: 'Contestadas', formatter: CenterAlign,  width:100 },
        { key: 'porcentaje_contestadas', name: 'Contestadas %', formatter: CenterAlign, width: 130 },
        { key: 'porcentaje_cumplimiento_final', name: 'Cumplimiento %', formatter: CenterAlign, width: 130 }
    ];
    const [showFormEncuesta, setShowFormEncuesta] = useState(false);
    const [rows, setRows] = useState([]);
    const [evaluacion, setEvaluacion] = useState({});
    const [fecha, setFecha] = useState(fechaHoy);
    const [regionId, setRegionId] = useState('');
    const [showEliminar, setShowEliminar] = useState(false);  // Modal para eliminar
    const [msgEliminar, setMsgEliminar] = useState('');
    const [evaluacionEliminarId, setEvaluacionEliminarId] = useState('');
    const contexto = useContext(UserContext)

    useEffect(
        () => {
            obtenerDatos();
        },
        [regionId, fecha]
    )

    async function obtenerDatos() {
        let resultado = await api.encuesta.evaluaciones(encuesta_id, {region_id: regionId, fecha: fecha.substring(0,4)});
        if (resultado && resultado.status === 200) {
            resultado.data.datos.forEach(evaluacionData => {
                evaluacionData.porcentaje_contestadas = Math.round(evaluacionData.porcentaje_contestadas) + '%';
                evaluacionData.porcentaje_cumplimiento_final = (evaluacionData.porcentaje_cumplimiento_total ? evaluacionData.porcentaje_cumplimiento_total : 0) + '%';
                evaluacionData.Cumplimiento= evaluacionData.porcentaje_cumplimiento_total;
                evaluacionData['Cumple'] = evaluacionData.porcentaje_cumplimiento;
                evaluacionData['Cumple parcialmente'] = evaluacionData.porcentaje_cumplimiento_parcial;
            });
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    const menuEditar = (e, { rowIdx }) => {
        setEvaluacion(rows[rowIdx]);
        if (rows[rowIdx].creador_usuario_id === contexto.data.usuario.id || contexto.data.usuario.administrador === true){
            setShowFormEncuesta(true);
        } else {
            toast.error('No cuenta con permisos para editar este registro');
        }
    }

    const menuRespuestas = (e, { rowIdx }) => {
        history.push(`/encuestas/1/evaluacion/${rows[rowIdx].id}`);
    }

    function exportarExcel(){
        exportar(`api/exportar/verificadores?region_id=${regionId}&fecha=${fecha.substring(0,4)}`);
    }

    const menuEliminar = (e, { rowIdx }) => {
        setEvaluacionEliminarId(rows[rowIdx].id);
        if (rows[rowIdx].creador_usuario_id === contexto.data.usuario.id || contexto.data.usuario.administrador===true){
            setMsgEliminar(`ASP: ${rows[rowIdx].area_protegida_nombre} - Región: ${rows[rowIdx].region_nombre}, año: ${rows[rowIdx].fecha}, contestadas: ${rows[rowIdx].cantidad_respuestas}, % Contestadas: ${rows[rowIdx].porcentaje_contestadas}`);
            setShowEliminar(true);
        } else {
            toast.error('No cuenta con permisos para eliminar este registro');
        }
    }

    const eliminarEvaluacion = async () => {
        let resultado = await api.evaluacion.delete(evaluacionEliminarId);
        if (resultado && resultado.status === 200) {
            toast.success('Evaluación eliminada');
            obtenerDatos();
        } else {
            toast.error(resultado.data.msg);
        }
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: ``, name: 'Listado evaluaciones'}]
                }>
                <CRow>
                    <CCol xs="12" sm="12" md="6" className="mb-2">
                        <FiltroRegionesTH
                            todos={true}
                            onChange={(e) => {
                                setRegionId(e ? e.value : '')
                            }}
                        />
                    </CCol>
                    <CCol xs="12" sm="12" md="3" className="mb-2">
                        <Fecha
                            name='fecha'
                            label='Año'
                            register={register}
                            errors={errors}
                            dateFormat='yyyy'
                            formatoMesAno={false}
                            dateFormatOculto='y'
                            inicial={fecha}
                            onChange={(fecha) => {
                                setFecha(fecha + '-01-01')
                            }}
                        />
                    </CCol>
                    <CCol xs={12} sm={12} md={6} lg={3} style={{paddingTop: '23px'}}>
                        <CButton
                            color="success"
                            className="float-lg-end"
                            onClick={() => {
                                setShowFormEncuesta(true)
                            }}
                        ><FaPlus /> Agregar</CButton>
                        <CTooltip content="Exportar indicadores y valores" placement="top">
                            <CButton color="light" style={{marginRight: '5px'}} className="float-lg-end" onClick={exportarExcel}><FaRegFileExcel /> Exportar</CButton>
                        </CTooltip>
                    </CCol>
                </CRow>
                <CRow className='mb-2 mt-2'>
                    <CCol xs="12" sm="12" md="12">
                    </CCol>
                </CRow>
                <GrillaBase
                    rows={rows}
                    columns={columns}
                    menu={<ContextMenu id="grid-context-menu">
                        <MenuItem onClick={menuEditar}><FaPencilAlt style={{position: 'relative', top: '-2px'}}/> Editar</MenuItem>
                        <MenuItem onClick={menuRespuestas}><FaRegEye style={{position: 'relative', top: '-2px'}}/> Ver preguntas</MenuItem>
                        <MenuItem onClick={menuEliminar}><FaTrashAlt style={{position: 'relative', top: '-2px'}}/> Eliminar</MenuItem>
                    </ContextMenu>}
                />

                <FormEvaluacion
                    show={showFormEncuesta}
                    evaluacionId={evaluacion.id}
                    encuestaId={encuesta_id}
                    close={()=> {
                        setEvaluacion({})
                        setShowFormEncuesta(false)
                        obtenerDatos()
                    }}
                    retorno={() => {}}/>
                <ModalEliminar
                    titulo="¿Está seguro de eliminar la evaluación?"
                    visible={showEliminar}
                    setShow={setShowEliminar}
                    confirmar={() => {
                        setShowEliminar(false);
                        eliminarEvaluacion();
                    }}>
                        {msgEliminar}
                </ModalEliminar>
            </ErpLayout>
        </>
    )
}

export default GrillaEvaluaciones