import React, { useEffect, useState, useContext } from 'react';
import { CCol, CRow, CButton,CNav, CNavItem, CNavLink, CTabContent, CTabPane, CFormLabel} from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase,LongTextFormatter, LongTextFormatter2, CenterAlign } from '../../componentes/GrillaBase';
import { FaCheck, FaQuestion, FaRegFileExcel } from 'react-icons/fa';
import {IoMdClose, IoMdTime} from 'react-icons/io';
import { exportar } from '../../utils/Funciones';
import FormEjecucion from './FormEjecucion';
import { useParams, useHistory } from 'react-router-dom';
import UserContext from '../../Contexto'


const Ejecucion = (props) => {
	const [planificacion, setPlanificacion] = useState({});
	const [poa, setPoa] = useState({});
	const [rows, setRows] = useState([]);
	const [modalEjecucion, setModalEjecucion] = useState(false);
	const [permisoEditor, setPermisoEditor] = useState(false)
	const [admin, setAdmin] = useState(false)
	const [responsable, setResponsable] = useState(false)
	const contexto = useContext(UserContext)
	let { id } = useParams();
	let history = useHistory();

	function accion(row, planificacion) {
		setPlanificacion(planificacion)
		if (responsable || permisoEditor || admin) {
			setModalEjecucion(true)
		} else {
				toast.error('No cuenta con permisos para editar este registro');
		}
	}

	function estadoMes(row, mes) {
		let botonAccion = '';
		row.planificacion.forEach(planificacionData => {
			if (planificacionData.mes === mes) {
				if (planificacionData.estado === 'No cumplido') {
					botonAccion = <CButton color="default" size="sm" className="mb-2 mt-1" style={{width: '100%'}} onClick={() => accion(row, planificacionData)}>
						<IoMdClose style={{color: 'red', fontSize:'20px'}}/>
					</CButton>
				} else if (planificacionData.estado === 'Con retraso') {
					botonAccion = <CButton color="default" size="sm" className="mb-2 mt-1" style={{width: '100%'}} onClick={() => accion(row, planificacionData)}>
						<IoMdTime style={{color: 'red', fontSize:'20px'}}/>
					</CButton>
				} else if (planificacionData.estado === 'Avances según lo planificado') {
					botonAccion = <CButton color="default" size="sm" className="mb-2 mt-1" style={{width: '100%'}} onClick={() => accion(row, planificacionData)}>
						<FaCheck style={{color: 'green', fontSize:'20px'}}/>
					</CButton>
				} else {
					botonAccion = <CButton color="default" size="sm" className="mb-2 mt-1" style={{width: '100%'}} onClick={() => accion(row, planificacionData)}>
						<FaQuestion style={{color: 'grey', fontSize:'20px'}}/>
					</CButton>
				}
			}
		});
		return botonAccion
	}

	const columns = [
		{ key: 'descripcion', name: 'Actividad', formatter: LongTextFormatter2, cellClass: "cont-longtext", minWidth: 300},
		{ key: 'responsable', name: 'Responsable', formatter: LongTextFormatter,width:100 },
		{ key: 'cantidad', name: 'Ejecutado', formatter: CenterAlign, width: 100 },
		{ key: 'meta', name: 'Meta', formatter: CenterAlign, width: 100 },
		{ key: 'porcentaje_cumplidas', name: '% Avance', formatter: CenterAlign, width: 120 },
		{
			key: 'enero',
			name: 'Ene',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 1)}</>)
			}
		},
		{
			key: 'febrero',
			name: 'Feb',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 2)}</>)
			}
		},
		{
			key: 'marzo',
			name: 'Mar',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 3)}</>)
			}
		},
		{
			key: 'abril',
			name: 'Abr',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 4)}</>)
			}
		},
		{
			key: 'mayo',
			name: 'May',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 5)}</>)
			}
		},
		{
			key: 'junio',
			name: 'Jun',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 6)}</>)
			}
		},
		{
			key: 'julio',
			name: 'Jul',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 7)}</>)
			}
		},
		{
			key: 'agosto',
			name: 'Ago',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 8)}</>)
			}
		},
		{
			key: 'septiembre',
			name: 'Sep',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 9)}</>)
			}
		},
		{
			key: 'octubre',
			name: 'Oct',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 10)}</>)
			}
		},
		{
			key: 'noviembre',
			name: 'Nov',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 11)}</>)
			}
		},
		{
			key: 'diciembre',
			name: 'Dic',
			width: 30,
			formatter({ row }) {
				return (<>{estadoMes(row, 12)}</>)
			}
		},
	];

	useEffect(
		() => {
			setAdmin(contexto.data.usuario.administrador)
			obtenerDatos();
			obtenerPoa();
		},
		[]
	)

	async function obtenerDatos() {
		let resultado = await api.poa.actividades(id, {planificacion: true});
		if (resultado && resultado.status === 200) {
			resultado.data.datos.forEach(actividad => {
				actividad.cantidad = actividad.cumplidas != '' ? actividad.cumplidas : '0';
				actividad.porcentaje_cumplidas = actividad.porcentaje_cumplidas != '' ? actividad.porcentaje_cumplidas : '0';
		});
			setRows(resultado.data.datos)
		} else {
			toast.error('Error al obtener datos');
		}
	}

	async function obtenerPoa() {
		let resultado = await api.poa.getOne(id, {});
		if (resultado && resultado.status === 200) {
			setPoa(resultado.data.datos);
			if (resultado.data.datos.usuario_id == contexto.data.usuario.id){
				setResponsable(true)
			}
			const permisosEdicion = contexto.data.usuario.permisos_registro
			permisosEdicion.forEach( permiso => {
					if (permiso.tabla_id == id && permiso.tabla_nombre === 'poa'){
							setPermisoEditor(true)
					}
			});
		} else {
			toast.error('Error al obtener POA');
		}
	}

	function exportarActividades(){
		exportar(`api/exportar/poa/${id}/actividades-ejecucion?planificacion=true`)
	}

  return (
		<>
			<ErpLayout
				breadcrumbs={[
					{active: true, pathname: '', name: 'Planificación'},
					{active: false, pathname: `/poa`, name: 'Poa'},
					{active: true, pathname: '', name: 'Ejecución'}]
				}>
				<CFormLabel className='mb-2'><b>{poa.area_protegida}</b> - {poa.periodo}</CFormLabel>
				<CRow>
					<CCol xs="12" sm="6" md="4" lg="3">
					</CCol>
				</CRow>
				<CNav variant="tabs">
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
								history.push(`/poa/${id}/elaboracion`)
							}}>
							1) Elaboración
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={true}>
							2) Ejecución
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
								history.push(`/poa/${id}/evaluacion`)
							}}>
							3) Evaluación
						</CNavLink>
					</CNavItem>
				</CNav>
				<CTabContent>
					<CTabPane visible={false}>

					</CTabPane>
					<CTabPane visible={true}>
						<CRow>
							<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
								<CButton
										color="light"
										style={{float:'right'}}
										onClick={exportarActividades}
										className="mx-1 my-1"
								>
										<FaRegFileExcel /> Exportar
								</CButton>
							</CCol>
						</CRow>
						<GrillaBase
							rowHeight={45}
							rowClass={(row) => (row.subestrategia === "2.4 Control de incendios forestales" ? 'subestrategia2-4' : undefined)}
							rows={rows}
							columns={columns}
						/>
					</CTabPane>
					<CTabPane visible={false}>

					</CTabPane>
				</CTabContent>

				<FormEjecucion
				  show={modalEjecucion}
				  planificacionId={planificacion.id}
				  actividadId={planificacion.actividad_id}
				  cerrar={() => {
					  setPlanificacion({})
					  setModalEjecucion(false)
					  obtenerDatos()
				  }}
				  retorno={() => {}} />
			</ErpLayout>
		</>
  )
}

export default Ejecucion