import React, { useState, useEffect, useContext } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import { FaSave, FaTimes } from 'react-icons/fa';
import SelectorAreaProtegidaTHRHF from '../../componentes/SelectorAreaProtegidaTHRHF';
import SelectorUsuarioMulti from '../../componentes/SelectorUsuarioMulti';
import SelectorUsuario from '../../componentes/SelectorUsuario';
import api from '../../utils/Routes';
import { fechaHoy } from '../../utils/Funciones';
import Fecha from '../../componentes/Fecha';
import UserContext from '../../Contexto'


const FormPoa = (props) => {
    const {show, close, poaId, ...rest} = props;
    const { register, handleSubmit, errors, setValue, control } = useForm({});
    const [poa, setPoa] = useState({});
    const [admin, setAdmin] = useState(false);
    const [fecha, setFecha] = useState(fechaHoy());
	const [usuarioId, setUsuarioId] = useState([]);
    const [responsableId, setResponsableId] = useState('');
    const contexto = useContext(UserContext)

    useEffect(
        () => {
            if (poaId) {
                obtenerDatos();
            }
            setAdmin(contexto.data.usuario.administrador)
        },
        [poaId]
    )

    const guardar = async (data) => {
        data.area_protegida_id = data.area_protegida.value;

        if (poa.id){
		    let resultado = await api.poa.put(poa.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    let resultado = await api.poa.post(data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                setPoa(resultado.data.datos)
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }

    const obtenerDatos = async () => {
        let resultado = await api.poa.getOne(poaId, {});
        if (resultado && resultado.status === 200) {
            let poaTemp = resultado.data.datos;
            setResponsableId(resultado.data.datos.usuario_id);
            setPoa(resultado.data.datos)
            setFecha(poaTemp.periodo + '-01-01');
            setValue([
                { responsable: poaTemp.usuario_nombre },
                { personal_permanente: poaTemp.personal_permanente },
                { personal_transitorio: poaTemp.personal_transitorio },
            ]);
            const usuarios = resultado.data.datos.usuarios
            setUsuarioId(usuarios)
        } else {
            cerrarModal()
            toast.error(`Error: ${resultado.data.msg}`);
        }
    }

    const cerrarModal = () => {
        setPoa({})
        close()
        setFecha(fechaHoy())
    }

    return (
        <CModal
            id="modal-poa"
            keyboard={false}
            visible={show}
            >
            <CModalHeader onDismiss={cerrarModal}>Plan Operativo Anual</CModalHeader>
            <CForm onSubmit={handleSubmit(guardar)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" className="mb-2">
                            <Fecha
                                name='periodo'
                                label='Periodo (año)'
                                register={register}
                                errors={errors}
                                dateFormat='yyyy'
                                formatoMesAno={false}
                                dateFormatOculto='y'
                                inicial={fecha}
                                onChange={() => {}}
                            />
                        </CCol>
                        {admin &&
                        <CCol xs="12" sm="12" md="12" className="mb-2">
                            <SelectorUsuario
                                iniciar={show}
                                label={'Seleccione responsable'}
                                control={control}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                setValue={setValue}
                                name='usuario_responsable'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadoId={responsableId ? responsableId : ''} // id del elemento seleccionado
                            />
                        </CCol>
                        }
                        <CCol xs="12" className="mb-2">
                            <CFormLabel htmlFor="nf-email">Responsable</CFormLabel>
                            <CFormControl
                                type="text"
                                name="responsable"
                                placeholder=""
                                disabled
                                ref={register({
                                    // required: { value: true, message: "campo obligatorio" }
                                })}
                            />
                            <ErrorMessage errors={errors} name="responsable" as="div" className="invalid-feedback" />
                        </CCol>
                        <CCol md='12' className="mb-2">
                            <SelectorUsuarioMulti
                                control={control}
                                label={'Seleccione usuarios editores'}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                iniciar={true}
                                name='usuarios'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadosId={usuarioId} // id de elementos seleccionado
                                defaultValue={usuarioId}
                                setValue={setValue}
                                onChange={(seleccionado) => {
                                    if (seleccionado) {
                                        let ids = []
                                        seleccionado.forEach(e => {
                                            ids.push(e.value)
                                        });
                                        setUsuarioId(ids)
                                    } else {
                                        setUsuarioId("")
                                    }
                                }}
                            />
                        </CCol>
                        <CCol xs="12" sm="12" md="6" className="mb-2">
                            <CFormLabel htmlFor="nf-email">Personal permanente</CFormLabel>
                            <CFormControl
                                type="number"
                                name="personal_permanente"
                                placeholder=""
                                ref={register({})}
                            />
                        </CCol>
                        <CCol xs="12" sm="12" md="6" className="mb-2">
                            <CFormLabel htmlFor="nf-email">Personal transitorio</CFormLabel>
                            <CFormControl
                                type="number"
                                name="personal_transitorio"
                                placeholder=""
                                ref={register({})}
                            />
                        </CCol>
                        <CCol xs="12" sm="12" md="12" className="mb-2">
                            <SelectorAreaProtegidaTHRHF
                                iniciar={show}
                                control={control}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                // opcionTodos={true}
                                setValue={setValue}
                                name='area_protegida'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadoId={poa.area_protegida_id ? poa.area_protegida_id : ''} // id del elemento seleccionado
                                // onChange={(seleccionado) => {
                                //     console.log('seleccionado', seleccionado)
                                // }}
                                // cargado={(data) => {
                                //     console.log('poa.id', poa.id)
                                //     setValue('area_protegida_id', poa.area_protegida_id)
                                //     console.log(data)
                                // }}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={cerrarModal}
                    ><FaTimes /> Cerrar</CButton>
                    <CButton color="success" type="submit"><FaSave/> Guardar</CButton>{' '}
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default FormPoa