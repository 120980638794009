import React, { useEffect, useState, useCallback } from 'react';
import { CCol, CRow, CBadge} from  '@coreui/react-pro';
import ErpLayout from '../layout/ErpLayout';
import CargarArchivos from '../componentes/CargarArchivos';
import FiltroAreaProtegidaTH from '../componentes/FiltroAreaProtegidaTH';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import Buscador from '../componentes/Buscador';
import FiltroTipoInformacion from '../componentes/FiltroTipoInformacion';
import VistaArchivos from '../componentes/VistaArchivos';

const Archivos = () => {
    const [documentos, setDocumentos] = useState([]);
    const [tipoInformacionSeleccionado, setTipoInformacionSeleccionado] = useState('');
    const [areaProtegida, setAreaProtegida] = useState('');
    const [textoBuscar, setTextoBuscar] = useState('');
    const [showFormArchivo, setShowFormArchivo] = useState(false);
    const [archivo, setArchivo] = useState({});
    const [total, setTotal] = useState('');
    const [parcial, setParcial] = useState('');

    const cargarDocumentos = useCallback(async () => {
        let resultado = await api.documentos.get({ tipo_informacion_id: tipoInformacionSeleccionado, buscador: textoBuscar, area_protegida_id: areaProtegida });
        if (resultado && resultado.status === 200) {
            setDocumentos(resultado.data.datos);
            setTotal(resultado.data.total);
            setParcial(resultado.data.parcial);
        } else {
            toast.error('Error al cargar información');
        }
    }, [tipoInformacionSeleccionado, textoBuscar, areaProtegida]);

    useEffect(
        () => {
            cargarDocumentos();
        }, [tipoInformacionSeleccionado, textoBuscar, areaProtegida, cargarDocumentos]
    )

    const imagen = (documento) => {
        if (['docx', 'doc'].includes(documento.tipo_archivo)) {
            return 'icon/doc.png'
        } else if (['pdf'].includes(documento.tipo_archivo)) {
            return 'icon/pdf.png'
        } else if (['png', 'jpg', 'jpeg'].includes(documento.tipo_archivo)) {
            return documento.ruta_completa
        } else if (['mp4', 'avi'].includes(documento.tipo_archivo)) {
            return 'icon/mp4.png'
        } else if (['ppt', 'pptx'].includes(documento.tipo_archivo)) {
            return 'icon/ppt.png'
        } else if (['xls', 'xlsx'].includes(documento.tipo_archivo)) {
            return 'icon/xls.png'
        } else if (['jpg', 'jpeg', 'jpg'].includes(documento.tipo_archivo)) {
            return 'icon/jpg.png'
        } else {
            return 'icon/file.png'
        }
    }

    const bagdeExtension = (documento) => {
        var color = '#0e9d58';
        if (['docx', 'doc', 'odt'].includes(documento.tipo_archivo)) {
            color = '#4285f4'
        } else if (['pdf'].includes(documento.tipo_archivo)) {
            color = '#ea4335'
        } else if (['png', 'jpg', 'jpeg', 'gif', 'svg'].includes(documento.tipo_archivo)) {
            color = '#d93025'
        } else if (['mp4', 'avi'].includes(documento.tipo_archivo)) {
            color = '#d93025'
        } else if (['ppt', 'pptx'].includes(documento.tipo_archivo)) {
            color = '#fd7541'
        } else if (['xls', 'xlsx'].includes(documento.tipo_archivo)) {
            color = '#0e9d58'
        } else {
            color = '#0e9d58';
        }
        return <CBadge style={{ backgroundColor: color, color: 'white', display: 'inline-block !important', width: '40px', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', textTransform:'uppercase' }}>
            {documento.tipo_archivo}
        </CBadge>
    }

    function verDoc(documento){
        setShowFormArchivo(true);
        setTimeout(function () {
            setArchivo(documento);
        }, 500);
    }
    return (
        <>
        <ErpLayout>
            <CRow>
                <CCol xs="12" sm="6" md="4" lg="3">
                    <CargarArchivos retorno={(archivo)=> {
                        cargarDocumentos(); // luego de cargardo el archivo en bd, actualizamos grilla
                    }}/>
                </CCol>
            </CRow>
            <CRow>
                <CCol xs="12" sm="4" md="4">
                    <Buscador
                        placeholder='Ingrese un texto para buscar...'
                        retornoBusqueda={(text) => {
                            setTextoBuscar(text);
                        }}
                    />
                </CCol>
                <CCol xs="12" sm="4" md="4">
                    <FiltroAreaProtegidaTH
                        todos={true}
                        onChange={(e) => {
                            setAreaProtegida(e ? e.value : '')
                        }}
                    />
                    {/* <SelectorAreaProtegidaTH
                        iniciar={true}
                        opcionTodos={true}
                        placeholder='Todos'
                        onChange={(e) => {
                            setAreaProtegida(e ? e.value : '')
                        }}
                    /> */}
                </CCol>
                <CCol xs="12" sm="4" md="4">
                    <FiltroTipoInformacion
                        todos={true}
                        onChange={(e) => {
                            setTipoInformacionSeleccionado(e ? e.value : '')
                        }}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    <div><span style={{fontSize: '20px', fontWeight: 500}}>Resultados de la búsqueda: </span> {parcial} de {total} archivos</div>
                </CCol>
            </CRow>
            <CRow>
                <VistaArchivos
                    documentos={documentos}
                    botonELiminar={true}
                    retorno={() => {
                        cargarDocumentos()
                        setArchivo({})
                        setShowFormArchivo(false)
                    }}
                    />
            </CRow>
        </ErpLayout>
        </>
    )
}

export default Archivos