import React, { useState } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { loading } from '../utils/Api';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import SelectorTipoInformacionValidacion from '../componentes/SelectorTipoInformacionValidacion';
import Fecha from '../componentes/Fecha';
import { fechaHoy } from '../utils/Funciones';
import { FaSave, FaTimes } from 'react-icons/fa';
import SelectorAreaProtegidaTHRHF from './SelectorAreaProtegidaTHRHF';
import SelectorTipoInformacionTHRHF from './SelectorTipoInformacionTHRHF';
import './CargarArchivos.css'

const CargarArchivos = (props) => {
    const { register, handleSubmit, errors, setValue, getValues, reset, control } = useForm({});
    const [modal, setModal] = useState(false);
    const actionBase = {
        meta: '',
        file: '',
        remove: ''
    }
    const [action, setAction] = useState(actionBase)

    const cerrarModal = () => {
        action.remove()
        setAction(actionBase)
        setModal(!modal);
        reset()
    }

    const handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status === 'done') {
            setAction({
                meta: meta,
                file: file,
                remove: remove
            })
            setModal(true);
            setValue('nombre_documento', meta.name.split('.').slice(0, -1).join('.'))
        } else if (status === 'preparing') {
        } else if (status === 'error_upload') {
            toast.error(`Error al cargar archivo ${meta.name}`);
        }
    }

    async function guardar() {
        let formData = new FormData();
        formData.append("file", action.file);
        formData.append("nombre", getValues().nombre_documento);
        formData.append("descripcion", getValues().descripcion);
        formData.append("tipo_informacion", getValues().tipo_informacion.value);
        formData.append("fecha", getValues().fecha);
        if (getValues().tipo_informacion === '') {
            toast.error(`Debe seleccionar tipo de información`);
        }
        if (getValues().area_protegida_id.value === 'general') {
            formData.append("area_protegida_id", '');
        } else {
            formData.append("area_protegida_id", getValues().area_protegida_id.value);
        }

        loading(true);
        toast.success(`Procesando archivo, espere unos momentos`);
        const token = localStorage.getItem('token');
        const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/documentos`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} });
        ruta.then(respuesta => {
            toast.success(`Archivo cargado ${action.meta.name}`);
            props.retorno(respuesta.data.data);
            cerrarModal()
            loading(false);
        }).catch((error) => {
            toast.error(`Error al cargar archivo ${action.meta.name}}`);
            toast.error(`Mensaje: ${error.response.data.msg}`);
            action.remove()
            loading(false);
        });
    }

    return (
        <>
            <Dropzone
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                // onSubmit={handleSubmit}
                // accept="image/*,audio/*,video/*"
                inputContent="Click para cargar documento"
                maxFiles={1}
                multiple={false}
                // canCancel={false}
                // styles={{
                //     dropzone: { width: 400, height: 200 },
                //     dropzoneActive: { borderColor: 'green' },
                // }}
            />
            <CModal
                id="modal-cargar-archivos"
                keyboard={false}
                visible={modal}
                >
                <CModalHeader onDismiss={cerrarModal}>Complete la información del documento</CModalHeader>
                <CForm onSubmit={handleSubmit(guardar)}>
                    <CModalBody>
                        <CRow>
                            <CCol xs="12" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Nombre de archivo</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="nombre_documento"
                                    placeholder=""
                                    ref={register({
                                        required: { value: true, message: "campo obligatorio" }
                                    })}
                                />
                                <ErrorMessage errors={errors} name="nombre_documento" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" className="mb-2">
                                {/* <SelectorTipoInformacionValidacion
                                    todos={false}
                                    name='tipo_informacion'
                                    // onChange={(e) => {
                                    //     console.log('onChange', e.target.value)
                                    // }}
                                    register={register}
                                    errors={errors}
                                    // cargado={(data) => {
                                    //     console.log('cargado', data)
                                    // }}
                                /> */}
                                <SelectorTipoInformacionTHRHF
                                    iniciar={modal}
                                    control={control}
                                    Controller={Controller}
                                    ErrorMessage={ErrorMessage}
                                    errors={errors}
                                    // opcionTodos={true}
                                    setValue={setValue}
                                    name='tipo_informacion'
                                    opcionGeneral={false}
                                    placeholder=''
                                    seleccionadoId={''} // id del elemento seleccionado
                                    // onChange={(seleccionado) => {
                                    //     console.log('seleccionado', seleccionado)
                                    // }}
                                    // cargado={(data) => {
                                    //     console.log(data)
                                    // }}
                                />
                            </CCol>
                            {/* <CCol xs="12">
                                <SelectorAreaProtegidaValidacionRHF
                                    todos={false}
                                    noSeleccionado={false}
                                    general={true}
                                    name='area_protegida_id'
                                    // onChange={(e) => {
                                    //     console.log('onChange', e.target.value)
                                    // }}
                                    register={register}
                                    errors={errors}
                                    // cargado={(data) => {
                                    //     console.log('cargado', data)
                                    // }}
                                />
                            </CCol> */}
                            <CCol xs="12" sm="12" md="12" className="mb-2">
                                {/* <SelectorAreaProtegidaTH
                                    iniciar={modal}
                                    // opcionTodos={true}
                                    opcionGeneral={true}
                                    placeholder='General'
                                    seleccionadoId={areaProtegidaId}
                                    onChange={(e) => {
                                        setAreaProtegidaId(e ? e.value : 'general')
                                    }}
                                /> */}
                                <SelectorAreaProtegidaTHRHF
                                    iniciar={modal}
                                    control={control}
                                    Controller={Controller}
                                    ErrorMessage={ErrorMessage}
                                    errors={errors}
                                    // opcionTodos={true}
                                    setValue={setValue}
                                    name='area_protegida_id'
                                    opcionGeneral={true}
                                    placeholder='No seleccionado'
                                    seleccionadoId={'general'} // id del elemento seleccionado
                                    // onChange={(seleccionado) => {
                                    //     console.log('seleccionado', seleccionado)
                                    // }}
                                    // cargado={(data) => {
                                    //     console.log(data)
                                    // }}
                                />
                            </CCol>
                            <CCol xs="12" className="mb-2">
                                <Fecha
                                    name='fecha'
                                    label='Fecha documento'
                                    register={register}
                                    errors={errors}
                                    dateFormat='dd/MM/yyyy'
                                    formatoMesAno={false}
                                    dateFormatOculto='y-m-d'
                                    inicial={fechaHoy()}
                                    onChange={() => {}}
                                />
                            </CCol>
                            <CCol xs="12" md="12" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Descripción</CFormLabel>
                                <CFormControl
                                    name="descripcion"
                                    component="textarea"
                                    rows="2"
                                    placeholder=""
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="descripcion" as="div" className="invalid-feedback" />
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="light"
                            onClick={cerrarModal}
                        ><FaTimes /> Cerrar</CButton>
                        <CButton color="success" type="submit"><FaSave/> Guardar</CButton>{' '}
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default CargarArchivos