import React, { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react-pro';
import ErpLayout from '../layout/ErpLayout';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter } from '../componentes/GrillaBase';
import { useParams } from 'react-router-dom';

const Personal = (props) => {
  const [fechaDoc, setFechaDoc] = useState('')
  const columns = [
    { key: 'rut', name: 'Rut', formatter: LongTextFormatter, width: 100 },
    { key: 'nombres', name: 'Nombres', formatter: LongTextFormatter, width: 150 },
    { key: 'apellido_paterno', name: 'Apellido', formatter: LongTextFormatter, width: 140 },
    { key: 'profesion', name: 'Profesión', formatter: LongTextFormatter, width: 200 },
    { key: 'nivel_educacional', name: 'Nivel educacional', formatter: LongTextFormatter, width: 200 },
    { key: 'cargo_funcion', name: 'Cargo/Función', formatter: LongTextFormatter, width: 200 },
    { key: 'nombre_area_protegida', name: 'Unidad ASP', formatter: LongTextFormatter, width: 250 },
    { key: 'nombre_region', name: 'Región', formatter: LongTextFormatter },
  ];

  let { id } = useParams();
  const [rows, setRows] = useState([]);

  useEffect(
    () => {
      if (id) {
        fechaDoc != '' ? obtenerDatos(fechaDoc) : obtenerDocumento()
      }
    },
    [fechaDoc, id]
  )

  async function obtenerDocumento() {
    let resultado = await api.documentos.getOne(id);
    if (resultado && resultado.status === 200) {
      setFechaDoc(resultado.data.datos.fecha_documento)
    } else {
      toast.error('Error al obtener los datos del documento');
    }
  }
  async function obtenerDatos(fecha) {
    let resultado = await api.personal.get({ fecha_documento: fecha })
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else { toast.error('Error al obtener datos'); }
  }

  return (
    <>
      <ErpLayout
          breadcrumbs={[
            {active: true, pathname: '', name: 'Administración'},
            {active: false, pathname: `/previa-personal`, name: 'Personal'},
            {active: true, pathname: '', name: 'Periodo: '+fechaDoc}]
        }
      >
        <GrillaBase
          rows={rows}
          columns={columns}
        />
      </ErpLayout>
    </>
  )
}

export default Personal