import React, { useEffect, useState } from 'react';
import { CRow, CCol, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter } from '../../componentes/GrillaBase';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import { exportar } from '../../utils/Funciones';
import { FaRegFileExcel } from 'react-icons/fa';


const GrillaResumenInvestigacionAsp = () => {
  const [rows, setRows] = useState([]);
  const [regionId, setRegionId] = useState('');
  const columns = [
    { key: 'nombre', name: 'ASP', minWidth: 250 },
    { key: 'nombre_region', name: 'Región', width: 250 },
    { key: 'tramitacion', name: 'En tramitación', width: 150, formatter: NumberFormatter },
    { key: 'en_proceso', name: 'En proceso', width: 150, formatter: NumberFormatter },
    { key: 'informe_pendiente', name: 'Informe pendiente', width: 150, formatter: NumberFormatter },
    { key: 'sin_informacion', name: 'Sin información', width: 150, formatter: NumberFormatter },
    { key: 'ampliacion_plazo', name: 'Ampliación de plazo', width: 150, formatter: NumberFormatter },
    { key: 'finalizada', name: 'Finalizada', width: 150, formatter: NumberFormatter },
    { key: 'total', name: 'Total', width: 150, formatter: NumberFormatter },
  ];

  useEffect(
    () => {
      obtenerDatos()
    },
    [regionId]
  )

  async function obtenerDatos() {
    let resultado = await api.investigacion.reporteAsp({ region_id: regionId });
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  function exportarReporte(){
    exportar(`/api/exportar/investigacion/resumen-asp?region_id=${regionId}`);
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          { active: true, pathname: '', name: 'Investigación' },
          { active: true, pathname: `/investigacion`, name: 'Resumen por área protegida' }]
        }>
        <CRow className='mb-2' >
          <CCol xs="12" sm="9" className="mb-2">
            <FiltroRegionesTH
              todos={true}
              onChange={(e) => {
                setRegionId(e ? e.value : '')
              }}
            />
          </CCol>
          <CCol sm="3">
            <CButton
                color="light"
                onClick={exportarReporte}
                className="mt-4 float-sm-end mx-1"
                ><FaRegFileExcel />
                    Exportar
            </CButton>
        </CCol>
        </CRow>
        <GrillaBase
          className="mt-2"
          rows={rows}
          columns={columns}
        />
      </ErpLayout>
    </>
  )
}

export default GrillaResumenInvestigacionAsp