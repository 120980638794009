import React from 'react';
import { CCol, CRow} from '@coreui/react-pro';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraficoCumplimientoTotal = (props) => {
    const { rows, regionId, fecha, encuestaId, ...rest } = props;

    return (
        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12" className="py-2 mt-2" style={{textAlign: 'center'}}>
                <h5>Porcentaje de cumplimiento total o parcial de los indicadores</h5>
            </CCol>

            <CCol xs="12" sm="12" md="12" lg="12" className="py-2"  style={{overflowX: 'scroll'}}>
                <div style={{ minWidth: '650px', width: '100%', height: 600 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            width={500}
                            height={700}
                            data={rows}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="4 4" />
                        <XAxis type="number" />
                        <YAxis dataKey="area_protegida_nombre" interval={0} type="category" width={400} fontSize={14} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Cumplimiento" stackId="a" fill="#8884d8" label={{ value: 'Porcentaje', position: 'right' }}/>
                        </BarChart>
                        {/* <XAxis  dataKey="area_protegida_nombre"/>
                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" label={{ value: 'Porcentaje',angle: -90,  position: 'insideLeft' }}  />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Cumplimiento" fill="#8884d8" label={{ value: 'pv of page',  position: 'insideLeft' }}/>
                        </BarChart> */}
                        {/* angle={-90} height={300} allowDataOverflow={true} */}
                    </ResponsiveContainer> 
                </div>
            </CCol>
        </CRow>
    )
}

export default GraficoCumplimientoTotal