import React, { useEffect, useState } from 'react';
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CFormCheck, CCard, CCardHeader, CCardBody, CNav, CNavItem, CNavLink, CTabPane, CTabContent } from '@coreui/react-pro'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave } from 'react-icons/fa';
import { IoMdReturnLeft } from 'react-icons/io';
import { useHistory, useParams } from "react-router-dom";
import SelectorAreaTHRHF from '../../componentes/SelectorAreaTHRHF';
import SelectorSectorTHRHF from '../../componentes/SelectorSectorTHRHF';
import SelectorTipoInstalacionTHRHF from '../../componentes/SelectorTipoInstalacionTHRHF';
import { Link } from 'react-router-dom';
import CargarDocumento from '../../componentes/CargarDocumento';
import Mapa from '../../componentes/Mapa';
import SelectorAreaProtegidaTHRHF from '../../componentes/SelectorAreaProtegidaTHRHF';
import Fecha from '../../componentes/Fecha';

const FormInfraestructura = () => {
	let history = useHistory();
	const { register, handleSubmit, errors, setValue, control, watch } = useForm({});
	const [infraestructura, setInfraestructura] = useState({});
	let { id, area_protegida_id } = useParams();
	const [unidadesTipoInstalacion, setUnidadesTipoInstalacion] = useState([]);
	const [areaProtegida, setAreaProtegida] = useState({})
	const [areaProtegidaId, setAreaProtegidaId] = useState("")
	const [activeTab, setActiveTab] = useState('información');
	const [sectorSeleccionado, setSectorSeleccionado] = useState({});
	const [areaSeleccionada, setAreaSeleccionada] = useState({});
	const [documentos, setDocumentos] = useState([]);
	const [enviarArchivos, setEnviarArchivos] = useState(false);
	const [fechaConstruccion, setFechaConstruccion] = useState('');
	const [fechaUltimaMantencion, setFechaUltimaMantencion] = useState('');

	// optimizamos la caraga del mapa, solo si presionan el tab cargamos esto
	const [cargarMapa, setCargarMapa] = useState(false);
	const [marcador, setMarcador] = useState({
		latitud: '',
		longitud: '',
		zoom: 6
	})

	useEffect(
		() => {
			if (id && id !== 'crear') {
				obtenerDatos()
			}
			if (area_protegida_id) {
				obtenerAreaProtegida()
			}
		},
		[id, area_protegida_id]
	)

	useEffect(
		() => {
			if (infraestructura.unidades) {
				infraestructura.unidades.forEach(unidad => {
					setValue(String(unidad.unidad_id), unidad.cantidad)
				});
			}
		},
		[unidadesTipoInstalacion]
	)

	async function obtenerDatos() {
		let resultado = await api.infraestructura.getOne(id);//rows[rowIdx].id
		if (resultado && resultado.status === 200) {
			let infraestructura = resultado.data.datos;
			setInfraestructura(resultado.data.datos)
			setDocumentos(infraestructura.documentos);

			setFechaConstruccion(infraestructura.fecha_construccion ? infraestructura.fecha_construccion + '-01-01' : '');
			setFechaUltimaMantencion(infraestructura.fecha_ultima_mantencion ? infraestructura.fecha_ultima_mantencion + '-01-01' : '')
			setValue([
				{ nombre: infraestructura.nombre },
				// { fecha_construccion: infraestructura.fecha_construccion },
				// { fecha_ultima_mantencion: infraestructura.fecha_ultima_mantencion },
				{ administrador_instalacion_nombre: infraestructura.administrador_instalacion_nombre },
				{ codigo_inventario: infraestructura.codigo_inventario },
				{ capacidad_carga: infraestructura.capacidad_carga },
				{ capacidad_hombres: infraestructura.capacidad_hombres },
				{ capacidad_mujeres: infraestructura.capacidad_mujeres },
				{ capacidad_otros: infraestructura.capacidad_otros },
				{ accesibilidad_universal: infraestructura.accesibilidad_universal },
				{ costo_anual_mantencion: infraestructura.costo_anual_mantencion },
				{ costo_anual_operacion: infraestructura.costo_anual_operacion },
				{ monto_inversion: infraestructura.monto_inversion },
				{ obra_gruesa: infraestructura.obra_gruesa },
				{ terminaciones: infraestructura.terminaciones },
				{ instalaciones: infraestructura.instalaciones },
				{ captacion_agua: infraestructura.captacion_agua },
				{ potabilizacion_agua: infraestructura.potabilizacion_agua },
				{ tratamiento_aguas_servidas: infraestructura.tratamiento_aguas_servidas },
				{ reciclaje_agua: infraestructura.reciclaje_agua },
				{ sistema_generacion_electrica: infraestructura.sistema_generacion_electrica },
				{ sistema_calefaccion: infraestructura.sistema_calefaccion },
			])
			if (infraestructura.latitud) {
				setMarcador({
					latitud: infraestructura.latitud,
					longitud: infraestructura.longitud,
					zoom: infraestructura.zoom_mapa
				});
			}
			setAreaSeleccionada(infraestructura.area_id)
		} else {
			setInfraestructura({})
			toast.error(resultado.data.msg);
		}
	}

	async function obtenerAreaProtegida() {
		let resultado = await api.areaProtegida.getOne(area_protegida_id);
		if (resultado && resultado.status === 200) {
			setAreaProtegida(resultado.data.datos)
			setAreaProtegidaId(resultado.data.datos.id)
		} else {
			toast.error('Error al obtener datos');
		}
	}

	const onSubmit = async (data) => {
		data.area_id = data.area.value;
		data.tipo_instalacion_id = data.tipo_instalacion.value;

		let unidades = []
		unidadesTipoInstalacion.forEach(unidad => {
			unidades.push({
				unidad_id: unidad.id,
				cantidad: data[unidad.id],
				tipo_instalacion_id: data.tipo_instalacion_id
			})
		});
		data.unidades = unidades;
		if (marcador.latitud !== '' && (marcador <= -90 || marcador >= 90)) {
			toast.error('Latitud incorrecta', { autoClose: 6000 });
			return false;
		}
		if (marcador.longitud !== '' && (marcador.longitud <= -180 || marcador.longitud >= 180)) {
			toast.error('Longitud incorrecta', { autoClose: 6000 });
			return false;
		}
		data.latitud = marcador.latitud;
		data.longitud = marcador.longitud;
		data.zoom_mapa = marcador.zoom;

		if (infraestructura.id) {
			let resultado = await api.infraestructura.put(infraestructura.id, data);
			if (resultado && resultado.status === 200) {
				setEnviarArchivos(true)
			} else {
				toast.error(resultado.data.msg, { autoClose: 8000 });
			}
		} else {
			let resultado = await api.infraestructura.post(data);
			if (resultado && resultado.status === 200) {
				setInfraestructura(resultado.data.datos)
				setEnviarArchivos(true)
			} else {
				toast.error(resultado.data.msg, { autoClose: 8000 });
			}
		}
	}

	return (
		<>
			<ErpLayout
				breadcrumbs={[
					{ active: true, pathname: '', name: 'Administración' },
					history.location.state ? {active: false , pathname: '/infraestructuras', name:'Infraestructuras'}:{ active: false , pathname: area_protegida_id ? `/infraestructurasAsp` : `/infraestructuras`, name: 'Infraestructuras' },
					history.location.state ? {}:{ active: false, pathname: `/area-protegida/${area_protegida_id}/infraestructura`, name: areaProtegida.nombre },
					{ active: true, pathname: '', name: 'Formulario' },]
				}
			>
				<CForm onSubmit={handleSubmit(onSubmit)}>
					<CNav variant="tabs">
						<CNavItem>
							<CNavLink data-tab="preview" active={activeTab === 'información'} onClick={() => setActiveTab('información')}>
								Información
						</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink data-tab="metadata" active={activeTab === 'ubicación'} onClick={() => {
								setActiveTab('ubicación')
								setCargarMapa(true)
							}}>
								Ubicación
						</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink data-tab="metadata" active={activeTab === 'documentos'} onClick={() => setActiveTab('documentos')}>
								Documentos
						</CNavLink>
						</CNavItem>
					</CNav>
					<CTabContent>
						<CTabPane data-tab="preview" visible={activeTab === 'información'}>
							<CCard className="mb-4 mt-2">
								<CCardHeader style={{ textAlign: 'center' }}>
									Información
							</CCardHeader>
								<CCardBody>
									<CRow>
										<CCol >
											<CRow>
												<CCol xs="12" sm="12" md="4" lg="4" className="mb-2">
													<SelectorAreaProtegidaTHRHF
														iniciar={true}
														control={control}
														Controller={Controller}
														ErrorMessage={ErrorMessage}
														errors={errors}
														// opcionTodos={true}
														setValue={setValue}
														name='area_protegida_id'
														opcionGeneral={false}
														placeholder='No seleccionado'
														seleccionadoId={areaProtegidaId} // id del elemento seleccionado
														onChange={(seleccionado) => {
															if (seleccionado) {
																setAreaProtegidaId(seleccionado.value)
																setSectorSeleccionado({})
															} else {
																setAreaProtegida("")
															}
														}}
													/>
												</CCol>
												{areaProtegidaId &&
													<CCol xs="12" sm="12" md="4" lg="4" className="mb-2">
														<SelectorSectorTHRHF
															areaProtegidaId={areaProtegidaId}
															iniciar={true}
															control={control}
															Controller={Controller}
															ErrorMessage={ErrorMessage}
															errors={errors}
															// opcionTodos={true}
															setValue={setValue}
															name='sector'
															// opcionGeneral={true}
															placeholder='No seleccionado'
															seleccionadoId={infraestructura.sector_id} // id del elemento seleccionado
															onChange={(seleccionado) => {
																if (seleccionado) {
																	setSectorSeleccionado(seleccionado)
																	setAreaSeleccionada('')
																} else {
																	setSectorSeleccionado({})
																	setAreaSeleccionada('')
																}
															}}
														// cargado={(data) => {
														// }}
														/>
													</CCol>}
												{sectorSeleccionado.id &&
													<CCol xs="12" sm="12" md="4" lg="4" className="mb-2">
														<SelectorAreaTHRHF
															areaProtegidaId={areaProtegidaId}
															sectorId={sectorSeleccionado.id ? sectorSeleccionado.id : ''}
															iniciar={false}
															control={control}
															Controller={Controller}
															ErrorMessage={ErrorMessage}
															errors={errors}
															// opcionTodos={true}
															setValue={setValue}
															name='area'
															// opcionGeneral={true}
															placeholder='No seleccionado'
															seleccionadoId={areaSeleccionada} // id del elemento seleccionado
															onChange={(seleccionado) => {
																if (seleccionado) {
																	setAreaSeleccionada(seleccionado.value)
																} else {
																	setAreaSeleccionada(null)
																}
															}}
															cargado={(data) => {
																data.forEach(area => {
																	if (area.id === infraestructura.area_id) {
																		setAreaSeleccionada(infraestructura.area_id)
																	}
																});
															}}
														/>
													</CCol>}
											</CRow>
											<CRow>
												<CCol xs="6" sm="6" md="12" className="mb-2">
													<CFormLabel htmlFor="nf-email">Nombre de la instalación turística</CFormLabel>
													<CFormControl
														type="text"
														name="nombre"
														placeholder=""
														ref={register({
															required: { value: true, message: "campo obligatorio" }
														})}
													/>
													<ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
												</CCol>
											</CRow>
											<CRow>
												<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
													<SelectorTipoInstalacionTHRHF
														iniciar={true}
														control={control}
														Controller={Controller}
														ErrorMessage={ErrorMessage}
														errors={errors}
														setValue={setValue}
														name='tipo_instalacion'
														opcionGeneral={true}
														placeholder='No seleccionado'
														seleccionadoId={infraestructura.tipo_instalacion_id} // id del elemento seleccionado
														onChange={(seleccionado) => {
															if (seleccionado) {
																setUnidadesTipoInstalacion(seleccionado.unidades)
															} else {
																setUnidadesTipoInstalacion([])
															}
														}}
													// cargado={(data) => {
													// }}
													/>
												</CCol>
												{unidadesTipoInstalacion.map((tipo) =>
													<CCol xs="12" sm="12" md="4" className="mb-2" key={tipo.id + 'a'}>
														<CFormLabel htmlFor="nf-email">{tipo.nombre}</CFormLabel>
														<CFormControl
															type="number"
															step="any"
															name={tipo.id}
															placeholder=""
															ref={register({
																// required: { value: true, message: "campo obligatorio" }
															})}
														/>
														{/* <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" /> */}
													</CCol>
												)}
											</CRow>
											<CRow>
												<CCol xs="12" sm="12" md="3" className="mb-2">
													<Fecha
														name='fecha_construccion'
														label='Año Construcción'
														register={register}
														dateFormat='yyyy'
														formatoMesAno={false}
														dateFormatOculto='y'
														inicial={fechaConstruccion}
														onChange={() => {}}
													/>
												</CCol>
												<CCol xs="12" sm="12" md="3" className="mb-2">
													<Fecha
														name='fecha_ultima_mantencion'
														label='Año última mantención'
														register={register}
														dateFormat='yyyy'
														formatoMesAno={false}
														dateFormatOculto='y'
														inicial={fechaUltimaMantencion}
														onChange={() => {}}
													/>
												</CCol>
												<CCol xs="6" sm="6" md="6" className="mb-2">
													<CFormLabel htmlFor="nf-email">Administración de la instalación turística</CFormLabel>
													<select
														className="form-select"
														name="administrador_instalacion_nombre"
														ref={register({
															required: { message: "campo obligatorio" }
														})}>
														<option value="CONAF">CONAF</option>
														<option value="Concesión">Concesión</option>
														<option value="Convenio de Colaboración">Convenio de Colaboración</option>
														<option value="Comodato">Comodato</option>
														<option value="Ministerio de Bienes Nacionales">Ministerio de Bienes Nacionales</option>
														<option value="Otro">Otro</option>
													</select>

													<ErrorMessage errors={errors} name="administrador_instalacion_nombre" as="div" className="invalid-feedback" />
												</CCol>
												<CCol xs="6" sm="6" md="6" className="mb-2">
													<CFormLabel htmlFor="nf-email">Código inventario/nomenclatura GASP</CFormLabel>
													<CFormControl
														type="text"
														name="codigo_inventario"
														placeholder=""
														ref={register({})}
													/>
												</CCol>

											</CRow>
											<CRow>
												<CCol xs="3" sm="3" md="3" className="mb-2">
													<CFormLabel htmlFor="nf-email">Capacidad carga / ocupación</CFormLabel>
													<CFormControl
														type="number"
														name="capacidad_carga"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
												<CCol xs="3" sm="3" md="3" className="mb-2">
													<CFormLabel htmlFor="nf-email">Capacidad Hombres</CFormLabel>
													<CFormControl
														type="number"
														name="capacidad_hombres"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
												<CCol xs="3" sm="3" md="3" className="mb-2">
													<CFormLabel htmlFor="nf-email">Capacidad Mujeres</CFormLabel>
													<CFormControl
														type="number"
														name="capacidad_mujeres"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
												<CCol xs="3" sm="3" md="3" className="mb-2">
													<CFormLabel htmlFor="nf-email">Capacidad Otros</CFormLabel>
													<CFormControl
														type="number"
														name="capacidad_otros"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
											</CRow>
											<CFormCheck
												label="Instalación Cuenta con accesibilidad universal"
												className="mb-2"
												name="accesibilidad_universal"
												ref={register({})} />
											<CRow>
												<CCol xs="4" sm="4" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Costo anual mantención</CFormLabel>
													<CFormControl
														type="number"
														step="any"
														name="costo_anual_mantencion"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
												<CCol xs="4" sm="4" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Costo anual operación</CFormLabel>
													<CFormControl
														type="number"
														step="any"
														name="costo_anual_operacion"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
												<CCol xs="4" sm="4" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto estimado inversión</CFormLabel>
													<CFormControl
														type="number"
														step="any"
														name="monto_inversion"
														placeholder=""
														ref={register({})}
													/>
												</CCol>
											</CRow>
										</CCol>
									</CRow>
								</CCardBody>
							</CCard>
							<CCard>
								<CCardHeader style={{ textAlign: 'center' }}>
									Estado de la instalación turística
							</CCardHeader>
								<CCardBody>
									<CRow>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Obra Gruesa</CFormLabel>
											<select
												className="form-select"
												name="obra_gruesa"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Nuevo">Nuevo</option>
												<option value="Bueno">Bueno</option>
												<option value="Satisfactorio">Satisfactorio</option>
												<option value="Regular">Regular</option>
												<option value="Deficiente">Deficiente</option>
												<option value="Malo">Malo</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="obra_gruesa" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Terminaciones</CFormLabel>
											<select
												className="form-select"
												name="terminaciones"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Nuevo">Nuevo</option>
												<option value="Bueno">Bueno</option>
												<option value="Satisfactorio">Satisfactorio</option>
												<option value="Regular">Regular</option>
												<option value="Deficiente">Deficiente</option>
												<option value="Malo">Malo</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="terminaciones" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Instalaciones</CFormLabel>
											<select
												className="form-select"
												name="instalaciones"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Nuevo">Nuevo</option>
												<option value="Bueno">Bueno</option>
												<option value="Satisfactorio">Satisfactorio</option>
												<option value="Regular">Regular</option>
												<option value="Deficiente">Deficiente</option>
												<option value="Malo">Malo</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="instalaciones" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Captación de agua</CFormLabel>
											<select
												className="form-select"
												name="captacion_agua"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Red pública">Red pública</option>
												<option value="Pozo profundo">Pozo profundo</option>
												<option value="Captación rio">Captación rio</option>
												<option value="Captación vertiente">Captación vertiente</option>
												<option value="Captación lago">Captación lago</option>
												<option value="Captación aguas lluvias">Captación aguas lluvias</option>
												<option value="No tiene">No tiene</option>
												<option value="Otro">Otro</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="captacion_agua" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Potabilización del agua</CFormLabel>
											<select
												className="form-select"
												name="potabilizacion_agua"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Sí">Sí</option>
												<option value="No">No</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="potabilizacion_agua" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Tratamiento aguas servidas</CFormLabel>
											<select
												className="form-select"
												name="tratamiento_aguas_servidas"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Red pública">Red pública</option>
												<option value="Planta de tratamiento">Planta de tratamiento</option>
												<option value="Fosa séptica con pozo absorvente">Fosa séptica con pozo absorvente</option>
												<option value="Fosa séptica con cancha de drenes">Fosa séptica con cancha de drenes</option>
												<option value="Directo a pozo absorvente">Directo a pozo absorvente</option>
												<option value="No tiene">No tiene</option>
												<option value="Otro">Otro</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="tratamiento_aguas_servidas" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Reciclaje de aguas</CFormLabel>
											<select
												className="form-select"
												name="reciclaje_agua"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Sí">Sí</option>
												<option value="No">No</option>
												<option value="No corresponse">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="reciclaje_agua" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Sistema generación eléctrica</CFormLabel>
											<select
												className="form-select"
												name="sistema_generacion_electrica"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Generador">Generador</option>
												<option value="Turbina eólica">Turbina eólica</option>
												<option value="Panel solar">Panel solar</option>
												<option value="Mixto eólico-solar">Mixto eólico-solar</option>
												<option value="Central hidroeléctrica">Central hidroeléctrica</option>
												<option value="Red pública aérea">Red pública aérea</option>
												<option value="Red pública subterránea">Red pública subterránea</option>
												<option value="No tiene">No tiene</option>
												<option value="Otro">Otro</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="sistema_generacion_electrica" as="div" className="invalid-feedback" />
										</CCol>
										<CCol xs="3" sm="3" md="3" className="mb-2">
											<CFormLabel htmlFor="nf-email">Sistema de calefacción</CFormLabel>
											<select
												className="form-select"
												name="sistema_calefaccion"
												ref={register({
													required: { message: "campo obligatorio" }
												})}>
												<option value="Losa radiante">Losa radiante</option>
												<option value="Calefacción central con radiadores">Calefacción central con radiadores</option>
												<option value="Estufa a parafina">Estufa a parafina</option>
												<option value="Estufa a gas">Estufa a gas</option>
												<option value="Estufa a pallets">Estufa a pallets</option>
												<option value="Estufa eléctrica">Estufa eléctrica</option>
												<option value="Chimenea">Chimenea</option>
												<option value="Estufa a combustión lenta">Estufa a combustión lenta</option>
												<option value="Cocina a leña">Cocina a leña</option>
												<option value="No tiene">No tiene</option>
												<option value="Otro">Otro</option>
												<option value="No corresponde">No corresponde</option>
											</select>
											<ErrorMessage errors={errors} name="sistema_calefaccion" as="div" className="invalid-feedback" />
										</CCol>
									</CRow>
								</CCardBody>
							</CCard>
						</CTabPane>
					</CTabContent>
					<CTabPane data-tab="preview" visible={activeTab === 'ubicación'}>
						<CRow>
							{cargarMapa &&
								<Mapa
									latitud={areaProtegida ? areaProtegida.latitud : ''}
									longitud={areaProtegida ? areaProtegida.longitud : ''}
									latitudMarcador={marcador.latitud}
									longitudMarcador={marcador.longitud}
									zoom={infraestructura.zoom_mapa ? infraestructura.zoom_mapa : 9}
									onChange={(event) => {
										setMarcador({
											latitud: event.latitud,
											longitud: event.longitud,
											zoom: event.zoom
										});
									}} />
							}
						</CRow>
					</CTabPane>
					<CTabPane data-tab="preview" visible={activeTab === 'documentos'}>
						<CRow className="mt-2">
							<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
								<CargarDocumento
									documentos={documentos} // documentos que ya tiene la entidad en base de datos
									enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
									entidad_nombre={'Infraestructura'}
									entidad_id={infraestructura.id}
									enviado={() => { // cuando se enviaron todos los archivos
										setEnviarArchivos(false)
										if (id === undefined){
											history.push(`/area-protegida/${areaProtegidaId}/infraestructura/${infraestructura.id}`)
										}
										toast.success('Cambios almacenados');
									}}
									cambioArchivos={(data) => {
										// Es el estado interno de los documentos si quiere guardar utilice otro estado para no crear un ciclo infinito
									}} />
							</CCol>
						</CRow>
					</CTabPane>
					<CCard className="mt-2">
						<CCardBody>
							<CRow>
								<CCol lg={12}>
									<Link to={'/area-protegida/' + area_protegida_id + '/infraestructura'}>
										<CButton className="mt-2 float-start" size="lg" color="light"><IoMdReturnLeft /> Volver</CButton>
									</Link>
									<CButton className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave /> Guardar</CButton>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CForm>
			</ErpLayout>
		</>
	)
}

export default FormInfraestructura