import React, { useEffect, useState } from 'react';
import { CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, CenterAlign, DateFormatter } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import ModalEliminar from '../../modulos/administracion/ModalEliminar'
import Buscador from '../../componentes/Buscador';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import FiltroAño from '../../componentes/FiltroAño';


const GrillaInvestigacion = () => {
    let history = useHistory();
    const columns = [
        { key: 'numero_autorizacion', name: 'N° de autorización', formatter: CenterAlign, width: 40},
        { key: 'numero_solicitud', name: 'N° de solicitud', formatter: CenterAlign, width: 40},
        { key: 'ano', name: 'Año', formatter: CenterAlign, width: 30},
        { key: 'nombre', name: 'Nombre investigación', formatter: LongTextFormatter, width: 250 },
        { key: 'regiones_nombres', name: 'Regiones', formatter: LongTextFormatter, width: 350},
        { key: 'areas_protegidas', name: 'Areas protegidas', formatter: LongTextFormatter, width: 400 },
        { key: 'area_tematica', name: 'Area temática', formatter:CenterAlign,  width:100 },
        { key: 'objetivo', name: 'Objetivo', formatter: LongTextFormatter, width: 100 },
        { key: 'responsable', name: 'Responsable', formatter: LongTextFormatter, width:100 },
        { key: 'institucion', name: 'Institución', formatter: LongTextFormatter, width:100 },
        { key: 'telefono', name: 'Teléfono', formatter: LongTextFormatter, width:100 },
        { key: 'correo', name: 'Correo', width:100 },
        { key: 'fecha_autorizacion', name: 'Fecha de Autorizacion', formatter: DateFormatter, width:100 },
        { key: 'fecha_inicio', name: 'Fecha inicio', formatter: DateFormatter, width:100 },
        { key: 'fecha_termino', name: 'Fecha cierre', formatter: DateFormatter, width:100 },
        { key: 'plazo_envio', name: 'Plazo envío informe Final', formatter: DateFormatter, width:100 },
        { key: 'estado', name: 'Estado de investigación', formatter: CenterAlign, width:150 },
    ];
    const [rows, setRows] = useState([]);
    const [msgEliminar, setMsgEliminar] = useState('');
    const [showEliminarInvestigacion, setShowEliminarInvestigacion] = useState(false);  // Modal para eliminar usuario
    const [investigacion, setInvestigacion] = useState({});
    const [textoBuscar, setTextoBuscar] = useState('');
    const [año, setAño] = useState('');
    const [regionId, setRegionId] = useState('');

    useEffect(
        () => {
            obtenerDatos();
        },
        [showEliminarInvestigacion, textoBuscar, regionId, año]
    )

    async function obtenerDatos() {
        let resultado = await api.investigacion.get({texto: textoBuscar, region_id: regionId, año: año});
		if (resultado && resultado.status === 200) {
            setRows((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos');
		}
	}

    const menuEditarInvestigacion = (e, { rowIdx }) => {
        history.push({
            pathname: `/investigacion/${rows[rowIdx].id}`,
            state: true
        });
    }
    const menuCrearInvestigacion = (e) => {
        history.push({
            pathname: `/investigacion/crear`,
            state: true
        });
    }

    const menuEliminar = (e, { rowIdx }) => {
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id) {
                setInvestigacion(element)
                setMsgEliminar('¿Estás seguro de eliminar la investigación?');
                setShowEliminarInvestigacion(true)
            }
        });
    }
    async function retornoEliminarInvestigacion() {
        let resultado = await api.investigacion.delete(investigacion.id);
        if (resultado && resultado.status === 200) {
            setInvestigacion({})
            obtenerDatos()
            toast.success('Investigación eliminada');
            setShowEliminarInvestigacion(false)
        } else {
            toast.error(resultado.data.msg);
            setShowEliminarInvestigacion(false)
        }
    }

    const colorearEstado = (row) => {
        if (row.estado === 'Finalizada') return 'std-finalizado'
        else if (row.estado === 'En proceso') return 'std-enproceso'
        else if (row.estado === 'Ampliación de plazo') return 'std-ampliaciondeplazo'
        else if (row.estado === 'Informe pendiente') return 'std-informependiente'
        else if (row.estado === 'Sin información') return 'std-sininformacion'
        else return undefined
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Investigación'},
                    {active: true, pathname: `/investigacion`, name: 'Listado de investigación'}
                ]
                }>
                <CRow>
                    <CCol xs="12" md="4" className="mb-2">
                        <Buscador
                            titulo='Búsqueda'
                            placeholder='Búsqueda por texto'
                            retornoBusqueda={(text) => {
                                setTextoBuscar(text);
                            }}
                        />
                    </CCol>
                    <CCol xs="12" md="4" className="mb-2">
                        <FiltroRegionesTH
                            todos={true}
                            onChange={(e) => {
                                setRegionId(e ? e.value : '')
                            }}
                        />
                    </CCol>
                    <CCol xs="7" md="2" className="mb-2">
                        <FiltroAño
                            todos={true}
                            isClearable={true}
                            onChange={(e) => {
                                setAño(e ? e.value : '')
                            }}
                            valorInicial={año}
                            cargado={(data)=>{}}
                        />
                    </CCol>
                    <CCol xs="5" md="2" className="mb-2 mt-4">
                        <CButton
                            color="success"
                            className="float-end"
                            size="md"
                            onClick={menuCrearInvestigacion}
                            ><FaPlus />
                                Nuevo
                        </CButton>
                    </CCol>
                </CRow>
                <GrillaBase
                    rows={rows}
                    columns={columns}
                    rowClass={colorearEstado}
                    menu={<ContextMenu id="grid-context-menu">
                        <MenuItem onClick={menuEditarInvestigacion}><FaPencilAlt/> Editar Investigación</MenuItem>
                        <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
                    </ContextMenu>}
                />
            </ErpLayout>
            <ModalEliminar
                titulo="Eliminar"
                visible={showEliminarInvestigacion}
                investigacion={investigacion}
                setShow={setShowEliminarInvestigacion}
                confirmar={retornoEliminarInvestigacion}
            >
                {msgEliminar}
            </ModalEliminar>
        </>
    )
}

export default GrillaInvestigacion