import React, { useEffect, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { CFormLabel, CFormControl, CCol, CAlert, CAccordion, CAccordionItem, CAccordionHeader, CAccordionButton, CAccordionCollapse, CAccordionBody  } from '@coreui/react-pro'
import { toast } from 'react-toastify';

const Mapa = (props) => {
	const [primerCarga, setPrimerCarga] = useState(true);
	const [viewport, setViewport] = useState({
		latitude: -43.4095433,
		longitude: -71.6486941,
		zoom: 9
	})

	const [marcador, setMarcador] = useState({
		latitud: '',
		longitud: ''
	})

	const SIZE = 20;
	const _onClick = (event) => {
		setViewport({
			...viewport,
			latitude: event.lngLat[1],
			longitude: event.lngLat[0],
		});
		setMarcador({
			...marcador,
			latitud: event.lngLat[1],
			longitud: event.lngLat[0]
		});
	}
	const _onDragEnd = (event) => {
		setViewport({
			...viewport,
			latitude: event.lngLat[1],
			longitude: event.lngLat[0],
		});
		setMarcador({
			...marcador,
			latitud: event.lngLat[1],
			longitud: event.lngLat[0]
		});
	}
	const [activeKey, setActiveKey] = useState(0)

	// Cualquier modificación retornamos la info actualizada
	useEffect(
		() => {
			props.onChange({
				latitud: marcador.latitud,
				longitud: marcador.longitud,
				zoom: viewport.zoom
			})
		},
		[marcador, viewport]
	)

	// Seteamos la info por defecto del mapa solo una vez
	useEffect(
		() => {
			if (primerCarga) {
				if (props.latitudMarcador && props.longitudMarcador) {
					setMarcador({
						latitud: parseFloat(props.latitudMarcador),
						longitud: parseFloat(props.longitudMarcador),
					});
					// este es para la posición del mapa
					setViewport({
						...viewport,
						latitude: parseFloat(props.latitudMarcador),
						longitude: parseFloat(props.longitudMarcador),
						zoom: props.zoom ? parseFloat(props.zoom) : viewport.zoom
					});
				} else {
					// si no tiene marcador dejamos por defecto latitud, longitud, este corresponde al area protegida
					// este es para la posición del mapa
					if (props.latitud && props.longitud) {
						setViewport({
							...viewport,
							latitude: parseFloat(props.latitud),
							longitude: parseFloat(props.longitud),
							zoom: props.zoom ? parseFloat(props.zoom) : viewport.zoom
						});
					}
				}
				setPrimerCarga(false)
			}
		},
		[props.latitudMarcador, props.longitudMarcador, props.latitud, props.longitud]
	)

	const cambioCoordenadas = (e) => {
		if (e.target.name === 'latitud' && (e.target.value <= -90 || e.target.value >= 90)) {
			toast.error('Latitud incorrecta',{autoClose:6000});
		}
		if (e.target.name === 'longitud' && (e.target.value <= -180 || e.target.value >= 180)) {
			toast.error('Longitud incorrecta',{autoClose:6000});
		}
		setMarcador({
			...marcador,
			[e.target.name]: e.target.value ? parseFloat(e.target.value) : ''
		})
	}

	return (
		<>
			{marcador.latitud === '' &&
				<CCol lg={12}>
					<CAlert color="info" className="mt-2 mb-0">
						Aún no selecciona la posición en el mapa, revise información a continuación.
					</CAlert>
				</CCol>
			}
			<CCol lg={12} className="mb-2 mt-2">
				<CAccordion>
					<CAccordionItem>
						<CAccordionHeader>
							<CAccordionButton type="button" collapsed={activeKey !== 1} onClick={() => activeKey === 1 ? setActiveKey(0) : setActiveKey(1)}>
								Información
							</CAccordionButton>
						</CAccordionHeader>
						<CAccordionCollapse visible={activeKey === 1}>
							<CAccordionBody>
							<ul>
								<li>Haga click sobre el mapa para posicionar el marcador, deberá aparecer el siguiente simbolo <FaMapMarkerAlt style={{
										color: 'red',
										fill: '#d00',
										stroke: 'none'
									}}/> </li>
								<li>Puede arrastrar el marcador para cambiar la posición.</li>
								<li>Puede ingresar manualmente las coordenadas latitud y longitud en los campos correspondientes.</li>
								<li>Puede ampliar o disminuir el mapa con la rueda del mouse.</li>
							</ul>
							</CAccordionBody>
						</CAccordionCollapse>
					</CAccordionItem>
				</CAccordion>
			</CCol>
			<CCol xs="12" sm="12" md="4" className="mb-2">
				<CFormLabel htmlFor="nf-email">Latitud</CFormLabel>
				<CFormControl
					type="number"
					step="any"
					name="latitud"
					placeholder=""
					value={marcador.latitud}
					onChange={cambioCoordenadas}
				/>
			</CCol>
			<CCol xs="12" sm="12" md="4" className="mb-2">
				<CFormLabel htmlFor="nf-email">Longitud</CFormLabel>
				<CFormControl
					type="number"
					step="any"
					name="longitud"
					placeholder=""
					value={marcador.longitud}
					onChange={cambioCoordenadas}
				/>
			</CCol>
			<CCol lg={12} style={{ height: '400px' }}>
				<ReactMapGL
					{...viewport}
					width="100%"
					height="100%"
					onClick={_onClick}
					mapStyle="mapbox://styles/mapbox/streets-v11"
					mapboxApiAccessToken={process.env.REACT_APP_TOKEN_MAPBOX}
					onViewportChange={(viewport) => {
						setViewport(viewport)
					}}>

					{((marcador.latitud !== '') && (marcador.longitud !== '')) &&
					<>
							{/* <div style={{ position: 'absolute', right: 0 }}>
								<FullscreenControl container={document.getElementById('mapa')} />
							</div> */}
							<Marker
								latitude={marcador.latitud}
								longitude={marcador.longitud}
								draggable={true}
								onDragEnd={_onDragEnd}>
								<FaMapMarkerAlt
									// style={{ color: 'red' }}
									height={SIZE}
									// viewBox="0 0 24 24"
									style={{
										color: 'red',
										cursor: 'pointer',
										fill: '#d00',
										stroke: 'none',
										transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
									}} />
							</Marker>
						</>
					}
				</ReactMapGL>
			</CCol>
		</>
	)
}

export default Mapa