import React, { useState, useEffect } from 'react'
import { CCol, CRow, CButton} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, check } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import FormUsuario from './FormUsuario';
import ModalEliminar from './ModalEliminar';
import { FaPlus, FaPencilAlt, FaTimes, FaRegFileExcel } from 'react-icons/fa';
import { exportar } from '../../utils/Funciones';
import Buscador from '../../componentes/Buscador';


const Usuarios = () => {
    const columns = [
        { key: 'nombre_usuario', name: 'Usuario', formatter: LongTextFormatter, width:200 },
        { key: 'nombre_completo', name: 'Nombre', formatter: LongTextFormatter, width:200 },
        { key: 'correo', name: 'Correo', formatter: LongTextFormatter, width:200 },
        { key: 'habilitado', name: 'Habilitado', formatter: check, width:150 },
        { key: 'administrador', name: 'Administrador', formatter: check, width:150 },
        { key: 'telefono', name: 'Teléfono', formatter: LongTextFormatter, width:150 },
        { key: 'categorias_modulo', name: 'Módulo', formatter: LongTextFormatter, minWidth:300 },
    ];
    const [rows, setRows] = useState([]);
    const [showFormUsuario, setShowFormUsuario] = useState(false);  // Modal para crear/editar usuario
    const [usuario, setUsuario] = useState({});
    const [showEliminarUsuario, setShowEliminarUsuario] = useState(false);  // Modal para eliminar usuario
    const [msgEliminar, setMsgEliminar] = useState('');
    const [textoBuscar, setTextoBuscar] = useState('');

    useEffect(
        () => {
            obtenerDatos();
        },
        [textoBuscar]
    )

    async function obtenerDatos() {
		let resultado = await api.usuarios.get({texto: textoBuscar});
		if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos)
		} else {
			toast.error('Error al obtener datos');
		}
	}

    async function menuEditar(e, { rowIdx }) {
        let resultado = await api.usuarios.getOne(rows[rowIdx].id);
        if (resultado && resultado.status === 200) {
            setUsuario(resultado.data.datos);
            setShowFormUsuario(true)
        } else {
            setUsuario({});
            toast.error('Error al obtener datos');
        }
    }

    const menuEliminar = (e, {rowIdx}) => {
        setUsuario({})
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id){
                setUsuario(element)
                setMsgEliminar('¿Estás seguro de eliminar el usuario ' + element.nombre_usuario + '? ');
                setShowEliminarUsuario(true)
            }
        });
    }

    async function retornoEliminarUsuario() {
        let resultado = await api.usuarios.delete(usuario.id);
        if (resultado && resultado.status === 200) {
            setUsuario({})
            obtenerDatos()
            toast.success('Usuario eliminado');
            setShowEliminarUsuario(false)
        } else {
            toast.success(resultado.data.msg);
            setShowEliminarUsuario(false)
        }
    }

    function cerrarModalUsuario(){
        setShowFormUsuario(false)
        obtenerDatos()
    }

    function functionRetorno(data) {
        // obtenerDatos()
        // setShowFormUsuario(false)
        // console.log('data', data)
    }

    function exportarUsuarios(){
        exportar(`/api/exportar/usuarios?texto=${textoBuscar}`);
    }

	return (
		<ErpLayout
            breadcrumbs={[
                {active: true, pathname: '', name: 'Administración'},
                {active: true, pathname: `/usuarios`, name: 'Usuarios'}]
            }
        >
			<CRow>
                <CCol xs="12" md="12" lg="7" className="mb-2">
                    <Buscador
                        titulo='Búsqueda'
                        placeholder='Búsqueda por texto'
                        retornoBusqueda={(text) => {
                            setTextoBuscar(text);
                        }}
                    />
                </CCol>
                <CCol xs="12" md="12" lg="5" className="mt-3">
                    <CButton
                        color="success"
                        onClick={() => setShowFormUsuario(true)}
                        style={{float:'right'}}
                        className="mr-1 my-2"
                        ><FaPlus/>
                            Agregar usuario
                    </CButton>
                    <CButton
                        color="light"
                        onClick={exportarUsuarios}
                        className="mt-2 float-sm-end mx-1"
                        ><FaRegFileExcel />
                            Exportar
                    </CButton>
                </CCol>
			</CRow>
            <CRow>
				<CCol xs="12">
                    <GrillaBase
                        menu={<ContextMenu id="grid-context-menu">
                            <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
                            <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
                        </ContextMenu>}
                        columns={columns}
                        rows={rows}
                    />
				</CCol>
			</CRow>
            <FormUsuario
                visible={showFormUsuario}
                usuario={usuario}
                onHide={cerrarModalUsuario}
                close={()=> {
                    setUsuario({})
                    setShowFormUsuario(false)
                    obtenerDatos()
                }}
                retorno={functionRetorno}/>
            <ModalEliminar
                titulo="Eliminar"
                visible={showEliminarUsuario}
                usuario={usuario}
                setShow={setShowEliminarUsuario}
                confirmar={retornoEliminarUsuario}
                >
                    {msgEliminar}
            </ModalEliminar>
		</ErpLayout>
	)
}

export default Usuarios