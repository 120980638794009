import React from 'react'
import { CCol, CRow, CAlert, CButton } from '@coreui/react-pro';
import ErpLayout from '../layout/ErpLayout';
import { FaFileWord, FaPlay } from 'react-icons/fa';

const Home = () => {

    let estilo = {
        float: 'left',
        display: 'inline-block',
        marginRight: '10px',
        marginTop: '5px'
    }

    return (
        <ErpLayout>
            <CRow style={{marginTop: '20px'}}>
                <CCol xs="12" sm="12" md="12">
                    <CAlert color="info">
                        Bienvenidos a VIGIA - Sistema de información del SNASPE
                    </CAlert>
                </CCol>

                <CCol sm="12">
                    <CButton color='info' variant="outline" href="https://docs.google.com/document/d/1mQuxLvTvlGu8TPfrXsI6YoZbGMyu7jnmhcZD_O1_Niw/edit" target="_blank">
                        <FaFileWord/> Manual de usuario
                    </CButton>
                </CCol>
                <CCol sm="12" lg="12" style={{marginTop: '20px'}}>
                    <h6><b>Videos de introducción</b></h6>
                </CCol>
                <CCol sm="12" lg="12">
                    <CButton color='info' variant="outline" style={estilo} href="https://drive.google.com/file/d/1uFk36RMmpE0IEw8Skkn-hMD40D4q5uMS/view" target="_blank">
                        <FaPlay/> Presentación</CButton>
                    <CButton color='info' variant="outline" style={estilo} href="https://drive.google.com/file/d/10Unq5U3PqOLMYgvwXYjXIE2_JflqC5_i/view" target="_blank">
                        <FaPlay/> Login</CButton>
                    <CButton color='info' variant="outline" style={estilo} href="https://drive.google.com/file/d/1elFK0frjn0HxOmsgPGEChG3oklnsg_7_/view" target="_blank">
                        <FaPlay/> POA</CButton>
                    <CButton color='info' variant="outline" style={estilo} href="https://drive.google.com/file/d/1U5MfmS1lZrAYAd5g4POmTxQzOgCAqD1M/view" target="_blank">
                        <FaPlay/> Infraestructura</CButton>
                </CCol>
            </CRow>
        </ErpLayout>
    )
}

export default Home