import React, { useEffect, useState } from 'react'
import {  CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter, CFormCheck, CNav} from '@coreui/react-pro'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes, FaTrash } from 'react-icons/fa';
import ModalEliminar from './ModalEliminar';

const FormCategoriaTipoInformacion = (props) => {
    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            habilitado: true
        }
    });
    const { tipoInformacionProps, ...rest } = props;
    const [tipoInformacion, setTipoInformacion] = useState({});
    const [showEliminarTipoInformacion, setShowEliminarTipoInformacion] = useState(false);  // Modal para eliminar usuario
    const [msgEliminar, setMsgEliminar] = useState('');

    useEffect(
        () => {
            if(props.visible && tipoInformacionProps.id){
                cargarData()
            }

        },
        [props.visible]
    )

    const onSubmit = async (data) => {
        let resultado;

        if (tipoInformacionProps.id){
		    resultado = await api.tipoInformacion.putCategoria(tipoInformacionProps.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    resultado = await api.tipoInformacion.postCategoria(data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        }
	}

    const cargarData = async () => {
        let resultado = await api.tipoInformacion.getOne(tipoInformacionProps.id);
        if (resultado && resultado.status === 200) {
            setTipoInformacion(resultado.data.datos)
            setValue('nombre', resultado.data.datos.nombre)
        } else {
            toast.error(resultado.data.msg);
            cerrarModal()
        }
    }

    const eliminar = async () => {
        setMsgEliminar(`${tipoInformacion.nombre}` )
        setShowEliminarTipoInformacion(true)
    }

    async function confirmarEliminar() {
        let resultado = await api.tipoInformacion.deleteCategoria(tipoInformacionProps.id);
        if (resultado && resultado.status === 200) {
            toast.success('Tipo de información eliminado');
            setShowEliminarTipoInformacion(false)
            cerrarModal()
        } else {
            toast.error(resultado.data.msg);
        }
    }

    function cerrarModal(){
        setTipoInformacion({})
        props.cerrar();
    }

	return (
        <CModal
            visible={rest.visible}
            onDismiss={rest.close}
            size='md'
            keyboard={false}
            >
            <CModalHeader onDismiss={() => cerrarModal()}>{tipoInformacion.id ? 'Editar': 'Crear'} categoría</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                            <CFormLabel htmlFor="nf-email">Nombre</CFormLabel>
                            <CFormControl
                                type="text"
                                name="nombre"
                                placeholder=""
                                ref={
                                    register({
                                        required: { value: true, message: "campo obligatorio" }
                                    })
                                }
                            />
                            <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={() => cerrarModal()}
                    ><FaTimes/> Cerrar </CButton>
                    {tipoInformacion.id &&
                        <CButton
                            color="danger"
                            onClick={() => eliminar()}
                            ><FaTrash/> Eliminar </CButton>
                    }
                    <CButton
                        color="success"
                        type="submit"
                        ><FaSave/> Guardar </CButton>
                </CModalFooter>
                <ModalEliminar
                    titulo="¿Está seguro de eliminar el tipo de información?"
                    visible={showEliminarTipoInformacion}
                    setShow={setShowEliminarTipoInformacion}
                    confirmar={confirmarEliminar}>
                        {msgEliminar}
                </ModalEliminar>
            </CForm>
        </CModal>
	)
}

export default FormCategoriaTipoInformacion


