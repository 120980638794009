import React, { useEffect, useState } from 'react';
import { CCollapse, CBadge, CProgress, CCol, CRow, CButton, CCard, CCardHeader, CListGroup, CCardBody, CCardTitle, CCardText } from '@coreui/react-pro';

import ErpLayout from '../../layout/ErpLayout';
import { FaRegClock, FaCheckCircle,FaFileAlt, FaPencilAlt, FaInfoCircle, FaCaretRight } from 'react-icons/fa';
import { useHistory, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useForm } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import VistaArchivos from '../../componentes/VistaArchivos';

const PreviaEncuesta = () => {
	let history = useHistory();
	let { evaluacion_id, encuesta_id } = useParams();
	const { setValue } = useForm({});
	const [areaProtegidaId, setAreaProtegidaId] = useState('');
	const [evaluacion, setEvaluacion] = useState({});
	const [totalIndicadores, setTotalIndicadores] = useState(0);
	const [totalRespuestas, setTotalRespuestas] = useState(0);
	const [porcentaje, setPorcentaje] = useState('');
	const [encuesta, setEncuesta] = useState({
		secciones: []
	});
	const [siguienteIndicador, setSiguienteIndicador] = useState({});

	useEffect(
	    () => {
			if (evaluacion_id) {
				obtenerEncuesta()
			}
	    },
	    [evaluacion_id]
	)

	async function obtenerEncuesta() {
		let resultado = await api.encuesta.getOne(encuesta_id);
		if (resultado && resultado.status === 200) {
			obtenerEvaluacion(resultado.data.datos);
		} else {
		    toast.error('Error al obtener datos');
		}
	}

	async function obtenerEvaluacion(encuestaData) {
		let resultado = await api.evaluacion.getOne(evaluacion_id, {respuestas: true});
		if (resultado && resultado.status === 200) {
			let evaluacionData = resultado.data.datos;
		    setEvaluacion(evaluacionData)
			setAreaProtegidaId(evaluacionData.area_protegida_id)
			setValue('fecha', evaluacionData.fecha)

			let totalRespuestasTemp = 0; // total de respuestas que se consideran validas(con alternativas)
			let totalIndicadores = 0;

			let flagPrimeraPendiente = false;

			encuestaData.secciones.forEach(seccion => {
				seccion.indicadores.forEach(indicador => {
					totalIndicadores++;
					let encontro = false;
					indicador.documentos = [];
					indicador.visible = false;

					evaluacionData.respuestas.forEach(respuesta => {
						if(indicador.id===respuesta.indicador_id) {
							// Solo si la respuesta tiene una alternativa seleccionada se considera como respondida
							if (respuesta.valor) {
								indicador.completa = true;
								totalRespuestasTemp++;
								encontro = true;
							}
							indicador.respuesta_valor = respuesta.valor;
							indicador.documentos = respuesta.documentos;
							indicador.respuesta_descripcion = respuesta.descripcion;
						}
					});
					if(!encontro && !flagPrimeraPendiente) {
						setSiguienteIndicador(indicador);
						flagPrimeraPendiente = true;
					}
				});
			});
			setTotalRespuestas(totalRespuestasTemp)
			setTotalIndicadores(totalIndicadores)
			setPorcentaje(Math.round((totalRespuestasTemp/totalIndicadores)*100))
			setEncuesta(encuestaData)
		} else {
		    toast.error('Error al obtener datos');
		}
	}

	function cambioVisible(indicadorId) {
		let encuestaData = Object.assign({}, encuesta);
		encuestaData.secciones.forEach(seccion => {
			seccion.indicadores.forEach(indicador => {
				if(indicador.id===indicadorId) {
					indicador.visible = !indicador.visible;
				}
			});
		});
		setEncuesta(encuestaData)
	}


	return (
		<>
			<ErpLayout
				breadcrumbs={[
					// {active: true, pathname: '', name: 'Encuesta'},
					{active: false, pathname: `/encuestas/${encuesta_id}`, name: `Evaluaciones ${encuesta.nombre}`},
					{active: true, pathname: '', name: 'Lista de preguntas'}]
				}>
				<CCol xs="12" sm="12" md="12" lg="12">
					<h5>{evaluacion.area_protegida_nombre} - Año: {evaluacion.fecha}</h5>
				</CCol>
				<CRow className='mb-2'>
					<CRow className='mb-2'>
						<CCol xs="12" sm="12" md="6" className="mb-sm-2 mb-0">
							<div className="text-medium-emphasis">Contestadas</div>
							<strong>{totalRespuestas} de {totalIndicadores} ({porcentaje}%)</strong>
							<CProgress className="mt-2" thin color="success-gradient" value={porcentaje} />
						</CCol>
						<CCol xs="12" sm="12" md="6">
							{totalRespuestas > 0 && totalRespuestas !== totalIndicadores &&
								<Link to={`/encuestas/${encuesta_id}/evaluacion/${evaluacion_id}/seccion/${siguienteIndicador.seccion_id}/indicador/${siguienteIndicador.id}`}>
									<CButton color="success" className="float-lg-end mt-4">
										<FaCaretRight/> Continuar
									</CButton>
								</Link>
							}

							{totalRespuestas === 0 &&
								<Link to={`/encuestas/${encuesta_id}/evaluacion/${evaluacion_id}/seccion/${siguienteIndicador.seccion_id}/indicador/${siguienteIndicador.id}`}>
									<CButton color="success" className="float-lg-end mt-4">
										<FaCaretRight/> Comenzar
									</CButton>
								</Link>
							}
						</CCol>
					</CRow>

					<CCol xs="12" sm="12" md="12">

						{encuesta.secciones.map((seccion) =>
							<CCard style={{marginTop: '10px'}}>
								<CCardBody>
									<CCardTitle>{seccion.nombre}</CCardTitle>
									<CCardText>
										{ReactHtmlParser(seccion.descripcion)}
									</CCardText>
									{(seccion.indicadores && seccion.indicadores.length > 0) &&
										<CListGroup>
											{seccion.indicadores.map((indicador) =>
												<CCard style={{marginTop: '10px'}}>
													<CCardHeader>
														<Link to={`/encuestas/${encuesta.id}/evaluacion/${evaluacion_id}/seccion/${seccion.id}/indicador/${indicador.id}`}>
															<p style={{display: 'inline-block', margin: '0px', fontWeight:'500'}}>{indicador.criterio   ? 'Criterio: ' + indicador.criterio : ''} - Indicador: {indicador.numero}</p>
														</Link>

														<Link to={`/encuestas/${encuesta_id}/evaluacion/${evaluacion_id}/seccion/${indicador.seccion_id}/indicador/${indicador.id}`}>
															<CButton size="sm" color={indicador.completa ? 'warning' : 'success'} style={{marginLeft:'10px', float: 'right', padding: '0.1rem 0.2rem'}}>
																{indicador.completa ? 
																	<><FaPencilAlt/> Editar </> : <><FaCaretRight/> Responder</>}
															</CButton>
														</Link>

														{indicador.completa ?
															<FaCheckCircle style={{color: 'green', float: 'right', marginTop: '5px'}}/>
														:
															<FaRegClock style={{color: 'grey', float: 'right', marginTop: '5px'}}/>
														}
														{(indicador.documentos && indicador.documentos.length > 0) &&
												 			<FaFileAlt style={{color: 'black', float: 'right', marginRight: '10px', marginTop: '5px'}}/>
														}
													</CCardHeader>
														<CCardBody>
															<CCardText>
																{ReactHtmlParser(indicador.descripcion)}
															</CCardText>
															{indicador.respuesta_valor &&
																<>
																<CBadge color={
																	indicador.respuesta_valor === 'Cumple' ? 'success' :
																	indicador.respuesta_valor === 'Cumple parcialmente' ? 'info' :
																	indicador.respuesta_valor === 'No cumple' ? 'danger'
																	: 'dark'
																}>{indicador.respuesta_valor}</CBadge>

																{indicador.respuesta_descripcion &&
																	<p><b>Descripción: </b>{indicador.respuesta_descripcion}</p>
																}

																{indicador.documentos.length > 0 &&
																	<CRow className="mt-2">
																		<b>Documentos cargados</b>
																		<VistaArchivos
																			documentos={indicador.documentos}
																			/>
																	</CRow>
																}
																</>
															}

														</CCardBody>
													</CCard>
											)}
										</CListGroup>
									}
								</CCardBody>
							</CCard>
						)}
					</CCol>
				</CRow>
			</ErpLayout>
		</>
  )
}

export default PreviaEncuesta