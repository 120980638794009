import React, { useEffect, useState, useContext } from 'react';
import { CCol, CRow, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CFormLabel } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter2, CenterAlign, PercentFormatter } from '../../componentes/GrillaBase';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { ContextMenu, MenuItem } from 'react-contextmenu';
import FormEvaluacion from './FormEvaluacion';
import UserContext from '../../Contexto'


const Evaluacion = (props) => {
    const columns = [
        { key: 'subestrategia', name: 'Subestrategia', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 200},
        { key: 'descripcion', name: 'Actividad', formatter: LongTextFormatter2, cellClass: "cont-longtext", minWidth: 300},
		{ key: 'estado_evaluacion', name: 'Estado', width: 150},
        { key: 'responsable', name: 'Responsable', width: 150},
        { key: 'descripcion_cumplimiento', name: 'Descripción', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 280},
        { key: 'cumplimiento', name: 'Cumplimiento', formatter: CenterAlign, width: 110},
        { key: 'porcentaje_cumplimiento', name: '% Cumplimiento', formatter: PercentFormatter, width: 110}
    ];
    const [rows, setRows] = useState([]);
    const [poa, setPoa] = useState({});
    let { id } = useParams();
	let history = useHistory();
    const [modalActividad, setModalActividad] = useState(false);
    const [actividad, setActividad] = useState({});
    const contexto = useContext(UserContext)
    const [permisoEditor, setPermisoEditor] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [responsable, setResponsable] = useState(false)

    useEffect(
        () => {
            setAdmin(contexto.data.usuario.administrador)
            obtenerDatos();
            obtenerPoa();
        },
        []
    )

    async function obtenerDatos() {
		let resultado = await api.poa.actividades(id, {planificacion: true});
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos)
		} else {
			toast.error('Error al obtener datos');
		}
    }

    async function obtenerPoa() {
        let resultado = await api.poa.getOne(id, {});
        if (resultado && resultado.status === 200) {
            setPoa(resultado.data.datos);
            if (resultado.data.datos.usuario_id == contexto.data.usuario.id){
                setResponsable(true)
            }
            const permisosEdicion = contexto.data.usuario.permisos_registro
            permisosEdicion.forEach( permiso => {
                if (permiso.tabla_id == id && permiso.tabla_nombre === 'poa'){
                    setPermisoEditor(true)
                }
            });
        } else {
            toast.error('Error al obtener POA');
        }
    }

    async function menuEditar(e, { rowIdx }) {
        setActividad(rows[rowIdx])
        if (responsable || permisoEditor || admin) {
            setModalActividad(true)
        } else {
            toast.error('No cuenta con permisos para evaluar esta actividad');
        }
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Planificación'},
                    {active: false, pathname: `/poa`, name: 'Poa'},
                    {active: true, pathname: '', name: 'Evaluación'}]
                }>
				<CRow>
					<CCol xs="12" sm="6" md="4" lg="3">
                    </CCol>
				</CRow>
                <CFormLabel className='mb-2'><b>{poa.area_protegida}</b> - {poa.periodo}</CFormLabel>
				<CNav variant="tabs">
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
                                history.push(`/poa/${id}/elaboracion`)
                            }}>
							1) Elaboración
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
                                history.push(`/poa/${id}/ejecucion`)
                            }}>
							2) Ejecución
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={true}
							onClick={() => {}}>
							3) Evaluación
						</CNavLink>
					</CNavItem>
				</CNav>
				<CTabContent>
					<CTabPane visible={false}>
					</CTabPane>
					<CTabPane visible={false}>

					</CTabPane>
					<CTabPane visible={true} >
                        <CRow>
							<CCol xs="12" sm="6" md="4" lg="3" className="mb-2"></CCol>
						</CRow>
                        <GrillaBase
                            rowHeight={45}
                            rowClass={(row) => (row.subestrategia === "2.4 Control de incendios forestales" ? 'subestrategia2-4' : undefined)}
                            rows={rows}
                            columns={columns}
                            menu={
                                <ContextMenu id="grid-context-menu">
                                    <MenuItem onClick={menuEditar}> Evaluar actividad</MenuItem>
                                </ContextMenu>}
                        />
					</CTabPane>
				</CTabContent>
				<FormEvaluacion
                  show={modalActividad}
                  actividadId={actividad.id}
                  poaId={id}
                  cerrar={() => {
                      setActividad({})
                      setModalActividad(false)
                      obtenerDatos()
                  }}
                  retorno={() => {
                      console.log('retorno---')
                  }} />
            </ErpLayout>
        </>
  )
}

export default Evaluacion