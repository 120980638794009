import React, { useState, useEffect } from 'react';
import { CCol, CRow, CButton, CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { FaTimes } from 'react-icons/fa';
import api from '../utils/Routes';

const ModalMapaInfraestructura = (props) => {
    const {show, close, infraestructuraId, ...rest} = props;
    const [data, setData] = useState({});

    useEffect(
        () => {
            if (infraestructuraId) {
                obtenerDatos();
            }
        },
        [infraestructuraId]
    )

    const obtenerDatos = async () => {
        let resultado = await api.infraestructura.getOne(infraestructuraId);
        if (resultado && resultado.status === 200) {
            setData(resultado.data.datos)
        } else {
            cerrarModal()
            toast.error(`Error: ${resultado.data.msg}`);
        }
    }

    const cerrarModal = () => {
        setData({})
        close()
    }

    return (
        <CModal
            id="modal-poa"
            keyboard={false}
            visible={show}
            >
            <CModalHeader onDismiss={cerrarModal}>{data.nombre}</CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol>
                            <b>Sector:</b> {data.sector_nombre}<br/>
                            <b>Área:</b> {data.area_nombre}<br/>
                            <b>Captación agua:</b> {data.captacion_agua}<br/>
                            <b>Instalaciones:</b> {data.instalaciones}<br/>
                            <b>Obra Gruesa:</b> {data.obra_gruesa}<br/>
                            <b>Potabilización de agua:</b> {data.potabilizacion_agua}<br/>
                            <b>Sistema calefacción:</b> {data.sistema_calefaccion}<br/>
                            <b>Sistema generación eléctrica:</b> {data.sistema_generacion_electrica}<br/>
                            <b>Terminaciones:</b> {data.terminaciones}<br/>
                            <b>Tratamiento aguas servidas:</b> {data.tratamiento_aguas_servidas}<br/>
                       </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={cerrarModal}
                    ><FaTimes /> Cerrar</CButton>
                </CModalFooter>
        </CModal>
    )
}

export default ModalMapaInfraestructura