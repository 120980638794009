import React, { useEffect, useState } from 'react';
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CFormCheck, CCard, CCardHeader, CCardBody, CNav, CNavItem, CNavLink, CTabPane, CTabContent } from '@coreui/react-pro'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaPlus } from 'react-icons/fa';
import { IoMdReturnLeft } from 'react-icons/io';
import { useHistory, useParams } from "react-router-dom";
import SelectorSectorTHRHF from '../../componentes/SelectorSectorTHRHF';
import { Link } from 'react-router-dom';
import CargarDocumento from '../../componentes/CargarDocumento';
import Mapa from '../../componentes/Mapa';
import Fecha from '../../componentes/Fecha';
import SelectorProveedor from '../../componentes/SelectorProveedor';
import FormProveedor from './FormProveedor'
import SelectorServicio from '../../componentes/SelectorServicio';
import SelectorTipoServicio from '../../componentes/SelectorTipoServicio';
import SelectorAreaProtegidaTHRHF from '../../componentes/SelectorAreaProtegidaTHRHF';
import { useLocation } from "react-router-dom";

const FormConcesion = () => {
	let history = useHistory();
	const location = useLocation();
	const { register, handleSubmit, errors, setValue, control, watch } = useForm({});
	const [concesion, setConcesion] = useState({});
	let { id, area_protegida_id } = useParams();
	const [areaProtegida, setAreaProtegida] = useState("")
	const [area, setArea] = useState({});
	const [activeTab, setActiveTab] = useState('información');
	const [sectorSeleccionado, setSectorSeleccionado] = useState({});
	const [proveedorSeleccionado, setProveedorSeleccionado] = useState({});
	const [servicioSeleccionado, setServicioSeleccionado] = useState({});
	const [idServicio, setIdServicio] = useState("");
	const [idTipoServicio, setIdTipoServicio] = useState("");
	const [showTipoServicio, setShowTipoServicio] = useState(false);
	const [unidadesTipoInstalacion, setUnidadesTipoInstalacion] = useState([]);
	const [showFormProveedor, setShowFormProveedor] = useState(false);
	const [nombreAreaProtegida, setNombreAreaProtegida] = useState("");
	const [labelOtroContrato, setLabelOtroContrato] = useState(false)
	const [random, setRandom] = useState("")
	const [creando, setCreando] = useState(false)
	const [documentos, setDocumentos] = useState([]);
	const [enviarArchivos, setEnviarArchivos] = useState(false);

	const [cargarMapa, setCargarMapa] = useState(false);
	const [marcador, setMarcador] = useState({
		latitud: '',
		longitud: '',
	})
	useEffect(
		() => {
			if (id && id !== 'crear') {
				obtenerDatos()
			}
			if(location.pathname == "/concesion/crear" || location.state != undefined){
				setCreando(true)
			}else{setCreando(false)}

			if (area_protegida_id) {
				obtenerAreaProtegida()
			}

		},
		[id]
	)

	useEffect(
		() => {
			if (concesion.unidades) {
				concesion.unidades.forEach(unidad => {
					setValue(String(unidad.unidad_id), unidad.cantidad)
				});
			}
		},
		[unidadesTipoInstalacion]
	)

	function setMeses(meses) {
		meses.forEach(mes => {
			setValue(mes, true)
		});
	}

	async function obtenerDatos() {
		let resultado = await api.concesion.getOne(id);//rows[rowIdx].id
		if (resultado && resultado.status === 200) {
			let concesion = resultado.data.datos;
			setUnidadesTipoInstalacion(concesion.unidades)
			setDocumentos(concesion.documentos);
			setConcesion(concesion)
			setMeses((concesion.disponibilidad_meses).split(','))
			if (concesion.otro_tipo_contrato) {
				setLabelOtroContrato(true)
			} else { setLabelOtroContrato(false) }

			if (concesion.proveedor_id) {
				setProveedorSeleccionado(concesion.proveedor_id)
			} else { setProveedorSeleccionado({}) }

			setValue([
				{ categoria_manejo: concesion.categoria_manejo },
				{ accesibilidad_universal: concesion.accesibilidad_universal },
				{ certificacion_sernatur: concesion.certificacion_sernatur },
				{ descripcion_tipo_servicio: concesion.descripcion_tipo_servicio },
				{ disponibilidad_meses: concesion.disponibilidad_meses },
				{ fecha_inicio: concesion.fecha_inicio },
				{ fecha_termino: concesion.fecha_termino },
				{ fecha_ultimo_pago: concesion.fecha_ultimo_pago },
				{ fiscalizacion_vigente: concesion.fiscalizacion_vigente },
				{ monto_aporte_extra: concesion.monto_aporte_extra },
				{ monto_cuota_fija: concesion.monto_cuota_fija },
				{ monto_cuota_variable: concesion.monto_cuota_variable },
				{ monto_total_pago_anual: concesion.monto_total_pago_anual },
				{ monto_total_ultimo_pago: concesion.monto_total_ultimo_pago },
				{ nombre_proveedor: concesion.nombre_proveedor },
				{ rut_proveedor: concesion.rut_proveedor },
				{ tipo_contrato: concesion.tipo_contrato },
				{ otro_tipo_contrato: concesion.otro_tipo_contrato }
			])
			if (concesion.tipo_servicio_id) {
				let resultado_tipo_servicio = await api.tipoServicio.getOne(concesion.tipo_servicio_id);
				if (resultado_tipo_servicio && resultado.status === 200) {
					setIdServicio(resultado_tipo_servicio.data.datos.servicio_id)
					setIdTipoServicio(resultado_tipo_servicio.data.datos.id)
				}
			}
			if (concesion.latitud) {
				setMarcador({
					latitud: concesion.latitud,
					longitud: concesion.longitud,
				});
			}else if (concesion.latitud == '' && area.latitud && area.longitud){
				setMarcador({
					latitud: area.latitud,
					longitud: area.longitud,
				})
			}else{
				setMarcador({
					latitud: "",
					longitud: "",
				})
			}

		} else {
			setConcesion({})
			toast.error("NO se encontró la concesión");
		}
	}

	async function obtenerAreaProtegida() {
		let resultado = await api.areaProtegida.getOne(area_protegida_id);
		if (resultado && resultado.status === 200) {
			setAreaProtegida(resultado.data.datos.id)
			setNombreAreaProtegida(resultado.data.datos.nombre)
			setArea(resultado.data.datos)
		} else {
			toast.error('Error al obtener datos');
		}
	}

	const onSubmit = async (data) => {
		let resultado;
		let unidades = []
		unidadesTipoInstalacion.forEach(unidad => {
			console.log(unidad)
			unidades.push({
				unidad_id: unidad.id,
				cantidad: data[unidad.id],
				tipo_servicio_id: idTipoServicio
			})
		});
		data.unidades = unidades;
		if (marcador.latitud !== '' && (marcador <= -90 || marcador >= 90)) {
			toast.error('Latitud incorrecta', { autoClose: 6000 });
			return false;
		}
		if (marcador.longitud !== '' && (marcador.longitud <= -180 || marcador.longitud >= 180)) {
			toast.error('Longitud incorrecta', { autoClose: 6000 });
			return false;
		}
		data.latitud = marcador.latitud;
		data.longitud = marcador.longitud;

		if (concesion.id) {
			resultado = await api.concesion.put(concesion.id, data);
			if (resultado && resultado.status === 200) {
				setEnviarArchivos(true)
				toast.success('Concesión editada con éxtito');
			} else {
				toast.error(resultado.data.msg);
			}
		} else {
			resultado = await api.concesion.post(data);
			if (resultado && resultado.status === 200) {
				setEnviarArchivos(true)
				if (creando){
					history.push(`/concesiones`)
				}else{
					history.push(`/area-protegida/${areaProtegida}/concesiones`)
				}
				
				toast.success('Concesión agregada con éxito');
			} else {
				toast.error(resultado.data.msg);
			}
		}
	}

	return (
		<>
			<ErpLayout
				breadcrumbs={[

					{ active: true, pathname: '', name: 'Servicios Ecoturísticos' },
					{ active: false, pathname: creando ? `/concesiones` : `/concesion`, name: 'Concesiones' },
					{ active: false, pathname: `/area-protegida/${areaProtegida}/concesiones`, name: nombreAreaProtegida },
					{ active: true, pathname: '', name: 'Formulario' },]
				}
			>
				<CForm onSubmit={handleSubmit(onSubmit)}>
					<CNav variant="tabs">
						<CNavItem>
							<CNavLink data-tab="preview" active={activeTab === 'información'} onClick={() => setActiveTab('información')}>
								Información
						</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink data-tab="metadata" active={activeTab === 'ubicación'} onClick={() => {
								setActiveTab('ubicación')
								setCargarMapa(true)
							}}>
								Ubicación
						</CNavLink>
						</CNavItem>
						<CNavItem>
							<CNavLink data-tab="metadata" active={activeTab === 'documentos'} onClick={() => setActiveTab('documentos')}>
								Documentos
						</CNavLink>
						</CNavItem>
					</CNav>
					<CTabContent>
						<CTabPane data-tab="preview" visible={activeTab === 'información'}>
							<CCard className="mb-4 mt-2">
								<CCardHeader style={{ textAlign: 'center' }}>
									Información
							</CCardHeader>
								<CCardBody>
									<CRow>
										<CCol >
											<CRow>
											<CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
													<SelectorAreaProtegidaTHRHF
														iniciar={true}
														control={control}
														Controller={Controller}
														ErrorMessage={ErrorMessage}
														errors={errors}
														// opcionTodos={true}
														setValue={setValue}
														name='area_protegida_id'
														opcionGeneral={false}
														placeholder='No seleccionado'
														seleccionadoId={areaProtegida} // id del elemento seleccionado
														onChange={(seleccionado) => {
															if (seleccionado){
																setAreaProtegida(seleccionado.value)
																setSectorSeleccionado({})
															}else{
																setAreaProtegida("")
															}
														}}

													/>
												</CCol>
												{areaProtegida &&
													<CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
														<SelectorSectorTHRHF
															areaProtegidaId={areaProtegida}
															iniciar={true}
															control={control}
															Controller={Controller}
															ErrorMessage={ErrorMessage}
															errors={errors}
															// opcionTodos={true}
															setValue={setValue}
															name='sector'
															opcionGeneral={true}
															placeholder='No seleccionado'
															seleccionadoId={concesion.sector_id} // id del elemento seleccionado
															onChange={(seleccionado) => {
																if (seleccionado) {
																	setSectorSeleccionado(seleccionado)

																} else {
																	setSectorSeleccionado({})

																}
															}}
														// cargado={(data) => {
														// }}
														/>
													</CCol>
												}
											</CRow>
											<CRow>
												<CCol xs="12" sm="6" md="6" className="mb-2">
													<CFormLabel htmlFor="nf-email">Categoría Manejo</CFormLabel>
													<select
														className="form-select"
														name="categoria_manejo"
														ref={register({
															required: { message: "campo obligatorio" }
														})}>
														<option value="Parque">Parque</option>
														<option value="Reserva">Reserva</option>
														<option value="Monumento">Monumento</option>
														<option value="Santuario">Santuario</option>
													</select>
													<ErrorMessage errors={errors} name="categoria_manejo" as="div" className="invalid-feedback" />
												</CCol>

												<CCol xs="12" sm="6" md="6" lg="6" className="mb-2">

													<SelectorServicio
														iniciar={true}
														control={control}
														Controller={Controller}
														ErrorMessage={ErrorMessage}
														errors={errors}
														// opcionTodos={true}
														setValue={setValue}
														name='servicios'
														// opcionGeneral={true}
														placeholder='No seleccionado'
														seleccionadoId={idServicio} // id del elemento seleccionado
														onChange={(seleccionado) => {
															if (seleccionado) {
																setServicioSeleccionado(seleccionado)
																setShowTipoServicio(true)
																// setTipoServicioSeleccionado('')
																setUnidadesTipoInstalacion([])

															} else {
																setServicioSeleccionado("")
																// setTipoServicioSeleccionado('')
																setShowTipoServicio(false)
																setUnidadesTipoInstalacion([])

															}
														}}
													// cargado={(data) => {
													// }}
													/>

												</CCol>
											</CRow>
											<CRow>
												{showTipoServicio &&
													<CCol xs="12" sm="4" md="4" className="mb-2">
														<SelectorTipoServicio
															iniciar={true}
															control={control}
															Controller={Controller}
															ErrorMessage={ErrorMessage}
															errors={errors}
															opcionTodos={false}
															setValue={setValue}
															name='tipo_servicio'
															opcionGeneral={false}
															onChange={(value) => {
																if (value) {

																	// setTipoServicioSeleccionado(value)
																	setUnidadesTipoInstalacion(value.unidades)

																} else {
																	setUnidadesTipoInstalacion([])
																	// setTipoServicioSeleccionado('')
																}
															}}
															placeholder='No seleccionado'
															seleccionadoId={idTipoServicio} // id del elemento seleccionado
															servicioId={servicioSeleccionado.id}
														/>
													</CCol>
												}
												{unidadesTipoInstalacion && unidadesTipoInstalacion.map((tipo) =>
													<CCol xs="12" sm="12" md="4" className="mb-2" key={tipo.id + 'a'}>
														<CFormLabel htmlFor="nf-email">{tipo.nombre}</CFormLabel>
														<CFormControl
															type="number"
															step="any"
															name={tipo.id}
															placeholder=""
															ref={register({
																// required: { value: true, message: "campo obligatorio" }
															})}
														/>
														{/* <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" /> */}
													</CCol>
												)}
											</CRow>
											<CRow>
												<CCol xs="12" sm="6" md="6" className="mb-2">
													<CFormLabel htmlFor="nf-email">Tipo Contrato</CFormLabel>
													<select
														onChange={(seleccionado) => {
															if (seleccionado.target.value == 'Otro') {
																setLabelOtroContrato(true)
															} else {
																setLabelOtroContrato(false)
															}
														}}
														className="form-select"
														name="tipo_contrato"
														ref={register({
															required: { message: "campo obligatorio" }
														})}>
														<option value="Concesión">Concesión</option>
														<option value="Convenio">Convenio</option>
														<option value="Servicios ecoturísticos">Servicios ecoturísticos</option>
														<option value="Otro">Otro</option>
													</select>
													<ErrorMessage errors={errors} name="tipo_contrato" as="div" className="invalid-feedback" />
												</CCol>
												{labelOtroContrato &&
													<CCol xs="12" sm="6" md="6" className="mb-2">
														<CFormLabel htmlFor="nf-email">Otro Contrato</CFormLabel>
														<CFormControl
															type="text"
															name="otro_tipo_contrato"
															placeholder="Indique el tipo de contrato"
															ref={register({
																required: { value: true, message: "campo obligatorio" }
															})}
														/>
														<ErrorMessage errors={errors} name="otro_tipo_contrato" as="div" className="invalid-feedback" />
													</CCol>
												}
											</CRow>
											<CRow>
												<CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
													<Fecha
														name='fecha_inicio'
														label='Fecha de inicio'
														register={register}
														errors={errors}
														dateFormat='dd/MM/yyyy'
														formatoMesAno={false}
														dateFormatOculto='y-m-d'
														inicial={concesion.fecha_inicio}
														onChange={() => { }}
													/>
												</CCol>
												<CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
													<Fecha
														name='fecha_termino'
														label='Fecha de término'
														register={register}
														errors={errors}
														dateFormat='dd/MM/yyyy'
														formatoMesAno={false}
														dateFormatOculto='y-m-d'
														inicial={concesion.fecha_termino}

													/>
												</CCol>

											</CRow>
											<CRow>
												<CCol xs="12" sm="6" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto Cuota Fija</CFormLabel>
													<CFormControl
														type="text"
														name="monto_cuota_fija"
														placeholder=""
														ref={register({

														})}
													/>
													<ErrorMessage errors={errors} name="monto_cuota_fija" as="div" className="invalid-feedback" />
												</CCol>
												<CCol xs="12" sm="6" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto Cuota Variable</CFormLabel>
													<CFormControl
														type="text"
														name="monto_cuota_variable"
														placeholder=""
														ref={register({

														})}
													/>
													<ErrorMessage errors={errors} name="monto_cuota_variable" as="div" className="invalid-feedback" />
												</CCol>
												<CCol xs="12" sm="12" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto Aporte Extra</CFormLabel>
													<CFormControl
														type="text"
														name="monto_aporte_extra"
														placeholder=""
														ref={register({

														})}
													/>
													<ErrorMessage errors={errors} name="monto_aporte_extra" as="div" className="invalid-feedback" />
												</CCol>

											</CRow>

											<CRow>
												<CCol xs="12" sm="6" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto Total Pago Anual</CFormLabel>
													<CFormControl
														type="number"
														name="monto_total_pago_anual"
														placeholder=""
														ref={register({
														})}
													/>
													<ErrorMessage errors={errors} name="monto_total_pago_anual" as="div" className="invalid-feedback" />
												</CCol>
												<CCol xs="12" sm="6" md="4" className="mb-2">
													<CFormLabel htmlFor="nf-email">Monto Total Último Pago</CFormLabel>
													<CFormControl
														type="number"
														concesion name="monto_total_ultimo_pago"
														placeholder=""
														ref={register({
														})}
													/>
													<ErrorMessage errors={errors} name="monto_total_ultimo_pago" as="div" className="invalid-feedback" />
												</CCol>
												<CCol xs="12" sm="12" md="4" lg="4" className="mb-2">
													<Fecha
														name='fecha_ultimo_pago'
														label='Fecha de último pago'
														register={register}
														errors={errors}
														dateFormat='dd/MM/yyyy'
														formatoMesAno={false}
														dateFormatOculto='y-m-d'
														inicial={concesion.fecha_ultimo_pago}
														onChange={() => { }}
													/>
												</CCol>

											</CRow>
											<CRow>
												<CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
													<Fecha
														name='fiscalizacion_vigente'
														label='Fiscalización vigente'
														register={register}
														errors={errors}
														dateFormat='dd/MM/yyyy'
														formatoMesAno={false}
														dateFormatOculto='y-m-d'
														inicial={concesion.fiscalizacion_vigente}
														onChange={() => { }}
													/>
												</CCol>
												<CCol xs="12" sm="6" md="6" className="mb-2">
													<CFormLabel htmlFor="nf-email">Certificación Sernatur</CFormLabel>
													<select
														className="form-select"
														name="certificacion_sernatur"
														ref={register({
															required: { message: "campo obligatorio" }
														})}>
														<option value="Sello de calidad turística">Sello de calidad turística</option>
														<option value="Sello de sustentabilidad turística">Sello de sustentabilidad turística</option>
														<option value="Protocolo sanitario ante COVID-19">Protocolo sanitario ante COVID-19</option>
														<option value="Otros">Otros</option>
													</select>
													<ErrorMessage errors={errors} name="certificacion_sernatur" as="div" className="invalid-feedback" />
												</CCol>

											</CRow>
											<CRow>
												<CFormLabel htmlFor="nf-email" className="mt-2"><b>Temporabilidad del servicio (meses)</b></CFormLabel>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="enero"
														label="Enero"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="febrero"
														label="Febrero"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="marzo"
														label="Marzo"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="abril"
														label="Abril"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="mayo"
														label="Mayo"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="junio"
														label="Junio"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="julio"
														label="Julio"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="agosto"
														label="Agosto"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="septiembre"
														label="Septiembre"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="octubre"
														label="Octubre"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="noviembre"
														label="Noviembre"
														ref={register({})}
													/>
												</CCol>
												<CCol xs="6" lg="2" className="mb-2">
													<CFormCheck
														type="checkbox"
														name="diciembre"
														label="Diciembre"
														ref={register({})}
													/>
												</CCol>
											</CRow>
											<CRow>

												<CCol xs="12" sm="12" md="12" className="mb-2">
													<CFormLabel htmlFor="nf-email">Accesibilidad Universal</CFormLabel>
													<CFormControl
														type="text"
														name="accesibilidad_universal"
														placeholder=""
														ref={register({
														})}
													/>
													<ErrorMessage errors={errors} name="accesibilidad_universal" as="div" className="invalid-feedback" />
												</CCol>
											</CRow>
											<CRow>
												
												<CCol xs="12" sm="4" md="4" lg="4" className="mb-2">

													<SelectorProveedor
														refrescar={random}
														iniciar={true}
														control={control}
														Controller={Controller}
														ErrorMessage={ErrorMessage}
														errors={errors}
														// opcionTodos={true}
														setValue={setValue}
														name='proveedor'
														// opcionGeneral={true}
														placeholder='No seleccionado'
														seleccionadoId={proveedorSeleccionado} // id del elemento seleccionado
														onChange={(seleccionado) => {
															if (seleccionado) {
																setProveedorSeleccionado(seleccionado)

															} else {
																setProveedorSeleccionado({})

															}
														}}
													// cargado={(data) => {
													// }}
													/>

												</CCol>
												<CCol xs="12" sm="2" md="2" lg="2" className="mb-2">
													<CButton className="mt-4 " size="sm" color="info" style={{ color: 'white' }} onClick={() => { setShowFormProveedor(true) }}><FaPlus />Añadir proveedor</CButton>
												</CCol>
											</CRow>
										</CCol>
									</CRow>
								</CCardBody>
							</CCard>

						</CTabPane>
					</CTabContent>
					<CTabPane data-tab="preview" visible={activeTab === 'ubicación'}>
						<CRow>
							{cargarMapa &&
								<Mapa
									latitud={area ? area.latitud : ''}
									longitud={area ? area.longitud : ''}
									latitudMarcador={marcador.latitud}
									longitudMarcador={marcador.longitud}
									zoom={9}
									onChange={(event) => {
										setMarcador({
											latitud: event.latitud,
											longitud: event.longitud,

										});
									}} />
							}
						</CRow>
					</CTabPane>
					<CTabPane data-tab="preview" visible={activeTab === 'documentos'}>
						<CRow className="mt-2">
							<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
								<CargarDocumento
									documentos={documentos} // documentos que ya tiene la entidad en base de datos
									enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
									entidad_nombre={'Concesion'}
									entidad_id={concesion.id}
									enviado={() => { // cuando se enviaron todos los archivos
										setEnviarArchivos(false)
										// toast.success('Cambios almacenados');
									}}
									cambioArchivos={(data) => {
										// Es el estado interno de los documentos si quiere guardar utilice otro estado para no crear un ciclo infinito
									}} />
							</CCol>
						</CRow>
					</CTabPane>
					<CCard className="mt-2">
						<CCardBody>
							<CRow>
								<CCol lg={12}>
									<Link to={'/area-protegida/' + area_protegida_id + '/concesiones'}>
										<CButton className="mt-2 float-start" size="lg" color="light"><IoMdReturnLeft /> Volver</CButton>
									</Link>
									<CButton className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave /> Guardar</CButton>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CForm>
				<FormProveedor
					visible={showFormProveedor}
					proveedor={[]}
					onHide={(proveedor) => {
						setShowFormProveedor(false)
						if (proveedor.id) {
							setRandom(Math.random())
							setProveedorSeleccionado(proveedor.id)
						}
					}}
					close={() => {
						setShowFormProveedor(false)
						// obtenerDatos()
					}}
				/>
			</ErpLayout>
		</>
	)
}

export default FormConcesion