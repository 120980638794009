import React, { useEffect, useState } from 'react';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import MapaMarcadores from '../../componentes/MapaMarcadores';
import { CNav, CNavItem, CNavLink} from '@coreui/react-pro';

const MapaInfraestructuras = () => {

    const [infraestructuras, setInfraestructuras] = useState([]);
    const [activeTab, setActiveTab] = useState('mapa');
    useEffect(
        () => {
            obtenerDatos()
        },
        []
    )

    async function obtenerDatos() {
        let resultado = await api.infraestructura.getAll();
        if (resultado && resultado.status === 200) {
            setInfraestructuras(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    { active: true, pathname: '', name: 'Infraestructuras' },]
                }>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink data-tab="preview" active={activeTab === 'mapa'} onClick={() => setActiveTab('mapa')}>
                            Mapa
						</CNavLink>
                    </CNavItem>
                </CNav>
                <MapaMarcadores
                    latitud={-38.4095433}
                    longitud={-71.6486941}
                    latitudMarcador={-43.4095433}
                    longitudMarcador={-71.6486941}
                    zoom={3.1}
                    marcadores={infraestructuras} />
            </ErpLayout>
        </>
    )
}

export default MapaInfraestructuras