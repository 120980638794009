import React, { useEffect, useState } from 'react';
import { CCol, CRow ,CButton} from '@coreui/react';
import ErpLayout from '../layout/ErpLayout';
import CargarArchivosAsp from '../componentes/CargarArchivosAsp';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase,LongTextFormatter, NumberFormatter } from '../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus, FaRegFileExcel, FaPencilAlt } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { exportar } from '../utils/Funciones';

const Asp = () => {
  let history = useHistory();
  const columns = [
    { key: 'codigo', name: 'CODSIG', formatter: LongTextFormatter },
    { key: 'nombre', name: 'Unidad', formatter: LongTextFormatter, width: 400},
    { key: 'categoria', name: 'Categoría', formatter: LongTextFormatter,width:230 },
    { key: 'nombre_region', name: 'Región', formatter: LongTextFormatter,width:250},
    { key: 'comuna', name: 'Comuna', formatter: LongTextFormatter,width:200},
    { key: 'superficie', name: 'Superficie', formatter: NumberFormatter },
  ];

  const [rows, setRows] = useState([]);
  useEffect(
    () => {
        obtenerDatos();
    },
    []
  )

  async function obtenerDatos() {
    let resultado = await api.areaProtegida.get();
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  async function menuEditar(e, { rowIdx }) {
    let resultado = await api.areaProtegida.getOne(rows[rowIdx].id);
    if (resultado && resultado.status === 200) {
      history.push(`/area-protegida/${resultado.data.datos.id}/editar`)
    } else {
        toast.error('Error al obtener datos');
    }
  }
  function agregarAsp(){
    history.push(`/area-protegida/crear`)
  }

  function exportarAsp(){
    exportar(`api/exportar/areas-protegidas`);
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          {active: true, pathname: '', name: 'Administración'},
          {active: true, pathname: `/asp`, name: 'ASP'}]
      }>
        <CRow>
				  <CCol xs="12" sm="12" md="12" lg="12">
            <CButton color="success" style={{float:'right'}} onClick={agregarAsp} className="mr-1 my-2"><FaPlus/> Agregar ASP </CButton>
            <CButton color="light" style={{float:'right'}} onClick={exportarAsp} className="mx-1 my-2"><FaRegFileExcel /> Exportar </CButton>
          </CCol>
			  </CRow>
        <CRow xs="12" sm="6" md="4" lg="3">
          <CCol >
            <CargarArchivosAsp retorno={(archivo) => {
               obtenerDatos()
            }} />
          </CCol>
        </CRow>
        <GrillaBase
          menu={<ContextMenu id="grid-context-menu">
            <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
            </ContextMenu>}
          rows = {rows}
          columns = {columns}
        />
      </ErpLayout>
    </>
  )
}

export default Asp