import React, { useEffect, useState } from 'react';
import {CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter,NumberFormatter } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import { FaPlus, FaPencilAlt, FaTimes} from 'react-icons/fa';

import ModalEliminar from './ModalEliminar';
import FormTipoArea from './FormTipoArea'


const TipoArea = () => {
  let history = useHistory();

  const columns = [
    { key: 'nombre_area', name: 'Área', formatter: LongTextFormatter},
    { key: 'unidades', name: 'Unidades', formatter: LongTextFormatter},
  ];

  const [rows, setRows] = useState([
    {'nombre_area':'Administracion','unidades':'Cantidad de personas'},
    {'nombre_area':'Sector Acampar','unidades':'Cantidad de carpas, máximo de personas'}
  ]);
  const [showFormTipoArea, setShowFormTipoArea] = useState(false);  // Modal para crear/editar usuario
  const [tipoArea, setTipoArea] = useState({});
  const [showEliminarTipoArea, setShowEliminarTipoArea] = useState(false);  // Modal para eliminar usuario
  const [msgEliminar, setMsgEliminar] = useState('');
  // useEffect(
  //   () => {
  //       obtenerDatos()
  //   },
  //   []
  // )

  async function obtenerDatos() {
    let resultado = await api.infraestructura.get();
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }
    async function menuEditar(e, { rowIdx }) {
        // let resultado = await api.usuarios.getOne(rows[rowIdx].id);
        // if (resultado && resultado.status === 200) {
        //     setUsuario(resultado.data.datos);
        //     setShowFormUsuario(true)
        // } else {
        //     setUsuario({});
        //     toast.error('Error al obtener datos');
        // }
        setShowFormTipoArea(true)
    }

    const menuEliminar = (e, {rowIdx}) => {
        setTipoArea({})
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id){
                setTipoArea(element)
                setMsgEliminar('¿Estás seguro de eliminar el area ' + element.nombre_area + '? ');
                setShowEliminarTipoArea(true)
            }
        });
    }
    function cerrarModalUsuario(){
        setShowFormTipoArea(false)
        // obtenerDatos()
    }
    async function retornoEliminarArea() {
        // let resultado = await api.usuarios.delete(usuario.id);
        // if (resultado && resultado.status === 200) {
        //     setUsuario({})
        //     obtenerDatos()
        //     toast.success('Usuario eliminado');
        //     setShowEliminarUsuario(false)
        // } else {
        //     toast.success(resultado.data.msg);
        //     setShowEliminarUsuario(false)
        // }
        setShowEliminarTipoArea(false)
    }
    function functionRetorno(data) {
        // obtenerDatos()
        // setShowFormUsuario(false)
        // console.log('data', data)
    }
  return (
    <>
      <ErpLayout
        breadcrumbs={[
          {active: true, pathname: '', name: 'Administración'},
          {active: true, pathname: `/area`, name: 'Tipo de áreas'}]
      }>
        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12">
                <CButton color="success" style={{float:'right'}} onClick={() => setShowFormTipoArea(true)} className="mr-1 my-2"><FaPlus/> Agregar Tipo de Área </CButton>
            </CCol>
        </CRow>
        <GrillaBase
            menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
                <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
            </ContextMenu>}
            columns={columns}
            rows={rows}
        />
        <FormTipoArea
            visible={showFormTipoArea}
            tipoArea={tipoArea}
            onHide={cerrarModalUsuario}
            close={()=> {
                setTipoArea({})
                setShowFormTipoArea(false)
                // obtenerDatos()
            }}
            retorno={functionRetorno}/>
        <ModalEliminar
            titulo="Eliminar"
            visible={showEliminarTipoArea}
            tipoArea={tipoArea}
            setShow={setShowEliminarTipoArea}
            confirmar={retornoEliminarArea}
            >
                {msgEliminar}
        </ModalEliminar>

      </ErpLayout>
    </>
  )
}

export default TipoArea