import React, { useState, useEffect } from 'react';
import { CFormLabel, CFormControl, CInputGroup, CButton } from '@coreui/react-pro';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { ErrorMessage } from 'react-hook-form';
import { getYMD, getYMDHIS, getYear } from '../utils/Funciones';
import CIcon from '@coreui/icons-react'
registerLocale('es', es)

function Fecha(props) {
    const [oculto, setOculto] = useState('');
    const [fecha, setFecha] = useState('');
    const [formatoHora, setFormatoHora] = useState(false);
    const [formatoMesAno, setFormatoMesAno] = useState(false);

    useEffect(
        () => {
            if (props.inicial) {
                let valorInicial = props.inicial.toString();
                /* Viene como fecha de base de datos del estilo y-m-d - y-m-d h:i:s
                 * debemos cambiar los - por / si no tendremos perdida de un día */
                valorInicial = valorInicial.replace(/-/g, '/');
                valorInicial = new Date(valorInicial);

                // Ahora actualizaremos el campo oculto al formato que nosotros queremos
                if (props.dateFormatOculto === 'y-m-d') {
                    setOculto(valorInicial ? getYMD(valorInicial) : '');
                } else if (props.dateFormatOculto === 'y-m-d h:i:s') {
                    setOculto(valorInicial ? getYMDHIS(valorInicial) : '');
                } else if (props.dateFormatOculto === 'y') {
                    setOculto(valorInicial ? getYear(valorInicial) : '');
                }

                // Actualizamos la fecha en el datepicker
                setFecha(props.inicial ? valorInicial : '');
            }

            // Estos son algunos props del datepicker
            if (props.dateFormat === 'dd/MM/Y h:mm aa') { // Para agregar formato hora
                setFormatoHora(true);
            } else if (props.dateFormat === 'MM/yyyy') { // Para que solo sea mes y año
                setFormatoMesAno(true);
            }
            if (props.formatoMesAno === true) { // Para que solo sea mes y año
                setFormatoMesAno(true);
            }
        },
        [props.inicial]
    )


    /**
     * Cada vez que cambia la fecha
     *
     * @param {array} selected
     */
    function onChangeDate(fechaData) {
        if (props.dateFormatOculto === 'y-m-d') {
            setOculto(fechaData ? getYMD(fechaData) : '');
        } else if (props.dateFormatOculto ==='y-m-d h:i:s') {
            setOculto(fechaData ? getYMDHIS(fechaData) : '');
        } else if (props.dateFormatOculto ==='y') {
            setOculto(fechaData ? getYear(fechaData) : '');
        }
        setFecha(fechaData);
        if (props.onChange) { // para ejecutar un onChange externo
            if (props.dateFormatOculto === 'y-m-d') {
                props.onChange(getYMD(fechaData))
            } else if (props.dateFormatOculto ==='y-m-d h:i:s') {
                props.onChange(getYMDHIS(fechaData))
            } else if (props.dateFormatOculto ==='y') {
                props.onChange(getYear(fechaData))
            } else {
                props.onChange(fechaData)
            }
        }
    }

    return (
        <>
            <CFormLabel htmlFor="nf-email">{props.label ? props.label : 'Fecha'}</CFormLabel>
            <CInputGroup>
                <div id="cont-fecha" style={{ width: 'calc(100% - 40px)' }} className={formatoHora ? 'formato-hora' : ''}>
                    <DatePicker
                        className="form-control"
                        name="fecha"
                        // peekNextMonth
                        // showPopperArrow={false}
                        minDate={props.minDate ? new Date(props.minDate.replace(/-/g, '/')) : false} // Debe venir formato base de datos 2008-08-01
                        maxDate={props.maxDate ? new Date(props.maxDate.replace(/-/g, '/')) : false} // Debe venir formato base de datos 2008-08-01
                        // placeholderText="mes día año"
                        // isClearable
                        showTimeSelect={formatoHora}
                        timeCaption="Hora"
                        disabled={props.disabled ? true : false}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat={props.dateFormat ? props.dateFormat : 'dd/MM/Y'}
                        showMonthYearPicker={formatoMesAno}
                        locale="es"
                        selected={fecha ? fecha : ''}
                        onChange={(e) => onChangeDate(e)}
                        showYearPicker={props.dateFormat === 'yyyy' ? true : false} />
                </div>
                    <CButton
                        style={{zIndex: 0}}
                        color="secondary"
                        variant="outline"
                        id={props.name}
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            setOculto('');
                            setFecha('');
                            if (props.limpiar) {
                                props.limpiar();
                            }
                        }}>
                        <CIcon name="cil-x" size={'sm'}/>
                    </CButton>
                {props.errors ?
                    <CFormControl
                        type="text"
                        name={props.name}
                        value={oculto}
                        hidden
                        ref={props.register({
                            required: "Seleccione la fecha"
                        })}
                    />
                :
                    <CFormControl
                        type="text"
                        name={props.name}
                        value={oculto}
                        hidden
                        ref={props.register()}
                    />
                }
            </CInputGroup>
            {props.errors &&
                <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
            }
        </>
    );
}

export default Fecha;