import React, { useEffect, useState } from 'react';
import { CCol, CRow, CNav,CTabContent, CTabPane, CNavItem, CNavLink, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, PercentFormatter } from '../../componentes/GrillaBase';
import FiltroPeriodosTH from '../../componentes/FiltroPeriodosTH';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getYearNow, NumberGraphicFormatter, exportar } from '../../utils/Funciones';
import { FaRegFileExcel } from 'react-icons/fa';


const ReporteEvaluacion = () => {
    const [activeTab, setActiveTab] = useState('reporte');
    const columns = [
        { key: 'accion', name: 'Acción', formatter: LongTextFormatter },
        { key: 'estrategia', name: 'Estrategia', formatter: LongTextFormatter },
        { key: 'subestrategia', name: 'Subestrategia', formatter: LongTextFormatter },
        { key: 'cantidad_poas', name: 'POAs', formatter: NumberFormatter, width: 80 },
        { key: 'cantidad_actividades', name: 'Actividades', formatter: NumberFormatter, width: 100 },
        { key: 'cantidad_evaluaciones', name: 'Evaluaciones', formatter: NumberFormatter, width: 100 },
        { key: 'porcentaje_evaluaciones', name: '%', formatter: PercentFormatter, width: 100 },
    ];
    const [rows, setRows] = useState([]);
    const [periodo, setPeriodo] = useState(getYearNow());
    const [regionId, setRegionId] = useState('');

    useEffect(
        () => {
            obtenerDatos();
        },
        [periodo, regionId]
    )

    async function obtenerDatos() {
        if(periodo){
            let resultado = await api.poa.reporteEvaluacion({'periodo': periodo, 'region_id': regionId});
            if (resultado && resultado.status === 200) {
                setRows(resultado.data.datos)
            } else {
                toast.error('Error al obtener datos');
            }
        }
    }

    function exportarReporte(){
        exportar(`api/exportar/poa/evaluacion?periodo=${periodo}&region_id=${regionId}`);
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Planificación'},
                    {active: true, pathname: `/poa`, name: 'Reporte evaluación'}]
                }>
                <CRow className='mb-2'>
                    <CCol xs="12" sm="6" md="4">
                        <FiltroPeriodosTH
                            todos={false}
                            valorInicial={periodo}
                            onChange={(e) => {
                                setPeriodo(e ? e.value : '')
                            }}
                        />
                    </CCol>
                    <CCol xs="12" sm="6" md="5">
                        <FiltroRegionesTH
                            todos={true}
                            onChange={(e) => {
                                setRegionId(e ? e.value : '')
                            }}
                        />
                    </CCol>
                    <CCol md="3">
                        <CButton
                            color="light"
                            onClick={exportarReporte}
                            className="mt-4 float-sm-end"
                            ><FaRegFileExcel />
                                Exportar
                        </CButton>
                    </CCol>
                </CRow>
                <CNav variant="tabs">
					<CNavItem>
						<CNavLink data-tab="preview" active={activeTab === 'reporte'} onClick={() => setActiveTab('reporte')}>
							Reporte
						</CNavLink>
					</CNavItem>
                    <CNavItem>
						<CNavLink data-tab="preview" active={activeTab === 'grafico'} onClick={() => setActiveTab('grafico')}>
							Gráfico
						</CNavLink>
					</CNavItem>
				</CNav>
                <CTabContent>
					<CTabPane data-tab="preview" visible={activeTab === 'reporte'}>
                        <GrillaBase
                            rows={rows}
                            columns={columns}
                        />
                    </CTabPane>
                    <CTabPane data-tab="preview" visible={activeTab === 'grafico'}>
                        <CRow >
                            <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                                <div style={{ width: '100%', height: 600 }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                        layout="vertical"
                                        width={500}
                                        height={600}
                                        data={rows}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number" tickFormatter={value => NumberGraphicFormatter({value:value})}/>
                                        <YAxis dataKey="subestrategia" type="category" width={350} />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)}/>
                                        <Legend />
                                        <Bar dataKey="porcentaje_evaluaciones" name="Porcentaje Evaluaciones" stackId="a" fill="#8884d8" />
                                        {/* <Bar dataKey="total_terceros" name="Total terceros" stackId="a" fill="#8884d8" /> */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </CCol>
                        </CRow>
                    </CTabPane>
                </CTabContent>
            </ErpLayout>
        </>
    )
}

export default ReporteEvaluacion