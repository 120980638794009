import React, { useState, useEffect } from 'react'
import { CTableHead, CTable, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CForm, CFormLabel, CFormControl, CCol, CRow, CButton, CModal, CModalBody, CBadge,CModalHeader, CModalFooter, CTabs, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CImage } from '@coreui/react-pro';
import FileViewer from 'react-file-viewer';
import { descargar } from '../utils/Funciones';
import { FaDownload, FaTimes, FaInfo,FaTrashAlt, FaSave} from 'react-icons/fa';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import ModalEliminar from './administracion/ModalEliminar';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import SelectorTipoInformacionTHRHF from '../componentes/SelectorTipoInformacionTHRHF';
import SelectorAreaProtegidaTHRHF from '../componentes/SelectorAreaProtegidaTHRHF';

const FormArchivo = (props) => {
    const { archivoData, botonELiminar, close, ...rest } = props;
    const [archivo, setArchivo] = useState({});
    const [activeTab, setActiveTab] = useState('preview');
    const [showEliminarArchivo, setShowEliminarArchivo] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    const alto = (window.innerHeight - 300) + 'px';
    const { register, handleSubmit, errors, setValue, reset, control } = useForm({});
    const [tipoInformacionId, setTipoInformacionId] = useState('');
    const [tagsEntidad, setTagEntidad] = useState([]);

    useEffect(
        () => {
            if (Object.keys(archivoData).length > 0) {
                cargarArchivo(archivoData.id);
            } else {
                setArchivo({})
            }
        }, [archivoData]
    )

    async function cargarArchivo(archivoId) {
        let resultado = await api.documentos.getOne(archivoId);
        if (resultado && resultado.status === 200) {
            let archivo = resultado.data.datos;
            setArchivo(resultado.data.datos)
            setValue([
                {'nombre_documento': archivo.nombre_documento},
                {'descripcion': archivo.descripcion},
            ])
            setTipoInformacionId(archivo.tipo_informacion_id);
            let tags = [];
            resultado.data.datos.entidades.forEach(entidad => {
                entidad.tags.forEach(tag => {
                    tag.estado = 'creado';
                    tags.push(tag)
                });
            });
            setTagEntidad(tags);
        } else {
            toast.error('Error al extraer texto, intente nuevamente.');
        }
    }

    function onError(e) {
    }

    const despliegue = (archivoData) => {
        if (['png', 'jpeg', 'jpg'].includes(archivoData.tipo_archivo)) {
            return (
                <CCol style={{textAlign:'center', marginTop: '10px', width: '100%', height: alto, overflowY: 'scroll' }}>
                    <CImage
                            src={archivoData.ruta_completa}
                            width='100%'
                            height='auto'
                        />
                </CCol>)
        } if (['pdf', 'doc', 'docx', 'doc', 'xlsx'].includes(archivoData.tipo_archivo)) {
            return (
                <CCol style={{textAlign:'center', marginTop: '10px', width: '100%', height: alto }}>
                    <FileViewer
                        fileType={archivo.tipo_archivo}
                        filePath={archivo.ruta_completa}
                        onError={onError}
                    />
                </CCol>)
        } else {
            return <span>Vista previa no disponible</span>
        }
    }

    function cerrar() {
        close()
        setActiveTab('preview')
    }

    async function extraerTexto(metodo) {
        let resultado = await api.documentos.extraerMetadata(archivo.id, metodo);
        if (resultado && resultado.status === 200) {
            setArchivo({
                ...archivo,
                texto_completo: resultado.data.data.texto_completo
            })
            toast.success('Información extraida.');
        } else {
            toast.error('Error al extraer texto, intente nuevamente.');
        }
    }

    async function retornoEliminarDocumento() {
        let resultado = await api.documentos.delete(archivo.id);
        if (resultado && resultado.status === 200) {
            toast.success('Archivo eliminado con éxito');
            setShowEliminarArchivo(false)
            close()
        } else {
            toast.error(resultado.data.msg, {autoClose: 6000});
            setShowEliminarArchivo(false)
        }
    }

    function eliminarDocumento(archivo) {
        setMsgEliminar('¿Estás seguro de eliminar el archivo ' + archivo.nombre_archivo + '? ');
        setShowEliminarArchivo(true)
    }

    const onSubmit = async (data) => {
        data.tipo_informacion_id = data.tipo_informacion.id;
        data.area_protegida_id = data.area_protegida.value === 'general' ? null : data.area_protegida.value;
        data.tags = tagsEntidad;
        if (archivo.id) {
            let resultado = await api.documentos.put(archivo.id, data);
            if (resultado && resultado.status === 200) {
				toast.success("Documento actualizado");
                let tags = Object.assign([], tagsEntidad);
                let nuevoTags = [];
                tags.forEach(tag => {
                    if (tag.estado !== 'eliminar') {
                        nuevoTags.push(tag);
                    }
                });
                setTagEntidad(nuevoTags);
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }

	return (
        <CModal
            visible={rest.show}
            keyboard={false}
            size='xl'
        >
            <CModalHeader onDismiss={cerrar}>{archivo.nombre_documento}</CModalHeader>
                <CModalBody>
                    {/* <CTabs activeTab={activeTab} onActiveTabChange={(e) => {
                        setActiveTab(e)
                    }}> */}
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="preview" active={activeTab === 'preview'} onClick={() => setActiveTab('preview')}>
                                    Vista previa
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="informacion" active={activeTab === 'informacion'} onClick={() => setActiveTab('informacion')}>
                                    Información
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="metadata" active={activeTab === 'metadata'} onClick={() => setActiveTab('metadata')}>
                                    Texto sin formato
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent style={{paddingTop: '10px'}}>
                            <CTabPane data-tab="preview" visible={activeTab === 'preview'}>
                                <CRow id="wena" style={{ height: '500px', overflow: 'scroll', width: '100%' }}>
                                        {(Object.keys(archivo).length > 0) &&
                                            <>
                                                {despliegue(archivo)}
                                            </>
                                        }
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="informacion" visible={activeTab === 'informacion'}>
                                <CRow>
                                    <CCol xs="12" sm="12" md="12" lg="12" style={{ height: alto, overflowY: 'scroll', width: '99%' }}>
                                        <CForm onSubmit={handleSubmit(onSubmit)}>
                                            <CRow>
                                                <CCol xs="12" sm="12" md="12" lg="12">
                                                    <CRow>
                                                        <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                                            <CFormLabel htmlFor="nf-email">Nombre documento</CFormLabel>
                                                            <CFormControl
                                                                type="text"
                                                                name="nombre_documento"
                                                                placeholder=""
                                                                ref={
                                                                    register({
                                                                        required: { value: true, message: "campo obligatorio" }
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMessage errors={errors} name="nombre_documento" as="div" className="invalid-feedback" />
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                                <CCol xs="12" md="12" className="mb-2">
                                                    <CFormLabel htmlFor="nf-email">Descripción</CFormLabel>
                                                    <CFormControl
                                                        name="descripcion"
                                                        component="textarea"
                                                        rows="4"
                                                        placeholder=""
                                                        ref={register({})}
                                                    />
                                                    <ErrorMessage errors={errors} name="descripcion" as="div" className="invalid-feedback" />
                                                </CCol>
                                                <CCol xs="12" className="mb-2">
                                                    <SelectorTipoInformacionTHRHF
                                                        iniciar={rest.show}
                                                        control={control}
                                                        Controller={Controller}
                                                        ErrorMessage={ErrorMessage}
                                                        errors={errors}
                                                        name='tipo_informacion'
                                                        opcionGeneral={false}
                                                        placeholder=''
                                                        setValue={setValue}
                                                        seleccionadoId={tipoInformacionId} // id del elemento seleccionado
                                                    />
                                                </CCol>
                                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                                    <SelectorAreaProtegidaTHRHF
                                                        iniciar={rest.show}
                                                        control={control}
                                                        Controller={Controller}
                                                        ErrorMessage={ErrorMessage}
                                                        errors={errors}
                                                        // opcionTodos={true}
                                                        setValue={setValue}
                                                        name='area_protegida'
                                                        opcionGeneral={true}
                                                        placeholder='No seleccionado'
                                                        seleccionadoId={archivo.area_protegida_id ? archivo.area_protegida_id : 'general'} // id del elemento seleccionado
                                                    />
                                                </CCol>
                                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2 mb-4">
                                                    {archivo.entidades && archivo.entidades.length > 0 &&
                                                        <>
                                                            <CFormLabel style={{fontWeight:500}}>Entidades donde es utlizado el documento</CFormLabel>
                                                            <CTable striped small>
                                                                <CTableHead>
                                                                    <CTableRow>
                                                                    <CTableHeaderCell scope="col" style={{fontWeight:500}}>Entidad</CTableHeaderCell>
                                                                    <CTableHeaderCell scope="col" style={{fontWeight:500}}>Tags</CTableHeaderCell>
                                                                    </CTableRow>
                                                                </CTableHead>
                                                                <CTableBody>
                                                                    {archivo.entidades.map((entidad) =>
                                                                        <CTableRow>
                                                                            <CTableDataCell>{entidad.nombre}</CTableDataCell>
                                                                                <CTableDataCell>
                                                                                {entidad.tags.map((tag) =>
                                                                                    <>
                                                                                        {tag.estado === 'creado' &&
                                                                                            <CBadge style={{ backgroundColor: '#8a93a2', color: 'white', display: 'inline-block !important', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', fontWeight:400 }}>
                                                                                                {tag.nombre}
                                                                                                <FaTimes style={{cursor: 'pointer'}} onClick={() => {
                                                                                                    let tags = Object.assign([], tagsEntidad);
                                                                                                    tags.forEach(tagData => {
                                                                                                        if (tag.id === tagData.id) {
                                                                                                            tagData.estado = 'eliminar'
                                                                                                        }
                                                                                                    });
                                                                                                    setTagEntidad(tags)
                                                                                                }}/>
                                                                                            </CBadge>
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                                </CTableDataCell>
                                                                        </CTableRow>
                                                                    )}
                                                                </CTableBody>
                                                            </CTable>
                                                        </>

                                                    }
                                                </CCol>
                                            </CRow>
                                            <CButton
                                                color="success"
                                                type="submit"
                                                style={{float:'right'}}
                                            ><FaSave /> Guardar </CButton>
                                        </CForm>
                                    </CCol>
                                </CRow>
                            </CTabPane>
                            <CTabPane data-tab="metadata" visible={activeTab === 'metadata'}>
                                <CRow style={{marginTop:'5px', marginBottom:'5px' }}>
                                    <CCol xs="12" sm="12" md="12" lg="12">
                                        <CButton style={{marginRight: '5px'}} size="sm" color="primary" onClick={() => extraerTexto('normal')}>
                                            <FaInfo /> Extraer texto normal
                                        </CButton>
                                        <CButton size="sm" color="primary" onClick={() => extraerTexto('tesseract')}>
                                            <FaInfo /> Extraer texto Tesseract
                                        </CButton>
                                    </CCol>
                                </CRow>
                                <CRow style={{}}>
                                    <CCol xs="12" sm="12" md="12" lg="12" style={{ height: alto, overflowY: 'scroll', width: '99%',marginTop: '10px' }}>
                                        {archivo.texto_completo ? archivo.texto_completo : 'No hay información'}
                                    </CCol>
                                </CRow>
                            </CTabPane>
                        </CTabContent>
                    {/* </CTabs> */}
                </CModalBody>
                <CModalFooter>
                    <CButton color="light" onClick={cerrar}>
                        <FaTimes /> Cerrar</CButton>
                    {botonELiminar &&
                        <CButton color="danger" onClick={() => eliminarDocumento(archivo)}>
                            <FaTrashAlt /> Eliminar</CButton>
                    }
                    {(Object.keys(archivo).length > 0) &&
                        <CButton color="info" onClick={() => descargar(archivo)}>
                            <FaDownload /> Descargar
                        </CButton>
                    }
                </CModalFooter>
                <ModalEliminar
                    titulo="Eliminar"
                    visible={showEliminarArchivo}
                    archivo={archivo}
                    setShow={setShowEliminarArchivo}
                    confirmar={retornoEliminarDocumento}
                    >
                        {msgEliminar}
                </ModalEliminar>
        </CModal>
	)
}

export default FormArchivo