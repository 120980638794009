import React, { useEffect, useState } from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter, CFormCheck} from '@coreui/react-pro'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';

const FormProveedor = (props) => {
    const { register, handleSubmit, errors, setValue, control, watch } = useForm({});
    const { proveedor, ...rest } = props;
    const [ nombreModal, setNombreModal ] = useState('');
    const onSubmit = async (data) => {
        let resultado;
        console.log(data)
        //validación de los rut
        let rut_valido = data.rut
        rut_valido = (rut_valido.replace(/\./g, ''));
        rut_valido = (rut_valido.replace(/\-/g, ''));
        console.log(rut_valido.length)
        if (rut_valido.length < 8 || rut_valido.length >9){
            toast.error("Rut del proveedor inválido")
            return false
        }else{data.rut = rut_valido}

        rut_valido = data.representante_legal_rut
        rut_valido = (rut_valido.replace(/\./g, ''));
        rut_valido = (rut_valido.replace(/\-/g, ''));
        if (rut_valido.length < 8 || rut_valido.length >9){
            toast.error("Rut del representante legal inválido")
            return false
        }else{data.representante_legal_rut = rut_valido}

        data.rut = data.rut.replace(/\-/g, '')
        data.representante_legal_rut = data.representante_legal_rut.replace(/\-/g, '')


        if (proveedor.id){
		    resultado = await api.proveedor.put(proveedor.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Proveedor actualizado con éxito');
                console.log(resultado)
                cerrarModal(resultado.data.data)
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    resultado = await api.proveedor.post(data);
            if (resultado && resultado.status === 200) {
                toast.success('Proveedor agregado con éxito');
                console.log(resultado)
                cerrarModal(resultado.data.data)
            } else {
                toast.error(resultado.data.msg);
            }
        }
	}

    useEffect(
        () => {
            if (Object.keys(proveedor).length > 0) {
                setValue([
                    { id: proveedor.id },
                    { nombre: proveedor.nombre },
                    { rut: proveedor.rut },
                    { representante_legal_nombre: proveedor.representante_legal_nombre },
                    { representante_legal_rut: proveedor.representante_legal_rut },
                ]);
                setNombreModal('Editar proveedor')
            } else {
                setNombreModal('Crear proveedor')
            }
        },
        [proveedor, setValue, nombreModal]
    )

    function cerrarModal(proveedor){
        props.onHide(proveedor);
    }

	return (
        <CModal
            visible={rest.visible}
            onDismiss={rest.close}
            size='lg'
            keyboard={false}
            >
            <CModalHeader onDismiss={rest.close}>{nombreModal}</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                            <CRow>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Razón Social</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Rut</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="rut"
                                        placeholder="Ingrese rut, Ej: 18888888-9"
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" },
                                                pattern: {
                                                    value: /^[0-9]+[-|‐]{1}[0-9kK]{1}$/i,
                                                    message: "formato de rut inválido"
                                                  }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="rut" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Representante Legal Nombre</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="representante_legal_nombre"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="representante_legal_nombre" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Representante Legal Rut</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="representante_legal_rut"
                                        placeholder="Ingrese rut, Ej: 18888888-9"
                                        ref={
                                            register({
                                                required: { value: false, message: "campo obligatorio" },
                                                pattern: {
                                                    value: /^[0-9]+[-|‐]{1}[0-9kK]{1}$/i,
                                                    message: "formato de rut inválido"
                                                  }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="representante_legal_rut" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>  
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={rest.close}
                    ><FaTimes/> Cerrar </CButton>
                    <CButton
                        color="success"
                        type="submit"
                        ><FaSave/> Guardar </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
	)
}

export default FormProveedor


