import React, { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import { useParams } from "react-router-dom";
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import Fecha from '../../componentes/Fecha';
import { useForm } from 'react-hook-form';
import { fechaHoy } from '../../utils/Funciones';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import GraficoIndicadoresFase from './GraficoIndicadoresFase';

const GraficosIndicadoresFase = (props) => {
    const { register, errors} = useForm({});
    let { encuesta_id } = useParams();
    const [rows, setRows] = useState([]);
    const [fecha, setFecha] = useState(fechaHoy);
    const [regionId, setRegionId] = useState('');
    const [faseAplicacion, setFaseAplicacion] = useState([]);
    const [faseCandidaturaBuenaGobernanza, setFaseCandidaturaBuenaGobernanza] = useState([]);
    const [faseCandidaturaDiseñoPlanificacion, setFaseCandidaturaDiseñoPlanificacion] = useState([]);
    const [faseCantidaturaEfectividadManejo, setFaseCantidaturaEfectividadManejo] = useState([]);
    const [faseCantidaturaEfectividadManejo2, setFaseCantidaturaEfectividadManejo2] = useState([]);

    useEffect(
        () => {
            obtenerDatos();
        },
        [regionId, fecha]
    )

    async function obtenerDatos() {
        let resultado = await api.encuesta.indicadoresDetalle(encuesta_id, {region_id: regionId, fecha: fecha.substring(0,4)});
        if (resultado && resultado.status === 200) {
            let faseAplicacionTemp = [];
            let faseCandidaturaBuenaGobernanzaTemp = [];
            let faseCandidaturaDiseñoPlanificacionTemp = [];
            let faseCantidaturaEfectividadManejoTemp = [];
            let faseCantidaturaEfectividadManejo2Temp = [];
            resultado.data.datos.forEach(data => {
                data.nombre = data.numero + ' ' + data.descripcion;
                if (['1.1.1', '2.1.1', '2.1.2', '2.1.3', '3.5.3'].includes(data.numero)) {
                    faseAplicacionTemp.push(data);
                } else if (['1.1.2', '1.1.3', '1.1.4', '1.1.5', '1.1.6', '1.2.1', '1.2.2', '1.2.3', '1.2.4', '1.3.1', '1.3.2', '1.3.3', '1.3.4'].includes(data.numero)) {
                    faseCandidaturaBuenaGobernanzaTemp.push(data);
                } else if (['2.1.4', '2.2.1', '2.2.2', '2.2.3', '2.3.1', '2.3.2', '2.4.1', '2.4.2'].includes(data.numero)) {
                    faseCandidaturaDiseñoPlanificacionTemp.push(data);
                } else if (['3.1.1', '3.1.2', '3.1.3', '3.1.4', '3.1.5', '3.1.6', '3.2.1', '3.2.2', '3.3.1', '3.3.2', '3.4.1'].includes(data.numero)) {
                    faseCantidaturaEfectividadManejoTemp.push(data);
                } else if (['3.5.1', '3.5.2', '3.6.1', '3.6.2', '3.6.3', '3.6.4', '3.7.1', '3.7.2'].includes(data.numero)) {
                    faseCantidaturaEfectividadManejo2Temp.push(data);
                }
            });

            setFaseAplicacion(faseAplicacionTemp);
            setFaseCandidaturaBuenaGobernanza(faseCandidaturaBuenaGobernanzaTemp);
            setFaseCandidaturaDiseñoPlanificacion(faseCandidaturaDiseñoPlanificacionTemp);
            setFaseCantidaturaEfectividadManejo(faseCantidaturaEfectividadManejoTemp);
            setFaseCantidaturaEfectividadManejo2(faseCantidaturaEfectividadManejo2Temp);
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    return (
        <ErpLayout
            breadcrumbs={[
                {active: true, pathname: ``, name: 'Gráfico indicadores de fase'}]
            }>
            <CRow>
                <CCol xs="12" sm="12" md="6" className="mb-2">
                    <FiltroRegionesTH
                        todos={true}
                        onChange={(e) => {
                            setRegionId(e ? e.value : '')
                        }}
                    />
                </CCol>
                <CCol xs="12" sm="12" md="3" className="mb-2">
                    <Fecha
                        name='fecha'
                        label='Año'
                        register={register}
                        errors={errors}
                        dateFormat='yyyy'
                        formatoMesAno={false}
                        dateFormatOculto='y'
                        inicial={fecha}
                        onChange={(fecha) => {
                            setFecha(fecha + '-01-01')
                        }}
                    />
                </CCol>
            </CRow>
            <GraficoIndicadoresFase rows={faseAplicacion} titulo='Fase  Aplicación: Nivel de cumplimiento de 5 Indicadores Base'/>
            <GraficoIndicadoresFase rows={faseCandidaturaBuenaGobernanza} height={1000} titulo='Fase Candidatura: Componente Buena Gobernanza'/>
            <GraficoIndicadoresFase rows={faseCandidaturaDiseñoPlanificacion} height={700} titulo='Fase Candidatura: Componente Diseño y Planificación'/>
            <GraficoIndicadoresFase rows={faseCantidaturaEfectividadManejo} height={1000} titulo='Fase Cantidatura: Componente Efectividad de Manejo'/>
            <GraficoIndicadoresFase rows={faseCantidaturaEfectividadManejo2} height={1000} titulo='Fase Cantidatura: Componente Efectividad de Manejo'/>
        </ErpLayout>
    )
}

export default GraficosIndicadoresFase