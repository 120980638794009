import React,{useContext} from 'react'
import { Link } from 'react-router-dom'
import { CButton, CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CImage, CFormControl, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { ToastContainer, Slide } from 'react-toastify';
import Contexto from '../../Contexto';

const Login = (props) => {

  const { register, handleSubmit, errors } = useForm({});
  const contexto = useContext(Contexto);

  const onSubmit = async (data, e) => {
    let resultado = await api.auth.post(data);
    if (resultado && resultado.status === 200) {
      toast.success('Bienvenido');
      localStorage.setItem('token', resultado.data.access_token);
      localStorage.setItem('usuario', resultado.data.usuario);
      props.history.push('/home');
      contexto.setData({usuario: resultado.data.usuario})
    } else {
      toast.error('Error al inciar sesión');
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(onSubmit)}>
                    {/* <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Password</label>
                      <div className="col-sm-10">
                        <input type="text" name="hola" className="form-control" id="inputPassword" ref={
                          register({
                            required: { value: true, message: "campo obligatorio" }
                          })
                        }/>
                      </div>
                    </div> */}
                    <ErrorMessage errors={errors} name="hola" as="div" className="invalid-feedback" />
                    <h1>Iniciar sesión</h1>
                    <p className="text-muted"></p>
                    <CInputGroup className="mb-3">
                      {/* <CInputGroupPrepend> */}
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      {/* </CInputGroupPrepend> */}
                      <CFormControl typpe="text" name="username" placeholder="Nombre de usuario" autoComplete="username"
                        ref={
                          register({
                            required: { value: true, message: "campo obligatorio" }
                          })
                        }/>
                      <ErrorMessage errors={errors} name="username" as="div" className="invalid-feedback" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      {/* <CInputGroupPrepend> */}
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      {/* </CInputGroupPrepend> */}
                      <CFormControl type="password" name="password" placeholder="Contraseña" autoComplete="current-password"
                        ref={
                          register({
                            required: { value: true, message: "campo obligatorio" }
                          })
                        }/>
                      <ErrorMessage errors={errors} name="username" as="div" className="invalid-feedback" />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="12">
                        <CButton color="primary" className="px-4" type="submit" style={{width:'100%'}}>Ingresar</CButton>
                      </CCol>
                      {/* <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">Forgot password?</CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white p-5" style={{background: '#da9700' }}>
                <CCardBody className="text-center">
                  <div>
                    {/* <h2>VIGIA</h2> */}
                    <CImage src="vigia.png" width="auto" height="150"/>
                    <p>Sistema de información del SNASPE.</p>
                    <Link to="#">
                      <CButton color="" style={{backgroundColor:'#ffffff'}} className="mt-3" active tabIndex={-1}>Solicita tu cuenta</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login