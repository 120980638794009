import React, { useEffect, useState, useContext } from 'react';
import { CFormLabel, CForm, CFormControl, CFormCheck, CCol, CRow, CButton, CCard, CListGroup, CListGroupItem, CCardBody, CCardTitle, CCardText } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import { toast } from 'react-toastify';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import { IoMdReturnLeft } from 'react-icons/io';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CargarDocumento from '../../componentes/CargarDocumento';
import api from '../../utils/Routes';
import { useForm, ErrorMessage } from 'react-hook-form';
import UserContext from '../../Contexto'


const FormResponderIndicador = () => {
	const { register, handleSubmit, errors, setValue } = useForm({});
	let { evaluacion_id, indicador_id, encuesta_id } = useParams();
	const [documentos, setDocumentos]= useState([]);
	const [enviarArchivos, setEnviarArchivos]= useState(false);
	const [indicador, setIndicador] = useState({});
	const [respuestaIndicador, setRespuestaIndicador] = useState({});
	const [evaluacion, setEvaluacion] = useState({});
	const contexto = useContext(UserContext)
	const [permisoEditor, setPermisoEditor] = useState(false)
	const [admin, setAdmin] = useState(false)
	const [responsable, setResponsable] = useState(false)

	useEffect(
	    () => {
				setAdmin(contexto.data.usuario.administrador)
				if (indicador_id) {
					obtenerDatosIndicador(indicador_id);
				}
				if (evaluacion_id) {
					obtenerEvaluacion()
				}
			},
	    [indicador_id]
	)

	useEffect(
	    () => {
			// solicitamos la respuesta solo despues de que tengamos la info del indicador, ya que se necesita que esté ddesplegada la información
			if (indicador.id) {
				obtenerRespuestaIndicador(indicador_id);
			}
	    },
	    [indicador]
	)

	async function obtenerDatosIndicador() {
		let resultado = await api.indicador.getOne(indicador_id);
		if (resultado && resultado.status === 200) {
		    setIndicador(resultado.data.datos)
		} else {
		    toast.error('Error al obtener datos');
		}
	}

	async function obtenerRespuestaIndicador() {
		let resultado = await api.respuesta.getOne({'indicador_id': indicador_id, 'evaluacion_id': evaluacion_id});
		if (resultado && resultado.status === 200) {
			if (resultado.data.datos.id) {
				setRespuestaIndicador(resultado.data.datos)
				setValue([
					{'alternativa': resultado.data.datos.valor},
					{'descripcion': resultado.data.datos.descripcion}])
				setDocumentos(resultado.data.datos.documentos)
			}
		} else {
		    toast.error('Error al obtener datos');
		}
	}

	async function obtenerEvaluacion() {
		let resultado = await api.evaluacion.getOne(evaluacion_id);
		if (resultado && resultado.status === 200) {
			setEvaluacion(resultado.data.datos)
				if (resultado.data.datos.creador_usuario_id == contexto.data.usuario.id){
					setResponsable(true)
				}
				const permisosEdicion = contexto.data.usuario.permisos_registro
				permisosEdicion.forEach( permiso => {
					if (permiso.tabla_id == evaluacion_id && permiso.tabla_nombre === 'evaluacion'){
						setPermisoEditor(true)
					}
				});
		} else {
		    toast.error('Error al obtener datos');
		}
	}

    const guardar = async (data) => {
			data.evaluacion_id = evaluacion_id;
			data.indicador_id = indicador_id;
			data.valor  = data.alternativa;
			let resultado = await api.respuesta.put(data);
			if (resultado && resultado.status === 200) {
				setRespuestaIndicador(resultado.data.datos)
				setEnviarArchivos(true)
			} else {
				toast.error(resultado.data.msg);
			}
    }

	return (
		<>
			<ErpLayout
				breadcrumbs={[
					// {active: true, pathname: '', name: 'Encuesta'},
					{active: false, pathname: `/encuestas/${encuesta_id}`, name: 'Listado evaluaciones'},
					{active: false, pathname: `/encuestas/${encuesta_id}/evaluacion/${evaluacion_id}`, name: 'Lista de preguntas'}]
				}>
				<CForm onSubmit={handleSubmit(guardar)}>
					<CRow>
						<CCol xs="12" sm="12" md="12" lg="12">
							<h5>{evaluacion.area_protegida_nombre} - Año: {evaluacion.fecha}</h5>
						</CCol>
						<CCol xs="12" sm="12" md="12" lg="12">
							<CCard style={{marginTop: '10px'}}>
								<CCardBody>
									<p style={{fontSize: '18px', fontWeight: '500'}}>{indicador.criterio   ? 'Criterio: ' + indicador.criterio : ''} - Indicador: {indicador.numero}</p>
									<CCardText>
										{ReactHtmlParser(indicador.descripcion)}
									</CCardText>
									{(indicador.tipo === 'alternativa' && indicador.alternativas) &&
										<CListGroup>
											{indicador.alternativas.map((alternativa) =>
												<CListGroupItem key={alternativa.id + 'd'}>
													<CFormCheck
														type="radio"
														value={alternativa.valor}
														ref={register({
															required: 'Campo obligatorio'
														})}
														name="alternativa"
														key={alternativa.id}
														label={alternativa.valor}/>
												</CListGroupItem>
											)}
										</CListGroup>
									}
									<CCol xs="12" sm="12" md="4" lg="3" style={{marginTop: '10px'}}>
									<	ErrorMessage errors={errors} name="alternativa" as="div" className="invalid-feedback" />
									</CCol>
									<CCol xs="12" md="12" className="mb-2 mt-4">
										<CFormLabel htmlFor="nf-email" className='mb-2'>
											Descripción breve y objetiva del cumplimiento con el indicador: acciones y fechas concretas, informes o investigaciones, campañas, otros.<br/>
											En caso de cumplimiento parcial o no cumplimiento: especifique qué elementos se requieren para lograr el cumplimiento en un mediano plazo.
										</CFormLabel>
										<CFormControl
											name="descripcion"
											component="textarea"
											rows="6"
											placeholder=""
											ref={register({
												required: 'Campo obligatorio'
											})}
										/>
										<ErrorMessage errors={errors} name="descripcion" as="div" className="invalid-feedback" />
									</CCol>
									<CCol xs="12" sm="12" md="12" lg="12" className="mb-2 mt-4">
										<CargarDocumento
											documentos={documentos} // documentos que ya tiene la entidad en base de datos
											enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
											entidad_nombre={'Respuesta'}
											entidad_id={respuestaIndicador.id}
											enviado={() => { // cuando se enviaron todos los archivos
												setEnviarArchivos(false)
												toast.success('Cambios almacenados');
											}}
											cambioArchivos={(data) => {
												// Es el estado interno de los documentos si quiere guardar utilice otro estado para no crear un ciclo infinito
											}} />
									</CCol>
								</CCardBody>
							</CCard>
						</CCol>
				</CRow>
				<CCard className="mt-2">
					<CCardBody>
						<CRow>
							<CCol lg={12}>
								<Link to={`/encuestas/${encuesta_id}/evaluacion/${evaluacion_id}`}>
									<CButton className="mt-2 float-start" size="lg" color="light"><IoMdReturnLeft/> Volver</CButton>
								</Link>
								{admin || responsable || permisoEditor ?
									<CButton className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave/> Guardar</CButton>
									:
									<CButton disabled className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave/> Guardar</CButton>
								}
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
				</CForm>
			</ErpLayout>
		</>
  )
}

export default FormResponderIndicador