import React, {useState} from 'react'
import AppSidebar from '../componentes/AppSidebar'
import AppHeader from '../componentes/AppHeader'
import AppContent from '../componentes/AppContent'
import AppFooter from '../componentes/AppFooter'
import AppAside from '../componentes/AppAside'

const Dashboard2 = () => {
    return (
        <>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
                <AppHeader />
                <div className="body flex-grow-1 px-3">
                    <AppContent />
                </div>
                <AppFooter />
            </div>
            <AppAside />
        </>
    )
}

export default Dashboard2