import React, { useEffect, useState } from 'react'
import { CTooltip, CTableHead, CTable, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter, CFormCheck, CNav} from '@coreui/react-pro'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';

const FormUsuario = (props) => {
    const { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
            habilitado: true
        }
    });
    const { usuario, ...rest } = props;
    const [ updatePassword, setUpdatePassword ] = useState(false);
    const [ nombreModal, setNombreModal ] = useState('');
    const [ modulos, setModulos ] = useState([]);
    const [ user, setUser ] = useState({});

    const onSubmit = async (data) => {
        let resultado;
        if (data.password !== data.confirm_password){
            toast.error('Las contraseñas deben ser iguales')
            return false
        }
        data.modulos = modulos;
        if (usuario.id){
		    resultado = await api.usuarios.put(usuario.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    resultado = await api.usuarios.post(data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        }
	}

    useEffect(
        () => {
            if(props.visible){
                obtenerModulos()
            }

        },
        [props.visible]
    )

    useEffect(
        () => {
            if (Object.keys(usuario).length > 0) {
                setUser(usuario)
                setValue([
                    { id: usuario.id },
                    { nombre_usuario: usuario.nombre_usuario },
                    { nombre_completo: usuario.nombre_completo },
                    { correo: usuario.correo },
                    { telefono: usuario.telefono },
                    { password: usuario.password },
                    { habilitado: usuario.habilitado },
                    { administrador: usuario.administrador }
                ]);
                setNombreModal('Editar usuario')
                setUpdatePassword(false)
            } else {
                reset()
                setUpdatePassword(true)
                setNombreModal('Crear usuario')
            }
        },
        [usuario, setValue, reset, nombreModal, user]
    )

    const obtenerModulos = async () => {
        let resultado = await api.modulo.get();
        if (resultado && resultado.status === 200) {
            if (usuario.id){
                resultado.data.datos.forEach(modulo => {
                    usuario.permisos.forEach(permisoUsuario => {
                        if (permisoUsuario.modulo_id === modulo.id){
                            modulo.ver = permisoUsuario.ver
                            modulo.editar = permisoUsuario.editar
                            modulo.eliminar = permisoUsuario.eliminar
                        }
                    });
                });
            } else {
                resultado.data.datos.forEach(modulo => {
                    modulo.ver = true;
                    modulo.editar = true;
                    modulo.eliminar = true;
                });
            }
            setModulos(resultado.data.datos)
        } else {
            setModulos([])
        }
    }

    function cerrarModal(){
        setUser({})
        setModulos([])
        props.onHide();
    }

    function cambio(modulo, accion){
        let modulosCopia = Object.assign([], modulos);
        modulosCopia.forEach(mod => {
            if (mod.id === modulo.id){
                mod[accion] = !mod[accion]
            }
        });
        setModulos(modulosCopia)
    }

	return (
        <CModal
            keyboard={true}
            visible={rest.visible}
            size='lg'
            >
            <CModalHeader onDismiss={cerrarModal}>{nombreModal}</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                            <CRow>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Usuario</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_usuario"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_usuario" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre Completo</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_completo"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_completo" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Correo</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="correo"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="correo" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Teléfono</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="telefono"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: false, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="telefono" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>

                            <CRow variant="custom-checkbox">
                                {Object.keys(usuario).length > 0 &&
                                <>
                                    <CTooltip content="Presione para actualizar contraseña" placement="top">
                                        <CCol xs="12" sm="6" md="6" lg="4" className="mb-2">
                                            <CFormCheck
                                                custom id="inline-checkbox1"
                                                onClick={() => updatePassword === false ? setUpdatePassword(true) : setUpdatePassword(false)}
                                                name="inline-checkbox1"
                                                value="option1"/>
                                            <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox1" style={{marginLeft: '5px'}}>Actualizar contraseña</CFormLabel>
                                        </CCol>
                                    </CTooltip>
                                </>
                                }
                                <CTooltip content="Una cuenta habilitada puede iniciar sesión, desactive está opción para inhabilitar una cuenta temporalmente." placement="top">
                                    <CCol xs="12" sm="6" md="6" lg="4" className="mb-2">
                                        <CFormCheck
                                            custom id="checkbox-habilitado"
                                            name="habilitado"
                                            defaultChecked
                                            ref={
                                                register({
                                                    required: { value: false }
                                                })
                                            }
                                            />
                                            <CFormLabel variant="custom-checkbox" htmlFor="checkbox-habilitado" style={{marginLeft: '5px'}}>Usuario habilitado</CFormLabel>
                                    </CCol>
                                </CTooltip>
                                <CTooltip content="Tiene acceso a todo el sistema, independiente de los permisos asignados" placement="top">
                                    <CCol xs="12" sm="6" md="6" lg="4" className="mb-2">
                                        <CFormCheck
                                            custom id="checkbox-administrador"
                                            name="administrador"
                                            // defaultChecked
                                            ref={register({
                                                    required: { value: false }
                                                })}
                                            />
                                            <CFormLabel variant="custom-checkbox" htmlFor="checkbox-administrador" style={{marginLeft: '5px'}}>Administrador</CFormLabel>
                                    </CCol>
                                </CTooltip>
                            </CRow>
                            {updatePassword === true &&
                                <CRow>
                                    <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Nueva contraseña</CFormLabel>
                                        <CFormControl
                                            type="password"
                                            name="password"
                                            setValue=""
                                            placeholder=""
                                            ref={
                                                register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="password" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="12" sm="6" md="6" lg="6" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Confirmar nueva contraseña</CFormLabel>
                                        <CFormControl
                                            type="password"
                                            name="confirm_password"
                                            setValue=""
                                            placeholder=""
                                            ref={
                                                register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="confirm_password" as="div" className="invalid-feedback" />
                                    </CCol>
                                </CRow>
                            }
                        </CCol>
                    </CRow>
                    <CRow>
                        <h5>Permisos de acceso</h5>
                        <CTable striped>
                            <CTableHead>
                                <CTableRow>
                                <CTableHeaderCell scope="col" style={{width: '30%'}}>Sección</CTableHeaderCell>
                                <CTableHeaderCell scope="col" style={{width: '30%'}}>Módulo</CTableHeaderCell>
                                <CTableHeaderCell scope="col" style={{textAlign: 'center', width:'13.3%'}}>Ver</CTableHeaderCell>
                                <CTableHeaderCell scope="col" style={{textAlign: 'center', width:'13.3%'}}>Editar</CTableHeaderCell>
                                <CTableHeaderCell scope="col" style={{textAlign: 'center', width:'13.3%'}}>Eliminar</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                        </CTable>
                        <div style={{height:'300px', overflowY:'scroll'}}>
                            <CTable striped hover>
                                <CTableBody>
                                    {modulos.map((modulo) =>
                                        <CTableRow>
                                            <CTableDataCell style={{width: '30%'}}>{modulo.categoria}</CTableDataCell>
                                            <CTableDataCell style={{width: '30%'}}>{modulo.nombre}</CTableDataCell>
                                            <CTableDataCell style={{textAlign: 'center', width:'13.3%'}}>
                                                <CFormCheck
                                                    name={'ver_' + modulo.id}
                                                    checked={modulo.ver}
                                                    onChange={() => cambio(modulo, 'ver')}/>
                                            </CTableDataCell>
                                            <CTableDataCell style={{textAlign: 'center', width:'13.3%'}}>
                                                <CFormCheck
                                                    name={'editar_' + modulo.id}
                                                    checked={modulo.editar}
                                                    onChange={() => cambio(modulo, 'editar')}/>
                                            </CTableDataCell>
                                            <CTableDataCell style={{textAlign: 'center', width:'13.3%'}}>
                                                <CFormCheck
                                                    name={'eliminar_' + modulo.id}
                                                    checked={modulo.eliminar}
                                                    onChange={() => cambio(modulo, 'eliminar')}/>
                                            </CTableDataCell>
                                        </CTableRow>
                                    )}
                                </CTableBody>
                            </CTable>
                        </div>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={() => cerrarModal()}
                    ><FaTimes/> Cerrar </CButton>
                    <CButton
                        color="success"
                        type="submit"
                        ><FaSave/> Guardar </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
	)
}

export default FormUsuario


