import React, {useState} from 'react';
import { CButton, CTooltip, CCard, CCardBody, CCardFooter, CCol, CRow, CFormControl, CCardImage, CBadge, CLink } from  '@coreui/react-pro';
import FormArchivo from '../modulos/FormArchivo';
import { FaTrashAlt} from 'react-icons/fa';

const Archivos = (props) => {
    const [showFormArchivo, setShowFormArchivo] = useState(false);
    const [archivo, setArchivo] = useState({});

    const imagen = (documento) => {
        if (['docx', 'doc'].includes(documento.tipo_archivo)) {
            return 'icon/doc.png'
        } else if (['pdf'].includes(documento.tipo_archivo)) {
            return 'icon/pdf.png'
        } else if (['png', 'jpg', 'jpeg'].includes(documento.tipo_archivo)) {
            return documento.ruta_completa
        } else if (['mp4', 'avi'].includes(documento.tipo_archivo)) {
            return 'icon/mp4.png'
        } else if (['ppt', 'pptx'].includes(documento.tipo_archivo)) {
            return 'icon/ppt.png'
        } else if (['xls', 'xlsx'].includes(documento.tipo_archivo)) {
            return 'icon/xls.png'
        } else if (['jpg', 'jpeg', 'jpg'].includes(documento.tipo_archivo)) {
            return 'icon/jpg.png'
        } else {
            return 'icon/file.png'
        }
    }

    const bagdeExtension = (documento) => {
        var color = '#0e9d58';
        if (['docx', 'doc', 'odt'].includes(documento.tipo_archivo)) {
            color = '#4285f4'
        } else if (['pdf'].includes(documento.tipo_archivo)) {
            color = '#ea4335'
        } else if (['png', 'jpg', 'jpeg', 'gif', 'svg'].includes(documento.tipo_archivo)) {
            color = '#d93025'
        } else if (['mp4', 'avi'].includes(documento.tipo_archivo)) {
            color = '#d93025'
        } else if (['ppt', 'pptx'].includes(documento.tipo_archivo)) {
            color = '#fd7541'
        } else if (['xls', 'xlsx'].includes(documento.tipo_archivo)) {
            color = '#0e9d58'
        } else {
            color = '#0e9d58';
        }
        return <CBadge style={{ fontSize: '10px', backgroundColor: color, color: 'white', display: 'inline-block !important', width: '40px', marginLeft: '5px', padding: '0.4em 0.4em 0.3em 0.4em', textTransform:'uppercase' }}>
            {documento.tipo_archivo}
        </CBadge>
    }

    function verDoc(documento){
        setShowFormArchivo(true);
        setTimeout(function () {
            setArchivo(documento);
        }, 500);
    }

    return (
        <>
            {props.documentos.map((documento) =>
            <>
                <CCol xs="12" sm="4" md="3" lg="3" key={documento.id} style={{marginBottom: '20px'}}>
                    <CCard className="card-archivo">
                        {props.eliminar &&
                            <>
                                {documento.estado !== 'eliminado' &&
                                    <CTooltip content="Presione para eliminar">
                                        <CButton style={{position: 'absolute', right: '0',color: 'white', zIndex:9}} color="danger"
                                            onClick={() => {
                                                props.eliminar(documento)
                                            }}>
                                            <CLink> <FaTrashAlt style={{color: 'white'}}/></CLink>
                                        </CButton>
                                    </CTooltip>
                                }
                            </>
                        }
                        <CCardBody style={{textAlign:'center', overflow:'hidden', height: '150px'}} onClick={()=>verDoc(documento)}>
                            {documento.ruta_thumb_completa ?
                                    <CCardImage src={documento.ruta_thumb_completa} fluid style={{height: 'auto', width: '100%', boxShadow:'10px 5px 10px 5px #c5c5c5 !important'}}></CCardImage>
                                :
                                    <CCardImage src={imagen(documento)} fluid style={{ height: '100px', width: 'auto' }}></CCardImage>
                            }
                        </CCardBody>
                        {props.seleccionarElemento ?
                            <CCardFooter>
                                <>
                                    {documento.estado !== 'eliminado' &&
                                        bagdeExtension(documento)
                                    }
                                    <CTooltip content={documento.nombre_documento}>
                                        <CFormControl
                                            type="email"
                                            style={{backgroundColor:'white', color:'#000000', width: 'calc(100% - 45px)', display: 'inline-block'}}
                                            value={documento.nombre_documento}
                                            readOnly
                                        />
                                    </CTooltip>
                                    <CRow>
                                        <CCol lg="12">
                                            <CButton color="info" style={{marginBottom: '10px', width: '100%', color: 'white'}}
                                            onClick={() => {
                                                props.seleccionarElemento(documento)
                                            }}>Seleccionar</CButton>
                                        </CCol>
                                    </CRow>
                                    <span style={{ color: 'grey', fontSize: '11px', paddingLeft: '5px', fontWeight:'600', display:'block', lineHeight: '13px' }}>{documento.tipo_informacion}</span>
                                    {documento.estado !== 'eliminado' ?
                                        <p style={{ color: 'grey', fontSize: '11px', paddingLeft:'5px', lineHeight: '13px', margin: '5px 0px' }}>{documento.area_protegida ? documento.area_protegida : 'General'}</p>
                                    :
                                        <CBadge style={{ backgroundColor: '#ea4335', color: 'white', display: 'inline-block !important', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', textTransform:'uppercase' }}>
                                            Pendiente a eliminar
                                        </CBadge>
                                    }
                                    {documento.tags && documento.tags.length > 0 &&
                                        <>
                                            <b style={{display:'block', marginLeft: '5px'}}>Tags</b>
                                            {documento.tags.map((item, index) => (
                                                <CTooltip content={item.nombre} placement='left'>
                                                    <CBadge style={{ backgroundColor: '#8a93a2', color: 'white', display: 'inline-block !important', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', fontWeight:400 }}>
                                                        {item.nombre.length >= 25 ? item.nombre.substring(0, 25) + '...' : item.nombre}
                                                    </CBadge>
                                                </CTooltip>
                                            ))}
                                        </>
                                    }
                                </>
                            </CCardFooter>
                        :
                            <CCardFooter onClick={()=>verDoc(documento)} style={{overflow:'hidden'}}>
                                <>
                                    {documento.estado !== 'eliminado' &&
                                        bagdeExtension(documento)
                                    }
                                    <CTooltip content={documento.nombre_documento}>
                                        <CFormControl
                                            type="email"
                                            style={{backgroundColor:'white', color:'#000000', width: 'calc(100% - 45px)', display: 'inline-block'}}
                                            value={documento.nombre_documento}
                                            readOnly
                                        />
                                    </CTooltip>
                                    <span style={{ color: 'grey', fontSize: '11px', paddingLeft: '5px', fontWeight:'600', display:'block', lineHeight: '13px' }}>{documento.tipo_informacion}</span>
                                    {documento.estado !== 'eliminado' ?
                                        <p style={{ color: 'grey', fontSize: '11px', paddingLeft:'5px', lineHeight: '13px', margin: '5px 0px' }}>{documento.area_protegida ? documento.area_protegida : 'General'}</p>
                                    :
                                        <CBadge style={{ backgroundColor: '#ea4335', color: 'white', display: 'inline-block !important', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', textTransform:'uppercase' }}>
                                            Pendiente a eliminar
                                        </CBadge>
                                    }
                                    {documento.tags && documento.tags.length > 0 &&
                                        <>
                                            <b style={{display:'block', marginLeft: '5px'}}>Tags</b>
                                            {documento.tags.map((item, index) => (
                                                <CTooltip content={item.nombre} placement='left'>
                                                    <CBadge style={{ backgroundColor: '#8a93a2', color: 'white', display: 'inline-block !important', marginLeft: '5px', padding: '0.25em 0.4em 0.3em 0.4em', fontWeight:400 }}>
                                                        {item.nombre.length >= 25 ? item.nombre.substring(0, 25) + '...' : item.nombre}
                                                    </CBadge>
                                                </CTooltip>
                                            ))}
                                        </>
                                    }
                                </>
                            </CCardFooter>
                        }
                    </CCard>
                </CCol>
            </>
            )}
            <FormArchivo
                show={showFormArchivo}
                archivoData={archivo}
                close={()=> {
                    setArchivo({})
                    setShowFormArchivo(false);
                    if (props.retorno) {
                        props.retorno();
                    }
                }}
                botonELiminar={props.botonELiminar ? props.botonELiminar : false}
                retorno={() => {
                }}/>
        </>
    )
}

export default Archivos