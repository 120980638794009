import React, { useEffect, useState, useMemo } from 'react';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, CenterAlign, separadorDeMiles } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import { CNav, CNavItem, CNavLink, CTabPane, CTabContent, CButton, CCol, CRow } from '@coreui/react-pro';
import { FaRegFileExcel, FaEye } from 'react-icons/fa';
import { exportar } from '../../utils/Funciones';

const GrillaResumenInfraestructuras = () => {
  let history = useHistory();

  const columns = [
    {
      key: 'nombre', name: 'ASP', minWidth: 200,
      summaryFormatter() {
        return <div style={{ textAlign: 'right' }}><strong>Total</strong></div>;
      },
      formatter({ row }) {
				return (<>{goVerInfraestructuras(row)}</>)
			}
    },
    {
      key: 'costo_anual_mantencion', name: 'Costo Anual Mantención', formatter: NumberFormatter, width: 150,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.costo_anual_mantencion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'monto_inversion', name: 'Monto Inversión', formatter: NumberFormatter, width: 120,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.monto_inversion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'costo_anual_operacion', name: 'Costo Anual Operación', formatter: NumberFormatter, width: 150,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.costo_anual_operacion
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'total_infraestructuras', name: 'Cantidad', formatter: CenterAlign, width: 40,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.total_infraestructuras
        });
        return <div style={{ textAlign: 'center' }}>{separadorDeMiles(total)}</div>;
      }
    },
    { key: 'nombres_infraestructuras', name: 'Infraestructuras', minWidth: 300 },
  ];
  const [activeTab, setActiveTab] = useState('información');
  const [rows, setRows] = useState([]);
  const [infraestructuras, setInfraestructuras] = useState([]);
  // const [SummaryRow, setSummaryRow] = useState([])
  const summaryRows = useMemo(() => {
    const summaryRow = [];
    return [summaryRow];
  }, [rows]);

  useEffect(
    () => {
      obtenerDatos()
      areasProtegidas()
    },
    []
  )

  function accion(row) {
    console.log(row)
    history.push(`/area-protegida/${row.id}/infraestructura`)
  }
  function goVerInfraestructuras(row) {
    let botonAccion = '';
    botonAccion = <CButton color="default" size="sm" className="mb-2 mt-1" onClick={() => accion(row)}>
      {row.nombre}
    </CButton>
    return botonAccion
  }

  async function obtenerDatos() {
    let resultado = await api.infraestructura.get();
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  async function areasProtegidas() {
    let resultado = await api.infraestructura.getAll();
    if (resultado && resultado.status === 200) {
      setInfraestructuras(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  async function menuVisualizar(e, { rowIdx }) {
    if (rows[rowIdx]) {
      history.push(`/area-protegida/${rows[rowIdx].id}/infraestructura`)
    } else {
      toast.error('Error al obtener la infraestructura');
    }
  }

  function exportarInfraestructurasAsp(){
    exportar(`api/exportar/infraestructurasAsp`);
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          { active: true, pathname: '', name: 'Administración' },
          { active: true, pathname: `/infraestructura`, name: 'Infraestructura' }]
        }>
        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12">
              <CButton color="light" style={{float:'right'}} onClick={exportarInfraestructurasAsp} className="mx-1 my-2"><FaRegFileExcel /> Exportar</CButton>
            </CCol>
        </CRow>
        <CTabContent>
          <CTabPane data-tab="preview" visible={activeTab === 'información'} className="mt-2">
            <GrillaBase
              menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuVisualizar}><FaEye/> Ver Infraestructuras</MenuItem>
              </ContextMenu>}
              rows={rows}
              columns={columns}
              summaryRows={summaryRows}
            />
          </CTabPane>

        </CTabContent>
      </ErpLayout>
    </>
  )
}

export default GrillaResumenInfraestructuras