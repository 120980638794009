import React, { useState, useEffect } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter, CFormCheck } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import { FaSave, FaTimes } from 'react-icons/fa';
import SelectorTipoCompromisoTHRHF from '../../componentes/SelectorTipoCompromisoTHRHF';
import SelectorProgramaManejoTHRHF from '../../componentes/SelectorProgramaManejoTHRHF';
import SelectorSubestrategiaTHRHF from '../../componentes/SelectorSubestrategiaTHRHF';
import api from '../../utils/Routes';

const FormElaboracion = (props) => {
    const {show, actividadId, poaId, cerrar, ...rest} = props;
    const [actividad, setActividad] = useState({});
    const { register, handleSubmit, errors, setValue, getValues, reset, control, watch } = useForm({});
    const [visible, setVisible] = useState('');

    useEffect(
        () => {
            if (actividadId) {
                obtenerDatos()
            }
        },
        [actividadId]
    )

    useEffect(
        () => {
            if ((watch('programa_manejo') && watch('programa_manejo').label === 'Otro' )) {
                setVisible('');
            } else {
                setVisible('none')
            }
        },
        [watch('programa_manejo')]
    )

    useEffect(
        () => {
            let bienes_y_servicios = parseInt(getValues().bienes_y_servicios || 0)
            let viaticos = parseInt(getValues().viaticos || 0)
            let jornales_transitorios = parseInt(getValues().jornales_transitorios || 0)
            let horas_extra_jtransitorio = parseInt(getValues().horas_extra_jtransitorio || 0)
            let horas_extra_empleados = parseInt(getValues().horas_extra_empleados || 0)
            let horas_extra_jpermanente = parseInt(getValues().horas_extra_jpermanente || 0)
            let fondo_terceros = parseInt(getValues().fondo_terceros || 0)
            let total_conaf = (bienes_y_servicios + viaticos + jornales_transitorios + horas_extra_jtransitorio + horas_extra_empleados + horas_extra_jpermanente)
            setValue('total_conaf', total_conaf)
            setValue('total_presupuesto', total_conaf + fondo_terceros)
        },
        [watch('bienes_y_servicios'), watch('viaticos'), watch('jornales_transitorios'), watch('horas_extra_jtransitorio'), watch('horas_extra_empleados'), watch('horas_extra_jpermanente'), watch('fondo_terceros')]
    )

    async function obtenerDatos() {
        let resultado = await api.actividad.get(actividadId);//rows[rowIdx].id
        if (resultado && resultado.status === 200) {
            setActividad(resultado.data.datos)
            let actividadTemp = resultado.data.datos;
            if (actividadTemp.planificacion.length > 0) {
                actividadTemp.planificacion.forEach(planificacion => {
                    if (planificacion.mes === 1){
                        setValue('enero', true)
                    } else if (planificacion.mes === 2) {
                        setValue('febrero', true)
                    } else if (planificacion.mes === 3) {
                        setValue('marzo', true)
                    } else if (planificacion.mes === 4) {
                        setValue('abril', true)
                    } else if (planificacion.mes === 5) {
                        setValue('mayo', true)
                    } else if (planificacion.mes === 6) {
                        setValue('junio', true)
                    } else if (planificacion.mes === 7) {
                        setValue('julio', true)
                    } else if (planificacion.mes === 8) {
                        setValue('agosto', true)
                    } else if (planificacion.mes === 9) {
                        setValue('septiembre', true)
                    } else if (planificacion.mes === 10) {
                        setValue('octubre', true)
                    } else if (planificacion.mes === 11) {
                        setValue('noviembre', true)
                    } else if (planificacion.mes === 12) {
                        setValue('diciembre', true)
                    }
                });
            }
            setValue([
                { descripcion: actividadTemp.descripcion },
                { responsable: actividadTemp.responsable },
                { unidad: actividadTemp.unidad },
                { numero_actividades: actividadTemp.numero_actividades },
                { meta: actividadTemp.meta },
                { bienes_y_servicios: actividadTemp.bienes_y_servicios },
                { viaticos: actividadTemp.viaticos },
                { jornales_transitorios: actividadTemp.jornales_transitorios },
                { horas_extra_jtransitorio: actividadTemp.horas_extra_jtransitorio },
                { horas_extra_jpermanente: actividadTemp.horas_extra_jpermanente },
                { horas_extra_empleados: actividadTemp.horas_extra_empleados },
                { numero_compromiso: actividadTemp.numero_compromiso },
                { descripcion_programa_manejo: actividadTemp.descripcion_programa_manejo },
                { fondo_terceros: actividadTemp.fondo_terceros },
                { nombre_fondo_terceros: actividadTemp.nombre_fondo_terceros }
            ]);
        } else {
            cerrarModal();
            toast.error('Error al obtener datos');
        }
    }

    const cerrarModal = () => {
        setActividad({})
        cerrar()
    }

    async function guardar(data) {
        data.subestrategia_id = data.subestrategia.value;
        data.programa_manejo_id = data.programa_manejo.value;
        data.tipo_compromiso_id = data.tipo_compromiso.value;
        if (actividad.id) {
            let resultado = await api.actividad.put(poaId, actividad.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg, {autoClose:8000});
            }
        } else {
            let resultado = await api.actividad.post(poaId, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                setActividad(resultado.data.data)
            } else {
                toast.error(resultado.data.msg, {autoClose:8000});
            }
        }
    }

    return (
        <>
            <CModal
                id="modal-cargar-archivos"
                keyboard={false}
                visible={show}
                size='xl'
                >
                <CModalHeader onDismiss={cerrarModal}>Actividad</CModalHeader>
                <CForm onSubmit={handleSubmit(guardar)}>
                    <CModalBody>
                        <CRow>
                        <CFormLabel className='fw-bold mb-2'>Información general</CFormLabel>
                            <CCol xs="12" sm="12" md="12" className="mb-2">
                                <SelectorSubestrategiaTHRHF
                                    iniciar={show}
                                    control={control}
                                    Controller={Controller}
                                    ErrorMessage={ErrorMessage}
                                    errors={errors}
                                    setValue={setValue}
                                    name='subestrategia'
                                    placeholder='No seleccionado'
                                    seleccionadoId={actividad.subestrategia_id} // id del elemento seleccionado
                                />
                            </CCol>
                            <CCol xs="12" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Actividad</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="descripcion"
                                    placeholder="Describa la actividad"
                                    ref={register({
                                        required: { value: true, message: "campo obligatorio" }
                                    })}
                                />
                                <ErrorMessage errors={errors} name="descripcion" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" sm="6" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Responsable</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="responsable"
                                    placeholder="Nombre y cargo"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="responsable" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" sm="6" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Unidad de medida</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="unidad"
                                    placeholder="Mts, instalación, cantidad..."
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="unidad" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" sm="6" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">N° actividades</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="numero_actividades"
                                    ref={register({
                                        required: { value: true, message: "campo obligatorio" }
                                    })}
                                />
                                <ErrorMessage errors={errors} name="numero_actividades" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" sm="6" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Meta</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="meta"
                                    placeholder=""
                                    ref={register({
                                        required: { value: true, message: "campo obligatorio" }
                                    })}
                                />
                                <ErrorMessage errors={errors} name="meta" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
                                <SelectorTipoCompromisoTHRHF
                                    iniciar={show}
                                    control={control}
                                    Controller={Controller}
                                    ErrorMessage={ErrorMessage}
                                    errors={errors}
                                    setValue={setValue}
                                    name='tipo_compromiso'
                                    placeholder='No seleccionado'
                                    seleccionadoId={actividad.tipo_compromiso_id} // id del elemento seleccionado
                                />
                            </CCol>
                            <CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Número de compromiso</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="numero_compromiso"
                                    placeholder=""
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="12" sm="12" md="6" lg="6" className="mb-2">
                                <SelectorProgramaManejoTHRHF
                                    iniciar={show}
                                    control={control}
                                    Controller={Controller}
                                    ErrorMessage={ErrorMessage}
                                    errors={errors}
                                    setValue={setValue}
                                    name='programa_manejo'
                                    placeholder='No seleccionado'
                                    seleccionadoId={actividad.programa_manejo_id} // id del elemento seleccionado
                                />
                            </CCol>
                            <CCol xs="12" sm="12" md="6" lg="6" className="mb-2" style={{display: visible}}>
                                <CFormLabel htmlFor="nf-email">Descripción programa de manejo</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="descripcion_programa_manejo"
                                    placeholder=""
                                    ref={register({})}
                                />
                            </CCol>
                            <CFormLabel htmlFor="nf-email" className="mt-2"><b>Planificación</b></CFormLabel>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="enero"
                                    label="Enero"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="febrero"
                                    label="Febrero"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="marzo"
                                    label="Marzo"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="abril"
                                    label="Abril"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="mayo"
                                    label="Mayo"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="junio"
                                    label="Junio"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="julio"
                                    label="Julio"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="agosto"
                                    label="Agosto"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="septiembre"
                                    label="Septiembre"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="octubre"
                                    label="Octubre"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="noviembre"
                                    label="Noviembre"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="6" lg="2" className="mb-2">
                                <CFormCheck
                                    type="checkbox"
                                    name="diciembre"
                                    label="Diciembre"
                                    ref={register({})}
                                />
                            </CCol>
                        </CRow>
                        <CRow className="mt-2">
                            <CFormLabel htmlFor="nf-email"><b>Presupuesto</b></CFormLabel>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Bienes y servicios ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="bienes_y_servicios"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="bienes_y_servicios" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Viáticos ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="viaticos"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="viaticos" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Jornales transitorios ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="jornales_transitorios"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="jornales_transitorios" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Hrs extras Jornales transitorios ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="horas_extra_jtransitorio"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="horas_extra_jtransitorio" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Hrs extras Empleados ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="horas_extra_empleados"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="horas_extra_empleados" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email">Hrs extras Jornal permanente ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="horas_extra_jpermanente"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                                <ErrorMessage errors={errors} name="horas_extra_jpermanente" as="div" className="invalid-feedback" />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email" className="fw-bold">Total CONAF ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="total_conaf"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                    readOnly
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email" className="">Fondos de terceros ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="fondo_terceros"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email" className="">Nombre fondos de terceros ($)</CFormLabel>
                                <CFormControl
                                    type="text"
                                    name="nombre_fondo_terceros"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                />
                            </CCol>
                            <CCol xs="12" md="4" lg="3" className="mb-2">
                                <CFormLabel htmlFor="nf-email" className="fw-bold">Total presupuesto ($)</CFormLabel>
                                <CFormControl
                                    type="number"
                                    name="total_presupuesto"
                                    placeholder="Valor en pesos"
                                    ref={register({})}
                                    readOnly
                                />
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="light"
                            onClick={cerrarModal}
                        ><FaTimes /> Cerrar</CButton>
                        <CButton color="success" type="submit"><FaSave/> Guardar</CButton>{' '}
                    </CModalFooter>
                </CForm>
            </CModal>
        </>
    )
}

export default FormElaboracion