import React, { useState, useEffect } from 'react';
import { CCol, CRow, CAlert, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter, CFormLabel, CFormControl } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { CFormGroup, CLabel } from '@coreui/react'
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import { FaSave, FaTimes, FaRegEye } from 'react-icons/fa';
import SelectorAreaProtegidaTHRHF from '../../componentes/SelectorAreaProtegidaTHRHF';
import SelectorUsuario from '../../componentes/SelectorUsuario';
import SelectorUsuarioMulti from '../../componentes/SelectorUsuarioMulti';
import api from '../../utils/Routes';
import { fechaHoy, getYearNow } from '../../utils/Funciones';
import Fecha from '../../componentes/Fecha';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Select from 'react-select';


const FormEvaluacion = (props) => {
    const { show, close, evaluacionId, encuestaId } = props;
    const { register, handleSubmit, errors, setValue, control } = useForm({});
    const [evaluacion, setEvaluacion] = useState({});
    const [fecha, setFecha] = useState(fechaHoy());
    let history = useHistory();
    const [fechasEvaluacionClonar, setFechasEvaluacionClonar] = useState([]);
    const [anoActual, setAnoActual] = useState(getYearNow());
    const [evaluacionIdClonar, setEvaluacionIdClonar] = useState('');
	const [usuarioId, setUsuarioId] = useState([]);
    const [responsableId, setResponsableId] = useState('');


    useEffect(
        () => {
            if (evaluacionId) {
                obtenerDatos();
            }
        },
        [evaluacionId]
    )

    const guardar = async (data) => {
        data.area_protegida_id = data.area_protegida.value;
        data.encuesta_id = encuestaId;
        data.creador_usuario_id = encuestaId;

        if (evaluacionIdClonar) {
            clonarEvaluacion(evaluacionIdClonar)
            return
        }

        if (evaluacion.id) {
            let resultado = await api.evaluacion.put(evaluacion.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
            let resultado = await api.evaluacion.post(data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                setEvaluacion(resultado.data.datos)
                history.push(`/encuestas/1/evaluacion/${resultado.data.datos.id}`);
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }

    const obtenerDatos = async () => {
        let resultado = await api.evaluacion.getOne(evaluacionId, {});
        if (resultado && resultado.status === 200) {
            let poaTemp = resultado.data.datos;
            setResponsableId(poaTemp.creador_usuario_id)
            setUsuarioId(poaTemp.usuarios)
            setEvaluacion(resultado.data.datos)
            setFecha(poaTemp.fecha + '-01-01');
        } else {
            cerrarModal()
            if (resultado.data) {
                toast.error(`Error: ${resultado.data.msg}`);
            } else {
                toast.error('Error al obtener datos');
            }
        }
    }

    const obtenerEvaluaciones = async (areaProtegidaId) => {
        let resultado = await api.evaluacion.get({ area_protegida_id: areaProtegidaId });
        if (resultado && resultado.status === 200) {
            let temporal = [];
            temporal.push({ value: '', label: 'No clonar' })
            resultado.data.datos.forEach(dato => {
                temporal.push({ value: dato.id, label: dato.fecha })
            });
            setFechasEvaluacionClonar(temporal)
        } else {
            toast.error('Error al obtener evaluaciones');
        }
    }

    const clonarEvaluacion = async (evaluacionId) => {
        if (anoActual === '') {
            toast.error('Debe seleccionar un año');
            return;
        }
        let data = {
            fecha: anoActual,
        }
        let resultado = await api.evaluacion.clonar(evaluacionId, data);
        if (resultado && resultado.status === 200) {
            toast.success('Evaluación clonada con exito');
            history.push(`/encuestas/1/evaluacion/${resultado.data.datos.id}`);
        } else {
            toast.error('Error al clonar evaluación');
        }
    }

    const cerrarModal = () => {
        setFechasEvaluacionClonar([]);
        setEvaluacionIdClonar('');
        setEvaluacion({})
        setResponsableId('')
        setUsuarioId([])
        close()
        setFecha(fechaHoy())
    }

    return (
        <CModal
            id="modal-poa"
            keyboard={false}
            visible={show}
        >
            <CModalHeader onDismiss={cerrarModal}>{evaluacionId ? 'Editar' : 'Nueva'} respuesta</CModalHeader>
            <CForm onSubmit={handleSubmit(guardar)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" className="mb-2">
                            <Fecha
                                name='fecha'
                                label='Año'
                                register={register}
                                errors={errors}
                                dateFormat='yyyy'
                                formatoMesAno={false}
                                dateFormatOculto='y'
                                inicial={fecha}
                                onChange={(info) => {
                                    setAnoActual(info)
                                }}
                                limpiar={() => {
                                    setAnoActual('')
                                }}
                            />
                        </CCol>
                        <CCol xs="12" sm="12" md="12" className="mb-2">
                            <SelectorAreaProtegidaTHRHF
                                iniciar={show}
                                control={control}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                // opcionTodos={true}
                                setValue={setValue}
                                name='area_protegida'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadoId={evaluacion.area_protegida_id ? evaluacion.area_protegida_id : ''} // id del elemento seleccionado
                                onChange={(data) => {
                                    if (data) {
                                        obtenerEvaluaciones(data.value);
                                    } else {
                                        setFechasEvaluacionClonar([]);
                                    }
                                }}
                            />
                        </CCol>
                        <CCol xs="12" sm="12" md="12" className="mb-2">
                            <SelectorUsuario
                                iniciar={show}
                                label={'Seleccione responsable'}
                                control={control}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                setValue={setValue}
                                name='usuario_responsable'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadoId={responsableId ? responsableId : ''} // id del elemento seleccionado
                            />
                        </CCol>
                        <CCol md='12' className="mb-2">
                            <SelectorUsuarioMulti
                                control={control}
                                label={'Seleccione usuarios editores'}
                                Controller={Controller}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                iniciar={true}
                                name='usuarios'
                                opcionGeneral={false}
                                placeholder='No seleccionado'
                                seleccionadosId={usuarioId} // id de elementos seleccionado
                                defaultValue={usuarioId}
                                setValue={setValue}
                                onChange={(seleccionado) => {
                                    if (seleccionado) {
                                        let ids = []
                                        seleccionado.forEach(e => {
                                            ids.push(e.value)
                                        });
                                        setUsuarioId(ids)
                                    } else {
                                        setUsuarioId("")
                                    }
                                }}
                            />
                        </CCol>
                        {evaluacionId === undefined && fechasEvaluacionClonar.length > 1 &&
                            <CCol>
                                <CAlert color="warning" style={{fontSize: '14px'}}>Puede clonar la evaluación de otro año y asignar el año de la parte superior (primer campo).<br />
                                    Seleccione el año a clonar más abajo y presione guardar, en caso contrario dejar la opción no clonar para crear desde cero.</CAlert>
                                <CFormGroup>
                                    <CLabel htmlFor="appendedInputButton">Año a clonar</CLabel>
                                    <Select
                                        onChange={(data) => {
                                            setEvaluacionIdClonar(data.value)
                                        }}
                                        options={fechasEvaluacionClonar}
                                    />
                                </CFormGroup>
                                {evaluacionIdClonar === '' ?
                                    <CAlert color="info" style={{fontSize: '14px'}}>Información: está creando una evaluación desde cero.</CAlert>
                                    :
                                    <CAlert color="info" style={{fontSize: '14px'}}>Información: está clonando una evaluación.</CAlert>
                                }
                            </CCol>
                        }
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={cerrarModal}
                    ><FaTimes /> Cerrar</CButton>
                    {evaluacionId &&
                        <Link to={'/encuestas/' + evaluacion.encuesta_id + '/evaluacion/' + evaluacionId}>
                            <CButton
                                color="default"
                                className=""
                            > <FaRegEye /> Ver preguntas</CButton>
                        </Link>
                    }
                    <CButton color="success" type="submit"><FaSave /> Guardar</CButton>{' '}
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default FormEvaluacion