import React, { useEffect, useState } from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react-pro'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';
import SelectorSectorTHRHF from '../../componentes/SelectorSectorTHRHF'
import SelectorTipoAreaTHRHF from '../../componentes/SelectorTipoAreaTHRHF'

const FormArea = (props) => {
    const { register, handleSubmit, errors, setValue, reset, control } = useForm({
        defaultValues: {
            habilitado: true
        }
    });
    const { area, ...rest } = props;
    const [nombreModal, setNombreModal] = useState('');
    const [sectorSeleccionado, setSectorSeleccionado] = useState({});
    const [areaSeleccionada, setAreaSeleccionada] = useState({});
    const [unidadesTipoArea, setUnidadesTipoArea] = useState([]);

    useEffect(
        () => {
            if (Object.keys(area).length > 0) {
                // obtenerDatos()
                setNombreModal('Editar área de emplazamiento')
            } else {
                reset()
                setNombreModal('Crear área de emplazamiento')
            }
        },
        [area, setValue, reset, nombreModal]
    )

    function cerrarModal() {
        setUnidadesTipoArea([])
        props.onHide();
    }
    const onSubmit = async (data) => {
        data.sector_id = data.sector.value;
		data.tipo_area_id = data.tipo_area.value;

		let unidades = []
		unidadesTipoArea.forEach(unidad => {
			unidades.push({
				unidad_id: unidad.id,
				cantidad: data[unidad.id],
				tipo_area_id: data.tipo_area_id
			})
		});
        data.unidades = unidades;
        if (area.id) {
            let resultado = await api.area.put(area.id, data);
            if (resultado && resultado.status === 200) {
				toast.error("Area editada correctamente")
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
            let resultado = await api.area.post(data);
            if (resultado && resultado.status === 200) {
                toast.success("Área creada con éxito");
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }
    return (
        <CModal
            visible={rest.visible}
            onDismiss={rest.close}
            size='lg'
            keyboard={false}
        >
            <CModalHeader onDismiss={rest.close}>{nombreModal}</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <SelectorSectorTHRHF
                                        areaProtegidaId={rest.area_protegida_id}
                                        iniciar={true}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        // opcionTodos={true}
                                        setValue={setValue}
                                        name='sector'
                                        // opcionGeneral={true}
                                        placeholder='No seleccionado'
                                        seleccionadoId={rest.sector_id} // id del elemento seleccionado
                                        onChange={(seleccionado) => {
                                            if (seleccionado) {
                                                setSectorSeleccionado(seleccionado)
                                                setAreaSeleccionada('')
                                            } else {
                                                setSectorSeleccionado({})
                                                setAreaSeleccionada('')
                                            }
                                        }}
                                    // cargado={(data) => {
                                    // }}
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre</CFormLabel>
                                    <CFormControl

                                        type="text"
                                        name="nombre_area"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_area" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <SelectorTipoAreaTHRHF
                                        iniciar={true}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        setValue={setValue}
                                        name='tipo_area'
                                        opcionGeneral={true}
                                        placeholder='No seleccionado'
                                        // seleccionadoId={infraestructura.tipo_instalacion_id} // id del elemento seleccionado
                                        onChange={(seleccionado) => {
                                            if (seleccionado) {
                                                setUnidadesTipoArea(seleccionado.unidades)
                                            } else {
                                                setUnidadesTipoArea([])
                                            }
                                        }}
                                    // cargado={(data) => {
                                    // }}
                                    />
                                </CCol>
                                    {unidadesTipoArea.map((tipo) =>
                                        <CCol xs="12" sm="12" md="6" className="mb-2" key={tipo.id + 'a'}>
                                            <CFormLabel htmlFor="nf-email">{tipo.nombre}</CFormLabel>
                                            <CFormControl
                                                type="number"
                                                step="any"
                                                name={tipo.id}
                                                placeholder=""
                                                ref={register({
                                                    // required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                            {/* <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" /> */}
                                        </CCol>
                                    )}
                                

                            </CRow>


                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={cerrarModal}
                    ><FaTimes /> Cerrar </CButton>
                    <CButton
                        color="success"
                        type="submit"
                    ><FaSave /> Guardar </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default FormArea


