import React, { useState } from 'react'
import {
    CCol, CRow, CFormLabel, CButton, CFormSelect, CFormCheck,
    CCard, CCardHeader, CBadge, CCardBody, CCardImage, CNav, CNavItem, CNavLink, CImage, CCardGroup, CLink
} from '@coreui/react-pro'
import {
    CChartBar
} from '@coreui/react-chartjs'
import ErpLayout from '../../layout/ErpLayout'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter } from '../../componentes/GrillaBase';

const MiCuenta = () => {
    const { register, handleSubmit, errors } = useForm({});
    const [activarInfo, setActivarInfo] = useState(false);
    const [asp, setAsp] = useState('Queulat');

    const onSubmit = async (data) => {
        console.log('data', data)
        let resultado = await api.usuario.post(data);
        if (resultado && resultado.status === 200) {
            toast.success('Cambios almacenados');
        } else {
            toast.error('Error al guardar');
        }
    }
    const columns = [
        { key: 'CODSIG', name: 'Titulo', formatter: LongTextFormatter },
        { key: '1001106', name: 'Titulo2', formatter: LongTextFormatter },
    ];
    const columns2 = [
        { key: 'l', name: 'Tipo', formatter: LongTextFormatter },
        { key: 'k', name: 'Descripción', formatter: LongTextFormatter },
        { key: 'h', name: 'Adquisición', formatter: LongTextFormatter },
        { key: 'j', name: 'Valoración', formatter: LongTextFormatter },
    ];
    const columns3= [
        { key: 'l', name: 'RUT', formatter: LongTextFormatter },
        { key: 'k', name: 'Nombre', formatter: LongTextFormatter },
        { key: 'h', name: 'Fecha/Edad', formatter: LongTextFormatter },
        { key: 'j', name: 'Cargo', formatter: LongTextFormatter },
    ];
    const columns4= [
        { key: 'l', name: 'Nacionalidad', formatter: LongTextFormatter },
        { key: 'k', name: 'Niño', formatter: LongTextFormatter },
        { key: 'h', name: 'Adolecente', formatter: LongTextFormatter },
        { key: 'j', name: 'Adulto', formatter: LongTextFormatter },
        { key: 'g', name: 'Adulto Mayor', formatter: LongTextFormatter },
    ];
    const columns5= [
        { key: 'l', name: '', formatter: LongTextFormatter },
        { key: 'k', name: '', formatter: LongTextFormatter },

    ];

    const columns6= [
        { key: 'l', name: 'Nombre', formatter: LongTextFormatter },
        { key: 'k', name: 'Descripción', formatter: LongTextFormatter },
        { key: 'h', name: 'Ingreso', formatter: LongTextFormatter },
    ];
    const columns7= [
        { key: 'l', name: 'Otros', formatter: LongTextFormatter },
        { key: 'k', name: 'Talaje', formatter: LongTextFormatter },
        { key: 'h', name: 'Entradas', formatter: LongTextFormatter },
        { key: 'j', name: 'Total', formatter: LongTextFormatter },
    ];
    const usersData = [
        { id: 0, CODSIG: ' Categoría', 1001106: 'Parque Nacional' },
        { id: 1, CODSIG: 'Región', 1001106: 'Parque Nacional' },
        { id: 2, CODSIG: 'Superficie (ha)', 1001106: 'Parque Nacional' },
        { id: 3, CODSIG: 'ID DP', 1001106: 'Parque Nacional' },

    ]
    const usersData2 = [
        { id: 0, l: ' Construcción', k: 'Torre vigia', h: '2005', j: '$3MM' },
        { id: 0, l: ' Vehículo', k: 'Camioneta 4x4', h: '2017', j: '$18MM' },
        { id: 0, l: ' Vehículo', k: 'jeep 4x4', h: '2012', j: '$12MM' },
        { id: 0, l: ' Herramienta', k: 'Motosierr', h: '2010', j: '$500MM' },

    ]
    const usersData3 = [
        { id: 0, l: '12.083.018-6', k: 'Ada Lovelace', h: '10/12/1975', j: 'Guardaparque' },
        { id: 0, l: ' 10.083.018-6', k: 'Grace Hopper', h: '09/08/1986', j: 'Guardaparque' },
        { id: 0, l: ' 19.083.018-6', k: 'Margaret Hamilton', h: '17/08/1981', j: 'Cuidador' },
        { id: 0, l: ' 12.083.018-6', k: 'Joan Clarke', h: '24/06/1985', j: 'Administrativo' },

    ]
    const usersData4 = [
        { id: 0, l: 'Chileno/a', k: '$0', h: '$2.100', j: '$4.100',g:'$0' },
        { id: 0, l: 'Discap. Chileno/a', k: '$0', h: '$0', j: '$4.100',g:'$0' },
        { id: 0, l: 'Extranjero/a', k: '$0', h: '$4.100', j: '$4.100',g:'$8200' },
        { id: 0, l: 'Discap. Extranjero/a', k: '$0', h: '$4.100', j: '$4.100',g:'$8200' },

    ]
    const usersData5 = [
        { id: 0, l: 'Gastos Sueldos', k: '$86.592.00'},
        { id: 0, l: 'Horas extras', k: '$2.357.00'},
        { id: 0, l: 'Jornales', k: '$29.851.00'},
    ]
    const usersData6 = [
        { id: 0, l: 'Adonis Acuña Medina', k: 'Giras nauticas y administración zona camping',h:'$15.026.00'},

    ]
    const usersData7 = [
        { id: 0, l: '$2.654.280', k: '$0',h:'$209.026.00',j:'$212.201.448'},

    ]
    const getBadge = status => {
        switch (status) {
            case 'Active': return 'success'
            case 'Inactive': return 'secondary'
            case 'Pending': return 'warning'
            case 'Banned': return 'danger'
            default: return 'primary'
        }
    }

    return (
        <ErpLayout>
            <CRow>
                <CCol xs="12" sm="12" md="12" lg="12">
                    <CCard>
                        <CCardHeader>
                            <h5 style={{ textAlign: 'center',fontWeight:'bold' }}>Filtros</h5>
                        </CCardHeader>
                        <CCardBody>
                            <CRow >
                                <CCol xs="12" sm="6" md="4" className="py-1 px-4 row justify-content-start">

                                    <CFormLabel htmlFor="Region:">Región:</CFormLabel>

                                    <CFormSelect custom name="select" id="select">
                                        <option value="0">Todas</option>
                                        <option value="1">I</option>
                                        <option value="2">II</option>
                                        <option value="3">III</option>
                                        <option value="4">IV</option>
                                        <option value="5">V</option>
                                        <option value="6">VI</option>
                                        <option value="7">VII</option>
                                        <option value="8">VIII</option>
                                        <option value="9">IX</option>
                                        <option value="10">X</option>
                                        <option value="11">XI</option>
                                        <option value="12">XII</option>
                                        <option value="13">R.M.</option>
                                        <option value="14">XIV</option>
                                        <option value="15">XV</option>
                                        <option value="16">XVI</option>
                                    </CFormSelect>

                                </CCol>
                                <CCol xs="12" sm="6" md="4" className="px-4 row justify-content-start">

                                    <CFormLabel htmlFor="Area protegida">Área protegida:</CFormLabel>

                                    <CFormSelect custom name="select" id="select" onChange={(e) => {
                                        if (e.target.value !== '') {
                                            setAsp(e.target.value)
                                        }
                                    }}>
                                        <option value="">Seleccione ASP</option>
                                        <option value="Queulat">Queulat</option>
                                        <option value="Cerro Castillo">Cerro Castillo</option>
                                        <option value="Hornopiren">Hornopiren</option>
                                        <option value="Laguna Parrillar">Laguna Parrillar</option>
                                        <option value="Torres del Paine">Torres del Paine</option>
                                    </CFormSelect>

                                </CCol>
                                <CCol xs="12" sm="6" md="4" className="py-1 px-4 row justify-content-start">

                                    <CFormLabel htmlFor="año">Año:</CFormLabel>

                                    <CFormSelect custom name="select" id="select">
                                        <option value="0">Seleccione año</option>
                                        <option value="1">2021</option>
                                        <option value="2">2020</option>
                                        <option value="3">2019</option>
                                        <option value="3">2018</option>
                                        <option value="3">2017</option>
                                        <option value="3">2016</option>
                                        <option value="3">2015</option>
                                    </CFormSelect>

                                </CCol>

                            </CRow>
                            <CRow className="mt-3">
                                {/* <CCol xs="12" sm="12" md="12" className="py-1 row justify-content-center"> */}
                                <CCol xs={2}></CCol>
                                <CCol xl={8}>
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox1"
                                        name="inline-checkbox1"
                                        value="option1"
                                        label="Información"
                                    />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox1">Información</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox2"
                                        name="inline-checkbox2"
                                        value="option2"
                                        label="Base"
                                    />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox2">Base</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox3"
                                        name="inline-checkbox3"
                                        value="option3"
                                        label="Administración"
                                        />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Administración</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox3"
                                        name="inline-checkbox3"
                                        value="option3"
                                        label="Planificación"
                                        />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Planificación</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox3"
                                        name="inline-checkbox3"
                                        value="option3"
                                        label="Monitoreo, manejo y gestión"
                                        />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Monitoreo, manejo y gestión</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox3"
                                        name="inline-checkbox3"
                                        value="option3"
                                        label="Financiamiento"
                                        />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Financiamiento</CFormLabel> */}
                                    <CFormCheck
                                        custom
                                        inline
                                        id="inline-checkbox3"
                                        name="inline-checkbox3"
                                        value="option3"
                                        label="Archivo Gráfico"
                                        />
                                    {/* <CFormLabel variant="custom-checkbox" htmlFor="inline-checkbox3">Archivo Gráfico</CFormLabel> */}
                                </CCol>
                                <CCol xs={2}></CCol>
                                {/* </CCol> */}
                            </CRow>
                            <CRow className="mt-3">
                                <CCol xs="12" sm="12" md="12" className="py-1 row justify-content-center">
                                    <CButton color="info" className="mx-1" onClick={() => setActivarInfo(true)}>Mostrar información</CButton>
                                </CCol>
                            </CRow>

                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            {activarInfo &&
                <>
                    <h3 className="font-weight-bold" >Parque {asp}</h3>
                    <CNav variant="tabs">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink>
                                    Información
                                                        </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink>
                                    Indicadores
                                                        </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink>
                                    Lista Verde
                                                        </CNavLink>
                            </CNavItem>
                        </CNav>

                    </CNav>
                    <CRow>
                        <CCol xs="12" sm="6" md="6" lg="6">

                            <CCard xs="12" sm="12" md="12" lg="12">

                                <CCardBody>
                                    <CRow>
                                        <CCol xs="12" sm="12" md="12" className="py-1 px-4 row justify-content-start">

                                        </CCol>

                                    </CRow>

                                    <CCol xs="12" lg="12">

                                        <GrillaBase style={{ maxHeight: 177, marginTop: 20, marginBottom: 20 }}
                                            rows={usersData}
                                            columns={columns}
                                            showCountRows={false}
                                        />
                                        <CCard>
                                            <CCardHeader>
                                                <h5 className="text-center font-weight-bold">Información Base</h5>
                                            </CCardHeader>

                                            <CRow xs="12" sm="12" md="6" >
                                                <CCol xs="12" sm="12" md="6" >


                                                    <div className="text-center font-weight-bold">
                                                        <h5>Informe justificatorio: 2000</h5>
                                                    </div>


                                                    <CCardImage src="f1.png" fluid></CCardImage>


                                                </CCol>
                                                <CCol xs="12" sm="12" md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ul>
                                                        <li>Fecha Carga: 15/03/2021</li>
                                                        <li>Unidad: Depto. Administración</li>
                                                        <li>Usuario: Ivonne Molina</li>
                                                    </ul>
                                                </CCol>

                                            </CRow>
                                            <CRow xs="12" sm="12" md="6" >
                                                <CCol xs="12" sm="12" md="6" >



                                                    <div className="text-center font-weight-bold">
                                                        <h5>Informe justificatorio: 2000</h5>
                                                    </div>


                                                    <CCardImage src="f4.png" fluid></CCardImage>


                                                </CCol>
                                                <CCol xs="12" sm="12" md="6" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ul>
                                                        <li>Fecha Carga: 15/03/2021</li>
                                                        <li>Unidad: Depto. Administración</li>
                                                        <li>Usuario: Ivonne Molina</li>
                                                    </ul>
                                                </CCol>

                                            </CRow>
                                        </CCard>

                                    </CCol>


                                </CCardBody>
                            </CCard>


                        </CCol>

                        <CCol xs="12" sm="6" md="6" lg="6">

                            <CCard xs="12" sm="6" md="6" lg="6">
                                <CCardHeader>
                                    <h5 className="text-center font-weight-bold" >Cartografía</h5>
                                </CCardHeader>
                                <CCardBody>
                                    <CImage
                                        style={{ width: '100%' }}
                                        src="valdivia.png"
                                        width='auto'
                                    />
                                </CCardBody>
                            </CCard>
                            <CCard xs="12" sm="6" md="6" lg="6">
                                <CCardHeader>
                                    <h5 className="text-center font-weight-bold" >Archivo gráfico</h5>
                                </CCardHeader>
                                <CCardBody>
                                    <CImage
                                        style={{ width: '50%', padding: 5 }}
                                        src="parque.jpeg"
                                        width='auto'
                                    />
                                    <CImage
                                        style={{ width: '50%', padding: 5 }}
                                        src="parque1.jpg"
                                        width='auto'
                                    />
                                    <CImage
                                        style={{ width: '50%', padding: 5 }}
                                        src="parque2.jpg"
                                        width='auto'
                                    />
                                    <CImage
                                        style={{ width: '50%', padding: 5 }}
                                        src="parque.jpeg"
                                        width='auto'
                                    />

                                </CCardBody>
                                <CRow>
                                    <CCol xs="12" sm="12" md="12" className="py-1 row justify-content-center">
                                        <CButton color="info" className="mr-1" style={{ minWidth: 200 }}>Ver más</CButton>
                                    </CCol>
                                </CRow>

                            </CCard>

                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CCard>
                                <CCardHeader>
                                    <h5 style={{ textAlign: 'center' }} className="font-weight-bold">Administración</h5>
                                </CCardHeader>
                                <CCardGroup  >
                                    <CCard>
                                        <CCardHeader className="text-center">
                                            Visitación 2020  <CLink
                                                href="#"
                                                target="_blank"
                                            >
                                                ver más
                                        </CLink>
                                        </CCardHeader>

                                        <CCardBody >
                                            <CChartBar

                                                datasets={[
                                                    {
                                                        label: 'Adultos',
                                                        backgroundColor: '#36eb67',
                                                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                                                    },
                                                    {
                                                        label: 'Menores',
                                                        backgroundColor: '#32a852',
                                                        data: [40, 20, 12, 1, 10, 40, 39, 80, 40, 20, 12, 11]
                                                    },
                                                    {
                                                        label: 'Tercera edad',
                                                        backgroundColor: '#10752c',
                                                        data: [40, 20, 12, 1, 10, 40, 39, 80, 40, 20, 12, 11]
                                                    }
                                                ]}

                                                labels="months"
                                                options={{
                                                    tooltips: {
                                                        enabled: true
                                                    }
                                                }}
                                            />
                                        </CCardBody>
                                    </CCard>


                                </CCardGroup>
                                <CCardBody>
                                    <CRow>
                                        <CCol xs="12" sm="6" md="6" lg="6">

                                            <CCard xs="12" sm="12" md="12" lg="12" style={{ border: 'none' }}>
                                                <CCardHeader>
                                                    <h5 className="text-center font-weight-bold" >Accesibilidad Universal</h5>
                                                </CCardHeader>
                                                <CCardBody>
                                                    50 metros lineales de pasarela accesible a mirador del imponente Vestisquero Colgante. También el
                                                    sector cuenta con baños accesibles a 200 metros.
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                        <CCol xs="12" sm="6" md="6" lg="6">

                                            <CCard xs="12" sm="6" md="6" lg="6" style={{ border: 'none' }}>
                                                <CCardHeader>
                                                    <h5 className="text-center font-weight-bold" >Prevención y seguridad</h5>
                                                </CCardHeader>
                                                <CCardBody className="text-center" style={{ color: 'orange' }}>
                                                    Sin información.
                                                </CCardBody>
                                            </CCard>


                                        </CCol>
                                    </CRow>

                                </CCardBody>
                                <CCardBody>
                                    <CRow>
                                        <CCol xs="12" sm="12" md="12" lg="12">

                                            <CCard xs="12" sm="12" md="12" lg="12" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Infraestructura</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 177 }}
                                                        rows={usersData2}
                                                        columns={columns2}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                        <CCol xs="12" sm="12" md="12" lg="12">

                                            <CCard xs="12" sm="12" md="12" lg="12" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Personal</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 177 }}
                                                        rows={usersData3}
                                                        columns={columns3}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                    </CRow>

                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CCard>
                                <CCardHeader>
                                    <h5 style={{ textAlign: 'center' }} className="font-weight-bold">Financiamiento</h5>
                                </CCardHeader>


                                <CCardBody>
                                    <CRow>
                                        <CCol xs="12" sm="12" md="12" lg="12">

                                            <CCard xs="12" sm="12" md="12" lg="12" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Tarifas de ingreso 2020 - 2021 en $</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 177 }}
                                                        rows={usersData4}
                                                        columns={columns4}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                        <CCol xs="12" sm="12" md="6" lg="6">

                                            <CCard xs="12" sm="12" md="6" lg="6" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Personal CONAF, dotación 2019</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 142 }}
                                                        rows={usersData5}
                                                        columns={columns5}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                        <CCol xs="12" sm="12" md="6" lg="6">

                                            <CCard xs="12" sm="12" md="6" lg="6" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Ingresos 2019 concesiones</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 72 }}
                                                        rows={usersData6}
                                                        columns={columns6}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>
                                        <CCol xs="12" sm="12" md="12" lg="12">

                                            <CCard xs="12" sm="12" md="12" lg="12" style={{ border: 'none' }}>

                                                <h5 className="text-center font-weight-bold" >Ingresos operación 2019</h5>

                                                <CCardBody>
                                                    <GrillaBase style={{ maxHeight: 72 }}
                                                        rows={usersData7}
                                                        columns={columns7}
                                                        showCountRows={false}
                                                    />
                                                </CCardBody>
                                            </CCard>


                                        </CCol>

                                    </CRow>

                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CCard>
                                <CCardHeader>
                                    <h5 style={{ textAlign: 'center' }} className="font-weight-bold">Planificación</h5>
                                </CCardHeader>

                            </CCard>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CCard>
                                <CCardHeader>
                                    <h5 style={{ textAlign: 'center' }} className="font-weight-bold">Monitoreo, manejo y gestión</h5>
                                </CCardHeader>

                            </CCard>
                        </CCol>
                    </CRow>
                </>
            }
        </ErpLayout>
    )
}

export default MiCuenta