import React, { useState, useEffect } from 'react'
import { CCol, CRow, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CInputGroup, CInputGroupText, CFormInput} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter } from '../../componentes/GrillaBase';
// import { ContextMenu, MenuItem } from 'react-contextmenu';
import { TextEditor } from 'react-data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const VisitacionDatos = () => {
    const columns = [
        { key: 'periodo', name: 'Periodo'},
        { key: 'mes', name: 'mes'},
        { key: 'regiones', name: 'Regiones', formatter: NumberFormatter},
        { key: 'areas', name: 'Áreas', formatter: NumberFormatter},
        { key: 'covertura', name: 'Covertura', formatter: NumberFormatter},
        { key: 'chilenos', name: 'Chilenos', formatter: NumberFormatter},
        { key: 'extranjeros', name: 'Extranjeros', formatter: NumberFormatter},
        { key: 'total', name: 'Visitas', formatter: NumberFormatter},
        { key: 'usuario', name: 'Usuario'},
        { key: 'verificador', name: 'Verificador'},
    ];

    const [rows, setRows] = useState([])
    const [startDate, setStartDate] = useState(new Date());


    async function obtenerDatos() {
		let resultado = await api.visitacion.getDatos();
		if (resultado && resultado.status === 200) {
            setRows((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos');
		}
	}


    useEffect(
        () => {
            obtenerDatos();
        },
        [startDate]
    )

    const [activeTab, setActiveTab] = useState('datos');

    return (
        <ErpLayout
            breadcrumbs={[
                    {active: true, pathname: '', name: 'visitación'},
                    {active: true, pathname: `/visitacion`, name: 'Datos'}]
                }>

            <CRow>
                <CCol>
            <CNav variant="tabs">
                <CNavItem>
                    <CNavLink data-tab="datos" active={activeTab === 'datos'} onClick={() => setActiveTab('datos')}>
                        Datos
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink data-tab="graficos" active={activeTab === 'graficos'} onClick={() => { setActiveTab('graficos') }}>
                        Gráficos
                    </CNavLink>
                </CNavItem>
            </CNav>
            </CCol>
            </CRow>

            <CTabContent>
				<CTabPane data-tab="datos" visible={activeTab === 'datos'}>
                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                            <GrillaBase
                                // menu={<ContextMenu id="grid-context-menu">
                                //     {/* <MenuItem onClick={menuEditar}> Editar</MenuItem>
                                //     <MenuItem onClick={menuEliminar}> Eliminar</MenuItem> */}
                                // </ContextMenu>}
                                columns={columns}
                                showCountRows={true}
                                rows={rows} />
                        </CCol>
                    </CRow>
                </CTabPane>
            </CTabContent>

            <CTabContent>
				<CTabPane data-tab="graficos" visible={activeTab === 'graficos'}>
                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 300 }}>
                            {/* <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                width={500}
                                height={300}
                                data={datosRegion}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="region" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="femenino" stackId="a" fill="#8884d8" />
                                <Bar dataKey="masculino" stackId="a" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer> */}
                            </div>
                        </CCol>
                    </CRow>
                </CTabPane>
            </CTabContent>
  
        </ErpLayout>
    )
}

export default VisitacionDatos


