import React from 'react'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './modulos/auth/Login'
import Home from './modulos/Home'
import Dashboard from './modulos/Dashboard'
import Dashboard2 from './modulos/Dashboard2'
import Asp from './modulos/Asp'
import Personal from './modulos/Personal'
import Reportes from './modulos/Reportes'
import Archivos from './modulos/Archivos'
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './componentes/PrivateRoute'
import Logout from './layout/Logout'
import MiCuenta from './modulos/usuario/MiCuenta'
import Usuarios from './modulos/administracion/Usuarios'
import TipoInformacion from './modulos/administracion/TipoInformacion'
import Proyecto from './modulos/proyecto/Proyecto'
import VisitacionMensual from './modulos/visitacion/VisitacionMensual'
import VisitacionAnual from './modulos/visitacion/VisitacionAnual'
import VisitacionDatos from './modulos/visitacion/VisitacionDatos'
import GraficoAnual from './modulos/visitacion/GraficoAnual'
import Elaboracion from './modulos/planificacion/Elaboracion'
import GrillaPoa from './modulos/planificacion/GrillaPoa'
import ReporteEstrategias from './modulos/planificacion/ReporteEstrategias'
import ReporteEvaluacion from './modulos/planificacion/ReporteEvaluacion'
import './scss/style.scss'
import Ejecucion from './modulos/planificacion/Ejecucion'
import Evaluacion from './modulos/planificacion/Evaluacion'
import PreviaPersonal from './modulos/PreviaPersonal'
import GrillaResumenInfraestructuras from './modulos/infraestructura/GrillaResumenInfraestructuras'
import GrillaInfraestructurasAsp from './modulos/infraestructura/GrillaInfraestructurasAsp'
import ReporteRegiones from './modulos/infraestructura/ReporteRegiones'
import ReporteTipoInstalacion from './modulos/infraestructura/ReporteTipoInstalacion'
import Sectores from './modulos/administracion/Sectores'
import Area from './modulos/administracion/Area'
import TipoArea from './modulos/administracion/TipoArea'
import FormInfraestructura from './modulos/infraestructura/FormInfraestructura'
import GrillaEvaluaciones from './modulos/encuesta/GrillaEvaluaciones'
import GraficoIndicadoresCumplen from './modulos/encuesta/GraficoIndicadoresCumplen'
import GraficosIndicadoresFase from './modulos/encuesta/GraficosIndicadoresFase'
import GraficoCumplimiento from './modulos/encuesta/GraficoCumplimiento'
import GraficoCumplimientoPorComponente from './modulos/encuesta/GraficoCumplimientoPorComponente'
import FormAsp from './modulos/FormAsp'
import PreviaEncuesta from './modulos/encuesta/PreviaEncuesta'
import FormResponderIndicador from './modulos/encuesta/FormResponderIndicador'
import GrillaResumenConcesion from './modulos/servicios-ecoturisticos/GrillaResumenConcesion'
import GrillaReporteServicioEcoturistico from './modulos/servicios-ecoturisticos/GrillaReporteServicioEcoturistico'
import GrillaReporteTipoContrato from './modulos/servicios-ecoturisticos/GrillaReporteTipoContrato'
import GrillaConcesionesAsp from './modulos/servicios-ecoturisticos/GrillaConcesionesAsp'
import FormConcesion from './modulos/servicios-ecoturisticos/FormConcesion'
import Concesiones from './modulos/servicios-ecoturisticos/Concesiones'
import MapaConcesiones from './modulos/servicios-ecoturisticos/MapaConcesiones'
import GrillaInfraestructuras from './modulos/infraestructura/GrillaInfraestructuras'
import MapaInfraestructuras from './modulos/infraestructura/MapaInfraestructuras'
import GrillaInvestigacion from './modulos/investigacion/GrillaInvestigacion'
import FormInvestigacion from './modulos/investigacion/FormInvestigacion'
import GrillaResumenInvestigacionAsp from './modulos/investigacion/GrillaResumenInvestigacionAsp'
import { UserProvider } from './Contexto';
import Cargando from './modulos/auth/Cargando'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function App() {
  return (
      <BrowserRouter>
       <UserProvider>
        <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/cargando" render={props => <Cargando {...props}/>} />
              <Route exact path="/" name="Home" render={props => <Login {...props}/>} />
              <Route exact path="/login" name="Login" render={props => <Login {...props}/>} />
              <PrivateRoute exact path="/asp" component={Asp} modulo='Áreas protegidas'/>
              <PrivateRoute exact path="/reportes" component={Reportes} modulo='Reportes'/>
              <PrivateRoute exact path="/archivos" component={Archivos} modulo='Archivos'/>
              <PrivateRoute exact path="/home" component={Home} modulo='Acceso libre'/>
              <PrivateRoute exact path="/dashboard" component={Dashboard} modulo='Acceso libre'/>
              <PrivateRoute exact path="/dashboard2" component={Dashboard2}/>
              <PrivateRoute exact path="/mi-cuenta" component={MiCuenta} modulo='Acceso libre'/>
              <PrivateRoute exact path="/usuarios" component={Usuarios} administrador={true}/>
              <PrivateRoute exact path="/tipo-informacion" component={TipoInformacion} administrador={true}/>
              <PrivateRoute exact path="/visitacion-datos" component={VisitacionDatos} modulo='Datos'/>
              <PrivateRoute exact path="/visitacion-mensual" component={VisitacionMensual} modulo='Mensual'/>
              <PrivateRoute exact path="/visitacion-anual" component={VisitacionAnual} modulo='Anual'/>
              <PrivateRoute exact path="/visitacion-grafico-anual" component={GraficoAnual} modulo='Gráfico Anual'/>
              <PrivateRoute exact path="/poa" component={GrillaPoa} modulo='Plan operativo anual'/>
              <PrivateRoute exact path="/poa/reporte-estrategias" component={ReporteEstrategias} modulo='Reporte estrategias'/>
              <PrivateRoute exact path="/poa/reporte-evaluacion" component={ReporteEvaluacion} modulo='Reporte evaluación'/>
              <PrivateRoute exact path="/poa/:id/elaboracion" component={Elaboracion} modulo='Plan operativo anual'/>
              <PrivateRoute exact path="/poa/:id/ejecucion" component={Ejecucion} modulo='Plan operativo anual'/>
              <PrivateRoute exact path="/poa/:id/evaluacion" component={Evaluacion} modulo='Plan operativo anual'/>
              <PrivateRoute exact path="/personal/:id" component={Personal} modulo='Personal' />
              <PrivateRoute exact path="/previa-personal" component={PreviaPersonal} modulo='Personal'/>
              <PrivateRoute exact path="/infraestructurasAsp" name="GrillaResumenInfraestructuras" component={GrillaResumenInfraestructuras} modulo='Reporte por unidad ASP'/>
              <PrivateRoute exact path="/infraestructuras-reporte-region" name="InfraestructuraReporteRegion" component={ReporteRegiones} modulo='Reporte por Región'/>
              <PrivateRoute exact path="/infraestructuras-reporte-tipo" name="InfraestructuraReporteTipo" component={ReporteTipoInstalacion} modulo='Reporte por Tipo Instalación'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/infraestructura" name="GrillaInfraestructurasAsp" component={GrillaInfraestructurasAsp} modulo='Listado Infraestructura'/>
              <PrivateRoute exact path="/infraestructuras" name="GrillaInfraestructuras" component={GrillaInfraestructuras} modulo='Listado Infraestructura'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/infraestructura/:id" name="FormInfraestructura" component={FormInfraestructura} modulo='Listado Infraestructura'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/infraestructura/crear" name="FormInfraestructura" component={FormInfraestructura} modulo='Listado Infraestructura'/>
              <PrivateRoute exact path="/infraestructura/crear" name="FormConcesion" component={FormInfraestructura} modulo='Listado Infraestructura'/>
              <PrivateRoute exact path="/infraestructuras/mapa" name="MapaInfraestructuras" component={MapaInfraestructuras} modulo='Mapa Infraestructura'/>
              <PrivateRoute exact path="/area-protegida/crear" name="FormAsp" component={FormAsp} modulo='Áreas protegidas'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/editar" component={FormAsp} modulo='Áreas protegidas'/>
              <PrivateRoute exact path="/investigacion" name="Investigación" component={GrillaInvestigacion} modulo='Listado investigación'/>
              <PrivateRoute exact path="/investigacion/crear" name="FormInvestigacion" component={FormInvestigacion} modulo='Listado investigación'/>
              <PrivateRoute exact path="/investigacion/:id" name="FormInvestigacion" component={FormInvestigacion} modulo='Listado investigación'/>
              <PrivateRoute exact path="/investigacion-asp" name="GrillaResumenAsp" component={GrillaResumenInvestigacionAsp} modulo='Reporte por Asp'/>
              <PrivateRoute exact path="/sectores" name="Sectores" component={Sectores} />
              <PrivateRoute exact path="/area" name="Area" component={Area} />
              <PrivateRoute exact path="/tipo-area" name="TipoArea" component={TipoArea} modulo='Tipo de área'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id" name="GrillaEvaluaciones" component={GrillaEvaluaciones} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/grafico-indicadores" component={GraficoIndicadoresCumplen} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/grafico-indicadores-fase" component={GraficosIndicadoresFase} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/grafico-cumplimiento" component={GraficoCumplimiento} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/grafico-cumplimiento-componente" component={GraficoCumplimientoPorComponente} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/evaluacion/crear" component={PreviaEncuesta} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/evaluacion/:evaluacion_id" component={PreviaEncuesta} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/encuestas/:encuesta_id/evaluacion/:evaluacion_id/seccion/:seccion_id/indicador/:indicador_id" component={FormResponderIndicador} modulo='Listado evaluaciones'/>
              <PrivateRoute exact path="/concesion" name="GrillaResumenConcesion" component={GrillaResumenConcesion} modulo='Reporte por ASP'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/concesiones" name="GrillaConcesiones" component={GrillaConcesionesAsp} modulo='Reporte por ASP'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/concesion/:id" name="FormConcesion" component={FormConcesion} modulo='Reporte por ASP'/>
              <PrivateRoute exact path="/area-protegida/:area_protegida_id/concesion/crear" name="FormConcesion" component={FormConcesion} modulo='Reporte por ASP'/>
              <PrivateRoute exact path="/servicio-ecoturistico" name="GrillaReporteServicioEcoturistico" component={GrillaReporteServicioEcoturistico} modulo='Reporte servicio ecoturístico'/>
              <PrivateRoute exact path="/tipo-contrato" name="GrillaReporteTipoContrato" component={GrillaReporteTipoContrato} modulo='Reporte por tipo contrato'/>
              <PrivateRoute exact path="/concesiones" name="Concesiones" component={Concesiones} modulo='Concesiones'/>
              <PrivateRoute exact path="/concesiones/mapa" name="MapaConcesiones" component={MapaConcesiones} modulo='Mapa' />
              <PrivateRoute exact path="/concesion/crear" name="FormConcesion" component={FormConcesion} modulo='Concesiones'/>
              {/* <Route exact path="/infraestructura-formulario" name="FormInfraestructura" render={props => <FormInfraestructura {...props}/>} /> */}
              <Route exact path="/logout" component={Logout} />
              <PrivateRoute path="/asps" component={Proyecto}/>
            </Switch>
        </React.Suspense>
        </UserProvider>
      </BrowserRouter>

  );
}

export default App;

