import React, { useEffect, useState, useContext } from 'react';
import { CCol, CRow, CButton,CNav, CNavItem, CNavLink, CTabContent, CTabPane, CFormLabel} from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase,LongTextFormatter, LongTextFormatter2, NumberFormatter, CenterAlign } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus, FaRegFileExcel, FaPencilAlt, FaTrash } from 'react-icons/fa';
import FormElaboracion from './FormElaboracion';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { exportar } from '../../utils/Funciones';
import { useHistory } from "react-router-dom";
import ModalEliminar from '../administracion/ModalEliminar';
import UserContext from '../../Contexto'


const Elaboracion = (props) => {
	const { register, handleSubmit, errors, setValue, getValues, reset, control } = useForm({});
    let history = useHistory();
    const columns = [
        { key: 'subestrategia', name: 'Subestrategia', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 200},
        { key: 'descripcion', name: 'Actividad', formatter: LongTextFormatter2, cellClass: "cont-longtext", width:300 },
        { key: 'tipo_compromiso', name: 'Tipo Comp.', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 140 },
        { key: 'programa_manejo', name: 'Prog Man.', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 150 },
        { key: 'descripcion_programa_manejo', name: 'Desc. Prog. Manejo', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 120 },
        { key: 'meta', name: 'Meta', formatter: CenterAlign, width: 100 },
        { key: 'cantidad', name: 'Ejecutado', formatter: CenterAlign, width: 90 },
        { key: 'porcentaje_cumplidas', name: '% Cumplimiento', formatter: CenterAlign, width: 120 },
        { key: 'unidad', name: 'Unidad', formatter: CenterAlign, width: 160},
        { key: 'programacion', name: 'Programación', formatter: LongTextFormatter2, cellClass: "cont-longtext", width: 150 },
        { key: 'total_presupuesto', name: 'Presupuesto', width: 100, formatter: NumberFormatter },
        { key: 'responsable', name: 'Responsable', width: 100, formatter: LongTextFormatter2, cellClass: "cont-longtext" },
    ];
    const [rows, setRows] = useState([]);
    const [poa, setPoa] = useState({});
    const [modalActividad, setModalActividad] = useState(false);
    const [actividad, setActividad] = useState({});
    let { id } = useParams();
    const [showEliminar, setShowEliminar] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    const contexto = useContext(UserContext)
    const [permisoEditor, setPermisoEditor] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [responsable, setResponsable] = useState(false)

    useEffect(
        () => {
            setAdmin(contexto.data.usuario.administrador)
            obtenerActividades();
            obtenerPoa();
        },
        []
    )

    async function obtenerActividades() {
        let resultado = await api.poa.actividades(id, {planificacion: true});
        if (resultado && resultado.status === 200) {
            resultado.data.datos.forEach(actividad => {
                let planificacionData = [];
                actividad.planificacion.forEach(planificacion => {
                    if (planificacion.mes === 1){
                        planificacionData.push('Ene')
                    } else if (planificacion.mes === 2) {
                        planificacionData.push('Feb')
                    } else if (planificacion.mes === 3) {
                        planificacionData.push('Mar')
                    } else if (planificacion.mes === 4) {
                        planificacionData.push('Abr')
                    } else if (planificacion.mes === 5) {
                        planificacionData.push('May')
                    } else if (planificacion.mes === 6) {
                        planificacionData.push('Jun')
                    } else if (planificacion.mes === 7) {
                        planificacionData.push('Jul')
                    } else if (planificacion.mes === 8) {
                        planificacionData.push('Ago')
                    } else if (planificacion.mes === 9) {
                        planificacionData.push('Sept')
                    } else if (planificacion.mes === 10) {
                        planificacionData.push('Oct')
                    } else if (planificacion.mes === 11) {
                        planificacionData.push('Nov')
                    } else if (planificacion.mes === 12) {
                        planificacionData.push('Dic')
                    }
                });
                actividad.programacion = planificacionData.join(', ')
                actividad.cantidad = actividad.cumplidas != '' ? actividad.cumplidas : '0';
                actividad.porcentaje_cumplidas = actividad.porcentaje_cumplidas != '' ? actividad.porcentaje_cumplidas : '0';
            });
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    async function obtenerPoa() {
        let resultado = await api.poa.getOne(id, {});
        if (resultado && resultado.status === 200) {
            setPoa(resultado.data.datos);
            if (resultado.data.datos.usuario_id == contexto.data.usuario.id){
                setResponsable(true)
            }
            const permisosEdicion = contexto.data.usuario.permisos_registro
            permisosEdicion.forEach( permiso => {
                if (permiso.tabla_id == id && permiso.tabla_nombre === 'poa'){
                    setPermisoEditor(true)
                }
            });
        } else {
            toast.error('Error al obtener POA');
        }
    }

    async function menuEditar(e, { rowIdx }) {
        setActividad(rows[rowIdx])
        if (responsable ||permisoEditor || admin){
            setModalActividad(true)
        } else {
            toast.error('No cuenta con permisos para editar este registro');
        }
    }

    const menuEliminar = (e, { rowIdx }) => {
        setActividad(rows[rowIdx]);
        if (responsable || permisoEditor || admin){
            setMsgEliminar(`Está seguro que desea eliminar ${rows[rowIdx].descripcion}?`);
            setShowEliminar(true);
        } else {
            toast.error('No cuenta con permisos para eliminar este registro');
        }
    }

    async function confirmarEliminar() {
        let resultado = await api.actividad.delete(id, actividad.id);
        if (resultado && resultado.status === 200) {
            toast.success('Actividad eliminada correctamente');
            setShowEliminar(false);
            obtenerActividades();
        } else {
            toast.error('Error al eliminar actividad');
        }
    }

    function exportarActividades(){
        exportar(`api/exportar/poa/${id}/actividades?planificacion=true`)
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Planificación'},
                    {active: false, pathname: `/poa`, name: 'Poa'},
                    {active: true, pathname: '', name: 'Elaboración'}]
                }>
                <CFormLabel className='mb-2'><b>{poa.area_protegida}</b> - {poa.periodo}</CFormLabel>
				<CNav variant="tabs">
					<CNavItem>
						<CNavLink
							href="#"
							active={true}
							onClick={() => {}}>
							1) Elaboración
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
                                history.push(`/poa/${id}/ejecucion`)
                            }}>
							2) Ejecución
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="#"
							active={false}
							onClick={() => {
                                history.push(`/poa/${id}/evaluacion`)
                            }}>
							3) Evaluación
						</CNavLink>
					</CNavItem>
				</CNav>
				<CTabContent>
					<CTabPane visible={true}>
						<CRow>
							<CCol xs="12" sm="12" md="12" lg="12">
                                {(permisoEditor || admin) &&
								<CButton
									color="success"
									className="mb-2 mt-2 float-lg-end"
									onClick={() => {
										setActividad({});
										setModalActividad(true);
									}}
								><FaPlus /> Nueva actividad</CButton>
                                }
                                <CButton
                                    color="light"
                                    style={{float:'right'}}
                                    onClick={exportarActividades}
                                    className="mx-1 my-2"
                                >
                                    <FaRegFileExcel /> Exportar
                                </CButton>
							</CCol>
						</CRow>
						<GrillaBase
                            rowHeight={45}
                            rowClass={(row) => (row.subestrategia === "2.4 Control de incendios forestales" ? 'subestrategia2-4' : undefined)}
							rows={rows}
							columns={columns}
							menu={<ContextMenu id="grid-context-menu">
								<MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
								<MenuItem onClick={menuEliminar}><FaTrash style={{top:'-2px', position: 'relative'}}/> Eliminar</MenuItem>
							</ContextMenu>}
						/>
					</CTabPane>
					<CTabPane visible={false}>

					</CTabPane>
					<CTabPane visible={false}>

					</CTabPane>
				</CTabContent>

              <FormElaboracion
                  show={modalActividad}
                  actividadId={actividad.id}
                  poaId={id}
                  cerrar={() => {
                      setActividad({})
                      setModalActividad(false)
                      obtenerActividades()
                  }}
                  retorno={() => {
                      console.log('retorno---')
                  }} />
            </ErpLayout>
            <ModalEliminar
                titulo="Eliminar"
                visible={showEliminar}
                setShow={setShowEliminar}
                confirmar={confirmarEliminar}>
                    {msgEliminar}
                    <br/><br/>
                    Una vez eliminada la información no se podrá recuperar.
            </ModalEliminar>
        </>
    )
}

export default Elaboracion