import React, {useEffect,useContext} from 'react';
import Contexto from '../Contexto'


function Logout(props) {

    const contexto = useContext(Contexto)

    useEffect(
        () => {
            localStorage.clear();
            props.history.push('/login');
            contexto.setData({});
        },
        []
    )

    return (
        <p>See you later!</p>
    );
}

export default Logout;