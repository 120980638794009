import React, { useState, useEffect } from 'react'
import { CCol, CRow, CNav, CNavItem, CNavLink, CTabContent, CTabPane, CInputGroup, CInputGroupText, CFormInput} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter } from '../../componentes/GrillaBase';
// import { ContextMenu, MenuItem } from 'react-contextmenu';
import { TextEditor } from 'react-data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, 
    ResponsiveContainer, Pie, PieChart, RadarChart, PolarGrid, Radar, PolarAngleAxis, PolarRadiusAxis } from 'recharts';


const VisitacionMensual = () => {
    const columns = [
        { key: 'nombre', name: 'ASP'},
        { key: 'ch_menor', name: 'Niño', width: 80, formatter: NumberFormatter, editor: TextEditor },
        // { key: 'ch_adscte', name: 'Adscte', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ch_adulto', name: 'Adulto', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ch_mayor', name: 'Mayor', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ex_menor', name: 'Niño', width: 80, formatter: NumberFormatter, editor: TextEditor },
        // { key: 'ex_adscte', name: 'Adscte', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ex_adulto', name: 'Adulto', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ex_mayor', name: 'Mayor', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ch_menor', name: 'Niño', width: 80, formatter: NumberFormatter, editor: TextEditor },
        // { key: 'dis_ch_adscte', name: 'Adscte', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ch_adulto', name: 'Adulto', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ch_mayor', name: 'Mayor', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ex_menor', name: 'Niño', width: 80, formatter: NumberFormatter, editor: TextEditor },
        // { key: 'dis_ex_adscte', name: 'Adscte', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ex_adulto', name: 'Adulto', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dis_ex_mayor', name: 'Mayor', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'gen_masculino', name: 'Masculino', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'gen_femenino', name: 'Femenino', width: 80, formatter: NumberFormatter, editor: TextEditor },
        // { key: 'gen_otro', name: 'Otro', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'total', name: 'Total', width: 80, formatter: NumberFormatter, editor: TextEditor },
    ];

    const titulos = [
        { key: '1', name: ''},
        { key: '2', name: 'Chileno', width: 240 },
        { key: '3', name: 'extranjero', width: 240 },
        { key: '4', name: 'Discapacitado Chileno', width: 240 },
        { key: '5', name: 'Discapacitado Extranjero', width: 240 },
        { key: '6', name: 'Genero', width: 160 },
        { key: '7', name: '', width: 80 },
    ]

    const [rows, setRows] = useState([])
    const [datosRegion, setDatosRegion] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [segmentado1, setSegmentado1] = useState([])
    const [segmentado2, setSegmentado2] = useState([])
    const [chilenos, setChilenos] = useState([])
    const [extranjeros, setExtranjeros] = useState([])

    // const [showFormVisitacion, setShowFormVisitacion] = useState(false);
    // const [visitacion, setVisitacion] = useState({});

    // const menuEliminar = (e, {rowIdx}) => {
    //     console.log('rowIdx', rowIdx)
    //     console.log('menuEliminar', rows[rowIdx])
    // }

    // async function menuEditar(e, { rowIdx }) {
    //     let resultado = await api.usuarios.getOne(rows[rowIdx].id);
    //     if (resultado && resultado.status === 200) {
    //         setVisitacion(resultado.data.datos);
    //         setShowFormVisitacion(true)
    //     } else {
    //         setVisitacion({});
    //         toast.error('Error al obtener datos');
    //     }
    // }

    async function obtenerDatos(y, m) {
		let resultado = await api.visitacion.getMensual(y, m);
		if (resultado && resultado.status === 200) {
            setRows((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos');
		}
	}

    async function obtenerGraficoRegion(y, m) {
		let resultado = await api.visitacion.getGraficoMensualRegion(y, m);
		if (resultado && resultado.status === 200) {
            setDatosRegion((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos');
		}
	}

    async function obtenerGraficoSegmentado(y, m) {
		let resultado = await api.visitacion.getMensualSegmentado(y, m);
		if (resultado && resultado.status === 200) {
            let data = resultado.data.datos
            setSegmentado1([{'name':'chilenos', 'value': data[0]['chilenos']}, {'name': 'extranjeros', 'value': data[0]['extranjeros']}]);
            setSegmentado2([{'name': 'ch_menor', 'value': data[0]['ch_menor']}, {'name': 'ch_adscte', 'value': data[0]['ch_adscte']}, {'name': 'ch_adulto', 'value': data[0]['ch_adulto']},  {'name': 'ch_mayor', 'value': data[0]['ch_mayor']}, 
                {'name': 'ex_menor', 'value': data[0]['ex_menor']}, {'name': 'ex_adscte', 'value': data[0]['ex_adscte']}, {'name': 'ex_adulto', 'value': data[0]['ex_adulto']},{'name': 'ex_mayor', 'value': data[0]['ex_mayor']}]);

            setChilenos([{'name': 'Niños', 'value': data[0]['ch_menor']}, {'name': 'Adolescente', 'value': data[0]['ch_adscte']}, {'name': 'Adulto', 'value': data[0]['ch_adulto']},  {'name': 'Adulto Mayor', 'value': data[0]['ch_mayor']}]);
            setExtranjeros([{'name': 'Niños', 'value': data[0]['ex_menor']}, {'name': 'Adolescente', 'value': data[0]['ex_adscte']}, {'name': 'Adulto', 'value': data[0]['ex_adulto']},{'name': 'Adulto Mayor', 'value': data[0]['ex_mayor']}]);
		} else {
			toast.error('Error al obtener datos');
		}
	}

    useEffect(
        () => {
            let m = startDate.getMonth() + 1;
            let y =  startDate.getFullYear();
            obtenerDatos(y, m);
            obtenerGraficoRegion(y, m);
            obtenerGraficoSegmentado(y, m);
        },
        [startDate]
    )

    const [activeTab, setActiveTab] = useState('datos');

    return (
        <ErpLayout
            breadcrumbs={[
                    {active: true, pathname: '', name: 'visitación'},
                    {active: true, pathname: `/visitacion`, name: 'Mensual'}]
                }>
            <CRow>
                <CCol>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol>
            <CNav variant="tabs">
                <CNavItem>
                    <CNavLink data-tab="datos" active={activeTab === 'datos'} onClick={() => setActiveTab('datos')}>
                        Datos
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink data-tab="graficos" active={activeTab === 'graficos'} onClick={() => { setActiveTab('graficos') }}>
                        Gráficos
                    </CNavLink>
                </CNavItem>
            </CNav>
            </CCol>
            </CRow>

            <CTabContent>
				<CTabPane data-tab="datos" visible={activeTab === 'datos'}>
                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                            {/* <CButton color="info" onClick={() => setShowFormVisitacion(true)} className="mr-1">Agregar</CButton> */}
                            <GrillaBase
                                // menu={<ContextMenu id="grid-context-menu">
                                //     {/* <MenuItem onClick={menuEditar}> Editar</MenuItem>
                                //     <MenuItem onClick={menuEliminar}> Eliminar</MenuItem> */}
                                // </ContextMenu>}
                                columns={titulos}
                                showCountRows={false}
                                rows={[]}
                                minHeight={35} />
                            <GrillaBase
                                // menu={<ContextMenu id="grid-context-menu">
                                //     {/* <MenuItem onClick={menuEditar}> Editar</MenuItem>
                                //     <MenuItem onClick={menuEliminar}> Eliminar</MenuItem> */}
                                // </ContextMenu>}
                                columns={columns}
                                showCountRows={true}
                                rows={rows} />
                        </CCol>
                    </CRow>
                </CTabPane>
            </CTabContent>

            <CTabContent>
				<CTabPane data-tab="graficos" visible={activeTab === 'graficos'}>
                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                width={500}
                                height={300}
                                data={datosRegion}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="region" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="femenino" stackId="a" fill="#8884d8" />
                                <Bar dataKey="masculino" stackId="a" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                            </div>
                        </CCol>
                    </CRow>

                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                width={500}
                                height={300}
                                data={datosRegion}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="region" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="chilenos" stackId="a" fill="#e55353" />
                                <Bar dataKey="extranjeros" stackId="a" fill="#39f" />
                                <Bar dataKey="femenino" stackId="b" fill="#8884d8" />
                                <Bar dataKey="masculino" stackId="b" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                            </div>
                        </CCol>
                    </CRow>

                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 600 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={500} height={500}>
                            <Pie data={segmentado1} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label />
                            <Tooltip />
                            <Pie data={segmentado2} dataKey="value" cx="50%" cy="50%" innerRadius={160} outerRadius={190} fill="#82ca9d" label />
                            <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                        </div>
                        </CCol>
                    </CRow>

                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 600 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={datosRegion}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="region" />
                                <PolarRadiusAxis />
                                <Radar name="Mike" dataKey="total" stroke="#8884d8" fill="#e55353" fillOpacity={0.6} />
                            </RadarChart>
                        </ResponsiveContainer>
                        </div>
                        </CCol>
                    </CRow>

                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 600 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chilenos}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="name" />
                                <PolarRadiusAxis />
                                <Radar name="Mike" dataKey="value" stroke="#8884d8" fill="#39f" fillOpacity={0.6} />
                            </RadarChart>
                        </ResponsiveContainer>
                        </div>
                        </CCol>
                    </CRow>

                    <CRow >
                        <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                        <div style={{ width: '100%', height: 600 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx="50%" cy="50%" outerRadius="80%" data={extranjeros}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="name" />
                                <PolarRadiusAxis />
                                <Radar name="Mike" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            </RadarChart>
                        </ResponsiveContainer>
                        </div>
                        </CCol>
                    </CRow>
                </CTabPane>
            </CTabContent>
  
        </ErpLayout>
    )
}

export default VisitacionMensual


