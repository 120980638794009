import React, { useEffect, useState, useMemo } from 'react';
import { CCol, CRow, CButton, CNav, CNavItem, CNavLink } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, separadorDeMiles } from '../../componentes/GrillaBase';
import { useParams } from 'react-router-dom';
import { FaPlus, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import { ContextMenu, MenuItem } from 'react-contextmenu';
import ModalEliminar from '../administracion/ModalEliminar'

const GrillaConcesionesAsp = () => {
    let history = useHistory();
    let { area_protegida_id } = useParams();
    const columns = [
        {
            key: 'categoria_manejo', name: 'Categoría Manejo', formatter: LongTextFormatter, width: 170,
            summaryFormatter() {
                return <div style={{ textAlign: 'right' }}><strong>Total</strong></div>;
            }
        },
        { key: 'nombre_servicio', name: 'Tipo Servicio', formatter: LongTextFormatter, width: 150 },
        { key: 'nombre_tipo_servicio', name: 'Servicio Ecoturístico', formatter: LongTextFormatter, minWidth: 200 },
        {
            key: 'monto_total_pago_anual', name: 'Monto Total Pago Anual', width: 150, formatter: NumberFormatter,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.monto_total_pago_anual
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
            }
        },
        {
            key: 'monto_total_ultimo_pago', name: 'Monto Último Pago', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.monto_total_ultimo_pago
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
            }
        },
        { key: 'monto_cuota_fija', name: 'Monto Cuota Fija', formatter: LongTextFormatter, width: 150 },
        { key: 'monto_cuota_variable', name: 'Monto Cuota Variable', formatter: LongTextFormatter, width: 150 },
        { key: 'rut_proveedor', name: 'Rut Proveedor', formatter: LongTextFormatter, width: 120 },
        { key: 'nombre_proveedor', name: 'Nombre Proveedor', formatter: LongTextFormatter, width: 200 },

    ];

    const [rows, setRows] = useState([]);
    const [areaProtegida, setAreaProtegida] = useState([]);
    const [activeTab, setActiveTab] = useState('información');
    const [msgEliminar, setMsgEliminar] = useState('');
    const [showEliminarConcesion, setShowEliminarConcesion] = useState(false);  // Modal para eliminar usuario
    const [concesion, setConcesion] = useState({});

    const summaryRows = useMemo(() => {
        const summaryRow: SummaryRow = {
            id: 'total_0',
            totalCount: rows.length,
            yesCount: rows.filter((r) => r.available).length
        };
        return [summaryRow];
    }, [rows]);

    useEffect(
        () => {
            if (area_protegida_id){
                obtenerDatos()
                obtenerAreaProtegida()
            }
        },
        [area_protegida_id]
    )

    async function obtenerDatos() {
        let resultado = await api.areaProtegida.concesiones(area_protegida_id);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    async function obtenerAreaProtegida() {
        let resultado = await api.areaProtegida.getOne(area_protegida_id);
        if (resultado && resultado.status === 200) {
            setAreaProtegida(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    function agregarConcesion() {
        history.push(`/area-protegida/${area_protegida_id}/concesion/crear`)
    }

    const menuEliminar = (e, { rowIdx }) => {
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id) {
                setConcesion(element)
                setMsgEliminar('¿Estás seguro de eliminar la concesión con la siguiente categoría manejo: ' + element.categoria_manejo + '? ');
                setShowEliminarConcesion(true)
            }
        });
    }

    async function retornoEliminarConcesion() {
        let resultado = await api.concesion.delete(concesion.id);
        if (resultado && resultado.status === 200) {
            setConcesion({})
            obtenerDatos()
            toast.success('Concesion eliminado');
            setShowEliminarConcesion(false)
        } else {
            toast.error(resultado.data.msg);
            setShowEliminarConcesion(false)
        }
    }
    async function menuEditar(e, { rowIdx }) {
        history.push(`/area-protegida/${area_protegida_id}/concesion/${rows[rowIdx].id}`)
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    { active: true, pathname: '', name: 'Servicios Ecoturísticos' },
                    { active: false, pathname: `/concesion`, name: 'Concesiones ASP' },
                    { active: true, pathname: '', name: areaProtegida.nombre }]
                }
            >

                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink data-tab="preview" active={activeTab === 'información'} onClick={() => setActiveTab('información')}>
                            Información
						</CNavLink>
                    </CNavItem>

                </CNav>

                <CRow className="mt-2">
                    <CCol xs="12" sm="12" md="12" lg="12">
                        <CButton color="success" style={{ float: 'right' }} onClick={agregarConcesion} className="mr-1 my-2"><FaPlus /> Agregar concesion </CButton>
                    </CCol>
                </CRow>
                <GrillaBase
                    menu={<ContextMenu id="grid-context-menu">
                        <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
                        <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
                    </ContextMenu>}
                    rows={rows}
                    columns={columns}
                    summaryRows={summaryRows}
                />
            </ErpLayout>
            <ModalEliminar
                titulo="Eliminar"
                visible={showEliminarConcesion}
                concesion={concesion}
                setShow={setShowEliminarConcesion}
                confirmar={retornoEliminarConcesion}
            >
                {msgEliminar}
            </ModalEliminar>
        </>
    )
}

export default GrillaConcesionesAsp