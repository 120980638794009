import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { loading } from '../utils/Api';
import { useForm } from 'react-hook-form';

const CargarArchivosAsp = (props) => {
    const {getValues} = useForm({});

    const actionBase = {
        meta: '',
        file: '',
        remove: ''
    }
    const [action, setAction] = useState(actionBase)

    const reiniciaDatos = () => {
        action.remove()
        setAction(actionBase)
    }
    useEffect(
        () => {
            if (action.file !== ""){
                guardar_excel()
            }
        },
        [action]
    )
    const handleChangeStatus = ({ meta, file, remove }, status) => {

        if (status === 'done') {
            setAction({
                meta: meta,
                file: file,
                remove: remove
            })
            
        } else if (status === 'preparing') {
        } else if (status === 'error_upload') {
            toast.error(`Error al cargar archivo ${meta.name}`);
        }
    }

    async function guardar_excel() {
        let formData = new FormData();
        formData.append("file", action.file);
        formData.append("nombre", getValues().nombre_documento);

        loading(true);
        toast.success(`Procesando archivo, espere unos momentos`);
        const token = localStorage.getItem('token');
        const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/carga_excel_asp`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} });
        ruta.then(respuesta => {
            toast.success(`Archivo cargado ${action.meta.name}`);
            props.retorno(respuesta.data.data);
            reiniciaDatos()
            loading(false);
        }).catch((error) => {
            toast.error(`Error al cargar archivo ${action.meta.name}}`);
            toast.error(`Mensaje: ${error.response.data.msg}`,{autoClose:6000});
            action.remove()
            loading(false);
        });
    }
    return (
        <>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                inputContent="Click para cargar documento"
                maxFiles={1}
                multiple={false}
                accept=".csv,.xls,.xlsm,.xlsx"
            />
        </>
    )
}

export default CargarArchivosAsp