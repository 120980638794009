import React, {useContext} from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
  CImage, CBreadcrumb, CBreadcrumbItem, CHeaderDivider, CTooltip
} from '@coreui/react-pro'
import { Link } from 'react-router-dom'
import CIcon from '@coreui/icons-react';
import Contexto from '../Contexto';
import {permiso, administrador} from '../utils/Funciones';
import { IoMdHelpCircle } from 'react-icons/io';
import { FaExclamationCircle } from 'react-icons/fa';

const AppHeader = (props) => {
  const dispatch = useDispatch()
  const contexto = useContext(Contexto)

  const theme = useSelector((state) => state.theme)

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const sidebarShow = useSelector((state) => state.sidebarShow)
  const asideShow = useSelector((state) => state.asideShow)

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ms-md-3 d-lg-none"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon name="cil-menu" size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon name="logo" height="48" alt="Logo" /> */}
          <CImage src="/logo_vigia.png" width="70px"/>
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem className="px-3">
            <CNavLink to="/home" component={NavLink}>Inicio</CNavLink>
          </CNavItem>
          {/* <CNavItem className="px-3">
            <CNavLink to="/dashboard" component={NavLink}>Dashboard</CNavLink>
          </CNavItem> */}
          {/* <CNavItem className="px-3">
            <CNavLink to="/dashboard" component={NavLink}>Indicadores</CNavLink>
          </CNavItem> */}
          {/* <CNavItem className="px-3">
            <CNavLink to="/reportes" component={NavLink}>Reportes</CNavLink>
          </CNavItem> */}
          {permiso( contexto, 'Archivos', 'ver') &&
            <CNavItem className="px-3">
              <CNavLink to="/archivos" component={NavLink}>Archivos</CNavLink>
            </CNavItem>
      }
          {/* <CNavItem className="px-3">
            <CNavLink to="/asps" component={NavLink}>ASPs</CNavLink>
          </CNavItem> */}
          {/* <CDropdown component="li" variant="nav-item">
            <CDropdownToggle>Departamentos</CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="#"><CIcon name="cil-sitemap" className="mr-2 text-success" /> Gerencia ASPs</CDropdownItem>
              <CDropdownItem href="#"><CIcon name="cil-people" className="mr-2 text-success" /> Depto. de Administración</CDropdownItem>
              <CDropdownItem href="#"><CIcon name="cil-leaf" className="mr-2 text-success" /> Depto. de conservación de la biodiversidad biológica</CDropdownItem>
              <CDropdownItem href="#"><CIcon name="cil-globe-alt" className="mr-2 text-success" /> Depto. de monitoreo y desarrollo</CDropdownItem>
            </CDropdownMenu>
          </CDropdown> */}
        </CHeaderNav>

        {/* <CHeaderNav>
          <AppHeaderDropdownNotif />
          <AppHeaderDropdownTasks />
          <AppHeaderDropdownMssg />
        </CHeaderNav>
        <CHeaderNav className="ms-3 me-4">
          <AppHeaderDropdown />
        </CHeaderNav>
        <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon name="cil-applications-settings" size="lg" />
        </CHeaderToggler> */}

      <CHeaderNav>
        {/* Agregar DropDowns y Botón de configuración */}
        <CDropdown component="li" variant="nav-item">
          <div style={{display:'inline-block'}}>
            <CDropdownToggle style={{display:'inline-block !important'}}>
                {contexto && contexto.data.usuario &&
                  <span style={{paddingTop:'15px', margin: '0px', position:'relative', display: 'inline-block'}}> {contexto.data.usuario.nombre_usuario}</span>
                }
                <CIcon style={{marginLeft: '5px'}} name="cil-settings" className="mr-2 text-success" size="lg"/>
            </CDropdownToggle>
            </div>
            <CTooltip content="Manual de usuario" placement="left">
              <a href={'https://docs.google.com/document/d/1mQuxLvTvlGu8TPfrXsI6YoZbGMyu7jnmhcZD_O1_Niw/edit'} target="_blank" style={{display:'inline-block', fontSize:'22px'}}><IoMdHelpCircle/></a>
            </CTooltip>
            <CTooltip content="Reporte de error o sugerencia" placement="left">
              <a href={'https://forms.gle/NEwskGwtJ7rVZxJs5'} target="_blank"><FaExclamationCircle  style={{display:'inline-block', fontSize:'20px', color:'#ff7700', top: '-2px', position: 'relative'}}/>
              <div style={{display:'inline-block', fontSize:'15px', color:'#ff7700', top: '-1px', position: 'relative'}}>Reportar</div></a>
            </CTooltip>
          <CDropdownMenu>
            <CDropdownItem to="/mi-cuenta" component={NavLink}>Mi cuenta</CDropdownItem>
            <CDropdownDivider />
            {administrador(contexto) &&
              <>
                <CDropdownItem to="/usuarios" component={NavLink}>Usuarios</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem to="/tipo-informacion" component={NavLink}>Tipo información</CDropdownItem>
                <CDropdownDivider />
              </>
            }
            <CDropdownItem to="/logout" component={NavLink}>Cerrar sesión</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>

      </CContainer>
      {/* <AppBreadcrumb /> */}
      {props.breadcrumbs &&
        <>
          <CHeaderDivider />
          <CContainer fluid>
            <CBreadcrumb className="m-0 ms-2">
              {/* <CBreadcrumbItem>
                <Link to='dashboard'>Inicio</Link> 
              </CBreadcrumbItem> */}
              {props.breadcrumbs &&
                <>
                  {props.breadcrumbs.map((breadcrumb, index) => {
                      return (
                        <CBreadcrumbItem
                          {...(breadcrumb.active ? { active: true } : { href: '#' })}
                            key={index}
                        >
                          {breadcrumb.active === false ?
                            <Link to={breadcrumb.pathname}>
                                {breadcrumb.name}
                            </Link> 
                          :
                          <>
                            {breadcrumb.name}
                            </>
                          }
                        </CBreadcrumbItem>
                      )
                  })}
                </>
              }
            </CBreadcrumb>
          </CContainer>
        </>
      }
    </CHeader>
  )
}

export default AppHeader