import React, { useEffect, useState } from 'react';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {  CCol } from '@coreui/react-pro'
import ModalMapaInfraestructura from './ModalMapaInfraestructura';

const MapaMarcadores = (props) => {
	const [primerCarga, setPrimerCarga] = useState(true);
	const [viewport, setViewport] = useState({
		latitude: -43.4095433,
		longitude: -71.6486941,
		zoom: 9
	})
    const [data, setData] = useState({})

	const SIZE = 20;
    const [showModalInfo, setShowModalInfo] = useState(false)
    // const [mostrarTitulo, setMostrarTitul]
    // const [showPopup, togglePopup] = React.useState(true);

	// Seteamos la info por defecto del mapa solo una vez
	useEffect(
		() => {
			if (primerCarga) {
                // si no tiene marcador dejamos por defecto latitud, longitud, este corresponde al area protegida
                // este es para la posición del mapa
                if (props.latitud && props.longitud) {
                    setViewport({
                        ...viewport,
                        latitude: parseFloat(props.latitud),
                        longitude: parseFloat(props.longitud),
                        zoom: props.zoom ? parseFloat(props.zoom) : viewport.zoom
                    });
                }
				setPrimerCarga(false)
			}
		},
		[props.latitud, props.longitud]
	)

    function clickMarcador(info) {
        setData(info)
        setShowModalInfo(true)
    }

	return (
		<>
			<CCol lg={12} style={{ height: window.screen.height - 400 }} className="mt-2">
				<ReactMapGL
					{...viewport}
					width="100%"
					height="100%"
					// onClick={_onClick}
					mapStyle="mapbox://styles/mapbox/streets-v11"
					mapboxApiAccessToken={process.env.REACT_APP_TOKEN_MAPBOX}
					onViewportChange={(viewport) => {
						setViewport(viewport)
					}}>

                    {props.marcadores.map((marcador) =>
                    <>
                        {((marcador.latitud) && (marcador.longitud)) &&
                        <>
                                <Marker
                                    latitude={parseFloat(marcador.latitud)}
                                    longitude={parseFloat(marcador.longitud)}

                                    >
                                    {viewport.zoom > 9 &&
                                        <div style={{position:'absolute', width: '400px', top: '-40px', left: '-20px'}}>{marcador.nombre}</div>
                                    }
                                    <FaMapMarkerAlt
                                        // style={{ color: 'red' }}
                                        height={SIZE}
                                        onClick={() => clickMarcador(marcador)}
                                        // viewBox="0 0 24 24"
                                        style={{
                                            color: props.color ? props.color : 'red',
                                            cursor: 'pointer',
                                            // fill: '#d00',
                                            stroke: 'none',
                                            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                        }} />
                                </Marker>
                            </>
                        }
                    </>
                    )}
                    {/* {showPopup && <Popup
                    latitude={-45.999477}
                    longitude={-73.154311}
                    closeButton={true}
                    closeOnClick={false}
                    onClose={() => togglePopup(false)}
                    anchor="top" >
                    <div>You are here</div>
                    </Popup>} */}
				</ReactMapGL>
			</CCol>
            <ModalMapaInfraestructura
                show={showModalInfo}
                infraestructuraId={data.id}
                close={()=> {
                    setData({})
                    setShowModalInfo(false)
                }}
                retorno={() => {}}/>
		</>
	)
}

export default MapaMarcadores