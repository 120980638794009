import React from 'react';
import { CButton, CModal, CModalBody, CModalHeader, CModalFooter} from '@coreui/react-pro'
import { FaTimes, FaTrashAlt } from 'react-icons/fa';

// import ReactHtmlParser from 'react-html-parser';

function ModalEliminar(props) {

    const handleClose = () => props.setShow(false)

    return (
        <CModal visible={props.visible} onHide={handleClose} closeOnBackdrop={false} keyboard={false}>
            <CModalHeader>{props.titulo}</CModalHeader>

            <CModalBody>
                {props.children &&
                    <>
                        {/* {ReactHtmlParser(props.children)} */}
                        {props.children}
                    </>
                }
            </CModalBody>

            <CModalFooter>
                <CButton color="secondary" size="sm" onClick={handleClose}>
                    <FaTimes/> Cerrar
                </CButton>
                <CButton color="danger" size="sm" onClick={props.confirmar}>
                    <FaTrashAlt/> {props.texto_aceptar ? props.texto_aceptar : 'Eliminar'}
                </CButton>
            </CModalFooter>
        </CModal>
    )
}

export default ModalEliminar;