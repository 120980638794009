import React, { useState, useEffect,useCallback } from 'react';
import { CCol, CRow, CButton, CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react-pro';
import { FaTimes } from 'react-icons/fa';
import FiltroAreaProtegidaTH from '../componentes/FiltroAreaProtegidaTH';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import Buscador from '../componentes/Buscador';
import FiltroTipoInformacion from '../componentes/FiltroTipoInformacion';
import VistaArchivos from '../componentes/VistaArchivos';

const ModalArchivosExistentes = (props) => {
    const {show, close, ...rest} = props;
    const [documentos, setDocumentos] = useState([]);
    const [tipoInformacionSeleccionado, setTipoInformacionSeleccionado] = useState('');
    const [areaProtegida, setAreaProtegida] = useState('');
    const [textoBuscar, setTextoBuscar] = useState('');
    const alto = (window.innerHeight - 200) + 'px';
    const [total, setTotal] = useState('');
    const [parcial, setParcial] = useState('');

    const cargarDocumentos = useCallback(async () => {
        let resultado = await api.documentos.get({ tipo_informacion_id: tipoInformacionSeleccionado, buscador: textoBuscar, area_protegida_id: areaProtegida });
        if (resultado && resultado.status === 200) {
            setDocumentos(resultado.data.datos);
            setTotal(resultado.data.total);
            setParcial(resultado.data.parcial);
        } else {
            toast.error('Error al cargar información');
        }
    }, [tipoInformacionSeleccionado, textoBuscar, areaProtegida]);

    useEffect(
        () => {
            cargarDocumentos();
        }, [tipoInformacionSeleccionado, textoBuscar, areaProtegida, cargarDocumentos]
    )

    const cerrarModal = () => {
        close()
    }

    return (
        <>
            <CModal
                keyboard={false}
                size="xl"
                visible={show}>
                <CModalHeader onDismiss={cerrarModal}>Otros documentos en el sistema</CModalHeader>
                    <CModalBody style={{  overflowY: 'scroll', width: '100%',height: alto }}>
                        <CRow>
                            <CCol xs="12" sm="4" md="4">
                                <Buscador
                                    placeholder='Ingrese un texto para buscar...'
                                    retornoBusqueda={(text) => {
                                        setTextoBuscar(text);
                                    }}
                                />
                            </CCol>
                            <CCol xs="12" sm="4" md="4">
                                <FiltroAreaProtegidaTH
                                    todos={true}
                                    onChange={(e) => {
                                        setAreaProtegida(e ? e.value : '')
                                    }}
                                />
                            </CCol>
                            <CCol xs="12" sm="4" md="4">
                                <FiltroTipoInformacion
                                    todos={true}
                                    onChange={(e) => {
                                        setTipoInformacionSeleccionado(e ? e.value : '')
                                    }}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                            <div><span style={{fontSize: '20px', fontWeight: 500}}>Resultados de la búsqueda: </span> {parcial} de {total} archivos</div>
                            </CCol>
                        </CRow>
                        <div style={{  overflowY: 'scroll', width: '100%',height: '600px' }}>
                            <CRow>
                                <VistaArchivos
                                    documentos={documentos}
                                    seleccionarElemento={(archivo) => {
                                        props.archivoSeleccionado(archivo);
                                    }}
                                    retorno={() => {
                                        cargarDocumentos()
                                    }}
                                    />
                            </CRow>
                        </div>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="light"
                            onClick={cerrarModal}
                        ><FaTimes /> Cerrar</CButton>
                    </CModalFooter>
            </CModal>
        </>
    )
}

export default ModalArchivosExistentes