import React, { useEffect, useState } from 'react';
import { CCol, CRow } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import { useParams } from "react-router-dom";
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import Fecha from '../../componentes/Fecha';
import { useForm } from 'react-hook-form';
import { fechaHoy } from '../../utils/Funciones';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraficoCumplimientoPorComponente = (props) => {
    const { register, errors} = useForm({});
    let { encuesta_id } = useParams();
    const [rows, setRows] = useState([]);
    const [fecha, setFecha] = useState(fechaHoy);
    const [regionId, setRegionId] = useState('');

    useEffect(
        () => {
            obtenerDatos();
        },
        [regionId, fecha]
    )

    async function obtenerDatos() {
        let resultado = await api.encuesta.cumplimientoPorComponente(encuesta_id, {region_id: regionId, fecha: fecha.substring(0,4)});
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }


    return (
        <ErpLayout
            breadcrumbs={[
                {active: true, pathname: ``, name: 'Gráfico por componente'}]
            }>
            <CRow>
                <CCol xs="12" sm="12" md="6" className="mb-2">
                    <FiltroRegionesTH
                        todos={true}
                        onChange={(e) => {
                            setRegionId(e ? e.value : '')
                        }}
                    />
                </CCol>
                <CCol xs="12" sm="12" md="3" className="mb-2">
                    <Fecha
                        name='fecha'
                        label='Año'
                        register={register}
                        errors={errors}
                        dateFormat='yyyy'
                        formatoMesAno={false}
                        dateFormatOculto='y'
                        inicial={fecha}
                        onChange={(fecha) => {
                            setFecha(fecha + '-01-01')
                        }}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2 mt-2" style={{textAlign: 'center'}}>
                    <h5>Porcentaje de cumplimiento total o parcial de los indicadores por Componente </h5>
                </CCol>
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2" style={{overflowX: 'scroll'}}>
                    <div style={{ minWidth: '650px', width: '100%', height: 700 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                layout="vertical"
                                width={500}
                                height={600}
                                data={rows}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                            }}>
                                <CartesianGrid strokeDasharray="4 4" />
                                <XAxis type="number" />
                                <YAxis dataKey="area_protegida_nombre" interval={0} type="category" width={400} fontSize={13} />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="porcentaje_fase_1" stackId="a" fill="#8884d8" name="Fase 1"/>
                                <Bar dataKey="porcentaje_c1_gobernanza" name="C1 Gobernanza" stackId="a" fill="#82ca9d"/>
                                <Bar dataKey="porcentaje_c2_planificacion" name="C2 Planificación" stackId="a" fill="#10B9CD" />
                                <Bar dataKey="porcentaje_c3_manejo" name="C3 Manejo" stackId="a" fill="#CDA510"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CCol>
            </CRow>
        </ErpLayout>
    )
}

export default GraficoCumplimientoPorComponente