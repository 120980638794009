import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { loading } from '../utils/Api';
import ModalErrores from '../componentes/ModalErrores'
import { CCol, CRow, CButton, CModal, CModalBody, CModalHeader, CModalFooter, CAlert } from '@coreui/react-pro'
import { FaSave, FaTimes, FaInfo } from 'react-icons/fa';
import SelectorHoja from '../componentes/SelectorHoja'

const CargarArchivosAsp = (props) => {
    const actionBase = {
        meta: '',
        file: '',
        remove: ''
    }
    const [action, setAction] = useState(actionBase)
    const [modalErrores, setModalErrores] = useState(false)
    const [modalSheet, setModalSheet] = useState(false)
    const [errores, setErrores] = useState([])
    const [nombreHojas, setNombreHojas] = useState([])
    const [hoja, setHoja] = useState('');

    const reiniciaDatos = () => {
        if (action.remove) {
            action.remove()
        }

        setAction(actionBase)
        setHoja('')
        setNombreHojas([])
        setModalSheet(false)
    }
    useEffect(
        () => {
            if (action.file !== "") {
                //guardar_excel()
                getHojas()

            }

        },
        [action]
    )

    useEffect(
        () => {
            if (nombreHojas.length > 0) {
                if (nombreHojas.length == 1) {
                    setHoja(nombreHojas[0].name)
                    guardar_excel(nombreHojas[0].name)
                } else {
                    setModalSheet(true)
                }
            }
        },
        [nombreHojas]
    )

    async function getHojas() {
        let formData = new FormData();
        formData.append("file", action.file);
        loading(true);
        const token = localStorage.getItem('token');
        const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/personal/get-hojas`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` } });
        ruta.then(respuesta => {
            setNombreHojas(respuesta.data.datos)
            loading(false);
        }).catch((error) => {
            setNombreHojas([])
            loading(false)
            toast.error(`Error al cargar el documento`);
        });
    }
    const handleChangeStatus = ({ meta, file, remove }, status) => {

        if (status === 'done' && modalSheet == false) {
            setAction({
                meta: meta,
                file: file,
                remove: remove
            })

        } else if (status === 'preparing') {
        } else if (status === 'error_upload') {
            toast.error(`Error al cargar archivo ${meta.name}`);
        }
    }
    
    async function guardar_excel(hoja_seleccionada) {
        let formData = new FormData();
        formData.append("file", action.file);
        formData.append("fecha", props.fecha);
        formData.append("hoja", hoja_seleccionada);

        loading(true);
        toast.success(`Procesando archivo, espere unos momentos`);
        const token = localStorage.getItem('token');
        const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/carga_excel_personal`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} });
        ruta.then(respuesta => {
            toast.success(`Archivo cargado ${action.meta.name}`);
            props.retorno(respuesta.data.data);
            reiniciaDatos()
            loading(false);
            setModalErrores(false)
        }).catch((error) => {
            reiniciaDatos()
            if (error.response.data.errores) {
                setModalErrores(true)
                setErrores(error.response.data.errores)
            }

            toast.error(`Error al cargar archivo ${action.meta.name}`);
            toast.error(`Mensaje: ${error.response.data.msg}`, { autoClose: 6000 });
            action.remove()
            loading(false);
        });
    }

    const enviaExcel = () => {
        guardar_excel(hoja)
    }

    return (
        <>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                inputContent="Click para cargar documento"
                maxFiles={1}
                multiple={false}
                accept=".csv,.xls,.xlsm,.xlsx"
            />
            <ModalErrores
                visible ={ modalErrores}
                errores ={ errores}
                close={() => {
                    setModalErrores(false)
                }}
            />
            <CModal
                visible={modalSheet}
                onDismiss={() => { reiniciaDatos() }}
                size='lg'
                keyboard={false}
            >
                <CModalHeader onDismiss={() => { reiniciaDatos() }}> <CAlert color="info"><FaInfo />Varias hojas detectadas en el documento</CAlert></CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="4" md="4">
                            <SelectorHoja
                                nombreHojas={nombreHojas}
                                todos={false}
                                onChange={(e) => {
                                    setHoja(e.target.value)
                                }}
                            />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={() => { reiniciaDatos() }}
                    ><FaTimes /> Cerrar </CButton>
                    <CButton
                        color="success"
                        onClick={() => { enviaExcel() }}
                    ><FaSave /> Guardar </CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default CargarArchivosAsp