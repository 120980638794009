import React, { useEffect, useState } from 'react';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import MapaMarcadores from '../../componentes/MapaMarcadores';

const MapaConcesiones = () => {

    const [concesiones, setConcesiones] = useState([]);
    const [activeTab, setActiveTab] = useState('mapa');
    useEffect(
        () => {
            obtenerDatos()
        },
        []
    )

    async function obtenerDatos() {
        let resultado = await api.concesion.getAll();
        if (resultado && resultado.status === 200) {
            setConcesiones(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    { active: true, pathname: '', name: 'Servicios Ecoturísticos' },
                    { active: true, pathname: `/concesiones`, name: 'Concesiones' }]
                }>
                <MapaMarcadores
                    color='green'
                    latitud={-38.4095433}
                    longitud={-71.6486941}
                    latitudMarcador={-43.4095433}
                    longitudMarcador={-71.6486941}
                    zoom={3.1}
                    marcadores={concesiones} />
            </ErpLayout>
        </>
    )
}

export default MapaConcesiones