import React, { useState, useEffect } from 'react'
import { CCol, CRow, CCard, CCardBody, CCardHeader} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, ResponsiveContainer } from 'recharts';
import { NumberGraphicFormatter } from '../../utils/Funciones';


const GraficoAnual = () => {
    const [data_anual, setDataAnual] = useState([])
    const [data_mensual, setDataMensual] = useState([])
    const [data_nacionalidad, setDataNacionalidad] = useState([])
    const [data_genero, setDataGenero] = useState([])

    async function obtenerAnual() {
		let resultado = await api.visitacion.getGraficoAnual();
		if (resultado && resultado.status === 200) {
            setDataAnual((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos anuales');
		}
	}

    async function obtenerMensual() {
		let resultado = await api.visitacion.getGraficoMensual();
		if (resultado && resultado.status === 200) {
            setDataMensual((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos mensuales');
		}
	}

    async function obtenerNacionalidad() {
		let resultado = await api.visitacion.getGraficoNacionalidad();
		if (resultado && resultado.status === 200) {
            setDataNacionalidad((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos nacionalidad');
		}
	}

    async function obtenerGenero() {
		let resultado = await api.visitacion.getGraficoGenero();
		if (resultado && resultado.status === 200) {
            setDataGenero((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos genero');
		}
	}

    useEffect(
        () => {
            obtenerAnual();
            obtenerNacionalidad();
            obtenerMensual();
            obtenerGenero();
        },
        []
    )

    return (
        <ErpLayout
            breadcrumbs={[
                    {active: true, pathname: '', name: 'visitación'},
                    {active: true, pathname: `/visitacion-grafico-anual`, name: 'Gráfico Anual'}]
                }>
            <CRow >
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                    <CCard>
                        <CCardHeader>
                            Total de visitas por año
                        </CCardHeader>
                        <CCardBody>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={data_anual}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="periodo" />
                                    <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                                    <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)}/>
                                    <Legend />
                                    <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow >
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                    <CCard>
                        <CCardHeader>
                            Total de visitas segmentado por nacionalidad
                        </CCardHeader>
                        <CCardBody>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                    width={500}
                                    height={400}
                                    data={data_nacionalidad}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="periodo"  />
                                    <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})}/>
                                    <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)}/>
                                    <Area type="monotone" dataKey="chileno" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                    <Area type="monotone" dataKey="extranjero" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow >
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                    <CCard>
                        <CCardHeader>
                            Total de visitas segmentado por género
                        </CCardHeader>
                        <CCardBody>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                    width={500}
                                    height={400}
                                    data={data_genero}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="periodo" />
                                    <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                                    <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)}/>
                                    <Area type="monotone" dataKey="femenino" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                    <Area type="monotone" dataKey="masculino" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow >
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                    <CCard>
                        <CCardHeader>
                            Total de visitas por mensuales históricas
                        </CCardHeader>
                        <CCardBody>
                            <div style={{ width: '100%', height: 300 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={data_mensual}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="mes" />
                                    <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                                    <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)}/>
                                    <Legend />
                                    <Line type="monotone" dataKey="total" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </ErpLayout>
    )
}

export default GraficoAnual
