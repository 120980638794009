import React from 'react';
import Contenido from './Contenido';
import Header from './Header';
import MenuLateral from './MenuLateral';
import Footer from './Footer';
import {
  CContainer, CRow, CBreadcrumb, CBreadcrumbItem, CLink, CCol
} from '@coreui/react'
import AppSidebar from '../componentes/AppSidebar'
import AppHeader from '../componentes/AppHeader'
import AppFooter from '../componentes/AppFooter'
import AppAside from '../componentes/AppAside'

function ErpLayout(props) {

  return (
    // <div className="c-app c-default-layout">
    //   <MenuLateral/>
    //   <div className="c-wrapper">
    //     <Header/>
    //     <CContainer fluid>
    //       <CRow>
    //         <CCol>
    //           {props.breadcrumb}
    //         </CCol>
    //       </CRow>
    //       <CRow>
    //         <CCol>
    //         {props.children}
    //         </CCol>
    //       </CRow>
    //     </CContainer>
    //     <Footer/>
    //   </div>
    // </div>
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader breadcrumbs={props.breadcrumbs}/>
        <div className="body flex-grow-1 px-3">
          {/* <AppContent /> */}
          <CContainer fluid>
            <CRow>
               <CCol>
            {props.children}
              </CCol>
            </CRow>
          </CContainer>
        </div>
        <AppFooter />
      </div>
      <AppAside />
    </>
  )
}

export default ErpLayout