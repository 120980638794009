import React, { useState } from 'react';
import { CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import ModalEliminar from './ModalEliminar';
import FormArea from './FormArea'

const Area = () => {
  let history = useHistory();

  const columns = [
    { key: 'nombre_area', name: 'Área', formatter: LongTextFormatter},
    { key: 'nombre_sector', name: 'Sector', formatter: LongTextFormatter},
    { key: 'nombre_area_protegida', name: 'ASP', formatter: LongTextFormatter},
  ];

  const [rows, setRows] = useState([
    {'nombre_area_protegida':'Parque Nacional Cerro Castillo','nombre_sector':'Cerro Castillo','nombre_area':'Administracion'},
    {'nombre_area_protegida':'Parque Nacional Torres del Paine','nombre_sector':'Cerro Castillo','nombre_area':'Sector Acampar'}
  ]);
  const [showFormArea, setShowFormArea] = useState(false);  
  const [area, setArea] = useState({});
  const [showEliminarArea, setShowEliminarArea] = useState(false);  
  const [msgEliminar, setMsgEliminar] = useState('');
  // useEffect(
  //   () => {
  //       obtenerDatos()
  //   },
  //   []
  // )

  async function obtenerDatos() {
    let resultado = await api.infraestructura.get();
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }
    async function menuEditar(e, { rowIdx }) {
        // let resultado = await api.usuarios.getOne(rows[rowIdx].id);
        // if (resultado && resultado.status === 200) {
        //     setUsuario(resultado.data.datos);
        //     setShowFormUsuario(true)
        // } else {
        //     setUsuario({});
        //     toast.error('Error al obtener datos');
        // }
        setShowFormArea(true)
    }

    const menuEliminar = (e, {rowIdx}) => {
        setArea({})
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id){
                setArea(element)
                setMsgEliminar('¿Estás seguro de eliminar el area ' + element.nombre_area + '? ');
                setShowEliminarArea(true)
            }
        });
    }
    function cerrarModalUsuario(){
        setShowFormArea(false)
        // obtenerDatos()
    }
    async function retornoEliminarArea() {
        // let resultado = await api.usuarios.delete(usuario.id);
        // if (resultado && resultado.status === 200) {
        //     setUsuario({})
        //     obtenerDatos()
        //     toast.success('Usuario eliminado');
        //     setShowEliminarUsuario(false)
        // } else {
        //     toast.success(resultado.data.msg);
        //     setShowEliminarUsuario(false)
        // }
        setShowEliminarArea(false)
    }
    function functionRetorno(data) {
        // obtenerDatos()
        // setShowFormUsuario(false)
        // console.log('data', data)
    }
  return (
    <>
      <ErpLayout
        breadcrumbs={[
          {active: true, pathname: '', name: 'Administración'},
          {active: true, pathname: `/area`, name: 'Área'}]
      }>
        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12">
                <CButton color="success" style={{float:'right'}} onClick={() => setShowFormArea(true)} className="mr-1 my-2"><FaPlus/> Agregar Área </CButton>
            </CCol>
        </CRow>
        <GrillaBase
            menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuEditar}> Editar</MenuItem>
                <MenuItem onClick={menuEliminar}> Eliminar</MenuItem>
            </ContextMenu>}
            columns={columns}
            rows={rows}
        />
        <FormArea
            visible={showFormArea}
            area={area}
            onHide={cerrarModalUsuario}
            close={()=> {
                setArea({})
                setShowFormArea(false)
                // obtenerDatos()
            }}
            retorno={functionRetorno}/>
        <ModalEliminar
            titulo="Eliminar"
            visible={showEliminarArea}
            area={area}
            setShow={setShowEliminarArea}
            confirmar={retornoEliminarArea}
            >
                {msgEliminar}
        </ModalEliminar>

      </ErpLayout>
    </>
  )
}

export default Area