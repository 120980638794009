import React, { useState, useEffect} from 'react';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { CFormGroup, CLabel} from '@coreui/react'
import Select from 'react-select';

function FiltroPeriodosTH(props) {
    const { cargado, todos, noSeleccionado, onChange, valorInicial, isClearable,  ...rest } = props;
    const [informacion, setInformacion] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadingSelect, setLoadingSelect] = useState(true);
    const [valor, setValor] = useState('');

    useEffect(
        () => {
            function loadData() {
                setInformacion([]);
                api.poa.periodos().then(
                    resultado => {
                        if (resultado && resultado.status === 200) {
                            let temporal  = [];
                            if (todos) {
                                temporal.push({ value: '', label: 'Todos' })
                            }
                            resultado.data.datos.forEach(dato => {
                                temporal.push({ value: dato, label: dato })
                            });
                            setInformacion(temporal)
                            if (cargado) {
                                cargado(resultado.data.datos)
                            }
                            setLoadingSelect(false)
                            // setValor(temporal[0])
                        } else {
                            let temporal = [];
                            if (todos) {
                                temporal.push({ value: '', label: 'Todos' })
                            }
                            setInformacion(temporal);
                            toast.error('Error al cargar');
                            setLoadingSelect(false)
                        }
                    }
                )

            }
            if (informacion.length === 0 && loading === false) { // Loading = false, solo para cargar una vez
                setLoading(true);
                loadData();
            }
            if (informacion.length > 0 && valorInicial !== '') {
                setValor({ value: valorInicial, label: valorInicial });
            }
        },
        [informacion, cargado, loading]
    )

    function handleChange(selectedOption) {
        setValor(selectedOption);
        if (onChange) {
            onChange(selectedOption);
        }
    };

    return (
        <>
            <CFormGroup>
                <CLabel htmlFor="appendedInputButton">Periodo</CLabel>
                <Select
                    placeholder={todos ? 'Todos' : ''}
                    isClearable={isClearable ? isClearable : false}
                    isLoading={loadingSelect}
                    value={valor}
                    onChange={handleChange}
                    options={informacion}
                />
            </CFormGroup>
        </>
    );
}

export default FiltroPeriodosTH;