import React, { useEffect, useState } from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter} from '@coreui/react-pro'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';


const FormUsuario = (props) => {
    const { register, handleSubmit, errors, setValue, reset } = useForm({});
    const { sector, ...rest } = props;
    const [ nombreModal, setNombreModal ] = useState('');

    useEffect(
        () => {
            if (sector.nombre) {
                obtenerDatos();
                setNombreModal('Editar sector')
            } else {
                reset()
                setNombreModal('Crear sector')
            }
        },
        [sector, setValue, reset,nombreModal]
    )

    async function obtenerDatos() {
        let resultado = await api.areaProtegida.getOne(rest.area_protegida_id);//rows[rowIdx].id
        if (resultado && resultado.status === 200) {
            let area = resultado.data.datos;
            setValue([
                { area_protegida_id: area.id },
                { nombre_asp: area.nombre },
                { nombre_sector: sector.nombre},
            ]);
        }
    }

    function cerrarModal(){
        props.onHide();
    }
    const onSubmit = async (data) => {
        let resultado;
        if (sector.id){
		    resultado = await api.sector.put(rest.area_protegida_id,sector.id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    resultado = await api.sector.post(rest.area_protegida_id,data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
                cerrarModal()
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }
    
	return (
        <CModal
            visible={rest.visible}
            onDismiss={rest.close}
            size='lg'
            keyboard={false}
            >
            <CModalHeader onDismiss={rest.close}>{nombreModal}</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre</CFormLabel>
                                    <CFormControl 
                                        type="text"
                                        name="nombre_sector"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_sector" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>
                            {/* <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Categoría Accesibilidad Universal</CFormLabel>

                                    <select class="form-select" aria-label="Default select example">
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <ErrorMessage errors={errors} name="correo" as="div" className="invalid-feedback" />
                                </CCol>

                            </CRow> */}
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={rest.close}
                    ><FaTimes/> Cerrar </CButton>
                    <CButton
                        color="success"
                        type="submit"
                        ><FaSave/> Guardar </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
	)
}

export default FormUsuario


