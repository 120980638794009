import React, { useEffect, useState } from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react-pro'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import { FaSave } from 'react-icons/fa';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import SelectorRegionTHRHF from '../componentes/SelectorRegionTHRHF';
import SelectorProvincia from '../componentes/SelectorProvincia';
import SelectorComuna from '../componentes/SelectorComuna';
import Fecha from '../componentes/Fecha';
import { Link, useParams } from 'react-router-dom';
import { IoMdReturnLeft } from 'react-icons/io';
import ErpLayout from '../layout/ErpLayout'
import Sectores from './administracion/Sectores';

const FormAsp = (props) => {
    let { area_protegida_id } = useParams();

    const [areaProtegida, setAreaProtegida] = useState({});
    const [nombreFormulario, setNombreFormulario] = useState('');
    const [regionId, setRegionId] = useState('');
    const [nombreProvincia, setNombreProvincia] = useState('');
    const [activeTab, setActiveTab] = useState('información');
    const [ocultaTab, setOcultaTab] = useState(false)

    const { register, handleSubmit, errors, setValue, control, reset } = useForm({})

    useEffect(
        () => {
            if (area_protegida_id) {
                obtenerDatos()
                setNombreFormulario('Editar ASP')
            } else {
                setRegionId('')
                setNombreFormulario('Crear ASP')
                setOcultaTab(true)
            }
        },
        [area_protegida_id]
    )

    async function obtenerDatos() {
        let resultado = await api.areaProtegida.getOne(area_protegida_id);
        if (resultado && resultado.status === 200) {
            setAreaProtegida(resultado.data.datos)
            let asp = resultado.data.datos
            setValue([
                { id: asp.id },
                { nombre: asp.nombre },
                { codigo: asp.codigo },
                { region_id: asp.region_id },
                { superficie: asp.superficie },
                { provincia: asp.provincia },
                { decreto: asp.decreto },
                { categoria: asp.categoria },
                { fecha: asp.fecha_promulgacion }
            ]);
        } else {
            setAreaProtegida({});
            toast.error('Error al obtener datos');
        }
    }

    const onSubmit = async (data) => {
        let resultado;
        if (area_protegida_id) {
            resultado = await api.areaProtegida.put(area_protegida_id, data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
            resultado = await api.areaProtegida.post(data);
            if (resultado && resultado.status === 200) {
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }

    return (
        <ErpLayout
            breadcrumbs={[
                { active: true, pathname: '', name: 'Administración' },
                { active: false, pathname: `/asp`, name: 'ASP' },
                { active: true, pathname: ``, name: areaProtegida.nombre }]
            }>
            <CNav variant="tabs">
                <CNavItem>
                    <CNavLink data-tab="preview" active={activeTab === 'información'} onClick={() => setActiveTab('información')}>
                        Información
						</CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink data-tab="metadata" active={activeTab === 'sectores'} disabled={ocultaTab} onClick={() => {
                        setActiveTab('sectores')
                    }}>
                        Sectores
						</CNavLink>
                </CNavItem>
            </CNav>
            <CTabContent>
                <CTabPane data-tab="preview" visible={activeTab === 'información'}>
                    <CForm onSubmit={handleSubmit(onSubmit)}>
                        <CCard className="mb-4 mt-2">
                            <CCardHeader>{nombreFormulario}</CCardHeader>
                            <CCardBody>
                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                    <CFormLabel >Área Protegida</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                    <SelectorRegionTHRHF
                                        iniciar={true}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        opcionTodos={true}
                                        setValue={setValue}
                                        name='region'
                                        opcionGeneral={false}
                                        onChange={(value) => {
                                            if (value) {
                                                setRegionId(value.value)
                                                setNombreProvincia('')
                                            } else {
                                                setRegionId('')
                                            }
                                        }}
                                        placeholder='No seleccionado'
                                        seleccionadoId={areaProtegida.region_id ? areaProtegida.region_id : ''} // id del elemento seleccionado
                                    />
                                </CCol>
                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                    <SelectorProvincia
                                        iniciar={true}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        opcionTodos={false}
                                        setValue={setValue}
                                        name='provincia'
                                        opcionGeneral={false}
                                        onChange={(value) => {
                                            if (value) {
                                                setNombreProvincia(value.value)
                                            } else {
                                                setNombreProvincia('')
                                            }
                                        }}
                                        placeholder='No seleccionado'
                                        seleccionadoId={areaProtegida.provincia ? areaProtegida.provincia : ''} // id del elemento seleccionado
                                        region_id={regionId}
                                    />
                                </CCol>
                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                    <SelectorComuna
                                        iniciar={true}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        opcionTodos={false}
                                        setValue={setValue}
                                        name='comuna'
                                        opcionGeneral={false}
                                        placeholder='No seleccionado'
                                        seleccionadoId={areaProtegida.comuna ? areaProtegida.comuna : ''} // id del elemento seleccionado
                                        nombre_provincia={nombreProvincia}
                                        region_id={regionId}
                                    />
                                </CCol>
                                <CRow>
                                    <CCol xs="12" sm="4" md="4" className="mb-2">
                                        <CFormLabel >Decreto</CFormLabel>
                                        <CFormControl
                                            type="text"
                                            name="decreto"
                                            placeholder=""
                                            ref={
                                                register({
                                                    //required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        {/* <ErrorMessage errors={errors} name="decreto" as="div" className="invalid-feedback" /> */}
                                    </CCol>
                                    <CCol xs="12" sm="4" md="4" lg="4" className="mb-2">
                                        <CFormLabel >Categoria</CFormLabel>
                                        <CFormControl
                                            type="text"
                                            name="categoria"
                                            placeholder=""
                                            ref={
                                                register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="categoria" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="12" sm="4" md="4" lg="4" className="mb-2">
                                        <CFormLabel >CODSIG</CFormLabel>
                                        <CFormControl
                                            type="text"
                                            name="codigo"
                                            placeholder=""
                                            ref={
                                                register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="codigo" as="div" className="invalid-feedback" />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol xs="12" sm="6" md="6" lg="6">
                                        <Fecha
                                            name='fecha_promulgacion'
                                            label='Fecha de promulgación'
                                            register={register}
                                            errors={errors}
                                            dateFormat='dd/MM/yyyy'
                                            formatoMesAno={false}
                                            dateFormatOculto='y-m-d'
                                            inicial={areaProtegida.fecha_promulgacion}
                                            onChange={() => { }}
                                        />
                                    </CCol>
                                    <CCol xs="12" sm="6" md="6" lg="6">
                                        <CFormLabel >Superficie</CFormLabel>
                                        <CFormControl
                                            type="number"
                                            name="superficie"
                                            placeholder=""
                                            ref={
                                                register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })
                                            }
                                        />
                                        <ErrorMessage errors={errors} name="superficie" as="div" className="invalid-feedback" />
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                        <CCard className="mt-2">
                            <CCardBody>
                                <CRow>
                                    <CCol lg={12}>
                                        <Link to={'/asp'}>
                                            <CButton className="mt-2 float-start" size="lg" color="light"><IoMdReturnLeft /> Volver</CButton>
                                        </Link>
                                        <CButton className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave /> Guardar</CButton>
                                    </CCol>
                                </CRow>
                            </CCardBody>

                        </CCard>
                    </CForm>
                </CTabPane>
            </CTabContent>
            <CTabPane data-tab="preview" visible={activeTab === 'sectores'} >
                <Sectores
                    area_protegida_id={area_protegida_id}
                />
            </CTabPane>
        </ErpLayout>
    )
}

export default FormAsp