import React, { useEffect, useState } from 'react';
import { CRow, CCol, CButton } from '@coreui/react-pro';
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter } from '../../componentes/GrillaBase';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import FiltroServicio from '../../componentes/FiltroServicio';
import SelectorAreaProtegidaTHRHF from '../../componentes/SelectorAreaProtegidaTHRHF';
import { exportar } from '../../utils/Funciones';
import { FaRegFileExcel } from 'react-icons/fa';


const GrillaReporteServicioEcoturistico = () => {
	const { errors, setValue, control, watch } = useForm({});
  const [servicioId, setServicioId] = useState('');
  const [regionId, setRegionId] = useState('');
	const [areaProtegidaId, setAreaProtegidaId] = useState('')
  const [rows, setRows] = useState([]);

  const columns = [
    { key: 'nombre', name: 'Servicio Ecoturístico', minWidth: 200 },
    { key: 'cantidad', name: 'Cantidad', formatter: NumberFormatter, width: 100 },
    { key: 'nombre_servicio', name: 'Nombre servicio', width: 150 },
    { key: 'nombres_asp', name: 'Nombre ASP', width: 400 },
    { key: 'nombres_region', name: 'Nombre Región', width: 400 },
    { key: 'monto_total_pago_anual_tipo', name: 'Monto total pago anual', formatter: NumberFormatter, width: 150 },
  ];

  useEffect(
    () => {
      obtenerDatos()
    },
    [servicioId, regionId, areaProtegidaId]
  )

  async function obtenerDatos() {
    let resultado = await api.tipoServicio.getAll({
      area_protegida_id: areaProtegidaId,
      region_id: regionId,
      servicio_id: servicioId
    });
    if (resultado && resultado.status === 200) {
      let tipoServicios = resultado.data.datos
      console.table(tipoServicios);
      setRows(tipoServicios)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  function exportarResumenASP(){
    exportar(`api/exportar/tipo-servicio?area_protegida_id=${areaProtegidaId}&region_id=${regionId}&servicio_id=${servicioId}`);
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          { active: true, pathname: '', name: 'Servicios Ecoturísticos' },
          { active: true, pathname: `/servicio-ecoturistico`, name: 'Reporte Servicios Ecoturísticos' }]
        }>
        <CRow className='mb-2' >
          <CCol xs="12" sm="6" md="2" className="mb-2">
            <FiltroServicio
              todos={true}
              onChange={(e) => {
                setServicioId(e ? e.value : '')
              }}
            />
          </CCol>
          <CCol xs="12" sm="6" md="3" className="mb-2">
            <FiltroRegionesTH
              todos={true}
              onChange={(e) => {
                setRegionId(e ? e.value : '')
              }}
            />
          </CCol>
          <CCol xs="12" sm="9" md="4" className="mb-2">
            <SelectorAreaProtegidaTHRHF
              iniciar={true}
              control={control}
              Controller={Controller}
              ErrorMessage={ErrorMessage}
              errors={errors}
              opcionTodos={true}
              setValue={setValue}
              name='area_protegida_id'
              opcionGeneral={false}
              placeholder='No seleccionado'
              seleccionadoId={areaProtegidaId} // id del elemento seleccionado
              onChange={(seleccionado) => {
                if (seleccionado) {
                  setAreaProtegidaId(seleccionado.value)
                } else {
                  setAreaProtegidaId("")
                }
              }}
            />
          </CCol>
          <CCol sm="3" md="3">
            <CButton
                color="light"
                onClick={exportarResumenASP}
                className="mt-4 float-sm-end"
                ><FaRegFileExcel />
                    Exportar
            </CButton>
          </CCol>
        </CRow>
        <GrillaBase
          className="mt-2"
          rows={rows}
          columns={columns}
        />
      </ErpLayout>
    </>
  )
}

export default GrillaReporteServicioEcoturistico