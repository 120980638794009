import React, { useState } from 'react'
import { CCol, CRow, CButton, CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react-pro'
import { FaTimes} from 'react-icons/fa';
import CargarArchivoPersonal from '../componentes/CargarArchivoPersonal'
import FiltroAreaProtegidaTH from '../componentes/FiltroAreaProtegidaTH'
import Fecha from '../componentes/Fecha';
import { fechaHoy } from '../utils/Funciones';
import { useForm } from 'react-hook-form';
const FormPersonal = (props) => {
    const { register, errors } = useForm({});
    const [fecha,setFecha] = useState(fechaHoy)

    return (
        <>
            <CModal
                visible={props.visible}
                onDismiss={props.close}
                size='lg'
            >
                <CModalHeader onDismiss={props.close}> Instrucciones para cargar el archivo</CModalHeader>
                <CModalBody>
                    <ol>
                        {/* <li>En la primera fila y columna del documento se debe indicar la fecha en el siguiente formato: <b>dd/mm/yyyy</b></li>
                        <br></br> */}
                        <li>
                            <span>El nombre de cada columna debe ser <b>exactamente</b> el siguiente:</span>
                            <ul>
                                <li style={{fontSize:16}}>PROCESO, ROL, DV, APELLIDO_PATERNO, APELLIDO_MATERNO, NOMBRES, C.F., DIAS_TRABAJADOS, DURAC_CTTO, F.INICIO, F.TERMINO, 
                                    FONDO, REGION, PROVINCIA, UNIDAD, ESTAMENTO, UBICACION, DEPENDENCIA, CARGO, TOTAL M$
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span>Considere escribir el nombre de la <b>UNIDAD</b> lo más exacta posible, a continuación tiene una lista con todas las áreas protegidas disponibles:</span>
                            <CCol xs="12" sm="12" md="12">
                                <FiltroAreaProtegidaTH
                                    todos={true}
                                />
                            </CCol>
                        </li>
                        <li>
                            <CCol xs="12" sm="6" md="6">
                                <Fecha
                                    name='fecha'
                                    label='Seleccione la fecha del documento'
                                    register={register}
                                    errors={errors}
                                    dateFormat='dd/MM/yyyy'
                                    formatoMesAno={false}
                                    dateFormatOculto='y-m-d'
                                    inicial={fechaHoy()}
                                    onChange={(fecha) => {
                                        setFecha(fecha)
                                        console.log(fecha)
                                    }}
                                />
                            </CCol>
                        </li>
                    </ol>

                    <CRow >
                        <CCol >
                            <CargarArchivoPersonal
                            fecha={fecha}
                            retorno={(archivo) => {
                                props.close()
                            }} />
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color="light" onClick={props.close}>
                        <FaTimes /> Cerrar</CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default FormPersonal