import React, { useState, useEffect } from 'react'
import {
    CCol,
    CRow,
} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter } from '../../componentes/GrillaBase';
// import { ContextMenu, MenuItem } from 'react-contextmenu';
import { TextEditor } from 'react-data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';


const VisitacionAnual = () => {
    const columns = [
        { key: 'nombre', name: 'ASP'},
        { key: 'ene', name: 'Enero', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'feb', name: 'Febrero', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'mar', name: 'Marzo', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'abr', name: 'Abril', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'may', name: 'Mayo', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'jun', name: 'Junio', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'jul', name: 'Julio', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'ago', name: 'Agosto', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'sep', name: 'Septiembre', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'oct', name: 'Octubre', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'nov', name: 'Noviembre', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'dic', name: 'Diciembre', width: 80, formatter: NumberFormatter, editor: TextEditor },
        { key: 'total', name: 'Total', width: 80, formatter: NumberFormatter, editor: TextEditor },
    ];

    const [rows, setRows] = useState([])
    const [startDate, setStartDate] = useState(new Date());


    async function obtenerDatos(y) {
		let resultado = await api.visitacion.getAnual(y);
		if (resultado && resultado.status === 200) {
            setRows((resultado.data.datos))
		} else {
			toast.error('Error al obtener datos');
		}
	}

    useEffect(
        () => {
            let y =  startDate.getFullYear();
            obtenerDatos(y);
        },
        [startDate]
    )

    return (
        <ErpLayout
            breadcrumbs={[
                    {active: true, pathname: '', name: 'visitación'},
                    {active: true, pathname: `/visitacion`, name: 'Anual'}]
                }>
            <CRow>
                <CCol>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                    yearItemNumber={9}
                />
                </CCol>
            </CRow>
            <CRow >
                <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                    {/* <CButton color="info" onClick={() => setShowFormVisitacion(true)} className="mr-1">Agregar</CButton> */}
                    <GrillaBase
                        // menu={<ContextMenu id="grid-context-menu">
                        //     {/* <MenuItem onClick={menuEditar}> Editar</MenuItem>
                        //     <MenuItem onClick={menuEliminar}> Eliminar</MenuItem> */}
                        // </ContextMenu>}
                        columns={columns}
                        showCountRows={true}
                        rows={rows} />
                </CCol>
            </CRow>

        </ErpLayout>
    )
}

export default VisitacionAnual
