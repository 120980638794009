import React, { useState, useEffect } from 'react'
import { CCol, CRow, CButton} from '@coreui/react-pro'
import ErpLayout from '../../layout/ErpLayout'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import FormTipoInformacion from './FormTipoInformacion';
import FormCategoriaTipoInformacion from './FormCategoriaTipoInformacion';
import { FaPlus, FaPen } from 'react-icons/fa';

const Usuarios = () => {
    const [rows, setRows] = useState([]);
    const [showFormTipoInformacion, setShowFormTipoInformacion] = useState(false);  // Modal para crear/editar tipo información
    const [hijo, setHijo] = useState({});
    const [showFormCategoriaTipoInformacion, setShowFormCategoriaTipoInformacion] = useState(false);  // Modal para crear/editar categoria
    const [categoria, setCategoria] = useState({});

    useEffect(
        () => {
            obtenerDatos();
        },
        []
    )

    async function obtenerDatos() {
		let resultado = await api.tipoInformacion.get({agrupado: true});
		if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos)
		} else {
			toast.error('Error al obtener datos');
		}
	}

    function editarHijo(data) {
        setHijo(data)
        setShowFormTipoInformacion(true)
    }

    function editarCategoria(data) {
        setCategoria(data)
        setShowFormCategoriaTipoInformacion(true)
    }

	return (
		<ErpLayout
            breadcrumbs={[
                {active: true, pathname: '', name: 'Administración'},
                {active: true, pathname: `/usuarios`, name: 'Tipo de información'}]
            }
        >
			<CRow>
				<CCol xs="12" sm="12" md="12" lg="12">
                    <CButton color="success" style={{float:'right', marginLeft: '5px'}} onClick={() => setShowFormCategoriaTipoInformacion(true)} className="mr-1 my-2"><FaPlus/> Agregar categoría </CButton>
                    <CButton color="success" style={{float:'right'}} onClick={() => setShowFormTipoInformacion(true)} className="mr-1 my-2"><FaPlus/> Agregar tipo de información </CButton>
                </CCol>
			</CRow>
            <CRow>
				<CCol xs="12" sm="12" md="12" lg="12">
                    {
                        rows.map((categoria, index) => (
                            <ul>
                                <h5>{categoria.nombre} <CButton size="sm" color="warning" style={{padding:'0px 5px', marginLeft: '10px'}} onClick={() => editarCategoria(categoria)} className=""><FaPen/> Editar </CButton></h5>
                                {categoria.hijos.map((hijo, index) => (
                                    <li key={index}>{hijo.nombre}
                                        <CButton size="sm" color="warning" style={{padding:'0px 5px', marginLeft: '10px'}} onClick={() => editarHijo(hijo)} className=""><FaPen/> Editar </CButton>
                                    </li>
                                ))}
                            </ul>
                        ))
                    }
				</CCol>
			</CRow>
            <FormTipoInformacion
                visible={showFormTipoInformacion}
                tipoInformacionProps={hijo}
                cerrar={()=> {
                    setHijo({})
                    setShowFormTipoInformacion(false)
                    obtenerDatos()
                }}/>
            <FormCategoriaTipoInformacion
                visible={showFormCategoriaTipoInformacion}
                tipoInformacionProps={categoria}
                cerrar={()=> {
                    setCategoria({})
                    setShowFormCategoriaTipoInformacion(false)
                    obtenerDatos()
                }}
                />
		</ErpLayout>
	)
}

export default Usuarios