import React, { useEffect, useState } from 'react'
import { CCol, CRow, CForm, CFormLabel, CFormControl, CButton, CModal, CModalBody, CModalHeader, CModalFooter} from '@coreui/react-pro'
import { useForm, ErrorMessage } from 'react-hook-form'
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { FaSave, FaTimes } from 'react-icons/fa';

const FormTipoArea = (props) => {
    const { register, handleSubmit, errors, setValue, reset } = useForm({
        defaultValues: {
        }
    });
    const { tipoArea, ...rest } = props;
    const [ nombreModal, setNombreModal ] = useState('');
    const [areaProtegida, setAreaProtegida] = useState('');

    useEffect(
        () => {
            if (Object.keys(tipoArea).length > 0) {
                setValue([
                    // { nombre_area_protegida: area.nombre_area_protegida },
                    // { nombre_sector: area.nombre_sector },
                    // { nombre_area: area.nombre_area },
                ]);
                setNombreModal('Editar el tipo de área')
            } else {
                reset()
                setNombreModal('Crear el tipo de área')
            }
        },
        [tipoArea, setValue, reset]
    )

    function cerrarModal(){
        props.onHide();
    }
    const onSubmit =  (data) => {
        console.log(data)
    }
	return (
        <CModal
            visible={rest.visible}
            onDismiss={rest.close}
            size='lg'
            keyboard={false}
            >
            <CModalHeader onDismiss={rest.close}>{nombreModal}</CModalHeader>
            <CForm onSubmit={handleSubmit(onSubmit)}>
                <CModalBody>
                    <CRow>
                        <CCol xs="12" sm="12" md="12" lg="12">
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_sector"
                                        placeholder="Tipo de area"
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_sector" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre Unidad</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_sector"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_sector" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre Segunda Unidad</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_sector"
                                        placeholder=""
                                        ref={
                                            register({
                                                required: { value: true, message: "campo obligatorio" }
                                            })
                                        }
                                    />
                                    <ErrorMessage errors={errors} name="nombre_sector" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="light"
                        onClick={rest.close}
                    ><FaTimes/> Cerrar </CButton>
                    <CButton
                        color="success"
                        type="submit"
                        ><FaSave/> Guardar </CButton>
                </CModalFooter>
            </CForm>
        </CModal>
	)
}

export default FormTipoArea


