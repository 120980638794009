import React, { useState, useEffect } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CForm, CTabContent, CTabPane, CCard, CCardHeader, CCardBody, CNav, CNavItem, CNavLink } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { IoMdReturnLeft } from 'react-icons/io';
import { useParams, Link } from "react-router-dom";
import api from '../../utils/Routes';
import Fecha from '../../componentes/Fecha';
import ErpLayout from '../../layout/ErpLayout';
import SelectorAreaProtegidaMulti from '../../componentes/SelectorAreaProtegidaMulti';
import CargarDocumento from '../../componentes/CargarDocumento';


const FormInvestigacion = () => {
    const { register, handleSubmit, errors, setValue, control } = useForm({});
    const [areasProtegidas, setAreasProtegidas] = useState();
	const [areasProtegidasId, setAreasProtegidasId] = useState([]);
    const [ investigacion, setInvestigacion ] = useState({});
	const [fechaAutorizacion, setFechaAutorizacion] = useState('');
	const [fechaInicio, setFechaInicio] = useState('');
	const [fechaTermino, setFechaTermino] = useState('');
	const [plazoEnvio, setPlazoEnvio] = useState('');
	const [año, setAño] = useState('');
	const [labelAreaTematica, setLabelAreaTematica] = useState(false)
	const [activeTab, setActiveTab] = useState('investigación');
	const [documentos, setDocumentos] = useState([]);
	const [enviarArchivos, setEnviarArchivos] = useState(false);

	let { id } = useParams();

    useEffect(
        () => {
            if (id) {
                obtenerDatos();
            }
        },
        [id]
    )

    const guardar = async (data) => {
        if (data.area_protegida.length === 0){
            toast.error('Debe seleccionar al menos una area protegida')
            return false
        }
        if (data.area_tematica === 'Otro'){
            data.area_tematica = data.otro_area_tematica
        }

        if (investigacion.id){
	        let resultado = await api.investigacion.put(investigacion.id, data);
            if (resultado && resultado.status === 200) {
                setEnviarArchivos(true)
                toast.success('Cambios almacenados');
            } else {
                toast.error(resultado.data.msg);
            }
        } else {
		    let resultado = await api.investigacion.post(data);
            if (resultado && resultado.status === 200) {
                setEnviarArchivos(true)
                toast.success('Cambios almacenados');
                setInvestigacion(resultado.data.data)
            } else {
                toast.error(resultado.data.msg);
            }
        }
    }

    const obtenerDatos = async () => {
        let resultado = await api.investigacion.getOne(id, {});
        if (resultado && resultado.status === 200) {
            let inv = resultado.data.datos;

            setInvestigacion(inv)
			setDocumentos(inv.documentos);
            const apId = inv.areas_protegidas_id.split(', ');
            setAreasProtegidasId(apId)

            setValue([
                { nombre: inv.nombre},
                { numero_autorizacion: inv.numero_autorizacion},
                { numero_solicitud: inv.numero_solicitud},
                { estado: inv.estado},
                { ano: inv.ano},
                { area_tematica: inv.area_tematica},
                { areas_protegidas: inv.areas_protegidas},
                { regiones_nombres: inv.regiones_nombres},
                { objetivo: inv.objetivo},
                { institucion: inv.institucion},
                { responsable: inv.responsable},
                { correo: inv.correo},
                { telefono: inv.telefono},
                { fecha_autorizacion: inv.fecha_autorizacion},
                { fecha_inicio: inv.fecha_inicio},
                { fecha_termino: inv.fecha_termino},
            ]);

            setFechaAutorizacion(inv.fecha_autorizacion)
            setFechaInicio(inv.fecha_inicio)
            setFechaTermino(inv.fecha_termino)
            setAño(inv.ano + '-01-01')
            const areasPorDefecto = ['Geología', 'Cambio climático', 'Arqueología',
                                'Antropología', 'Turismo', 'Gestión de áreas protegidas']
            if (areasPorDefecto.indexOf(inv.area_tematica) === -1){
                setLabelAreaTematica(true)
                setValue([
                    {area_tematica: 'Otro'},
                    {otro_area_tematica: inv.area_tematica},
                ])
            } else {
                setLabelAreaTematica(false)
                setValue([{area_tematica: inv.area_tematica}])
            }

        } else {
            if (resultado){
                toast.error(`Error: ${resultado.data.msg}`);
            }
        }
    }
    return (
        <ErpLayout
            breadcrumbs={[
                { active: true, pathname: '', name: 'Investigación' },
                { active: false , pathname: '/investigacion', name:'Listado de investigación'},
                { active: true, pathname: '', name: 'Formulario' },
            ]}
        >
            <CForm onSubmit={handleSubmit(guardar)}>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink data-tab="preview" active={activeTab === 'investigación'} onClick={() => setActiveTab('investigación')}>
                            Investigación
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink data-tab="metadata" active={activeTab === 'documentos'} onClick={() => setActiveTab('documentos')}>
                            Documentos
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane data-tab="preview" visible={activeTab === 'investigación'}>
                        <CCard className="mb-4 mt-2">
                            <CCardHeader style={{ textAlign: 'center' }}>
                                Investigación
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol xs="12" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Nombre del proyecto de investigación</CFormLabel>
                                            <CFormControl
                                                type="text"
                                                name="nombre"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol  xs="6" md="4" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">N° autorización</CFormLabel>
                                            <CFormControl
                                                type="number"
                                                name="numero_autorizacion"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="numero_autorizacion" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="6" md="4" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">N° solicitud</CFormLabel>
                                            <CFormControl
                                                type="number"
                                                name="numero_solicitud"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="numero_solicitud" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="6" md="4" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Estado</CFormLabel>
                                            <select
                                                className="form-select"
                                                name="estado"
                                                ref={register({
                                                    required: { message: "campo obligatorio" }
                                                })}>
                                                <option value="En tramitación">En tramitación</option>
                                                <option value="En proceso">En proceso</option>
                                                <option value="Informe pendiente">Informe pendiente</option>
                                                <option value="Sin información">Sin información</option>
                                                <option value="Ampliación de plazo">Ampliación de plazo</option>
                                                <option value="Finalizada">Finalizada</option>
                                            </select>
                                        <ErrorMessage errors={errors} name="estado" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="6" md="3" className="mb-2">
                                        <Fecha
                                            name='ano'
                                            label='Año'
                                            register={register}
                                            errors={errors}
                                            dateFormat='yyyy'
                                            formatoMesAno={false}
                                            dateFormatOculto='y'
                                            inicial={año}
                                        />
                                    </CCol>
                                    <CCol xs="12" md="9" className="mb-2">
                                        <CRow>
                                            <CCol xs="6" md="6" className="mb-2">
                                                <CFormLabel htmlFor="nf-email">Área temática de investigación</CFormLabel>
                                                    <select
                                                        onChange={(seleccionado) => {
                                                            if (seleccionado.target.value === 'Otro') {
                                                                setLabelAreaTematica(true)
                                                            } else {
                                                                setLabelAreaTematica(false)
                                                            }
                                                        }}
                                                        className="form-select"
                                                        name="area_tematica"
                                                        ref={register({
                                                            required: { message: "campo obligatorio" }
                                                        })}>
                                                        <option value="Geología">Geología</option>
                                                        <option value="Cambio climático">Cambio climático</option>
                                                        <option value="Arqueología">Arqueología</option>
                                                        <option value="Antropología">Antropología</option>
                                                        <option value="Turismo">Turismo</option>
                                                        <option value="Gestión de areas protegidas">Gestión de areas protegidas</option>
                                                        <option value="Otro">Otro</option>
                                                    </select>
                                                <ErrorMessage errors={errors} name="area_tematica" as="div" className="invalid-feedback" />
                                            </CCol>
                                            {labelAreaTematica &&
                                                <CCol xs="6" sm="6" md="6" className="mb-2">
                                                    <CFormLabel htmlFor="nf-email">Otra área temática</CFormLabel>
                                                    <CFormControl
                                                        type="text"
                                                        name="otro_area_tematica"
                                                        placeholder="Indique área temática"
                                                        ref={register({
                                                            required: { value: true, message: "campo obligatorio" }
                                                        })}
                                                    />
                                                    <ErrorMessage errors={errors} name="otro_area_tematica" as="div" className="invalid-feedback" />
                                                </CCol>
                                            }
                                        </CRow>
                                    </CCol>
                                    <CCol xs="12" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Objetivo</CFormLabel>
                                            <CFormControl
                                                component="textarea"
                                                name="objetivo"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="objetivo" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol md='12' className="mb-2">
                                        <SelectorAreaProtegidaMulti
                                            control={control}
                                            Controller={Controller}
                                            ErrorMessage={ErrorMessage}
                                            errors={errors}
                                            iniciar={true}
                                            name='area_protegida'
                                            opcionGeneral={false}
                                            placeholder='No seleccionado'
                                            seleccionadosId={areasProtegidasId} // id de elementos seleccionado
                                            defaultValue={areasProtegidasId}
                                            setValue={setValue}
                                            onChange={(seleccionado) => {
                                                if (seleccionado) {
                                                    let ids = []
                                                    seleccionado.forEach(e => {
                                                        ids.push(e.value)
                                                    });
                                                    setAreasProtegidasId(ids)
                                                } else {
                                                    setAreasProtegidas("")
                                                }
                                            }}
                                        />
                                    </CCol>
                                    <CCol xs="7" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Institución patrocinante</CFormLabel>
                                            <CFormControl
                                                type="text"
                                                name="institucion"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="institucion" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="5" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Responsable</CFormLabel>
                                            <CFormControl
                                                type="text"
                                                name="responsable"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="responsable" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="7" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Correo</CFormLabel>
                                            <CFormControl
                                                type="text"
                                                name="correo"
                                                placeholder=""
                                                ref={register({
                                                    required: { value: true, message: "campo obligatorio" }
                                                })}
                                            />
                                        <ErrorMessage errors={errors} name="correo" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="5" className="mb-2">
                                        <CFormLabel htmlFor="nf-email">Teléfono</CFormLabel>
                                            <CFormControl
                                                type="text"
                                                name="telefono"
                                                placeholder=""
                                                ref={register({})}
                                            />
                                        <ErrorMessage errors={errors} name="telefono" as="div" className="invalid-feedback" />
                                    </CCol>
                                    <CCol xs="4" className="mb-2">
                                        <Fecha
                                            name='fecha_autorizacion'
                                            label='Fecha Autorización'
                                            register={register}
                                            dateFormat='dd/MM/yyyy'
                                            dateFormatOculto='y-m-d'
                                            inicial={fechaAutorizacion}
                                            onChange={() => {}}
                                        />
                                    </CCol>
                                    <CCol xs="4" className="mb-2">
                                        <Fecha
                                            name='fecha_inicio'
                                            label='Fecha Inicio'
                                            register={register}
                                            dateFormat='dd/MM/yyyy'
                                            dateFormatOculto='y-m-d'
                                            inicial={fechaInicio}
                                            onChange={() => {}}
                                        />
                                    </CCol>
                                    <CCol xs="4" className="mb-2">
                                        <Fecha
                                            name='fecha_termino'
                                            label='Fecha Termino'
                                            register={register}
                                            dateFormat='dd/MM/yyyy'
                                            dateFormatOculto='y-m-d'
                                            inicial={fechaTermino}
                                            onChange={() => {}}
                                        />
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CTabPane>
                    <CTabPane data-tab="preview" visible={activeTab === 'documentos'}>
						<CRow className="mt-2">
							<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
								<CargarDocumento
									documentos={documentos} // documentos que ya tiene la entidad en base de datos
									enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
									entidad_nombre={'Investigación'}
									entidad_id={investigacion.id}
									enviado={() => { // cuando se enviaron todos los archivos
										setEnviarArchivos(false)
									}}
									cambioArchivos={(data) => {
                                        // Es el estado interno de los documentos si quiere guardar utilice otro estado para no crear un ciclo infinito
									}} />
							</CCol>
						</CRow>
					</CTabPane>
                    <CCard className="mt-2">
						<CCardBody>
							<CRow>
								<CCol lg={12}>
									<Link to={'/investigacion/'}>
										<CButton className="mt-2 float-start" size="lg" color="light"><IoMdReturnLeft /> Volver</CButton>
									</Link>
									<CButton className="mt-2 float-end" size="lg" color="success" type="submit"><FaSave /> Guardar</CButton>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
                </CTabContent>
            </CForm>
        </ErpLayout>
    )
}

export default FormInvestigacion