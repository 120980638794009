import React, { useEffect, useState } from 'react';
import {CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus } from 'react-icons/fa';
import ModalEliminar from './ModalEliminar';
import FormSectores from './FormSectores'
import FormArea from './FormArea'

const Sectores = (props) => {
  var area_protegida_id = props.area_protegida_id
  const columns = [
    { key: 'nombre', name: 'Nombre sector', formatter: LongTextFormatter},
    { key: 'nombre_areas', name: 'Area(s)', formatter: LongTextFormatter},
  ];

  const [rows, setRows] = useState([]);
  const [showFormSectores, setShowFormSectores] = useState(false);  // Modal para crear/editar sectores
  const [area, setArea] = useState({});
  const [showFormArea, setShowFormArea] = useState(false);  // Modal para crear/editar areas
  const [sector, setSector] = useState({});
  const [showEliminarSectores, setShowEliminarSectores] = useState(false);  // Modal para eliminar sectores
  const [msgEliminar, setMsgEliminar] = useState('');

  useEffect(
    () => {
      if (area_protegida_id){
        obtenerDatos()
      }
    },
    [area_protegida_id,sector,showFormArea]
  )

  async function obtenerDatos() {
    let resultado = await api.areaProtegida.sectores(area_protegida_id,{areas:true});
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }
  
  async function menuEditar(e, { rowIdx }) {
      let resultado = await api.sector.getOne(rows[rowIdx].id);
      if (resultado && resultado.status === 200) {
          setSector(resultado.data.datos);
          setShowFormSectores(true)
      } else {
          setSector({});
          toast.error('Error al obtener datos');
      }
      setShowFormSectores(true)
  }

  async function menuAgregarArea(e, { rowIdx }) {
    let resultado = await api.sector.getOne(rows[rowIdx].id);
    if (resultado && resultado.status === 200) {
        setSector(resultado.data.datos);
        setShowFormArea(true)
    } else {
        setSector({});
        toast.error('Error al obtener datos');
    }
}

  const menuEliminar = (e, {rowIdx}) => {
      setSector({})
      rows.forEach(element => {
          if (element.id === rows[rowIdx].id){
              setSector(element)
              setMsgEliminar('¿Estás seguro de eliminar el sector ' + element.nombre + '? ');
              setShowEliminarSectores(true)
          }
      });
  }
  
  function cerrarModalSectores(){
      setShowFormSectores(false)
      setSector({});
      // obtenerDatos()
  }
  function cerrarModalArea(){
    setShowFormArea(false)
    setSector({});
    // obtenerDatos()
}
  async function retornoEliminarSectores() {
      let resultado = await api.sector.delete(sector.id);
      if (resultado && resultado.status === 200) {
          setSector({})
          obtenerDatos()
          toast.success('Sector eliminado');
          setShowEliminarSectores(false)
      } else {
          toast.success(resultado.data.msg);
          setShowEliminarSectores(false)
      }
  }
  function functionRetorno(data) {
      // obtenerDatos()
      // setShowFormUsuario(false)
      // console.log('data', data)
  }
  return (
    <>

        <CRow>
            <CCol xs="12" sm="12" md="12" lg="12">
                <CButton color="success" style={{float:'right'}} onClick={() => setShowFormSectores(true)} className="mr-1 my-2"><FaPlus/> Agregar Sector </CButton>
                <CButton color="success" style={{float:'right',marginRight:'5px'}} onClick={() => setShowFormArea(true)} className="mr-1 my-2"><FaPlus/> Agregar Área </CButton>
            </CCol>
        </CRow>
        <GrillaBase
            menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuAgregarArea}> Agregar Área</MenuItem>
                <MenuItem onClick={menuEditar}> Editar Sector</MenuItem>
                <MenuItem onClick={menuEliminar}> Eliminar</MenuItem>
            </ContextMenu>}
            columns={columns}
            rows={rows}
        />
        <FormSectores
            area_protegida_id = {area_protegida_id}
            visible={showFormSectores}
            sector={sector}
            onHide={cerrarModalSectores}
            close={()=> {
                setSector({})
                setShowFormSectores(false)
                // obtenerDatos()
            }}
            retorno={functionRetorno}/>
        <FormArea
            visible={showFormArea}
            area={area}
            area_protegida_id = {area_protegida_id}
            sector_id = {sector.id}
            onHide={cerrarModalArea}
            close={()=> {
                setArea({})
                setShowFormArea(false)
                // obtenerDatos()
            }}
            retorno={functionRetorno}/>
        <ModalEliminar
            titulo="Eliminar"
            visible={showEliminarSectores}
            sector={sector}
            setShow={setShowEliminarSectores}
            confirmar={retornoEliminarSectores}
            >
                {msgEliminar}
        </ModalEliminar>


    </>
  )
}

export default Sectores