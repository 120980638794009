import React, { useEffect, useState, useMemo } from 'react';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, NumberFormatter, separadorDeMiles, DateFormatter } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import { CRow, CCol, CButton, } from '@coreui/react-pro';
import { FaPlus, FaRegFileExcel, FaTimes, FaPencilAlt } from 'react-icons/fa';
import ModalEliminar from '../administracion/ModalEliminar'
import { exportar } from '../../utils/Funciones';

const Concesiones = () => {
    let history = useHistory();
    const columns = [
        { key: 'nombre_region', name: 'Región', width: 300 },
        { key: 'nombre_area_protegida', name: 'ASP', minWidth: 200 },
        {
            key: 'categoria_manejo', name: 'Categoría Manejo', width: 130,
            summaryFormatter() {
                return <div style={{ textAlign: 'right' }}><strong>Total</strong></div>;
            }
        },
        {
            key: 'monto_total_pago_anual', name: 'Monto Pago Anual', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.monto_total_pago_anual
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
            }
        },
        {
            key: 'monto_total_ultimo_pago', name: 'Monto Último Pago', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.monto_total_ultimo_pago
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
            }
        },
        { key: 'tipo_contrato', name: 'Tipo contrato', width: 150 },
        { key: 'tipo_servicio', name: 'Tipo servicio', width: 200 },
        { key: 'rut_proveedor', name: 'Rut Proveedor', width: 150 },
        { key: 'nombre_proveedor', name: 'Proveedor', width: 150 },
        { key: 'fecha_inicio', name: 'Fecha Inicio', width: 100, formatter: DateFormatter },
        { key: 'fecha_termino', name: 'Fecha Término', width: 100, formatter: DateFormatter },
    ];
    const [activeTab, setActiveTab] = useState('información');
    const [rows, setRows] = useState([]);
    const [msgEliminar, setMsgEliminar] = useState('');
    const [showEliminarConcesion, setShowEliminarConcesion] = useState(false);  // Modal para eliminar usuario
    const [concesion, setConcesion] = useState({});
    const summaryRows = useMemo(() => {
        const summaryRow = [];
        return [summaryRow];
    }, [rows]);

    useEffect(
        () => {
            obtenerDatos()
        },
        []
    )

    async function obtenerDatos() {
        let resultado = await api.concesion.getAll();
        if (resultado && resultado.status === 200) {
            const concesiones = resultado.data.datos
            setRows(concesiones)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    const menuEliminar = (e, { rowIdx }) => {
        rows.forEach(element => {
            if (element.id === rows[rowIdx].id) {
                setConcesion(element)
                setMsgEliminar('¿Estás seguro de eliminar la concesión con la siguiente categoría manejo: ' + element.categoria_manejo + '? ');
                setShowEliminarConcesion(true)
            }
        });
    }

    async function retornoEliminarConcesion() {
        let resultado = await api.concesion.delete(concesion.id);
        if (resultado && resultado.status === 200) {
            setConcesion({})
            obtenerDatos()
            toast.success('Concesion eliminado');
            setShowEliminarConcesion(false)
        } else {
            toast.error(resultado.data.msg);
            setShowEliminarConcesion(false)
        }
    }

    function menuEditar(e, { rowIdx }) {
        history.push({
            pathname: `/area-protegida/${rows[rowIdx].area_protegida_id}/concesion/${rows[rowIdx].id}`,
            state: { detail: true }
        });

    }

    function agregarConcesion() {
        history.push(`/concesion/crear`)
    }

    function exportarConcesion(){
        exportar(`api/exportar/concesion`);
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    { active: true, pathname: '', name: 'Servicios Ecoturísticos' },
                    { active: true, pathname: `/concesiones`, name: 'Concesiones' }]
                }>
                <CRow>
                    <CCol xs="12" sm="12" md="12" lg="12">
                        <CButton
                            color="success"
                            style={{ float: 'right' }}
                            onClick={agregarConcesion}
                            className="mr-1 my-2"
                            ><FaPlus />
                                Agregar concesión / permiso
                        </CButton>
                        <CButton
                            color="light"
                            onClick={exportarConcesion}
                            className="mt-2 float-sm-end mx-1"
                            ><FaRegFileExcel />
                                Exportar
                        </CButton>
                    </CCol>
                </CRow>
                <GrillaBase
                    menu={<ContextMenu id="grid-context-menu">
                        <MenuItem onClick={menuEditar}><FaPencilAlt/> Editar</MenuItem>
                        <MenuItem onClick={menuEliminar}><FaTimes/> Eliminar</MenuItem>
                    </ContextMenu>}
                    rows={rows}
                    columns={columns}
                    summaryRows={summaryRows}
                />

                <ModalEliminar
                    titulo="Eliminar"
                    visible={showEliminarConcesion}
                    concesion={concesion}
                    setShow={setShowEliminarConcesion}
                    confirmar={retornoEliminarConcesion}
                >
                    {msgEliminar}
                </ModalEliminar>
            </ErpLayout>
        </>
    )
}

export default Concesiones