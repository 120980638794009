import React, { useEffect, useState } from 'react';
import { CCol, CRow, CNav,CTabContent, CTabPane, CNavItem, CNavLink } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter } from '../../componentes/GrillaBase';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { NumberGraphicFormatter } from '../../utils/Funciones';

const ReporteTipoInstalacion = () => {
    const [activeTab, setActiveTab] = useState('reporte');
    const columns = [
        { key: 'nombre', name: 'Tipo Instalación', formatter: LongTextFormatter },
        { key: 'infraestructuras', name: 'Infraestructuras', formatter: LongTextFormatter },
        { key: 'total_inversion', name: 'Total Inversión', formatter: NumberFormatter, width: 100 },
        { key: 'anual_mantencion', name: 'Mantención Anual', formatter: NumberFormatter, width: 100 },
        { key: 'anual_operacion', name: 'Operación Anual', formatter: NumberFormatter, with: 100 },
    ];
    const [rows, setRows] = useState([]);

    useEffect(
        () => {
            obtenerDatos();
        },
        []
    )

    async function obtenerDatos() {
        let resultado = await api.infraestructura.reporte_tipo_instalacion();
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }


  return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Infraestructura'},
                    {active: true, pathname: `/poa`, name: 'Reporte Tipo Instalación'}]
                }>
                <CRow className='mb-2'>
                    <CCol xs="12" sm="6" md="3">
                        {/* Filtros del reporte! */}
                    </CCol>
                </CRow>
                <CNav variant="tabs">
					<CNavItem>
						<CNavLink data-tab="preview" active={activeTab === 'reporte'} onClick={() => setActiveTab('reporte')}>
							Reporte
						</CNavLink>
					</CNavItem>
                    <CNavItem>
						<CNavLink data-tab="preview" active={activeTab === 'grafico'} onClick={() => setActiveTab('grafico')}>
							Gráfico
						</CNavLink>
					</CNavItem>
				</CNav>
                <CTabContent>
					<CTabPane data-tab="preview" visible={activeTab === 'reporte'}>
                        <GrillaBase
                            rows={rows}
                            columns={columns}
                        />
                    </CTabPane>
                    <CTabPane data-tab="preview" visible={activeTab === 'grafico'}>
                        <CRow >
                            <CCol xs="12" sm="12" md="12" lg="12" className="py-2">
                            <div style={{ width: '100%', height: 600 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                width={500}
                                height={600}
                                data={rows}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="nombre" />
                                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" tickFormatter={value => NumberGraphicFormatter({value:value})}/>
                                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                <Legend />
                                <Bar yAxisId="left" dataKey="total_inversion" name="Total inversión" fill="#8884d8" />
                                <Bar yAxisId="right" dataKey="infraestructuras" name="Infraestructuras" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                            </div>
                            </CCol>
                        </CRow>
                    </CTabPane>
                </CTabContent>
            </ErpLayout>
        </>
  )
}

export default ReporteTipoInstalacion