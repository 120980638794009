import React, { useEffect, useState, useMemo, useContext } from 'react';
import { CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, CenterAlign, separadorDeMiles } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FaPlus, FaPencilAlt, FaEye, FaRegFileExcel, FaTrash } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import FormPoa from './FormPoa';
import Buscador from '../../componentes/Buscador';
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import FiltroPeriodosTH from '../../componentes/FiltroPeriodosTH';
import { exportar, getYearNow } from '../../utils/Funciones';
import ModalEliminar from '../administracion/ModalEliminar';
import UserContext from '../../Contexto'


const GrillaPoa = () => {
    let history = useHistory();
    const columns = [
        { key: 'periodo', name: 'Periodo', formatter: CenterAlign, width: 30},
        { key: 'area_protegida', name: 'Unidad', formatter: LongTextFormatter, minWidth: 300 },
        { key: 'region', name: 'Región', formatter: LongTextFormatter, width: 300},
        { key: 'actividades_cantidad', name: 'Actividades', formatter:CenterAlign,  width:90,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.actividades_cantidad
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
        }},
        { key: 'presupuesto_conaf', name: 'Presupuesto CONAF', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let totalConaf = 0;
                rows.forEach(data => {
                    totalConaf += data.presupuesto_conaf
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(totalConaf)}</div>;
        }},
        { key: 'presupuesto_terceros', name: 'Aporte terceros', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let totalTerceros = 0;
                rows.forEach(data => {
                    totalTerceros += data.presupuesto_terceros
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(totalTerceros)}</div>;
        }},
        { key: 'presupuesto', name: 'Total presupuesto', formatter: NumberFormatter, width: 150,
            summaryFormatter({ row }) {
                let total = 0;
                rows.forEach(data => {
                    total += data.presupuesto
                });
                return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
        }},
        { key: 'responsable', name: 'Responsable', formatter: LongTextFormatter,width:150 },
        { key: 'porcentaje_avance', name: '% Avance', formatter: CenterAlign, width:100 },
    ];
    const [rows, setRows] = useState([]);
    const [poa, setPoa] = useState({});
    const [showFormPoa, setShowFormPoa] = useState(false);
    const [textoBuscar, setTextoBuscar] = useState('');
    const [regionId, setRegionId] = useState('');
    const [periodo, setPeriodo] = useState(getYearNow());
    const summaryRows = useMemo(() => {
        const summaryRow = [];
        return [summaryRow];
    }, [rows]);
    const [showEliminar, setShowEliminar] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    const contexto = useContext(UserContext)

    useEffect(
        () => {
            obtenerDatos();
        },
        [textoBuscar, regionId, periodo]
    )

    async function obtenerDatos() {
        let resultado = await api.poa.get({actividades: true, texto: textoBuscar, region_id: regionId, periodo: periodo});
        if (resultado && resultado.status === 200) {
            let datos = []
            datos.push({
                id: 1,
                periodo: 2021,
                area_protegida: 'Parque Nacional la Campana',
                region: 'Valparaíso',
                actividades: 4,
                presupuesto: '280.000.000',
                cumplimiento: '98%',
                responsable: 'Félix Forno'

            }, {
                id: 1,
                periodo: 2021,
                area_protegida: 'Monumento Natural Picaflor de Arica',
                region: 'Arica y Parinacota',
                actividades: 10,
                presupuesto: '200.000.000',
                cumplimiento: '80%',
                responsable: 'Félix Forno'

            },{
                id: 1,
                periodo: 2021,
                area_protegida: 'Parque Nacional Alerce Costero',
                region: 'Los Ríos',
                actividades: 6,
                presupuesto: '150.000.000',
                cumplimiento: '50%',
                responsable: 'Félix Forno'

            },{
                id: 1,
                periodo: 2021,
                area_protegida: 'Reserva Nacional Torres del Paine',
                region: 'Magallanes y la Antártica Chilena',
                actividades: 43,
                presupuesto: '350.000.000',
                cumplimiento: '90%',
                responsable: 'Félix Forno'

            })
            resultado.data.datos.forEach(poaData => {
                poaData.actividades_cantidad = poaData.actividades.length;
                let total = 0;
                let totalTerceros = 0;
                let totalConaf = 0;
                poaData.actividades.forEach(actividadData => {
                    total += actividadData.total_presupuesto || 0
                    totalTerceros += actividadData.fondo_terceros || 0
                    totalConaf += actividadData.total_conaf || 0
                });
                poaData.presupuesto = total;
                poaData.presupuesto_terceros = totalTerceros;
                poaData.presupuesto_conaf = totalConaf;
            });
            setRows(resultado.data.datos)
        } else {
            toast.error('Error al obtener datos');
        }
    }

    const menuEditar = (e, { rowIdx }) => {
        history.push(`/poa/${rows[rowIdx].id}/elaboracion`);
    }

    const menuEditarPoa = (e, { rowIdx }) => {
        setPoa(rows[rowIdx]);
        if (rows[rowIdx].usuario_id === contexto.data.usuario.id || contexto.data.usuario.administrador === true){
            setShowFormPoa(true);
        } else {
            toast.error('No cuenta con permisos para editar este registro');
        }
    }

    const menuEliminar = (e, { rowIdx }) => {
        setPoa(rows[rowIdx]);
        if (rows[rowIdx].usuario_id === contexto.data.usuario.id || contexto.data.usuario.administrador===true){
            setMsgEliminar(`Está seguro que desea eliminar ${rows[rowIdx].area_protegida}, periodo ${rows[rowIdx].periodo}?`);
            setShowEliminar(true);
        } else {
            toast.error('No cuenta con permisos para eliminar este registro');
        }
    }

    async function confirmarEliminar() {
        let resultado = await api.poa.delete(poa.id);
        if (resultado && resultado.status === 200) {
            toast.success('POA eliminado correctamente');
            setShowEliminar(false);
            obtenerDatos();
        } else {
            toast.error('Error al eliminar poa');
        }
    }

    function exportarPoa(){
        exportar(`api/exportar/poa?actividades=true&texto=${textoBuscar}&region_id=${regionId}&periodo=${periodo}`);
    }

    return (
        <>
            <ErpLayout
                breadcrumbs={[
                    {active: true, pathname: '', name: 'Planificación'},
                    {active: true, pathname: `/poa`, name: 'Poa'}]
                }>
                <CRow className='mb-2'>
                    <CCol xs="12" sm="6" md="3">
                        <Buscador
                            titulo='Búsqueda'
                            placeholder='Búsqueda por texto'
                            retornoBusqueda={(text) => {
                                setTextoBuscar(text);
                            }}
                        />
                    </CCol>
                    <CCol xs="12" sm="6" md="3" className="mb-2">
                        <FiltroRegionesTH
                            todos={true}
                            onChange={(e) => {
                                setRegionId(e ? e.value : '')
                            }}
                        />
                    </CCol>
                    <CCol xs="12" sm="6" md="2" className="mb-2">
                        <FiltroPeriodosTH
                            todos={true}
                            onChange={(e) => {
                                setPeriodo(e ? e.value : '')
                            }}
                            valorInicial={periodo}
                            cargado={(data)=>{}}
                        />
                    </CCol>
                    <CCol xs="12" sm="6" md="4" className="mb-2">
                        <CButton
                            color="success"
                            className="mt-4 float-sm-end"
                            onClick={() => {
                                setPoa({})
                                setShowFormPoa(true)
                            }}
                            ><FaPlus />
                                Nuevo
                        </CButton>
                        <CButton
                            color="light"
                            onClick={exportarPoa}
                            className="mt-4 float-sm-end mx-1"
                            ><FaRegFileExcel />
                                Exportar
                        </CButton>
                    </CCol>
                </CRow>
                <GrillaBase
                    rows={rows}
                    columns={columns}
                    menu={<ContextMenu id="grid-context-menu">
                        <MenuItem onClick={menuEditarPoa}><FaPencilAlt/> Editar Poa</MenuItem>
                        <MenuItem onClick={menuEditar}><FaEye/> Ver Elaboración</MenuItem>
                        <MenuItem onClick={menuEliminar}><FaTrash style={{top: '-3px', position: 'relative'}}/> Eliminar</MenuItem>
                    </ContextMenu>}
                    summaryRows={summaryRows}
                />
                <FormPoa
                    show={showFormPoa}
                    poaId={poa.id}
                    close={()=> {
                        setPoa({})
                        setShowFormPoa(false)
                        obtenerDatos()
                    }}
                    retorno={() => {}}/>
            </ErpLayout>
            <ModalEliminar
                titulo="Eliminar"
                visible={showEliminar}
                setShow={setShowEliminar}
                confirmar={confirmarEliminar}>
                    {msgEliminar}
                    <br/>
                    Una vez eliminada la información no se podrá recuperar.
            </ModalEliminar>
        </>
    )
}

export default GrillaPoa