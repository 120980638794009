import React, { useEffect, useState } from 'react';
import { CBreadcrumb, CBreadcrumbItem, CCol, CRow, CButton } from '@coreui/react-pro';
import ErpLayout from '../layout/ErpLayout';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter,DateFormatter } from '../componentes/GrillaBase';
import FormPersonal from './FormPersonal'
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import { FaEye } from 'react-icons/fa';

const PreviaPersonal = () => {
  const [cargaDocumento, setCargaDocumento] = useState(false);
  let history = useHistory();

  const columns = [
    { key: 'fecha_documento', name: 'Fecha documento', formatter: DateFormatter, width: 200 },
    { key: 'fecha_carga', name: 'Fecha de carga', formatter: DateFormatter,width:200 },
    { key: 'usuario_nombre', name: 'Usuario', formatter: LongTextFormatter },
    { key: 'nombre_archivo', name: 'Nombre Archivo', formatter: LongTextFormatter,minWidth:100},
    { key: 'count', name: 'Total', formatter: LongTextFormatter,width:150 },
  ];

  const [rows, setRows] = useState([]);
  useEffect(
    () => {
        obtenerDatos()
    },
    [cargaDocumento]
  )

  async function obtenerDatos() {
    let resultado = await api.documentosPersonal.get();
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datosasd');
    }
  }

  function nuevoDocumento() {
    setCargaDocumento(true)
  }

  async function menuVisualizar(e, { rowIdx }) {
    if (rows[rowIdx].fecha_documento){
      history.push(`/personal/${rows[rowIdx].id}`)
    }else{
      toast.error('Error al obtener la fecha del documento');
    }
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          {active: true, pathname: '', name: 'Administración'},
          {active: true, pathname: `/previa-personal`, name: 'Personal'}]
      }>
        <CRow >
          <CCol >
            <CButton color="info" style={{float:'right',color:'white'}} onClick={nuevoDocumento} className="mr-1 my-2">Click para cargar el documento</CButton>
          </CCol>
        </CRow>
        <GrillaBase
            menu={<ContextMenu id="grid-context-menu">
                <MenuItem onClick={menuVisualizar}><FaEye/> Visualizar</MenuItem>
            </ContextMenu>}
            rows={rows}
            columns={columns}
        />
        <FormPersonal
          visible={cargaDocumento}
          close={() => {
            setCargaDocumento(false)
          }}/>
      </ErpLayout>
    </>
  )
}

export default PreviaPersonal