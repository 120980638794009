import React  from 'react';
import { CButton, CModal, CModalBody,CModalFooter, CModalHeader,CAlert } from '@coreui/react-pro';
import { GrillaBase, LongTextFormatter } from '../componentes/GrillaBase';
import {FaTimes, FaInfo } from 'react-icons/fa';

const ModalErrores = (props) => {

    const columns = [
        { key: 'fila', name: 'Fila', formatter: LongTextFormatter, width: 10 },
        { key: 'columna', name: 'Columna', formatter: LongTextFormatter, width: 150 },
        { key: 'contenido', name: 'Contenido', formatter: LongTextFormatter,width:300 },
        { key: 'mensaje', name: 'Mensaje de error', formatter: LongTextFormatter },
    ];

    return (
        <>
            <CModal
                visible={props.visible}
                onDismiss={props.close}
                size='xl'
            >
                <CModalHeader onDismiss={props.close}> <CAlert color="danger"><FaInfo />Error al cargar el archivo, a continuación listamos todos los errores:</CAlert></CModalHeader>
                <CModalBody>

                    <GrillaBase
                        rows={props.errores}
                        columns={columns}
                    />
                </CModalBody>
                <CModalFooter>

                    <CButton color="light" onClick={props.close}>
                        <FaTimes /> Cerrar</CButton>
                </CModalFooter>

            </CModal>
        </>
    )
}

export default ModalErrores