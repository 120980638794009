import React, { useState } from 'react'

const Contexto = React.createContext(
  {data: {},
  setData: () => {}})

export const UserConsumer = Contexto.Consumer

export const UserProvider = (props) => {

  const setData = (data) => {
    setState({...state, data: data})
  }

  const initState = {
      data: {},
      setData: setData
  }

  const [state, setState] = useState(initState)

  return (
    <Contexto.Provider value={state}>
      {props.children}
    </Contexto.Provider>
  )
}

export default Contexto