import React, { useState, useEffect } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter, CTabContent, CTabPane, CNavItem, CNav, CNavLink, CFormSelect } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import { useForm, ErrorMessage } from 'react-hook-form';
import { FaSave, FaTimes } from 'react-icons/fa';
import api from '../../utils/Routes';
import CargarDocumento from '../../componentes/CargarDocumento';
import { mesTexto } from '../../utils/Funciones';

const FormEjecucion = (props) => {
	const {show, planificacionId, actividadId, poaId, cerrar, ...rest} = props;
	const [planificacion, setPlanificacion] = useState({});
	const { register, handleSubmit, errors, setValue} = useForm({});
	const [documentos, setDocumentos]= useState([]);
	const [enviarArchivos, setEnviarArchivos]= useState(false);
	const [activeKey, setActiveKey] = useState(1);
	const [actividad, setActividad] = useState({});

	useEffect(
		() => {
			if (planificacionId) {
				obtenerDatos();
			}
			if(actividadId) {
				obtenerActividad();
			}
		},
		[planificacionId]
	)

	async function obtenerDatos() {
		let resultado = await api.planificacion.get(planificacionId);
		if (resultado && resultado.status === 200) {
			setPlanificacion(resultado.data.datos)
			let planificacionTemp = resultado.data.datos;
			setDocumentos(resultado.data.datos.documentos);
			setValue([
				{ estado: planificacionTemp.estado || '' },
				{ riesgo: planificacionTemp.riesgo || '' },
				{ cumplimiento: planificacionTemp.cumplimiento || '' },
				{ descripcion_avance: planificacionTemp.descripcion_avance || '' },
			]);
		} else {
			cerrarModal();
			toast.error('Error al obtener datos');
		}
	}

    async function obtenerActividad() {
        let resultado = await api.actividad.get(actividadId);
        if (resultado && resultado.status === 200) {
            setActividad(resultado.data.datos)
        } else {
            cerrarModal();
            toast.error('Error al obtener datos de actividad');
        }
    }

	const cerrarModal = () => {
		setActiveKey(1)
		setPlanificacion({})
		cerrar()
	}

	async function guardar(data) {
		data.documentos = documentos;
		if (planificacion.id) {
			let resultado = await api.planificacion.put(actividadId, planificacion.id, data);
			if (resultado && resultado.status === 200) {
				setEnviarArchivos(true)
			} else {
				toast.error(resultado.data.msg);
			}
		}
	}

	return (
		<CModal id="modal-cargar-archivos" keyboard={false} visible={show} size='lg'>
			<CModalHeader onDismiss={cerrarModal}>Ejecución: {actividad.descripcion} - {mesTexto(planificacion.mes)}</CModalHeader>
			<CModalBody>
				<CNav variant="tabs">
					<CNavItem>
						<CNavLink
							href="javascript:void(0);"
							active={activeKey === 1}
							onClick={() => setActiveKey(1)}>
							Información
						</CNavLink>
					</CNavItem>
					<CNavItem>
						<CNavLink
							href="javascript:void(0);"
							active={activeKey === 2}
							onClick={() => setActiveKey(2)}>
							Verificadores
						</CNavLink>
					</CNavItem>
				</CNav>
				<CTabContent>
					<CTabPane
						role="tabpanel"
						aria-labelledby="home-tab"
						visible={activeKey === 1}>
							<CForm key={1} onSubmit={handleSubmit(guardar)}>
								<CRow className="mt-2">
									<CCol xs="12" sm="12" md="12" lg="6" className="mb-2">
										<CFormLabel>Estado</CFormLabel>
										<CFormSelect name="estado"
											ref={register({})}>
											<option value="No cumplido">No cumplido</option>
											<option value="Con retraso">Con retraso</option>
											<option value="Avances según lo planificado">Avances según lo planificado</option>
											<option value="">No seleccionado</option>
										</CFormSelect>
									</CCol>
									<CCol xs="12" sm="12" md="12" lg="4" className="mb-2">
										<CFormLabel>Riesgo</CFormLabel>
										<CFormSelect name="riesgo"
											ref={register({})}>
											<option value="Alto">Alto</option>
											<option value="Medio">Medio</option>
											<option value="Bajo">Bajo</option>
											<option value="">No seleccionado</option>
										</CFormSelect>
									</CCol>
								</CRow>
								<CRow>
									<CCol xs="12" sm="12" md="6" lg="4" className="mb-2">
										<CFormLabel htmlFor="nf-email">Cumplimiento acumulado</CFormLabel>
										<CFormControl
											type="number"
											name="cumplimiento"
											placeholder=""
											ref={register({
											})}
										/>
										<ErrorMessage errors={errors} name="cumplimiento" as="div" className="invalid-feedback" />
										<CFormLabel style={{fontSize: '14px', color: 'grey'}}>Número de actividades a la fecha</CFormLabel>
									</CCol>
									<CCol xs="4" sm="4" md="4" lg="2" className="mb-2" style={{paddingTop: '30px'}}>
										Meta: {actividad.meta}
									</CCol>
									<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
										<CFormLabel>Descripción del avance</CFormLabel>
										<CFormControl
											component="textarea"
											name="descripcion_avance"
											rows="3"
											ref={register({
												required: { value: true, message: "campo obligatorio" }
											})}
										></CFormControl>
										<ErrorMessage errors={errors} name="descripcion_avance" as="div" className="invalid-feedback" />
									</CCol>
								</CRow>
							</CForm>
					</CTabPane>
					<CTabPane
						role="tabpanel"
						aria-labelledby="profile-tab"
						visible={activeKey === 2}>
						<CRow className="mt-2">
							<CCol xs="12" sm="12" md="12" lg="12" className="mb-2">
								<CargarDocumento
									documentos={documentos} // documentos que ya tiene la entidad en base de datos
									enviar={enviarArchivos} // estado para indiciar que debe guardar documentos
									entidad_nombre={'Ejecución'}
									entidad_id={planificacion.id}
									enviado={() => { // cuando se enviaron todos los archivos
										setEnviarArchivos(false)
										toast.success('Cambios almacenados');
									}}
									cambioArchivos={(data) => {}}/>
							</CCol>
						</CRow>
					</CTabPane>
				</CTabContent>
			</CModalBody>
			<CModalFooter>
				<CButton
					color="light"
					onClick={cerrarModal}
				><FaTimes /> Cerrar</CButton>
				<CButton color="success" onClick={handleSubmit((d) => guardar(d))}><FaSave/> Guardar</CButton>{' '}
			</CModalFooter>
		</CModal>
	)
}

export default FormEjecucion