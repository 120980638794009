import React, { useState, useEffect } from 'react';
import { CCol, CRow, CFormLabel, CFormControl, CButton, CModal, CForm, CModalHeader, CModalBody, CModalFooter } from '@coreui/react-pro';
import { toast } from 'react-toastify';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { loading } from '../utils/Api';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import Fecha from '../componentes/Fecha';
import { fechaHoy } from '../utils/Funciones';
import { FaSave, FaTimes, FaTrashAlt } from 'react-icons/fa';
import SelectorAreaProtegidaTHRHF from './SelectorAreaProtegidaTHRHF';
import './CargarArchivos.css'
import VistaArchivos from '../componentes/VistaArchivos';
import { CLabel } from '@coreui/react';
import SelectorTipoInformacionTHRHF from './SelectorTipoInformacionTHRHF';
import ModalArchivosExistentes from './ModalArchivosExistentes';

const CargarDocumento = (props) => {
    const { register, handleSubmit: handleSubmit2, errors, setValue, getValues, reset, control } = useForm({
		mode: "onBlur",
	  });
    const [modal, setModal] = useState(false);
	const [documentos, setDocumentos] = useState([]);
    const [documentosEnviar, setDocumentosEnviar] = useState([]);
    const [documentoseExistentesEnviar, setDocumentosExistentesEnviar] = useState([]);
    const actionBase = {
        meta: '',
        file: '',
        remove: ''
    }
    const [action, setAction] = useState(actionBase);
    const [modalArchivoExistente, setModalArchivoExistente] = useState(false);


	useEffect(
		() => {
            // Documentos que ya están en base de datos, es cargado al inicio
            props.documentos.forEach(doc => {
                doc.estado = 'cargado'
            });
			setDocumentos(props.documentos)
		},
		[props.documentos]
	)

	useEffect(
		() => {
			// Cada vez que cambia un estado de los documentos devolvemos la info por si la necesitarán
            props.cambioArchivos(documentos)
		},
		[documentos]
	)

	useEffect(
		() => {
            // EStado para confirmar que se deben guardar los cambios
			if(props.enviar) {
                guardar_todos()
            }
		},
		[props.enviar]
	)

    const cerrarModal = () => {
        action.remove()
        setAction(actionBase)
        setModal(!modal);
        reset()
    }

    const handleChangeStatus = ({ meta, file, remove }, status) => {
        if (status === 'done') {
            setAction({
                meta: meta,
                file: file,
                remove: remove
            })
            setModal(true);
            setValue('nombre_documento', meta.name.split('.').slice(0, -1).join('.'))
        } else if (status === 'preparing') {
        } else if (status === 'error_upload') {
            toast.error(`Error al cargar archivo ${meta.name}`);
        }
    }

    async function guardarModal() {
        let info = Object.assign([], documentosEnviar);
        info.push({
            file: action.file,
            nombre: getValues().nombre_documento,
            descripcion:  getValues().descripcion,
            tipo_informacion: getValues().tipo_informacion.value,
            fecha: getValues().fecha,
            area_protegida_id: (getValues().area_protegida_id.value === 'general' ? '': getValues().area_protegida_id.value),
            mensaje: ''
        })
        setDocumentosEnviar(info)
        cerrarModal()
    }

    async function guardar_todos() {
        if (props.entidad_id === '') {
            toast.error(`Para cargar documentos debe ingresar entidad_id`);
            return false;
        }
        if (props.entidad_nombre === '') {
            toast.error(`Para cargar documentos debe ingresar entidad_nombre`);
            return false;
        }

        let documentosCopia = Object.assign([], documentos) // es una copia de documentos para alterar info o agregar
        // let documentosCopia = JSON.parse(JSON.stringify(documentos))

        let docELiminados = 0
        documentosCopia.forEach(doc => {
            if (doc.estado === 'eliminado') {
                docELiminados++;
            }
        });

        if (documentosEnviar.length > 0 || docELiminados > 0 || documentoseExistentesEnviar.length > 0) {
            loading(true);
            toast.info(`Enviando documentos, espere unos momentos`);

            const promises = documentosEnviar.length > 0 ?
                await new Promise((resolve, reject) => {
                    let respuestas = []
                    documentosEnviar.map(doc => {
                        let data = new Promise((resolve, reject) => {
                            let formData = new FormData();
                            formData.append("file", doc.file);
                            formData.append("nombre", doc.nombre);
                            formData.append("descripcion", doc.descripcion);
                            formData.append("tipo_informacion", doc.tipo_informacion);
                            formData.append("fecha", doc.fecha);
                            formData.append("area_protegida_id", doc.area_protegida_id);
                            formData.append("entidad_nombre", props.entidad_nombre);
                            formData.append("entidad_id", props.entidad_id);

                            const token = localStorage.getItem('token');
                            const ruta = axios.post(`${process.env.REACT_APP_API_URL}api2/documentos`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} });
                            ruta.then(respuesta => {
                                resolve({estado: 'listo', tipo: 'agregar', data: respuesta, tipo_carga: 'nuevo_documento'})
                            }).catch((error) => {
                                resolve({estado: 'error', tipo: 'agregar', data: doc, tipo_carga: 'nuevo_documento'})
                            })
                        })
                        respuestas.push(data)
                    });
                    resolve(respuestas)
                }) : []

            const promises2 = docELiminados > 0 ? await new Promise((resolve, reject) => {
                    let respuestas = []
                    documentosCopia.map(doc => {
                        if (doc.estado === 'eliminado') {
                            let data = new Promise((resolve, reject) => {
                                const token = localStorage.getItem('token');
                                const ruta = axios.delete(`${process.env.REACT_APP_API_URL}api2/documentos/${doc.id}?entidad_id=${props.entidad_id}&entidad_nombre=${props.entidad_nombre}`, { headers: {'Authorization': `Bearer ${token}`} });
                                ruta.then(respuesta => {
                                    resolve({estado: 'listo', tipo: 'eliminar', data: respuesta, documento: doc})
                                }).catch((error) => {
                                    resolve({estado: 'error', tipo: 'eliminar', documento: doc})
                                });
                            });
                            respuestas.push(data)
                        }
                    });
                    resolve(respuestas)
                }) : []


            const promises3 = documentoseExistentesEnviar.length > 0 ? await new Promise((resolve, reject) => {
                let respuestas = []
                documentoseExistentesEnviar.map(doc => {
                    // if (doc.estado === 'eliminado') {
                        let data = new Promise((resolve, reject) => {
                            const token = localStorage.getItem('token');
                            const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/documento-entidad?documento_id=${doc.id}&entidad_id=${props.entidad_id}&entidad_nombre=${props.entidad_nombre}`, {}, { headers: { 'Authorization': `Bearer ${token}`} });
                            ruta.then(respuesta => {
                                resolve({estado: 'listo', tipo: 'agregar', data: respuesta, tipo_carga: 'documento_existente'})
                            }).catch((error) => {
                                resolve({estado: 'error', tipo: 'agregar', documento: doc, tipo_carga: 'documento_existente'})
                            });
                        });
                        respuestas.push(data)
                    // }
                });
                resolve(respuestas)
                }) : []

            let respuestasPromise  = ''
            if (documentosEnviar.length > 0 && docELiminados > 0 && documentoseExistentesEnviar.length > 0) {
                respuestasPromise = await Promise.all([ ...promises, ...promises2, ...promises3 ])
            } else if (documentosEnviar.length > 0 && docELiminados > 0) {
                respuestasPromise = await Promise.all([ ...promises, ...promises2 ])
            } else if (documentosEnviar.length > 0 && documentoseExistentesEnviar.length > 0) {
                respuestasPromise = await Promise.all([ ...promises, ...promises3 ])
            } else if (docELiminados > 0 && documentoseExistentesEnviar.length > 0) {
                respuestasPromise = await Promise.all([ ...promises2, ...promises3 ])
            } else if (documentosEnviar.length > 0) {
                respuestasPromise = await Promise.all(promises)
            } else if (docELiminados > 0) {
                respuestasPromise = await Promise.all(promises2)
            } else if (documentoseExistentesEnviar.length > 0) {
                respuestasPromise = await Promise.all(promises3)
            }

            let nuevosEnviar = []
            let nuevosExistentesEnviar = []
            respuestasPromise.forEach(respuesta => {
                if (respuesta.tipo === 'agregar') {
                    if(respuesta.estado === 'listo') {
                        if(respuesta.tipo_carga === 'nuevo_documento') {
                            respuesta.data.data.data.estado = 'cargado'
                            documentosCopia.push(respuesta.data.data.data)
                        } else if (respuesta.tipo_carga === 'documento_existente') {
                            respuesta.data.data.data.estado = 'cargado'
                            documentosCopia.push(respuesta.data.data.data)
                        }
                    } else if (respuesta.estado === 'error') {
                        if(respuesta.tipo_carga === 'nuevo_documento') {
                            // solo agregamos los que tienen error
                            respuesta.data.mensaje = 'Error al guardar, intente nuevamente'
                            nuevosEnviar.push(respuesta.data)
                            toast.error(`Error al cargar archivo: ${respuesta.data.nombre}`, {autoClose:8000});
                        } else if (respuesta.tipo_carga === 'documento_existente') {
                            respuesta.documento.mensaje = 'Error al guardar, intente nuevamente'
                            nuevosExistentesEnviar.push(respuesta.documento)
                            toast.error(`Error al cargar archivo: ${respuesta.documento.nombre_documento}`, {autoClose:8000});
                        }
                    }
                } else if (respuesta.tipo === 'eliminar') {
                    if(respuesta.estado === 'listo') {
                        // debemos sacar el eliminado
                        let nuevosDocumentos = []
                        documentosCopia.forEach(docCargado => {
                            if (docCargado.id !== respuesta.documento.id) {
                                nuevosDocumentos.push(docCargado)
                            }
                        });
                        documentosCopia = nuevosDocumentos
                    } else if (respuesta.estado === 'error') {
                        // solo agregamos los que tienen error
                        // respuesta.documento.mensaje = 'Error al guardar- intente nuevamente'
                        documentosCopia.forEach(docTemp => {
                            if (docTemp.id === respuesta.documento.id) {
                                docTemp.estado = "eliminado"
                            }
                        });
                        toast.error(`Error al eliminar archivo: ${respuesta.documento.nombre_archivo}`, {autoClose:8000});
                    }
                }
            });
            loading(false);
            setDocumentos(documentosCopia); // todos los documentos cargados
            setDocumentosEnviar(nuevosEnviar) // reiniciamos el estado con los que se tienen que enviar de nuevo
            setDocumentosExistentesEnviar(nuevosExistentesEnviar) // reiniciamos el estado con los que se tienen que enviar de nuevo
            props.enviado();
        } else {
            props.enviado();
        }
    }

    function eliminarDocBD(doc) {
        let documentosCopia = Object.assign([], documentos)
        documentosCopia.forEach(docTemp => {
            if (doc.id === docTemp.id) {
                docTemp.estado = 'eliminado'
            }
        });
        setDocumentos(documentosCopia)
        toast.info(`Los cambios se verán reflejados cuando presione guardar`, {autoClose:5000});
    }

    function eliminarDocTemporal(doc) {
        let documentosCopia = Object.assign([], documentosEnviar)
        let nuevosDocumentos = []
        documentosCopia.forEach(docTemp => {
            if (doc.id !== docTemp.id) {
                nuevosDocumentos.push(docTemp)
            }
        });
        setDocumentosEnviar(nuevosDocumentos)
    }

    function eliminarDocTemporalExistente(doc) {
        let documentosCopia = Object.assign([], documentoseExistentesEnviar)
        let nuevosDocumentos = []
        documentosCopia.forEach(docTemp => {
            if (doc.id !== docTemp.id) {
                nuevosDocumentos.push(docTemp)
            }
        });
        setDocumentosExistentesEnviar(nuevosDocumentos)
    }

    return (
        <>
            <CRow>
                <CCol lg={6}>
                    <Dropzone
                        onChangeStatus={handleChangeStatus}
                        inputContent="Click para cargar documento"
                        maxFiles={1}
                        multiple={false}
                    />
                </CCol>
                <CCol lg={6}>
                    <CButton color="success" variant="outline" style={{border: '2px solid #2eb85c', width: '100%', fontWeight:600, fontSize:'14px', padding:'8px 3px 7px 3px'}} onClick={() => {
                        setModalArchivoExistente(true)
                    }}>
                        Seleccionar documentos del sistema
                    </CButton>
                </CCol>
            </CRow>
            <CRow className="mt-2">
                <ModalArchivosExistentes
                    show={modalArchivoExistente}
                    archivoSeleccionado={(archivo) => {
                        let documentosCopia = Object.assign([], documentoseExistentesEnviar)
                        documentosCopia.push(archivo)
                        setDocumentosExistentesEnviar(documentosCopia)
                        setModalArchivoExistente(false)
                    }}
                    close={() => {
                        setModalArchivoExistente(false)
                    }}/>
            </CRow>
            {documentoseExistentesEnviar.length > 0 &&
                <>
                    <CLabel><b>Documentos del sistema por guardar</b></CLabel>
                    {documentoseExistentesEnviar.map((item, index) => (
                        <CCol lg={12} key={index}>
                            {item.nombre_documento}
                            { item.mensaje &&
                                <>
                                - {item.mensaje}
                                </>
                            }
                            <CButton size="sm" style={{right: '0',color: 'white', marginLeft:'10px', padding: '1px 5px', marginLeft: '5px'}} color="danger"
                                onClick={() => eliminarDocTemporalExistente(item)}> <FaTrashAlt />
                            </CButton>
                        </CCol>
                    ))}
                </>
            }
            {documentosEnviar.length > 0 &&
                <>
                    <CLabel><b>Documentos pendientes por guardar</b></CLabel>
                    {documentosEnviar.map((item, index) => (
                        <CCol lg={12} key={index}>
                            {item.nombre}
                            { item.mensaje !== '' &&
                                <>
                                - {item.mensaje}
                                </>
                            }
                            <CButton size="sm" style={{right: '0',color: 'white', marginLeft:'10px', padding: '1px 5px'}} color="danger"
                                onClick={() => eliminarDocTemporal(item)}> <FaTrashAlt />
                            </CButton>
                        </CCol>
                    ))}
                </>
            }
            {documentos.length > 0 &&
                <CRow className="mt-2">
                    <CLabel><b>Documentos cargados</b></CLabel>
                    <VistaArchivos
                        documentos={documentos}
                        eliminar={eliminarDocBD}/>
                </CRow>
            }

            <CModal
				key={33}
                id="modal-cargar-archivos1234"
                keyboard={false}
                visible={modal}>
                <CModalHeader onDismiss={cerrarModal}>Complete la información del documento</CModalHeader>
                    <CModalBody>
                        <CForm key={2} onSubmit={handleSubmit2(guardarModal)}>
                            <CRow>
                                <CCol xs="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Nombre de archivo</CFormLabel>
                                    <CFormControl
                                        type="text"
                                        name="nombre_documento"
                                        placeholder=""
                                        ref={register({
                                            required: { value: true, message: "campo obligatorio" }
                                        })}
                                    />
                                    <ErrorMessage errors={errors} name="nombre_documento" as="div" className="invalid-feedback" />
                                </CCol>
                                <CCol xs="12" className="mb-2">
                                    <SelectorTipoInformacionTHRHF
                                        iniciar={modal}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        // opcionTodos={true}
                                        setValue={setValue}
                                        name='tipo_informacion'
                                        opcionGeneral={false}
                                        placeholder=''
                                        seleccionadoId={''} // id del elemento seleccionado
                                        // onChange={(seleccionado) => {
                                        //     console.log('seleccionado', seleccionado)
                                        // }}
                                        // cargado={(data) => {
                                        //     console.log(data)
                                        // }}
                                    />
                                </CCol>
                                <CCol xs="12" sm="12" md="12" className="mb-2">
                                    <SelectorAreaProtegidaTHRHF
                                        iniciar={modal}
                                        control={control}
                                        Controller={Controller}
                                        ErrorMessage={ErrorMessage}
                                        errors={errors}
                                        // opcionTodos={true}
                                        setValue={setValue}
                                        name='area_protegida_id'
                                        opcionGeneral={true}
                                        placeholder='No seleccionado'
                                        seleccionadoId={'general'} // id del elemento seleccionado
                                        // onChange={(seleccionado) => {
                                        // }}
                                        // cargado={(data) => {
                                        // }}
                                    />
                                </CCol>
                                <CCol xs="12" className="mb-2">
                                    <Fecha
                                        name='fecha'
                                        label='Fecha documento'
                                        register={register}
                                        errors={errors}
                                        dateFormat='dd/MM/yyyy'
                                        formatoMesAno={false}
                                        dateFormatOculto='y-m-d'
                                        inicial={fechaHoy()}
                                        onChange={() => {}}
                                    />
                                </CCol>
                                <CCol xs="12" md="12" className="mb-2">
                                    <CFormLabel htmlFor="nf-email">Descripción</CFormLabel>
                                    <CFormControl
                                        name="descripcion"
                                        component="textarea"
                                        rows="2"
                                        placeholder=""
                                        ref={register({})}
                                    />
                                    <ErrorMessage errors={errors} name="descripcion" as="div" className="invalid-feedback" />
                                </CCol>
                            </CRow>
						</CForm>
                    </CModalBody>
                    <CModalFooter>
                        <CButton
                            color="light"
                            onClick={cerrarModal}
                        ><FaTimes /> Cerrar</CButton>
                        <CButton color="success" onClick={handleSubmit2((d) => guardarModal(d))} ><FaSave/> Guardar</CButton>{' '}
                    </CModalFooter>
            </CModal>
        </>
    )
}

export default CargarDocumento