import React, { useEffect, useState, useMemo } from 'react';
import { CRow, CCol, CButton } from '@coreui/react-pro';
import ErpLayout from '../../layout/ErpLayout';
import api from '../../utils/Routes';
import { toast } from 'react-toastify';
import { GrillaBase, LongTextFormatter, NumberFormatter, separadorDeMiles, CenterAlign } from '../../componentes/GrillaBase';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useHistory } from "react-router-dom";
import Buscador from '../../componentes/Buscador'
import FiltroRegionesTH from '../../componentes/FiltroRegionesTH';
import { exportar } from '../../utils/Funciones';
import { FaRegFileExcel, FaEye } from 'react-icons/fa';


const GrillaResumenConcesion = () => {
  let history = useHistory();

  const columns = [
    {
      key: 'nombre', name: 'ASP', minWidth: 200,
      summaryFormatter() {
        return <div style={{ textAlign: 'right' }}><strong>Total</strong></div>;
      }
    },
    { key: 'nombre_region', name: 'Región', minWidth: 300 },
    {
      key: 'monto_total_pago_anual', name: 'Monto Total Pago Anual', formatter: NumberFormatter, width: 150,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.monto_total_pago_anual
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'monto_total_ultimo_pago', name: 'Monto Total Último Pago', formatter: NumberFormatter, width: 150,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.monto_total_ultimo_pago
        });
        return <div style={{ textAlign: 'right' }}>{separadorDeMiles(total)}</div>;
      }
    },
    {
      key: 'total_concesiones', name: 'Cantidad', formatter: CenterAlign, width: 40,
      summaryFormatter({ row }) {
        let total = 0;
        rows.forEach(data => {
          total += data.total_concesiones
        });
        return <div style={{ textAlign: 'center' }}>{separadorDeMiles(total)}</div>;
      }
    },
    { key: 'tipo_contratos', name: 'Tipo contrato', width: 150 },
  ];

  const [rows, setRows] = useState([]);
  const summaryRows = useMemo(() => {
    const summaryRow = [];
    return [summaryRow];
  }, [rows]);
  const [textoBuscar, setTextoBuscar] = useState('');
  const [regionId, setRegionId] = useState('');

  useEffect(
    () => {
      obtenerDatos()
    },
    [textoBuscar,regionId]
  )

  async function obtenerDatos() {
    let resultado = await api.concesion.get({ texto: textoBuscar, region_id: regionId });
    if (resultado && resultado.status === 200) {
      setRows(resultado.data.datos)
    } else {
      toast.error('Error al obtener datos');
    }
  }

  async function menuVisualizar(e, { rowIdx }) {
    if (rows[rowIdx]) {
      history.push(`/area-protegida/${rows[rowIdx].id}/concesiones`)
    } else {
      toast.error('Error al obtener la infraestructura');
    }
  }

  function exportarConcesion(){
    exportar(`api/exportar/resumen-asp?texto=${textoBuscar}&region_id=${regionId}`);
  }

  return (
    <>
      <ErpLayout
        breadcrumbs={[
          { active: true, pathname: '', name: 'Servicios Ecoturísticos' },
          { active: true, pathname: `/concesion`, name: 'Concesiones' }]
        }>
        <CRow className='mb-2' >
          <CCol xs="12" sm="6" md="5" >
            <Buscador
              titulo='Búsqueda'
              placeholder='Búsqueda por texto'
              retornoBusqueda={(text) => {
                setTextoBuscar(text);
              }}
            />
          </CCol>
          <CCol xs="12" sm="6" md="4" className="mb-2">
            <FiltroRegionesTH
              todos={true}
              onChange={(e) => {
                setRegionId(e ? e.value : '')
              }}
            />
          </CCol>
          <CCol md="3">
            <CButton
                color="light"
                onClick={exportarConcesion}
                className="mt-4 float-sm-end mx-1"
                ><FaRegFileExcel />
                    Exportar
            </CButton>
        </CCol>
        </CRow>

        <GrillaBase
          className="mt-2"
          menu={<ContextMenu id="grid-context-menu">
            <MenuItem onClick={menuVisualizar}><FaEye/> Ver Concesiones</MenuItem>
          </ContextMenu>}
          rows={rows}
          columns={columns}
          summaryRows={summaryRows}

        />

      </ErpLayout>
    </>
  )
}

export default GrillaResumenConcesion