import React, {useContext, useEffect, useState} from 'react';
import {
    Route, Redirect
  } from "react-router-dom";
import UserContext from '../Contexto'
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Cargando from '../modulos/auth/Cargando'
import {permiso} from '../utils/Funciones'

function PrivateRoute ({component: Component, ...rest}) {

  const contexto = useContext(UserContext)
  let history = useHistory();
  let [rutas, setRutas] = useState(<Cargando/>);

  useEffect(
    () => {
      var token = localStorage.getItem('token');
      if (token !== null && token !== '') {
        if (contexto.data.usuario === undefined) {
          getUsuario(rest.path) // info y permisos del usuario
        }
      } else {
        history.push('/login')
      }
    },
    []
  )

  useEffect(
    () => {
        var token = localStorage.getItem('token');
        if (token !== null && token !== '') {
          if (contexto.data.usuario !== undefined) {
            let respuesta = false;
            if(contexto.data.usuario.administrador === true) {
              respuesta = true;
            } else { // no es administrador
              if (rest.modulo === 'Acceso libre') {
                respuesta = true;
              } else if (rest.administrador !== undefined && rest.administrador === true) { // la ruta no es de administración
                respuesta = false
              } else { // rutas normales
                if (rest.modulo !== undefined && contexto.data.usuario !== undefined && contexto.data.usuario.permisos !== undefined) {
                    respuesta = permiso( contexto, rest.modulo, 'ver')
                  }
              }
            }
            if (respuesta === true) {
              setRutas(<Component {...rest} />)
            } else {
              history.push('/dashboard');
            }
          }
        } else {
          history.push('/login')
        }
    },
    [contexto.data, Component]
  )

  async function getUsuario(path) {
    let resultado = await api.usuarios.getOneToken({});
    if (resultado && resultado.status === 200) {
      contexto.setData({usuario: resultado.data.datos})
    } else {
      toast.error('Sesión expirada, vuelva a iniciar sesión.');
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
          return rutas
      }}
    />
  )
}

export default PrivateRoute;