import React, { useState, useEffect} from 'react';
import api from '../utils/Routes';
import { toast } from 'react-toastify';
import {
    CFormLabel,
    CFormSelect

} from '@coreui/react-pro';

function SelectorTipoInformacion(props) {
    const { cargado, todos, onChange, ...rest } = props;
    const [tipoInformacion, setTipoInformacion] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(
        () => {
            function loadData() {
                api.tipoInformacion.get().then(
                    resultado => {
                        if (resultado && resultado.status === 200) {
                            setTipoInformacion(resultado.data.datos)
                            if (cargado) {
                                cargado(resultado.data.datos)
                            }
                        } else {
                            setTipoInformacion([]);
                            toast.error('Error al cargar');
                        }
                    }
                )

            }
            if (tipoInformacion.length === 0 && loading === false) { // Loading = false, solo para cargar una vez
                setLoading(true);
                loadData();
            }
        },
        [tipoInformacion, cargado, loading]
    )

    return (
        <>
            {props.onChange ?
                <>
                    <CFormLabel htmlFor="appendedInputButton">Tipo de información</CFormLabel>
                    <CFormSelect custom name={rest.name} id="ccmonth"
                        onChange={onChange}
                        ref={rest.register({
                            required: { value: true, message: "campo obligatorio" }
                        })}>
                        {todos && <option value="">Todos</option>}
                        {tipoInformacion.map((tipo) =>
                            <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                        )}
                    </CFormSelect>
                    {rest.errors[rest.name] && <div className="invalid-feedback-manual" style={{ width: '100%', marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>Selecciona una opción</div>}
                </>
            :
                <>
                    <CFormLabel htmlFor="appendedInputButton">Tipo de información</CFormLabel>
                    <CFormSelect custom name={rest.name} id="ccmonth"
                        ref={rest.register({
                            required: { value: true, message: "campo obligatorio" }
                        })}>
                        {todos && <option value="">Todos</option>}
                        {tipoInformacion.map((tipo) =>
                            <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                        )}
                    </CFormSelect>
                    {rest.errors[rest.name] && <div className="invalid-feedback-manual" style={{ width: '100%', marginTop: '.25rem', fontSize: '80%', color: '#dc3545' }}>Selecciona una opción</div>}
                </>
            }
        </>
    );
}

export default SelectorTipoInformacion;