import React, {useState} from 'react'
import { CCol, CRow, CWidgetDropdown, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CAlert } from '@coreui/react-pro';
import {CChartLine} from '@coreui/react-chartjs';
import CIcon from '@coreui/icons-react';
import ErpLayout from '../layout/ErpLayout';
import { getStyle } from '@coreui/utils'

const Dashboard = () => {

  const [img_src, newSrc] = useState("dashboard2.png")

  function changeImg(e) {
    if (img_src == "dashboard2.png"){
      newSrc("dashboard.png")
    } else {
      newSrc("dashboard2.png")
    }
  }

//   function grafico(data) {
//       return
//   }

  return (
    <ErpLayout>
    <CRow style={{marginTop: '20px'}}>
        <CCol xs="12" sm="12" md="12">
            <CAlert color="info">
                Bienvenidos a VIGIA - Sistema de información del SNASPE
            </CAlert>
        </CCol>
    </CRow>
      <CRow style={{marginTop: '20px'}}>
        <Graficar region='Arica' porcentaje='80' color='gradient-primary'/>
        <Graficar region='Tarapaca' porcentaje='60' color='gradient-info' />
        <Graficar region='Antofagasta' porcentaje='30' color='gradient-success' />
        <Graficar region='Atacama' porcentaje='45' color='gradient-warning' />
        <Graficar region='Coquimbo' porcentaje='70' color='gradient-danger' />
        <Graficar region='Valparaiso' porcentaje='60' color='gradient-primary' />
        <Graficar region='Metropolitana' porcentaje='90' color='gradient-dark' />
        <Graficar region="O'Higgins" porcentaje='100' color='gradient-success' />
        <Graficar region='Maule' porcentaje='25' color='gradient-warning' />
        <Graficar region='Bio Bio' porcentaje='40' color='gradient-danger' />
        <Graficar region='Araucania' porcentaje='20' color='gradient-primary' />
        <Graficar region='Los Ríos' porcentaje='100' color='gradient-info' />
        {/* <CCol xs="12" sm="12" md="12">
            <CImg
            src= {img_src}
            fluid
            className="mb-2"
            onClick={changeImg}
            style={{width:'100%'}}></CImg>
        </CCol> */}

      </CRow>
    </ErpLayout>
  )
}

export function Graficar(data) {

    function color(porcentaje) {
        if (porcentaje > 80 && porcentaje <= 100) {
            return 'success-gradient'
        } if (porcentaje > 50 && porcentaje <= 80) {
            return 'primary-gradient'
        } else if (porcentaje > 40 && porcentaje <= 50) {
            return 'info-gradient'
        } else if (porcentaje > 30 &&  porcentaje <= 40) {
            return 'warning-gradient'
        } else if (porcentaje > 20 && porcentaje <= 30) {
            return 'danger-gradient'
        } else if (porcentaje >= 0 && porcentaje <= 20) {
            return 'dark-gradient'
        } else {
            return 'info-gradient'
        }
    }
    return <CCol sm="6" lg="3">
        <CWidgetDropdown
            className="mb-4"
            // color="primary-gradient"
            color={
                color(data.porcentaje)
            }
            value={data.porcentaje + '%'}
            title={data.region}
            action={
                <CDropdown alignment="end">
                    <CDropdownToggle color="transparent" caret={false} className="p-0">
                        <CIcon name="cil-options" className="text-high-emphasis-inverse" />
                    </CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem>Action</CDropdownItem>
                        <CDropdownItem>Another action</CDropdownItem>
                        <CDropdownItem>Something else here...</CDropdownItem>
                        <CDropdownItem disabled>Disabled action</CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            }
            chart={
                <CChartLine
                    className="mt-3 mx-3"
                    style={{ height: '70px' }}
                    data={{
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                        datasets: [
                            {
                                label: 'My First dataset',
                                backgroundColor: 'transparent',
                                borderColor: 'rgba(255,255,255,.55)',
                                pointBackgroundColor: getStyle('--cui-primary'),
                                data: [65, 59, 84, 84, 51, 55, 40],
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                            y: {
                                min: 30,
                                max: 89,
                                display: false,
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    display: false,
                                },
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 1,
                                tension: 0.4,
                            },
                            point: {
                                radius: 4,
                                hitRadius: 10,
                                hoverRadius: 4,
                            },
                        },
                    }}
                />
            }
        />
        {/* <CWidgetDropdown
            color={
                color(data.porcentaje)
            }
            header={<h1 style={{fontWeight:700}}>{data.porcentaje + '%'}</h1>}
            text={<>
                <h2>{data.region}</h2><p style={{margin: '0px', width:'100%'}}>Indicadores completados</p>
            </>}
            footerSlot={
                <>
                    <CChartLine
                        datasets={[
                            {
                                label: 'Indicadores completados',
                                yAxisID: false,
                                backgroundColor: 'white',
                                data: [30, 39, 10, 50, 30, 60, 35],
                                fill: false, // color fondo
                                showLine: true,
                                spanGaps: false,
                                stepped: false,
                                pointBackgroundColor: 'white',
                                pointBorderColor: 'white',
                                pointBorderWidth: 7,
                                borderColor: 'white',
                                borderWidth: 1,
                            },
                        ]}
                        options={{
                            layout: {
                                padding: {
                                    left: 20,
                                    right: 20,
                                    top: 10,
                                    bottom: 10
                                }
                            },
                            tooltips: {
                                enabled: false
                            },
                            legend: {
                                display: false,
                                labels: {
                                    enabled: false,
                                    fontColor: "white",
                                    fontSize: 15
                                }
                            },
                            scales:
                            {
                                yAxes: [{
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        display: false,
                                        // max: 70
                                    }
                                }],
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    },
                                    ticks: {
                                        display: false,
                                        // max: 70
                                    }
                                }]
                            },
                        }}
                    />
                </>
            }
        >
            <CDropdown>
                <CDropdownToggle color="transparent">
                    <CIcon name="cil-settings" />
                </CDropdownToggle>
                <CDropdownMenu className="pt-0" placement="bottom-end">
                    <CDropdownItem>Action</CDropdownItem>
                    <CDropdownItem>Another action</CDropdownItem>
                    <CDropdownItem>Something else here...</CDropdownItem>
                    <CDropdownItem disabled>Disabled action</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </CWidgetDropdown> */}
    </CCol>
}

export default Dashboard