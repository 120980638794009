import React, {useContext, useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { FaRegBuilding, FaStream, FaRegMoneyBillAlt, FaRegImage, FaUsers, FaList,FaHandsHelping,FaHotel, FaFlask } from 'react-icons/fa'
import UserContext from '../Contexto'
import {permiso, permisoClass} from '../utils/Funciones'

import {
  CCreateNavItem
} from '@coreui/react-pro'

const Nav = () => {
  const [xx, setxx] = useState([]);
  const contexto = useContext(UserContext);

  useEffect(
    () => {
      let clase = '';
      // if (contexto.data.usuario !== undefined && contexto.data.usuario.administrador === false) {
      //   clase = 'ocultar-menu';
      // }

      setxx([
        {
          _component: 'CNavTitle',
          anchor: 'Tipo de información',
        },
        // {
        //   _component: 'CNavGroup',
        //   as: NavLink,
        //   anchor: 'Información Base',
        //   to: '/to',
        //   icon: <BsInfoSquare className="nav-icon" />,
        //   items: [
        //     {
        //       _component: 'CNavItem',
        //       as: NavLink,
        //       anchor: 'Informes justificatorios',
        //       to: '/dashboard',
        //     },
        //     {
        //       _component: 'CNavItem',
        //       as: NavLink,
        //       anchor: 'Decretos de creación',
        //       to: '/dashboard',
        //     },
        //     {
        //       _component: 'CNavItem',
        //       as: NavLink,
        //       anchor: 'Cartografía',
        //       to: '/dashboard',
        //     },
        //   ],
        // },
        {
          _component: 'CNavGroup',
          anchor: 'Administración',
          icon: <FaRegBuilding className="nav-icon"/>,//<CIcon name="cil-building" customClasses="nav-icon" />,
          className: (permiso(contexto, 'Usuarios', 'ver') || permiso(contexto, 'Áreas protegidas', 'ver') || permiso(contexto, 'Personal', 'ver') || permiso(contexto, 'Tipo de área', 'ver')) ? '' : 'ocultar-menu',
          items: [
            {
                _component: 'CNavItem',
                as: NavLink,
                anchor: 'Usuarios',
                to: '/usuarios',
                className: permisoClass(contexto, 'Usuarios', 'ver'),
            },
            {
                _component: 'CNavItem',
                as: NavLink,
                anchor: 'ASP',
                to: '/asp',
                className: permisoClass(contexto, 'Áreas protegidas', 'ver'),
            },
            {
                _component: 'CNavItem',
                as: NavLink,
                anchor: 'Personal',
                to: '/previa-personal',
                className: permisoClass(contexto, 'Personal', 'ver'),
            },
            {
                _component: 'CNavItem',
              as: NavLink,
              anchor: 'Tipo de área',
              to: '/tipo-area',
              className: permisoClass(contexto, 'Tipo de área', 'ver'),
            },
          ],
        },
        {
          _component: 'CNavGroup',
          anchor: 'Planificación',
          icon: <FaStream className="nav-icon" />,
          className: (permiso(contexto, 'Plan operativo anual', 'ver') || permiso(contexto, 'Reporte estrategias', 'ver') || permiso(contexto, 'Reporte evaluación', 'ver')) ? '' : 'ocultar-menu',
          items: [
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Plan operativo anual',
              to: '/poa',
              className: permisoClass(contexto, 'Plan operativo anual', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte estrategias',
              to: '/poa/reporte-estrategias',
              className: permisoClass(contexto, 'Reporte estrategias', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte evaluación',
              to: '/poa/reporte-evaluacion',
              className: permisoClass(contexto, 'Reporte evaluación', 'ver'),
            },
          ],
        },
        {
          _component: 'CNavGroup',
          anchor: 'Visitación',
          icon: <FaUsers className="nav-icon" />,
          className: (permiso(contexto, 'Datos', 'ver') || permiso(contexto, 'Mensual', 'ver') || permiso(contexto, 'Anual', 'ver') || permiso(contexto, 'Gráfico Anual', 'ver')) ? '' : 'ocultar-menu',
          items: [{
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Datos',
              to: '/visitacion-datos',
              className: permisoClass(contexto, 'Datos', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Mensual',
              to: '/visitacion-mensual',
              className: permisoClass(contexto, 'Mensual', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Anual',
              to: '/visitacion-anual',
              className: permisoClass(contexto, 'Anual', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Gráfico Anual',
              to: '/visitacion-grafico-anual',
              className: permisoClass(contexto, 'Gráfico Anual', 'ver'),
            },
          ]
        },
        {
          _component: 'CNavGroup',
          anchor: 'Lista Verde',
          icon: <FaList className="nav-icon" />,
          className: permiso(contexto, 'Listado evaluaciones', 'ver') ? '' : 'ocultar-menu',
          items: [
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Listado evaluaciones',
              to: '/encuestas/1',
              className: permisoClass(contexto, 'Listado evaluaciones', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Gŕafico de cumplimiento',
              to: '/encuestas/1/grafico-cumplimiento',
              className: permisoClass(contexto, 'Listado evaluaciones', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Gŕafico indicadores cumplen',
              to: '/encuestas/1/grafico-indicadores',
              className: permisoClass(contexto, 'Listado evaluaciones', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Gŕafico indicadores de fase',
              to: '/encuestas/1/grafico-indicadores-fase',
              className: permisoClass(contexto, 'Listado evaluaciones', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Gŕafico por componente',
              to: '/encuestas/1/grafico-cumplimiento-componente',
              className: permisoClass(contexto, 'Listado evaluaciones', 'ver'),
            },
          ]
        },
        {
          _component: 'CNavGroup',
          anchor: 'Permisos Ecoturísticos',
          icon: <FaHandsHelping className="nav-icon" />,
          className: (permiso(contexto, 'Concesiones', 'ver') || permiso(contexto, 'Reporte por ASP', 'ver') || permiso(contexto, 'Reporte servicio ecoturístico', 'ver') || permiso(contexto, 'Reporte por tipo contrato', 'ver') || permiso(contexto, 'Mapa', 'ver')) ? '' : 'ocultar-menu',
          items: [
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Concesiones',
              to: '/concesiones',
              className: permisoClass(contexto, 'Concesiones', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por ASP',
              to: '/concesion',
              className: permisoClass(contexto, 'Reporte por ASP', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte servicio ecoturístico',
              to: '/servicio-ecoturistico',
              className: permisoClass(contexto, 'Reporte servicio ecoturístico', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por tipo contrato',
              to: '/tipo-contrato',
              className: permisoClass(contexto, 'Reporte por tipo contrato', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Mapa',
              to: '/concesiones/mapa',
              className: permisoClass(contexto, 'Mapa', 'ver'),
            },
          ]
        },
        {
          _component: 'CNavGroup',
          anchor: 'Infraestructura',
          icon: <FaHotel className="nav-icon" />,
          className: (permiso(contexto, 'Listado Infraestructura', 'ver') || permiso(contexto, 'Reporte por unidad ASP', 'ver') || permiso(contexto, 'Reporte por Región', 'ver') || permiso(contexto, 'Reporte por Tipo Instalación', 'ver') ||  permiso(contexto, 'Mapa Infraestructura', 'ver')) ? '' : 'ocultar-menu',
          items: [
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Listado Infraestructura',
              to: '/infraestructuras',
              className: permisoClass(contexto, 'Listado Infraestructura', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por unidad ASP',
              to: '/infraestructurasAsp',
              className: permisoClass(contexto, 'Reporte por unidad ASP', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por región',
              to: '/infraestructuras-reporte-region',
              className: permisoClass(contexto, 'Reporte por Región', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por Tipo',
              to: '/infraestructuras-reporte-tipo',
              className: permisoClass(contexto, 'Reporte por Tipo Instalación', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Mapa',
              to: '/infraestructuras/mapa',
              className: permisoClass(contexto, 'Mapa Infraestructura', 'ver'),
            },
          ]
        },
        {
          _component: 'CNavGroup',
          anchor: 'Investigación',
          icon: <FaFlask className="nav-icon" />,
          className: (permiso(contexto, 'Listado investigación', 'ver')) || permiso(contexto, 'Reporte por Asp', 'ver') ? '' : 'ocultar-menu',
          items: [
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Listado investigación',
              to: '/investigacion',
              className: permisoClass(contexto, 'Listado investigación', 'ver'),
            },
            {
              _component: 'CNavItem',
              as: NavLink,
              anchor: 'Reporte por Asp',
              to: '/investigacion-asp',
              className: permisoClass(contexto, 'Reporte por Asp', 'ver'),
            },
          ]
        },
      ])
    },
    [contexto.data]
  )

  return (<CCreateNavItem items={xx} />)
}

export default Nav